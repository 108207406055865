import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Events from "./Events";
import CalendarView from "./components/CalendarView.jsx";
import CalendarItem from "./components/CalendarItem.jsx";
import Spinner from "../components/Spinner/Spinner";
import { animateScroll as scroll } from "react-scroll";
import moment from "moment";
import getSuggestions, { getPlaceLatLong } from "./functions/getSuggestions";
import debounce from "lodash/debounce";
import "../components/scss/breadcrumbs.scss";
import Filter from "./components/Filter.jsx";
import { getMainFilters } from "./functions/eventFilters";
import { Helmet } from "react-helmet";
import GeneratedApi from "../components/functions/generatedApi";

const currentYear = moment().year();
const nextYear = moment().year() + 1;
const currentMonth = 1 + moment().month();

export default class EventsContainer extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      searchInput: "",
      upcomingEvents: 0,
      monthlyEvents: [],
      newEvents: [],
      start: 0,
      limit: 5,
      count: 0,
      loading: true,
      searchResults: [],
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      selectedAllEventTypes: true,
      calendarChanged: false,
      focusedInput: null,
      focusedInputMobile: false,
      focusedInputTablet: false,
      limitReached: false,
      moreFilters: false,
      dataLoaded: false,
      firstLoad: false,
      increasing: 0,
      modal: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.getEventsData();
    scroll.scrollToTop();
  }

  handleInput({ target: { value, name } }) {
    this.setState({ [name]: value });
  }

  getEventsData = debounce(() => {
    if (!this._isMounted) return false;

    this.setState({ loading: true });

    GeneratedApi("", false, false)
      .get("/events/calendarView")
      .then((response) => {
        const { upcomingEvents, monthlyEvents, newEvents } = response.data;
        if (this._isMounted) {
          this.setState(
            {
              upcomingEvents,
              monthlyEvents,
              newEvents,
              loading: false,
            },
            scroll.scrollToTop
          );
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 429) {
            console.log("limit reached");
            this._isMounted && this.setState({ limitReached: true });
            return false;
          }
        }
      });
  }, 300);
  // added
  loadData(data) {
    const filters = data && data.filters;
    this.setState(
      Object.assign(
        { dataLoaded: true },
        {
          limit: this.state.limit,
          filters:
            filters && filters.length > 0 ? [...getMainFilters(filters), ...filters.filter((filter) => !filter.mainAttribute)] : getMainFilters(),
        }
      )
    );
    filters && this.props.history.replace({ ...this.props.location, state: undefined });
  }
  handleMoreFilters = (moreFilters) => {
    this.setState({ moreFilters });
  };
  onCalendarFocusChangeMobile = (focusedInputMobile) => {
    this.setState({ focusedInputMobile });
  };
  onDatesChange = ({ startDate: dateEnd, endDate: dateStart }) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && (filter.attribute === "dateStart" || filter.attribute === "dateEnd")
          ? {
              ...filter,
              useFilter: true,
              value:
                filter.attribute === "dateStart"
                  ? dateStart
                    ? dateStart.endOf("day").format()
                    : null
                  : dateEnd
                  ? dateEnd.startOf("day").format()
                  : null,
            }
          : filter
      ),
    }));
  };
  onDateChangeStart = (dateStart) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "dateStart"
          ? {
              ...filter,
              useFilter: true,
              value: filter.attribute === "dateStart" ? dateStart.endOf("day").format() : null,
            }
          : filter
      ),
    }));
  };
  onDateChangeEnd = (dateEnd) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "dateEnd"
          ? {
              ...filter,
              useFilter: true,
              value: filter.attribute === "dateEnd" ? dateEnd.endOf("day").format() : null,
            }
          : filter
      ),
    }));
  };
  handleSelectedEventTypes = ({ target: { checked, name } }) => {
    this.setState(({ filters, selectedAllEventTypes }) => ({
      selectedAllEventTypes: !checked ? false : selectedAllEventTypes,

      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: checked
                ? filter.value.includes(name)
                  ? filter.value
                  : filter.value.concat([name])
                : filter.value.filter((item) => item !== name),
            }
          : filter
      ),
    }));
  };
  handleSelectedAll = ({ target: { checked } }) => {
    this.setState(({ filters }) => ({
      selectedAllEventTypes: checked,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: checked ? filter.values : [],
            }
          : filter
      ),
    }));
  };
  handleSelectedSegments = ({ target: { checked, name } }) => {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: checked
                ? filter.value.includes(name)
                  ? filter.value
                  : filter.value.concat([name])
                : filter.value.filter((item) => item !== name),
            }
          : filter
      ),
    }));
  };
  handleSelectAllSegments = () => {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: ["IoT", "AI", "VR/AR", "Blockchain"],
            }
          : filter
      ),
    }));
  };
  handleLocationChange = async (value, { name }) => {
    const currentCoordinates = value && (await getPlaceLatLong(value.value));

    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === name
          ? {
              ...filter,
              currentCoordinates,
              useFilter: true,
              value: value,
            }
          : filter
      ),
    }));
  };
  handleShowAllEvents = () => {
    this.setState(({ count, filters }) => ({
      limit: 10,
      selectedAllEventTypes: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: ["Webinar", "Conference", "Networking", "Hackathon", "Course", "Exhibition", "Panel", "Fair", "Startup"],
            }
          : filter
      ),
    }));
  };
  handleLocationInput(input, { action }) {
    if (action === "input-change" && input.length > 0) {
      debounce(() => getSuggestions(input).then((locationOptions) => this.setState({ locationOptions })), 500)();
    }
  }
  // finished
  componentDidMount() {
    this._isMounted = true;
    this.loadData(this.props.location.state);
    this.getEventsData();
  }
  handleSelectLocation = async (value) => {
    const currentCoordinates = value && (await getPlaceLatLong(value.value));

    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "Location"
          ? {
              ...filter,
              currentCoordinates,
              useFilter: true,
              value: value,
            }
          : filter
      ),
    }));
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleFilterCheckbox = async (event) => {
    const target = event.target;
    // const newArrayValue = {attribute: target.placeholder, operator: target.name, value: target.value}
    const keyId = target.id;
    if (target.checked) {
      if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount + 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount + 1 });
      }

      await this.setState((prevState) => ({
        checkedFilter: [
          ...prevState.checkedFilter,
          {
            _id: keyId,
            checked: {
              attribute: target.placeholder,
              operator: target.name,
              value: target.value,
            },
          },
        ],
      }));
    } else {
      if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount - 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount - 1 });
      }

      await this.setState((prevState) => ({
        checkedFilter: prevState.checkedFilter.map((obj) => obj._id !== keyId && obj).filter(Boolean),
      }));
    }
    await this.setState({
      filtersArray: [
        ...this.state.filters,
        ...this.state.checkedFilter.map((obj) => ({
          attribute: obj.checked.attribute.toLowerCase(),
          mainAttribute: true,
          operator: obj.checked.operator.toLowerCase(),
          operators: [obj.checked.operator],
          useFilter: true,
          value: obj.checked.value.toLowerCase(),
        })),
      ],
    });
    this.getEventsData();
  };
  handleClearAllFilters = async () => {
    await this.setState({
      filtersArray: [],
      checkedFilter: [],
      technologiesCount: 0,
      verticalsCount: 0,
    });
    this.getEventsData();
  };

  render() {
    const {
      searchInput,
      monthlyEvents,
      newEvents,
      upcomingEvents,
      loading,
      filters,
      moreFilters,
      count,
      limit,
      locationOptions,
      focusedInput,
      focusedInputMobile,
      calendarChanged,
      selectedAllEventTypes,
      dataLoaded,
    } = this.state;
    if (!dataLoaded) return false;

    const selectedEventTypes = filters.find((filter, i) => filter.attribute === "eventType" && filter.mainAttribute);

    const selectedSegments = filters.find((filter, i) => filter.attribute === "eventSegment" && filter.mainAttribute);

    const locationFilter = filters.find((filter, i) => filter.attribute === "Location" && filter.mainAttribute).value;

    const dateStart = filters.find((filter, i) => filter.attribute === "dateStart" && filter.mainAttribute).value;

    const dateEnd = filters.find((filter, i) => filter.attribute === "dateEnd" && filter.mainAttribute).value;

    const linkLocation = this.props.location.state && this.props.location.state;
    const filtersLink = linkLocation && linkLocation.filters ? linkLocation.filters : filters;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Events Calendar</title>
        </Helmet>
        <div className="container p-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Events Calendar
              </li>
            </ol>
          </nav>
        </div>
        <Events handleInput={this.handleInput} searchInput={searchInput} handleSearchSubmit={this.handleSearchSubmit}>
          <Filter
            data={{
              moreFilters,
              dateStart: dateEnd ? moment(dateEnd) : null,
              dateEnd: dateStart ? moment(dateStart) : null,
              selectedEventTypes,
              selectedSegments,
              locationFilter,
              locationOptions,
              focusedInput,
              focusedInputMobile,
              calendarChanged,
              selectedAllEventTypes,
              filters,
              count,
              limit,
              loading,
              filtersLink,
            }}
            handleClearAllFilters={this.handleClearAllFilters}
            handleFilterCheckbox={this.handleFilterCheckbox}
            handleClearFilter={this.handleClearFilter}
            verticalsCount={this.state.verticalsCount}
            technologiesCount={this.state.technologiesCount}
            checkedFilter={this.state.checkedFilter}
            handleInput={this.handleInput}
            searchInput={searchInput}
            handleSearchSubmit={this.handleSearchSubmit}
            handleMoreFilters={this.handleMoreFilters}
            onCalendarFocusChangeMobile={this.onCalendarFocusChangeMobile}
            onDatesChange={this.onDatesChange}
            onDateChangeStart={this.onDateChangeStart}
            onDateChangeEnd={this.onDateChangeEnd}
            handleSelectedEventTypes={this.handleSelectedEventTypes}
            handleSelectedAll={this.handleSelectedAll}
            handleSelectedSegments={this.handleSelectedSegments}
            handleSelectAllSegments={this.handleSelectAllSegments}
            handleLocationChange={this.handleLocationChange}
            handleShowAllEvents={this.handleShowAllEvents}
            handleLocationInput={this.handleLocationInput}
            handleSelectLocation={this.handleSelectLocation}
          />
          {loading ? (
            <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />
          ) : (
            <CalendarView upcomingEvents={upcomingEvents} newEvents={newEvents}>
              {monthlyEvents[currentYear] &&
                Object.keys(monthlyEvents[currentYear]).map(
                  (key, i) =>
                    key >= currentMonth && (
                      <CalendarItem
                        data={{
                          ...monthlyEvents[currentYear][key],
                          month: {
                            name: moment()
                              .month(key - 1)
                              .format("MMMM"),
                            monthStart: moment()
                              .month(key - 1)
                              .startOf("month")
                              .format("YYYY-MM-DD"),
                            monthEnd: moment()
                              .month(key - 1)
                              .endOf("month")
                              .format("YYYY-MM-DD"),
                          },
                        }}
                        key={i}
                      />
                    )
                )}
              {monthlyEvents[nextYear] &&
                Object.keys(monthlyEvents[nextYear]).map(
                  (key, i) =>
                    key < currentMonth && (
                      <CalendarItem
                        data={{
                          ...monthlyEvents[nextYear][key],
                          month: {
                            name: moment()
                              .month(key - 1)
                              .format("MMMM"),
                            monthStart: moment()
                              .add(1, "years")
                              .month(key - 1)
                              .startOf("month")
                              .format("YYYY-MM-DD"),
                            monthEnd: moment()
                              .add(1, "years")
                              .month(key - 1)
                              .endOf("month")
                              .format("YYYY-MM-DD"),
                          },
                        }}
                        key={i}
                      />
                    )
                )}
            </CalendarView>
          )}
        </Events>
      </>
    );
  }
}
