import React, { Component } from "react";
import getEventTypeIcon from '../functions/getEventTypeIcon'
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import ClearButton from "../../components/ClearButton/ClearButton";
import cx from 'classnames'
import './ListView.scss'
import {Helmet} from "react-helmet";

const CustomOption = ({ innerProps, isDisabled, data:{label, longLabel} }) =>
  !isDisabled ? (
    <div className="option" {...innerProps}>
      <div className="label">
        {label}
      </div>
      <div className="long-label">
        {longLabel}
      </div>
    </div>
  ) : null;

export default class ListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
      activeIndex: 0,
      categories: false,
      type: false,
      location: false,
      date: false,
      goBackFilter: false,
      focused: false,
      focusedEnd: false
    };
  }
  handleBackMainFilter = () => {
    
    this.setState({
      categories: false,
      type: false,
      location: false,
      date: false,
      goBackFilter: false,
      dropDown: true
    })
  }
  toggleNavbar = () => {
    if(!this.state.categories && !this.state.type && !this.state.location && !this.state.date){
      this.setState({
        dropDown: !this.state.dropDown
      });
    }
    
  }
  handleCloseFilter = () => {
    this.setState({ 
      dropDown: false,
      categories: false,
      type: false,
      location: false,
      date: false,
      goBackFilter: false,
    });
  }
  handleCategories = () => {
    this.setState({ 
      categories: !this.state.categories,
      goBackFilter: true,
    });
  }
  handleType = () => {
    this.setState({ 
      type: !this.state.type,
      goBackFilter: true,
    });
  }
  handleLocation = () => {
    this.setState({ 
      location: !this.state.location,
      goBackFilter: true,
    });
  }
  handleDate = () => {
    this.setState({ 
      date: !this.state.date,
      goBackFilter: true,
    });
  }
  
  render() {
    
    const {
      handleSelectedSegments,
      handleClearAllSegments,
      handleSelectAllSegments,
      handleSelectedAll,
      handleSelectedEventTypes,
      onCalendarFocusChange,
      onDatesChange,
      onCalendarFocusChangeTablet,
      children,
      handleLocationChange,
      handleLocationInput,
      handleShowAllEvents,
    } = this.props;
    const {
      dateStart,
      dateEnd,
      focusedInput,
      focusedInputTablet,
      selectedAllEventTypes,
      selectedEventTypes,
      selectedSegments,
      locationFilter,
      locationOptions
    } = this.props.data;
    
    const selectedSegmentsLength = selectedSegments.value.length
    const selectedSegmentsHeaderText = selectedSegments.value.join(', ')

    const calendarClasses = cx({
      "col-lg-4":true,
      "initial-calendar":!dateStart && !dateEnd,
      "active-calendar":focusedInput
    })
    
    return (
    <div className="ListView">
      <Helmet>
        <meta charSet="utf-8" />
        <title>IoT Events Listing</title>
      </Helmet>
      <div className="container">
        {/* tablet */}
        <div className="d-none d-md-block d-lg-none">
          <div className="row special-row filters-panel mb-3">
            <div className="col-6">
              <Select
                options={locationOptions}
                isClearable={true}
                name="Location"
                value={locationFilter}
                inputValue={locationFilter}
                onChange={handleLocationChange}
                onInputChange={handleLocationInput}
                components={{
                  Option:CustomOption,
                  IndicatorSeparator:false,
                  DropdownIndicator:false
                }}
                placeholder="Locations"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className={`${calendarClasses} col-6`}>
              <div className="ClearButton-container tablet-calendar-select">
                {/* <div className={`ClearButton-container tablet-date `}> */}
                  <ClearButton 
                  manualPadding="0 18px" 
                  active={focusedInputTablet} 
                  clearInfo={()=>onDatesChange({
                    startDate:null,
                    endDate:null
                    })} 
                  hidden={!dateStart && !dateEnd}  />
                  <DateRangePicker
                    //showClearDates
                    
                    minimumNights={1}
                    navPrev={<div className="prev"><FaAngleLeft fill="#788ab3"/></div>}
                    navNext={<div className="next"><FaAngleRight fill="#788ab3" /></div>}
                    startDatePlaceholderText="Start Date"
                    endDatePlaceholderText="End Date"
                    startDate={dateStart}
                    startDateId="start_date_id_1"
                    endDate={dateEnd}
                    endDateId="end_date_id_1"
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInputTablet}
                    onFocusChange={onCalendarFocusChangeTablet}
                    displayFormat="ddd, MMM D, YYYY"
                    orientation="horizontal"
                    small
                  />
                  {/* </div> */}
              </div> 
            </div>
          </div>
        </div>
        <div className="d-none d-md-block d-lg-none">
          <div className="row special-row filters-panel mb-3">

            <div className="col-4 tablet">
            <CustomSelect 
                headerText='Categories'
                hiddenClearBtn={selectedSegmentsLength === 0 || selectedSegmentsLength === 4} 
                className="filter">
                  <div className="filter">
                <CustomCheckBox onChange={handleSelectedAll} checked={selectedAllEventTypes} />
                <span>Select all</span>
              </div>
              {
                selectedEventTypes.values.map((evenType, key)=>(
                  <div key={key} className="filter">
                    <CustomCheckBox 
                    name={evenType}
                    onChange={handleSelectedEventTypes}
                    checked={
                      selectedEventTypes.value.includes(evenType)
                    }
                    />
                    <span>
                      {evenType}
                      <img src={getEventTypeIcon(evenType)} alt={evenType} />
                    </span>
                  </div>
                ))
              }
              </CustomSelect>
              
            </div>
            <div className="col-4">
              <CustomSelect 
                onClear={handleClearAllSegments}
                headerText={selectedSegmentsLength > 0
                  ?selectedSegmentsLength === 4
                    ? 'Type'
                    : selectedSegmentsHeaderText
                  :'Select Type'
                }
                hiddenClearBtn={selectedSegmentsLength === 0 || selectedSegmentsLength === 4} 
                className="filter">
              {
                selectedSegments.values.map((segment, key)=>(
                  <div key={key} className="option">
                  <CustomCheckBox
                  name={segment}
                  onChange={handleSelectedSegments}
                  checked={selectedSegments.value.includes(segment)}
                  />
                  <span>{segment}</span>
                </div>
                ))
              }
              </CustomSelect>
            </div>

            {/* <div className="col-1 pl-0">
              <button className="form-control blue-like"><FaRegThumbsUp /></button>
            </div> */}
          </div>
        </div>
        {/* pc */}
        <div className="d-none d-lg-block">
          <div className="row special-row filters-panel mb-3">
          <div className="col-md-2">
            <div className="filter">
              <CustomCheckBox onChange={handleSelectedAll} checked={selectedAllEventTypes} />
              <span>Select all</span>
            </div>
          </div>
          <div className="col-md-2">
            <CustomSelect 
              onClear={handleClearAllSegments}
              headerText={selectedSegmentsLength > 0
                ?selectedSegmentsLength === 4
                  ? 'Type'
                  : selectedSegmentsHeaderText
                :'Select Type'
              }
              hiddenClearBtn={selectedSegmentsLength === 0 || selectedSegmentsLength === 4} 
              className="filter">
            {
              selectedSegments.values.map((segment, key)=>(
                <div key={key} className="option">
                <CustomCheckBox
                name={segment}
                onChange={handleSelectedSegments}
                checked={selectedSegments.value.includes(segment)}
                />
                <span>{segment}</span>
              </div>
              ))
            }
            </CustomSelect>
          </div>
          <div className="col-md-2">
            <Select
              options={locationOptions}
              isClearable={true}
              name="Location"
              value={locationFilter}
              inputValue={locationFilter}
              onChange={handleLocationChange}
              onInputChange={handleLocationInput}
              components={{
                Option:CustomOption,
                IndicatorSeparator:false,
                DropdownIndicator:false
              }}
              placeholder="Locationt"
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <div className={calendarClasses}>
          <div className="ClearButton-container pc-calendar-select">
              <ClearButton 
              manualPadding="0 18px" 
              active={focusedInput} 
              clearInfo={()=>onDatesChange({
                startDate:null,
                endDate:null
                })} 
              hidden={!dateStart && !dateEnd}  />
              <DateRangePicker
                //showClearDates
                
                minimumNights={1}
                navPrev={<div className="prev"><FaAngleLeft fill="#788ab3"/></div>}
                navNext={<div className="next"><FaAngleRight fill="#788ab3" /></div>}
                startDatePlaceholderText="Start Date"
                endDatePlaceholderText="End Date"
                startDate={dateStart}
                startDateId="start_date_id_2"
                endDate={dateEnd}
                endDateId="end_date_id_2"
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={onCalendarFocusChange}
                displayFormat="ddd, MMM D, YYYY"
                orientation="horizontal"
                small
              />
            </div> 
          </div>
          <div className="col-2 control-filters">
            <span className="btn clear" onClick={() => {handleLocationChange(null, 'Location'); handleSelectAllSegments(); handleShowAllEvents(); onDatesChange({
              startDate:null,
              endDate:null
              })} }>Clear All Filters</span>
          </div>
        </div>
        </div>


        {/* results */}
        <div className="row special-row">
          <div className="col-md-2 d-none d-lg-block">
            <div className="filters-sidebar rounded border bg-white">
              {
                selectedEventTypes.values.map((evenType, key)=>(
                  <div key={key} className="filter px-3 px-lg-2 px-xl-3">
                    <CustomCheckBox 
                    name={evenType}
                    onChange={handleSelectedEventTypes}
                    checked={
                      selectedEventTypes.value.includes(evenType)
                    }
                    />
                    <span>
                      {evenType}
                      <img src={getEventTypeIcon(evenType)} alt={evenType} />
                    </span>
                  </div>
                ))
              }
            </div>
          </div>
          <div className=" col-sm col-md-12 col-lg col-xl">
            <div className="main">
              {children}
            </div>
          </div>
          
          <div className="col-md-2 righter">
          </div>
        </div>
      </div>      
    </div>
    );
  }
}
