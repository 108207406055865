import React, { Component } from 'react'
import { Button, Popover, PopoverBody, Alert } from 'reactstrap';
import {Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from 'react-share';
import { FaFacebook, FaTwitter, FaLinkedin, FaTimes, FaEnvelope } from 'react-icons/fa';

export default class PopoverBlock extends Component {
  constructor(props){
    super(props)
    this.state = {
      eventData:null,
      loading: true,
      showContent:false,
      show: false,
      tooltipOpen: false,
      tooltipOpenHeader: false,
      copied: false,
      isDiscountOpen: false
    }
  }

  render() {
    const toggle = () => {return true} 
    const {
        eventId, discount, isLoggedIn, showSticky, prevQuery, filters, showDiscountCode, togglePopover, showShare, closeDiscountCode, title
    } = this.props
    const discountCode = discount&&discount.discountCode;
    const discountDescription = discount&&discount.discountDescription
    // console.log(isLoggedIn)
    return (
      <div>
        {showSticky&&
          <>
            {!isLoggedIn?
              <Popover className="popover-block-discount eventIdPopover" placement="top" isOpen={showDiscountCode} target={"DisabledHover1"} toggle={toggle}>
                <h4><p className="text-style grey-color text-center pt-24">Sign Up to View</p></h4> <div onClick={closeDiscountCode} className="close-button pointer"><FaTimes /></div>
                <PopoverBody style={{ paddingBottom: "20px"}}>
                  <div className="display-flex justify-center mr-b-15"><Link  to={{pathname:'/signup',  state:{page:`/event/${eventId}`, prevQuery:prevQuery, filters:filters}}} ><Button className="sign-up">Sign Up</Button></Link></div>
                  <div className="display-flex justify-center"><Link  to={{pathname:'/login',  state:{page:`event/${eventId}`, prevQuery:prevQuery, filters:filters}}} >Login</Link></div>
                </PopoverBody>
              </Popover>:discountCode?
              <Popover className="popover-block-discount eventIdPopover" placement="top" isOpen={showDiscountCode} target={"DisabledHover1"} toggle={toggle}>
                <h4><p className="text-style grey-color text-center pt-24">{discountDescription}</p></h4> <div onClick={closeDiscountCode} className="close-button pointer"><FaTimes /></div>
                <PopoverBody style={{ paddingBottom: "20px"}}>
                  <div className="discount-code"><b>{discountCode}</b></div>
                  <CopyToClipboard text={discountCode} onCopy={() => this.setState({copied: true})}>
                    <div className={`display-flex justify-center ${isLoggedIn?'mr-t-15':'mr-b-15'}`}><Button className="sign-up">Copy</Button></div>
                  </CopyToClipboard>
                  {this.state.copied ? <Alert color="success"> <p style={{margin: "0px", display: "flex ", justifyContent: "center"}}>Copied!</p> </Alert> : null}
          
                </PopoverBody>
              </Popover>:''
            }
          </> 
        }

        {!showSticky&&
          <>
          {!isLoggedIn?
            <Popover className="popover-block-discount eventIdPopover" placement="top" isOpen={showDiscountCode} target={"DisabledHover2"} toggle={toggle}>
              <h4><p className="text-style grey-color text-center pt-24">Sign Up to View</p></h4> <div onClick={closeDiscountCode} className="close-button pointer"><FaTimes /></div>
              <PopoverBody style={{ paddingBottom: "20px"}}>
                <div className="display-flex justify-center mr-b-15"><Link  to={{pathname:'/signup',  state:{page:`/event/${eventId}`, prevQuery:prevQuery, filters:filters}}} ><Button className="sign-up">Sign Up</Button></Link></div>
                <div className="display-flex justify-center"><Link  to={{pathname:'/login',  state:{page:`event/${eventId}`, prevQuery:prevQuery, filters:filters}}} >Login</Link></div>
              </PopoverBody>
            </Popover>:discountCode?
            <Popover className="popover-block-discount eventIdPopover" placement="top" isOpen={showDiscountCode} target={"DisabledHover2"} toggle={toggle}>
              <h4><p className="text-style grey-color text-center pt-24">{discountDescription}</p></h4> <div onClick={closeDiscountCode} className="close-button pointer"><FaTimes /></div>
              <PopoverBody style={{ paddingBottom: "20px"}}>
                <div className="discount-code"><b>{discountCode}</b></div>
                <CopyToClipboard text={discountCode}
                  onCopy={() => this.setState({copied: true})}>
                  <div className={`display-flex justify-center ${isLoggedIn?'mr-t-15':'mr-b-15'}`}><Button className="sign-up">Copy</Button></div>
                  
                </CopyToClipboard>
                {this.state.copied ? <Alert color="success"> <p style={{margin: "0px", display: "flex ", justifyContent: "center"}}>Copied!</p> </Alert> : null}
        
              </PopoverBody>
            </Popover>:''
          }
          </> 
        }
        { <Popover className={!showSticky?"eventIdPopover": "eventIdPopover-l-0"} placement="bottom" isOpen={showShare} target={showSticky?"Popover1":"Popover2"} toggle={togglePopover}>
          <p className="text-style grey-color text-center pt-24">Share Event via</p>
          <PopoverBody style={{width: "225px", display: "flex", justifyContent: "center", paddingBottom: "24px"}}>
            <EmailShareButton style={{cursor: "pointer"}} subject={`Check out this event I found on IoT Nation`} body={title}  separator={`\n`} url={window.location.href}><FaEnvelope style={{margin: "0px 4px"}} className="icons linkedin-icon" /></EmailShareButton>
            <LinkedinShareButton style={{cursor: "pointer"}} title={`Check out this event found on IoT Nation, the destination for all things IoT! (www.iotnation.com) \n\n`} url={window.location.href}><FaLinkedin style={{margin: "0px 4px"}} className="icons linkedin-icon" /></LinkedinShareButton>
            <TwitterShareButton style={{cursor: "pointer"}} title={`Check out this event found on IoT Nation, the destination for all things IoT! (www.iotnation.com) \n\n`}  url={window.location.href}><FaTwitter style={{margin: "0px 4px"}} className="icons twitter-icon" /></TwitterShareButton>
            <FacebookShareButton style={{cursor: "pointer"}} quote={`Check out this event found on IoT Nation, the destination for all things IoT! (www.iotnation.com) \n\n`}  url={window.location.href}><FaFacebook style={{margin: "0px 4px"}} className="icons facebook-icon" /></FacebookShareButton>
          </PopoverBody>
        </Popover>}
          <div style={{display:'none'}}>{this.state.copied ?setTimeout(() => {this.setState({ copied: false })}, 3000):''}</div>
      </div>
    )
  }
}
