import React, { Component } from "react";
import CardsTitle from "../../../components/CardsTitle/CardsTitle";
import moment from "moment-timezone";
import "./SmartCitiesEvents.scss";
import Spinner from "../../../components/Spinner/Spinner";
import EventItem from "./EventItem";
import GeneratedApi from "../../../components/functions/generatedApi";

export default class SmartCitiesEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      response: "",
    };
  }
  componentDidMount() {
    const { limit, type, title, APIcall } = this.props.propsData;
    const q = {
      search: {
        q: "",
        start: 0,
        limit: limit ? limit : 12,
      },
      filters: [{ attribute: "Company Rank", operator: "Less than", value: "3000" }, { attribute: type, operator: "Include", value: title }],
    };
    GeneratedApi("", false, true)
      .post(`/${APIcall}/gridView`, q)
      .then((response) => {
        response = response.data.body.data;
        this.setState({ response });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ response: "no info" });
        if (error.response) {
          if (error.response.status === 429) {
            return false;
          }
        }
      });
  }
  sortArray = (array) => {
    const item = [...array.horizontals, ...array.verticals].filter((item) => item);
    return item;
  };
  dayLeft = (date) => {
    const { startTime, endTime } = date;
    let now = moment();

    const fullDaysLeft = startTime && moment.unix(startTime).isAfter(now) && moment.unix(startTime).diff(now, "days");
    const hoursLeft = startTime && moment.unix(startTime).isAfter(now) && moment.unix(startTime).diff(now, "hours");
    const monthsLeft = startTime && moment.unix(startTime).isAfter(now) && moment.unix(startTime).diff(now, "months");
    const weeksLeft = startTime && moment.unix(startTime).isAfter(now) && moment.unix(startTime).diff(now, "weeks");
    const inProgress = startTime && moment.unix(startTime).isBefore(now) && moment.unix(endTime).isAfter(now);
    const isNotEnded = moment.unix(endTime) > now ? true : false;
    return (
      <div className="ml-auto mr-0 py-2 font-weight-bold">
        {inProgress
          ? "In Progress"
          : isNotEnded
          ? fullDaysLeft <= 2 && hoursLeft === 1
            ? `In ${hoursLeft} hour`
            : hoursLeft < 1
            ? `In less than an hour`
            : hoursLeft <= 48
            ? `In ${hoursLeft} hours`
            : fullDaysLeft <= 14
            ? `In ${fullDaysLeft} days`
            : fullDaysLeft <= 56
            ? `In ${weeksLeft} weeks`
            : fullDaysLeft > 56 && monthsLeft === 1
            ? "In 1 month"
            : fullDaysLeft > 56 && monthsLeft > 1
            ? `In ${monthsLeft} months`
            : "In Progress"
          : `Already ended`}
      </div>
    );
  };
  render() {
    const { response } = this.state;

    if (response === "no info") {
      return "";
    } else if (response && response !== "no info") {
      return (
        <div className="SmartCitiesEvents">
          <CardsTitle image="/images/calendar-2.svg" titleName={"Featured Events"} name="smart-cities-events" />
          <div className="border-botom border-right border-left rounded-8 bg-white rounded-t-0">
            {response.searchResults.map((item, key) => (
              <EventItem
                data={{
                  key,
                  item,
                  sortArray: this.sortArray,
                  dayLeft: this.dayLeft,
                }}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
