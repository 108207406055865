import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import './Description.scss';

export default class Description extends Component {
    render() {
        const {longDescription, shortDescription} = this.props
        return (
            <div className="Description" id="description">
                <CardsTitle image="/images/paragraph-1.svg" titleName={'Description'} name="description" />
                <div className="description-text-block">
                    <div className="description-title">
                        {shortDescription}
                    </div>
                    <div className="description-text">
                        {longDescription} 
                    </div>
                </div>
            </div>
        )
    }
}
