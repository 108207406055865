import _ from "lodash";

const flattenObject = (object) => {
  const func = (ob) => {
    var toReturn = {};
    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if (typeof ob[i] == "object" && ob[i] !== null && !_.isEmpty(ob[i])) {
        var flatObject = func(ob[i]);
        toReturn[i] = ob[i];
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };
  return _.keys(func(object));
};

export default flattenObject;
