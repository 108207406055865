import React, { Component } from "react";
import Rank from "./Rank/Rank";
import { Link } from "react-router-dom";
import GeneratedApi from "../functions/generatedApi";
// import { Button } from 'reactstrap';
// import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa';
// import RestrictedPopover from '../Modals/RestrictedPopoverSmall/RestrictedPopover'

export default class RowResultsMobile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      likeData: null,
      showModal: false,
      thumbsUp: false,
      likeLength: 0,
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  componentDidMount() {
    // const q = {"dataId":this.props.result&&this.props.result.id,"dataType":"companies"}
    // GeneratedApi("", true, true).post('/getLikeInfo', q)
    //     .then((response) => {
    //         response = response.data;
    //         this.setState({
    //             likeData:response,
    //             thumbsUp: response.currentUserLike,
    //             likeLength: response.userList.length,
    //         });
    //     })
    // .catch((error) => {
    //     console.log(error);
    //     if(error.response) {
    //         if(error.response.status === 429) {
    //             return false;
    //         }
    //     }
    // });
  }
  likeHandle = () => {
    if (!this.props.user) {
      this.setState({ showModal: true });
    } else {
      const like = {
        dataId: this.props.result && this.props.result.id,
        dataType: "companies",
        operation: "LIKE",
      };
      const unlike = {
        dataId: this.props.result && this.props.result.id,
        dataType: "companies",
        operation: "INDIFFERENT",
      };
      if (this.state.thumbsUp) {
        if (this.state.likeLength < 1) {
          this.setState({
            thumbsUp: false,
            likeLength: 0,
          });
        } else {
          this.setState({
            thumbsUp: false,
            likeLength: this.state.likeLength - 1,
          });
        }

        GeneratedApi("", true, true)
          .post("/updateLikeInfo", unlike)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            if (this.state.likeLength < 1) {
              this.setState({
                thumbsUp: false,
                likeLength: 0,
              });
            } else {
              this.setState({
                thumbsUp: false,
                likeLength: this.state.likeLength + 1,
              });
            }
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      } else {
        this.setState({
          thumbsUp: true,
          likeLength: this.state.likeLength + 1,
        });

        GeneratedApi("", true, true)
          .post("/updateLikeInfo", like)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength - 1,
            });
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      }
    }
  };
  render() {
    const { result, user } = this.props;
    const userType =
      user && user["cognito:groups"] && user["cognito:groups"][0];
    return (
      <tr className="row">
        <td className="col-3">
          {userType || (!userType && result.rank <= 5) ? (
            <Rank data={{ rank: result.rank, rankChange: result.rankChange }} />
          ) : (
            <div className="blurred-box">
              <Rank data={{ rank: "rank", rankChange: result.rankChange }} />
            </div>
          )}
        </td>
        <td className="col-2">
          <div className="company-logo d-inline-flex align-items-center">
            <img
              src={result.logo ? result.logo : "/images/gray-box.png"}
              alt={result.name}
            />
          </div>
        </td>
        <td className="col-7">
          <Link
            to={{
              pathname: `/company/${result.id}`,
              state: {
                prevQuery: `${this.props.pathname}${this.props.search}`,
              },
            }}
          >
            {result.name ? result.name : "Company Page Link"}
          </Link>
        </td>
        {/* <td className="col-2">
                    <div className="likes-block">
                        <div className="likes">
                            {this.state.likeLength}
                        </div>
                        <Button onClick={(() => this.likeHandle())} color="link" className="likes d-flex">
                            {this.props.user?'':
                                <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={this.props.id} />
                            }
                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                        </Button>
                    </div> 
                </td> */}
      </tr>
    );
  }
}
