import React from "react";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "simplebar";
import "simplebar/dist/simplebar.css";

class TooltipWithProps extends React.PureComponent {
  render() {
    const { tags, userType } = this.props;
    const content = tags.slice(0, 3);
    const weight = (tag) => {
      if (tag.name) {
        return `; w: ${tag.weight}`;
      }
      return `w: ${tag.weight}`;
    };
    const magnitude = (tag) => {
      if (tag.name || tag.weight) {
        return `; m: ${tag.magnitude}`;
      }
      return `m: ${tag.magnitude}`;
    };
    return (
      <Tippy
        content={
          <ul data-simplebar>
            {tags.map((tag, i) => {
              return (
                <li key={i}>
                  <a
                    target="_blank"
                    href={`/companies/?q=&filter=Verticals_Include_${tag.name &&
                      tag.name.replace("&", "and")}&limit=10`}
                  >
                    {userType === "admin"
                      ? `${tag.name}${weight(tag)}${magnitude(tag)}`
                      : tag.name}
                  </a>
                </li>
              );
            })}
          </ul>
        }
        animateFill={false}
        duration={500}
        delay={[100, 50]}
        theme="iot"
        interactive={true}
        trigger="click"
      >
        <span className="tags">
          {content.map((entry, i) => {
            if (i < 2 && i < tags.length - 1) {
              return userType === "admin"
                ? `${entry.name}${weight(entry)}${magnitude(entry)}, `
                : `${entry.name}, `;
            } else {
              return userType === "admin"
                ? `${entry.name}${weight(entry)}${magnitude(entry)}`
                : entry.name;
            }
          })}
        </span>
      </Tippy>
    );
  }
}

export default class Verticals extends React.PureComponent {
  render() {
    const { iotTags, userType } = this.props;

    if (
      iotTags &&
      iotTags.verticals_ &&
      iotTags.verticals_.constructor === Array &&
      iotTags.verticals_.length > 0
    ) {
      return <TooltipWithProps userType={userType} tags={iotTags.verticals_} />;
    }
    return null;
  }
}
