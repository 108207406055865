import { ResponsiveContainer, Cell,  XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar, Legend} from 'recharts';
import React, { useRef, useLayoutEffect, useState } from 'react'

function DonutChartWithLegend(props) {
  
  const data = [];
  const colors = [];

  const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  useLayoutEffect(()=>{
    const handleResize = () => setContainerWidth(containerRef.current.container.clientWidth);
    const handleResizeH = () => setContainerHeight(containerRef.current.container.clientHeight);
    window.addEventListener('resize', handleResize, handleResizeH);
    if(containerRef && containerRef.current){
        setContainerWidth(containerRef.current.container.clientWidth)
        setContainerHeight(containerRef.current.container.clientHeight)
    }
    return () => {
        window.removeEventListener('resize', handleResize, handleResizeH);
        };
    })

  const { organizations, conferences, ecosystems, socialmedia }=props.components

  if(organizations > 0) {
    data.push({name: 'Organizations', value: organizations });
    colors.push('#65d12f');
  }
  if(conferences > 0) {
    data.push({name: 'Conferences', value: conferences});
    colors.push('#FFA500');
  }
  if(ecosystems > 0) {
    data.push({name: 'Ecosystems', value: ecosystems});
    colors.push('#ff4848');
  }
  if(socialmedia > 0) {
    data.push({name: 'Social media', value: socialmedia});
    colors.push('#43a7ed');
  }

  return (
    <ResponsiveContainer className="border rounded background-white-2"  ref={containerRef} width="100%" height="100%">
      <BarChart
        width={200}
        height={100}
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        layout="vertical"
      >
        <Legend layout="horizontal" align="center"  verticalAlign="top"  content={(props)=>{
          return (
          <div className="text w-100">
              <p className="iot my-4 text-center">IoT Ace™ MVP Score Components</p>
          </div> 
          )
        }} />
        <XAxis axisLine={false} type="number" dataKey="value" />
        <YAxis axisLine={false} width={100} type="category" dataKey="name"  />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar barSize={15} maxBarSize={15} dataKey="value" yAxisId={0}  >
          {
            data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))
          }
        </Bar>
        
      </BarChart>
    </ResponsiveContainer>
  )
}
export default DonutChartWithLegend