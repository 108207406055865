import React, { Component } from 'react'
import Select from "react-select";
import '../AdvancedFiltersMobile.scss'
import '../../../Events/react-dates_style_overrides.scss'
import '../../../Events/react-select_styles_overrides.scss'
export default class Location extends Component {
    render() {
        const {  locationOptions, locationFilter, handleLocationChange, handleLocationInput, handleSelectLocation } = this.props
        const CustomOption = ({ innerProps, isDisabled, data:{label, longLabel} }) =>
            !isDisabled ? (
                <div className="option" {...innerProps}>
                <div className="label">
                    {label}
                </div>
                <div className="long-label">
                    {longLabel}
                </div>
                </div>
            ) : null;

        return (
            <>
                <div style={{padding: "10px"}}> 
                    <Select
                    style={{position:'none'}}
                    options={locationOptions}
                    isClearable={true}
                    name="Location"
                    value={locationFilter}
                    onChange={handleLocationChange}
                    onInputChange={handleLocationInput}
                     components={{
                         Option:CustomOption,
                        IndicatorSeparator:false,
                        DropdownIndicator:false
                     }}
                    placeholder="Location"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    />
                </div>
                <div className="row divider"></div>
                <div className='side-padding-15'>
                    <label className="custom-control-label" htmlFor='location-1' >
                        <div className="display-flex row-pad">
                            <div className="custom-control custom-checkbox vertical-align display-flex">
                                <input className="custom-control-input" id='location-1' onClick={() => handleSelectLocation('New York')}   placeholder="Location" name="Is near" value="New York"  type="checkbox" />
                                <div className="display-flex">
                                    <div>New York</div>
                                    <div className="grey-text">NY, USA</div>
                                </div>
                                {/* {IsChecked(checkedFilter, 'location-1') && <div className="blue-check"><FaCheck /></div>} */}
                            </div>
                        </div>
                    </label>
                    <div className="row divider"></div>
                    <label className="custom-control-label" htmlFor='location-2' >
                        <div className="display-flex row-pad">
                            <div className="custom-control custom-checkbox vertical-align display-flex">
                                <input className="custom-control-input" id='location-2'  onClick={() => handleSelectLocation("San Francisco")}   placeholder="Location" name="Is near" value="San Francisco"  type="checkbox" />
                                <div className="display-flex">
                                    <div>San Francisco</div>
                                    <div className="grey-text">CA, USA</div>
                                </div>
                                {/* {IsChecked(checkedFilter, 'location-2') && <div className="blue-check"><FaCheck /></div>} */}
                            </div>
                        </div>
                    </label>
                    <div className="row divider"></div>
                    <label className="custom-control-label" htmlFor='location-3' >
                        <div className="display-flex row-pad">
                            <div className="custom-control custom-checkbox vertical-align display-flex">
                                <input className="custom-control-input" id='location-3'  onClick={() => handleSelectLocation("Seattle")}   placeholder="Location" name="Is near" value="Seattle"  type="checkbox" />
                                <div className="display-flex">
                                    <div>Seattle</div>
                                    <div className="grey-text">WA, USA</div>
                                </div>
                                {/* {IsChecked(checkedFilter, 'location-3') && <div className="blue-check"><FaCheck /></div>} */}
                            </div>
                        </div>
                    </label>
                    <div className="row divider"></div>
                    <label className="custom-control-label" htmlFor='location-4' >
                        <div className="display-flex row-pad">
                            <div className="custom-control custom-checkbox vertical-align display-flex">
                                <input className="custom-control-input" id='location-4' onClick={() => handleSelectLocation("Boston")}  placeholder="Location" name="Is near" value="Boston"  type="checkbox" />
                                <div className="display-flex">
                                    <div>Boston</div>
                                    <div className="grey-text">MA, USA</div>
                                </div>
                                {/* {IsChecked(checkedFilter, 'location-4') && <div className="blue-check"><FaCheck /></div>} */}
                            </div>
                        </div>
                    </label>
                    <div className="row divider"></div>
                    <label className="custom-control-label" htmlFor='location-5' >
                        <div className="display-flex row-pad">
                            <div className="custom-control custom-checkbox vertical-align display-flex">
                                <input className="custom-control-input" id='location-5'  onClick={() => handleSelectLocation("Austin")}  placeholder="Location" name="Is near" value="Austin"  type="checkbox" />
                                <div className="display-flex">
                                    <div>Austin</div>
                                    <div className="grey-text">TX, USA</div>
                                </div>
                                {/* {IsChecked(checkedFilter, 'location-5') && <div className="blue-check"><FaCheck /></div>} */}
                            </div>
                        </div>
                    </label>
                </div>
            </>
        )
    }
}
