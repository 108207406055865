import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';
import './Approach.scss'
export default class Approach extends Component {
    render() {
        const {approach} = this.props
        const user = this.props.user
        const type = !user
        return (
            <div className="Approach" id="approach">
                <div>
                    <CardsTitle image="/images/target-1.svg" titleName={'Approach'} name="approach" />
                    <div className={user?`description`:'description not-login'}>
                        {approach}
                    </div>   
                </div>
                <SubscriberContent target="approach1" hidden={type} />
            </div>
        )
    }
}
