/*global AmazonCognitoIdentity*/
import React from 'react';
import { Redirect } from 'react-router-dom';
import { PasswordReset, NewPasswordInput}  from './PasswordReset';
import Confirm from './Confirm';
import {NotificationManager} from 'react-notifications';

export default class PasswordResetContainer extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            formData: {
                username: ""
            },
            confirmData: {
                code: "",
                password: "",
                passwordAgain: ""
            },
            loading: false,
            confirm: false,
            reset: false,
            confirmAvailable: true,
            redirect: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirmInputChange = this.handleConfirmInputChange.bind(this);
        this.initResetCounter = this.initResetCounter.bind(this);
        this.handleResendCode = this.handleResendCode.bind(this);
        this.handlePasswordChangeSubmit = this.handlePasswordChangeSubmit.bind(this);
        this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    }

    handlePasswordChangeSubmit(event) {
        event.preventDefault();
        const formData = {...this.state.formData}; 
        const data = {...this.state.confirmData};
        if(data.password !== data.passwordAgain) {
            NotificationManager.error('Password do not match.');
            return true;
        }
        this.setState({loading: true});
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
            ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
        });
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: formData.username,
            Pool: userPool,
        });
        cognitoUser.confirmPassword(data.code, data.password, {
            onSuccess: () => {
                alert('Password changed successfully');
                this.setState({redirect: true});
            },
            onFailure: (error) => {
                alert(error.message);
                this.setState({
                    loading: false,
                    reset: false,
                    confirm: false,
                    formData: {
                        username: "",
                    },
                    confirmData: {
                        code: "",
                        password: ""
                    }
                })
            }
        });
    }

    handleConfirmInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const confirmData = {...this.state.confirmData}
        confirmData[name] = value;
        this.setState({confirmData});
    }

    handleResendCode() {
        const user = {...this.state.formData};
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
            ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
        });
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: user.username,
            Pool: userPool,
        });
        this.setState({
            loading: true
        });
        cognitoUser.resendConfirmationCode((err, result) => {
            if(err) {
                this.setState({
                    loading: false,
                });
                NotificationManager.error(err.message);
                return false;
            }
            this.setState({
                loading: false,
                confirmAvailable: false,
                confirmCount: this.state.confirmCount + 1
            });
            this.initResetCounter();
            NotificationManager.success('Confirmation message resent.');
            
        })
    }

    initResetCounter() {
        let interval = setInterval(() => {
            const {confirmCount} = this.state;
            if(confirmCount <= 5) {
                this.setState({confirmAvailable: true});
            } else {
                alert('You have exhausted the maximum amount of retries available.');
            }
            clearInterval(interval);
          }, 60000);
    }

    handleConfirmSubmit(event) {
        event.preventDefault();
        this.setState({
            loading: false,
            confirm: true,
            reset: true,
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: false});
        const formData = {...this.state.formData};
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
            ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
        });
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: formData.username,
            Pool: userPool,
        });
        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                this.setState({loading: false, confirm: true});
            },
            onFailure: (err) => {
                NotificationManager.error(err.message);
            },
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const formData = {...this.state.formData}
        formData[name] = value;
        this.setState({formData});
    }

    render() {
        if(this.state.redirect) {
            return <Redirect to='/login'/>
        }
        if(!this.state.confirm) {
            return (
                <PasswordReset formData={this.state.formData} handleInputChange={this.handleInputChange} handleSubmit={this.handleSubmit}/>
            )
        } else if(this.state.reset) {
            return (
                <NewPasswordInput data={this.state.confirmData} handleSubmit={this.handlePasswordChangeSubmit} handleInputChange={this.handleConfirmInputChange} loading={this.state.loading}/>
            )
        } else {
            return (
                <Confirm data={this.state.formData} confirmData={this.state.confirmData} handleSubmit={this.handleConfirmSubmit} handleInputChange={this.handleConfirmInputChange} loading={this.state.loading}/>
            )
        }
    }
}