import React from "react";
import { StickyContainer, Sticky } from "react-sticky";
import { Link, withRouter } from "react-router-dom";
import CompanyInfo from "./components/CompanyInfo/CompanyInfo";
import Sidemenu from "./components/Sidemenu/Sidemenu";
import IotTags from "./components/IotTags/IotTags";
import IotIndustryActivity from "./components/IotIndustryActivity/IotIndustryActivity";
import FinancialData from "./components/FinancialData/FinancialData";
import MapContainer from "./components/MapContainer/MapContainer";
import ContactTable from "./components/ContactTable/ContactTable";
import TwitterPosts from "./components/TwitterPosts/TwitterPosts";
import Spinner from "../components/Spinner/Spinner";
import SubscriberDisclaimer from "../components/SubscriberDisclaimer/SubscriberDisclaimer";
import IotLinks from "./components/CompanyInfo/IotLinks";
import GeneralLinks from "./components/CompanyInfo/GeneralLinks";
import { Helmet } from "react-helmet";
import "./Company.scss";

class Company extends React.Component {
  handleMoreTags = () => this.setState();
  render() {
    const { prevQuery } = this.props.location.state
      ? this.props.location.state
      : { prevQuery: "" };
    const { loading } = this.props.data;
    const { user } = this.props;
    if (loading) {
      return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
    }

    const {
      companyProfile,
      financialData,
      iotIndustryActivity,
      useCases,
      contacts,
      twitter,
    } = this.props.data.response;
    const { address, hqLocation, location } = companyProfile;
    return (
      <div className="Company">
        <Helmet>
          <meta charSet="utf-8" />
          {companyProfile.name ? (
            <title>{`${companyProfile.name} | IoT Nation`}</title>
          ) : (
            <title>IoT Nation</title>
          )}
        </Helmet>
        <SubscriberDisclaimer user={user} />
        <div className="nav-divider" />
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={prevQuery ? prevQuery : ""}>Grid View</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Company profile
              </li>
            </ol>
          </nav>
        </div>
        <div className="container content-container">
          <StickyContainer>
            <div className="row">
              <div className="col-3 d-none d-lg-block">
                <Sticky topOffset={-132}>
                  {({ style, distanceFromBottom }) => (
                    <Sidemenu
                      id="2"
                      style={style}
                      user={this.props.user}
                      distanceFromBottom={distanceFromBottom}
                      data={{
                        companyProfile,
                        financialData,
                        iotIndustryActivity,
                        useCases,
                        contacts,
                      }}
                    />
                  )}
                </Sticky>
              </div>
              <div className="d-block d-lg-none col-12 d-block">
                <Sidemenu
                  id="1"
                  user={this.props.user}
                  data={{
                    companyProfile,
                    financialData,
                    iotIndustryActivity,
                    useCases,
                    contacts,
                  }}
                />
              </div>
              {/* pc */}

              <div className="padding-lr-0-lg col-12 col-sm-12 col-md-12 offset-lg-1 col-lg-8 offset-xl-0 col-xl-9">
                <div className="card info">
                  <div className="card-header">
                    <div className="icon info">
                      <img alt="company" src="/images/paragraph-1.svg" />
                    </div>
                    <div className="title-holder">
                      <p id="company">Company Info</p>
                    </div>
                  </div>
                  <div className="menu-divider" />
                  <div className="row d-none d-lg-flex">
                    <div className="col zero-pd right-bord">
                      <CompanyInfo companyProfile={companyProfile} />
                    </div>
                    {address && hqLocation && (
                      <div className="col-4 hq">
                        <MapContainer
                          data={{
                            address,
                            hqLocation,
                            location,
                            name: companyProfile.name,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row d-flex d-lg-none">
                    <div className="col-12 zero-pd right-bord">
                      <CompanyInfo companyProfile={companyProfile} />
                    </div>
                    {address && hqLocation && (
                      <div className="col-12 hq">
                        <MapContainer
                          data={{
                            address,
                            hqLocation,
                            location,
                            name: companyProfile.name,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-block d-lg-none menu-divider" />
                  <div className="row links d-flex d-lg-none">
                    <GeneralLinks links={companyProfile.generalLinks} />
                    <IotLinks links={companyProfile.iotLinks} />
                  </div>
                </div>
                <IotTags
                  handleMoreTags={this.handleMoreTags}
                  iotTags={companyProfile.iotTags_}
                  user={user}
                />
                {iotIndustryActivity && (
                  <IotIndustryActivity
                    iotIndustryActivity={iotIndustryActivity}
                    user={user}
                  />
                )}
                <FinancialData
                  financialData={financialData}
                  companyProfile={companyProfile}
                />
                {user && user.email !== "mitchelldemo@iotnation.com" && (
                  <ContactTable
                    profileName={companyProfile.name}
                    contacts={contacts}
                  />
                )}
                <TwitterPosts twitter={twitter} />
              </div>
            </div>
          </StickyContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(Company);
