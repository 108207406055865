 export default function intercom (profile=null, limits=null, expiration=null, activationCode=null){
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.setAttribute('data-cookieconsent', "marketing");s.async=true;s.src='https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    if(!profile) {
        if(window.location.pathname.includes('signup') || window.location.pathname.includes('login')){
            window.Intercom('update', {
                "hide_default_launcher": true
              });
        }else{
            setTimeout(() => {
                window.Intercom('boot', {
                    "hide_default_launcher": false,
                    app_id: process.env.REACT_APP_INTERCOM_ID,
                });
            }, 1)
        }
        
        //120000
    } else {
        let timeLeft = expiration.timeLeft / 86400;
        if(timeLeft < 1) {
            timeLeft = '< 1 day';
        } else if(timeLeft === 'no limit') {
            timeLeft = 'No limit';
        } else {
            timeLeft = `${Math.round(timeLeft)} days`;
        }
        if(window.location.pathname.includes('signup') || window.location.pathname.includes('login')){
            window.Intercom('update', {
                "hide_default_launcher": true
              });
        }else{
            setTimeout(() => {
                window.Intercom('boot', {
                    app_id: process.env.REACT_APP_INTERCOM_ID,
                    name: profile.name, // Full name
                    email: profile.email, // Email address
                    phone: profile.phone, //phone number
                    "hide_default_launcher": false,
                    "activationCode": activationCode,
                    "TermsOfService": profile.activationCode,
                    "companyName": profile.companyName, //company name,
                    "User Level": profile.usergroup,
                    "Environment": process.env.REACT_APP_ENV,
                    "Total API Usage": limits && limits.totalusage ? limits.totalusage : '',
                    "username": profile.username,
                    "Subscription Days Left": `${timeLeft}`,
                });
            }, 1)
        }
        
    }
    
}

