
export default function clearFilter(filter, advancedFilters, checkedFilter, LocationArray, locationFilter) {
    let array = { advancedFilters: advancedFilters, checkedFilter: checkedFilter, LocationArray, locationFilter}
    if(filter === 'Total Funding'){
        array = {...array, totalFcount: 0}
    }else if(filter === 'Technologies'){
        array = {...array, technologiesCount: 0}
    }else if(filter === 'Verticals'){
        array = {...array, verticalsCount: 0}
    }else if(filter === 'Number Of Employees'){
        array = {...array, employeeCount: 0}
    }else if(filter === 'Private / Public'){
        array = {...array, publicPrivateCount: 0}
    }else if(filter === 'Location'){
        array = {...array, locationCount: 0}
        array.locationFilter = ''
        array.LocationArray = ''
    }
    array.checkedFilter =  array.checkedFilter.map(
                obj => ((obj.checked.attribute !== filter.replace(/ \/ /g, '/')) && obj)
              ).filter(Boolean)
    if(array.LocationArray){
        array.advancedFilters =  [...array.checkedFilter.map(
                obj => ({attribute: obj.checked.attribute, operator: obj.checked.operator, value: obj.checked.value})
            ), array.LocationArray]
        
    }else{
            array.advancedFilters = [...array.checkedFilter.map(
                obj => ({attribute: obj.checked.attribute, operator: obj.checked.operator, value: obj.checked.value})
            )]
    }
    return (
        {...array}
    )
}

