import React, { Component } from 'react'

export default class RowSubscriptionsTablet extends Component {
    render() {
        const {name, registered, pro, enterprise, type} = this.props
        return (
            <>
                <div className="col-8 pr-md-0 new-line"> <div className="block">{name}</div></div>
                <div className="col-4 pr-0">
                    <div className={`block center de ${type === "selected-registered"? 'registered' : type === "selected-pro"? 'pro' : 'enterprise'}`}>
                        <div className={type === "selected-registered"? registered : type === "selected-pro"? pro : enterprise} />
                    </div>
                </div>

            </>
        )
    }
}
