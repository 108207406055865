/*global AmazonCognitoIdentity*/
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function logout(user = false) {
  if (user) {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: user,
      Pool: userPool,
    });
    cognitoUser.signOut();
  }
  let key;
  let obj = window.localStorage;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key.includes("CognitoIdentityServiceProvider")) {
        cookies.remove(key, {
          path: "/",
          domain:
            process.env.REACT_APP_JSON_TYPE === "local"
              ? "localhost"
              : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
      }
    }
  }
  cookies.remove("jwtToken", { path: "/" });
  cookies.remove("jwtToken", {
    path: "/",
    domain:
      process.env.REACT_APP_JSON_TYPE === "local"
        ? "localhost"
        : "iotnation.com",
    expires: new Date(Date.now() + 86400000),
    sameSite: false,
  });
  cookies.remove("refreshToken", {
    path: "/",
    domain:
      process.env.REACT_APP_JSON_TYPE === "local"
        ? "localhost"
        : "iotnation.com",
    expires: new Date(Date.now() + 86400000),
    sameSite: false,
  });
  localStorage.clear();

  alert("You have been logged out");
  window.location.href = "/";
  return "";
}
