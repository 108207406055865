import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"

import './MarketResearchReports.scss'

export default class MarketResearchReports extends Component {
    render() {
        return (
            <div className="MarketResearchReports">
                <CardsTitle image="/images/single-content-03.svg" titleName={'Featured Reports'} name="market-research-reports" />
                <div className={`description`}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <img src="/testing-images/bitmap-copy-4.jpg" alt=""/>
                            <div className="text-block">
                                <div className="text-title">Home Mobile Apps</div>
                                <div className="text-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <img src="/testing-images/bitmap-copy-5.jpg" alt=""/>
                            <div className="text-block">
                                <div className="text-title">Autonomous Robots</div>
                                <div className="text-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <img src="/testing-images/bitmap-copy-6.jpg" alt=""/>
                            <div className="text-block">
                                <div className="text-title">Agricultural Drones</div>
                                <div className="text-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}
