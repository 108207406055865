import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';
import './Outcome.scss'
export default class Outcome extends Component {
    render() {
        const {outcome} = this.props
        const user = this.props.user
        const type = !user
        return (
            <div className="Outcome" id="outcome">
                <div>
                    <CardsTitle image="/images/trophy-1.svg" titleName={'Outcome'} name="outcome" />
                    <div className={user?`description`:'description not-login'}>
                        {outcome}
                    </div>   
                </div>
                <SubscriberContent target="outcome1" hidden={type} />
            </div>
        )
    }
}
