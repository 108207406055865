import React from "react";
import Select from "react-select";
import {
  Input } from 'reactstrap';
  import {DebounceInput} from 'react-debounce-input';
  import '../../../Events/react-dates_style_overrides.scss'
import '../../../Events/react-select_styles_overrides.scss'

export default class SearchInput extends React.PureComponent {
    constructor (props){
        super(props);
        this.state = {
            inputValue: ''
        }
    }
    handleChange=(event)=> {
        this.props.setQuery(event.target.value, 'timer')
        this.setState({inputValue: event.target.value})
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.setQuery(this.state.inputValue);
    }

    render() {
        const { key, value } = this.props.fieldData;
        const {  dependsOn, locationOptions, locationFilter,  handleLocationInput, handleLocationChange } = this.props;

        const CustomOption = ({ innerProps, isDisabled, data:{label, longLabel} }) =>
            !isDisabled ? (
                <div className="option" {...innerProps}>
                <div className="label">
                    {label}
                </div>
                <div className="long-label">
                    {longLabel}
                </div>
                </div>
            ) : null;
        if(key !== 'value') {
            return false;
        }
        let placeholder = "Enter value";
        if(dependsOn && (dependsOn.parrentAttribute === 'Description' || dependsOn.parrentAttribute === 'Name')){
            placeholder = `Enter text`;
        }else if(value.unit) {
            placeholder = `Enter ${value.unit}`;
        }

        if( dependsOn && dependsOn.parrentAttribute === 'Location'){
            placeholder = 'Enter Location'
            return (
                <div className="margin-r-8 break w-150">
                    <Select
                    style={{position:'none'}}
                    options={locationOptions}
                    isClearable={true}
                    name="Location"
                    value={locationFilter}
                    onChange={handleLocationChange}
                    onInputChange={handleLocationInput}
                     components={{
                         Option:CustomOption,
                        IndicatorSeparator:false,
                        DropdownIndicator:false
                     }}
                    placeholder="Enter Location"
                    className="react-select-container w-100"
                    classNamePrefix="react-select"
                    />
                </div>
            )
        }else{
            return (
                <div className="margin-r-8 break">
                    {/* <DebounceInput minLength={0} debounceTimeout={800} element={Input} type={value.type} value={initialValue} name={key} placeholder={placeholder} onChange={this.handleChange}/> */}
                    <form onSubmit={this.handleSubmit} >
                    {/* <input type={value.type}  name={key} placeholder={placeholder} className="form-control" /> */}
                    <DebounceInput minLength={0} debounceTimeout={1000} element={Input} type={value.type} value={this.props.initialValue} name={key} placeholder={placeholder} onChange={this.handleChange}/>
                    </form>
                </div>
            )   
        }
        
        
    }
}