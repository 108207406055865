import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Subscription } from "../components/Subscribe/Subscribe";
import RegisterPopover from "../components/Modals/RegisterPopover/RegisterPopover";
import { Input } from "reactstrap";
import "./UseCaseList.scss";
import "../components/scss/breadcrumbs.scss";
import { Helmet } from "react-helmet";
export default class UseCaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  componentDidMount() {
    if (!this.props.user && this.state.firstLoad) {
      setTimeout(() => {
        this.setState({ modal: true, firstLoad: false });
      }, 45000);
    }
  }
  render() {
    const { query, handleQueryChange, handleSubmit, user } = this.props;
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div className="UseCaseList">
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Use Cases</title>
        </Helmet>
        {!this.props.user ? (
          <RegisterPopover
            pathname={window.location.pathname}
            isOpen={this.state.modal}
            toggle={this.toggleModal}
          />
        ) : (
          ""
        )}
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Use case List
              </li>
            </ol>
          </nav>
        </div>
        <div className="top-panel w-100 ">
          <div className="container">
            <div className="d-flex">
              <div className="d-flex d-lg-none usecase-title-block">
                Use Cases
              </div>
              <form
                onSubmit={handleSubmit}
                className="col-8 bg-white search-box d-none d-lg-block"
              >
                <div className="row">
                  <div className="col-md-9 d-inline-flex align-items-center">
                    <span className="image-wrapper">
                      <Input
                        className="form-control mr-sm-2"
                        type="search"
                        onChange={handleQueryChange}
                        value={query}
                        name="searchInput"
                        placeholder="Search use cases..."
                        aria-label="Search use cases"
                      />
                    </span>
                  </div>
                  <div className="col-md-3 button-wrapper">
                    <button className="btn search" type="submit">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.props.children}
        {userType !== "admin" && userType !== "corporate" && (
          <div style={{ marginTop: "50px" }}>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
