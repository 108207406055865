import React, { Component } from "react";
import Blog from "./Blog";
import Gallery from "./components/Gallery";
import Meniu from "./components/Meniu";
import Spinner from "../components/Spinner/Spinner";
import GeneratedApi from "../components/functions/generatedApi";

export default class BlogContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      verticalsCount: 0,
      totalFcount: 0,
      advancedFilters: [],
      checkedFilter: [],
      query: "",
      getResults: [],
      loaded: false,
    };
    if (props.user && props.user["cognito:groups"]) {
      if (
        props.user["cognito:groups"].find((entry) => {
          return entry === "corporate";
        }) ||
        props.user["cognito:groups"].find((entry) => {
          return entry === "admin";
        })
      ) {
        this.state.profileToggleVisible = true;
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    await GeneratedApi("", false, false)
      .get(`https://community.iotnation.com/wp-json/wp/v2/posts?tags=61${process.env.REACT_APP_JSON_TYPE === "production" ? "" : ",143"}`)
      .then((res) => {
        this.setState({
          getResults: res.data,
          loaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loaded: true,
        });
      });
  }

  handleQueryChange = (event) => {
    this.setState({
      query: event.target.value,
    });
  };
  async handleSubmit(event) {
    event.preventDefault();
    // this.generateQueryParameter();
  }

  handleFilterCheckbox = async (event) => {
    const target = event.target;
    const newArrayValue = { attribute: target.placeholder, operator: target.name, value: target.value };
    const keyId = target.id;

    if (target.checked) {
      if (target.placeholder === "Financial - Total Funding") {
        this.setState({ totalFcount: this.state.totalFcount + 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount + 1 });
      }

      await this.setState((prevState) => ({
        checkedFilter: [
          ...prevState.checkedFilter,
          {
            _id: keyId,
            checked: {
              attribute: target.placeholder,
              operator: target.name,
              value: target.value,
            },
          },
        ],
      }));
    } else {
      if (target.placeholder === "Financial - Total Funding") {
        this.setState({ totalFcount: this.state.totalFcount - 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount - 1 });
      }

      await this.setState((prevState) => ({
        advancedFilters: [...prevState.advancedFilters, newArrayValue],
        checkedFilter: prevState.checkedFilter.map((obj) => obj._id !== keyId && obj).filter(Boolean),
      }));
    }
    await this.setState({
      advancedFilters: this.state.checkedFilter.map((obj) => ({
        attribute: obj.checked.attribute,
        operator: obj.checked.operator,
        value: obj.checked.value,
      })),
    });
    // this.handleSearchQuery()
  };
  handleClearFilter = async (filter) => {
    if (filter === "Financial - Total Funding") {
      this.setState({ totalFcount: 0 });
    } else if (filter === "Verticals") {
      this.setState({ verticalsCount: 0 });
    }
    await this.setState((prevState) => ({
      advancedFilters: [...prevState.advancedFilters],
      checkedFilter: prevState.checkedFilter.map((obj) => obj.checked.attribute !== filter && obj).filter(Boolean),
    }));
    await this.setState({
      advancedFilters: this.state.checkedFilter.map((obj) => ({
        attribute: obj.checked.attribute,
        operator: obj.checked.operator,
        value: obj.checked.value,
      })),
    });
    // this.handleSearchQuery()
  };
  handleClearAllFilters = async () => {
    await this.setState({
      advancedFilters: [],
      checkedFilter: [],
      totalFcount: 0,
      verticalsCount: 0,
    });
    // this.handleSearchQuery()
  };
  checkTag = (tagList) => {
    if (process.env.REACT_APP_JSON_TYPE === "production") {
      const status = tagList.filter((item) => item === 143);
      if (status.length > 0) return false;
    }
    return true;
  };
  render() {
    const { getResults, loaded } = this.state;
    const sortDataDate = getResults
      .filter((item) => this.checkTag(item.tags))
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .reverse();

    return (
      <div className="BlogContainer">
        <Blog propsData={sortDataDate[0]}>
          {loaded ? (
            <>
              <Gallery galleryClass="d-none d-lg-block" results={sortDataDate.filter((_item, key) => key > 0)} title="Latest Articles" />
              {/* <Gallery galleryClass="d-none d-lg-block" title="applications" />
                            <Gallery galleryClass="d-none d-lg-block" title="world" />  */}
            </>
          ) : (
            <Spinner addedClass="d-none d-lg-flex" />
          )}
        </Blog>
        {loaded ? <Meniu results={getResults} /> : <Spinner addedClass="d-flex d-lg-none" />}
      </div>
    );
  }
}
