import React, { Component } from "react";
import getEventTypeIcon from "../functions/getEventTypeIcon";
import Select from "react-select";
import { Input } from "reactstrap";
import { DateRangePicker } from "react-dates";
import { FaAngleLeft, FaAngleRight, FaTimes, FaCheck } from "react-icons/fa";
import ClearButton from "../../components/ClearButton/ClearButton";
import { NavLink } from "react-router-dom";
import { getMainFilters } from "../functions/eventFilters";
import cx from "classnames";
import Verticals from "./Verticals";
import Technologies from "./Technologies";
import "./Filter.scss";

function IsChecked(checkedFilter, id) {
  if (
    checkedFilter
      .map((filter) => (filter["_id"] === id ? true : ""))
      .filter(Boolean)[0]
  ) {
    return true;
  } else {
    return false;
  }
}

const CustomOption = ({ innerProps, isDisabled, data: { label, longLabel } }) =>
  !isDisabled ? (
    <div className="option" {...innerProps}>
      <div className="label">{label}</div>
      <div className="long-label">{longLabel}</div>
    </div>
  ) : null;

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
      activeIndex: 0,
      categories: false,
      type: false,
      location: false,
      date: false,
      goBackFilter: false,
      focused: false,
      focusedEnd: false,
      searchOpened: false,
      verticals: false,
      technologies: false,
    };
  }
  loadData(data) {
    const filters = data && data.filters;
    this.setState(
      Object.assign(
        { dataLoaded: true },
        {
          limit: this.state.limit,
          filters:
            filters && filters.length > 0
              ? [
                  ...getMainFilters(filters),
                  ...filters.filter((filter) => !filter.mainAttribute),
                ]
              : getMainFilters(),
        }
      )
    );
    filters &&
      this.props.history.replace({ ...this.props.location, state: undefined });
  }
  handleBackMainFilter = () => {
    this.setState({
      categories: false,
      type: false,
      location: false,
      date: false,
      goBackFilter: false,
      dropDown: true,
      verticals: false,
      technologies: false,
    });
  };
  toggleNavbar = () => {
    if (
      !this.state.categories &&
      !this.state.type &&
      !this.state.location &&
      !this.state.date
    ) {
      this.setState({
        dropDown: !this.state.dropDown,
      });
    }
  };
  handleCloseFilter = () => {
    this.setState({
      dropDown: false,
      categories: false,
      type: false,
      location: false,
      date: false,
      verticals: false,
      technologies: false,
      goBackFilter: false,
      searchOpened: false,
    });
  };
  handleCategories = () => {
    this.setState({
      categories: !this.state.categories,
      goBackFilter: true,
    });
  };
  handleType = () => {
    this.setState({
      type: !this.state.type,
      goBackFilter: true,
    });
  };
  handleLocation = () => {
    this.setState({
      location: !this.state.location,
      goBackFilter: true,
    });
  };
  handleDate = () => {
    this.setState({
      date: !this.state.date,
      goBackFilter: true,
    });
  };
  handleVerticals = () => {
    this.setState({
      verticals: true,
      goBackFilter: true,
    });
  };
  handleTechnologies = () => {
    this.setState({
      technologies: true,
      goBackFilter: true,
    });
  };
  handleSearch = () => {
    this.setState({
      searchOpened: !this.state.searchOpened,
    });
  };
  render() {
    const {
      handleSelectedSegments,
      handleSelectAllSegments,
      handleSelectedAll,
      handleSelectedEventTypes,
      onDatesChange,
      onCalendarFocusChangeMobile,
      handleLocationChange,
      handleLocationInput,
      handleShowAllEvents,
      handleInput,
      searchInput,
      handleSearchSubmit,
      handleSelectLocation,
      checkedFilter,
      handleFilterCheckbox,
      verticalsCount,
      technologiesCount,
      handleClearAllFilters,
    } = this.props;
    const {
      dateStart,
      dateEnd,
      focusedInput,
      focusedInputMobile,
      selectedAllEventTypes,
      selectedEventTypes,
      selectedSegments,
      locationFilter,
      locationOptions,
      filtersLink,
    } = this.props.data;
    const selectedSegmentsLength = selectedSegments.value.length;

    const calendarClasses = cx({
      "col-lg-4": true,
      "initial-calendar": !dateStart && !dateEnd,
      "active-calendar": focusedInput,
    });

    const isLocationSelected = locationFilter ? 1 : 0;
    const isDateStartPicked = dateStart ? 1 : 0;
    const isDateEndPicked = dateEnd ? 1 : 0;
    const isFiltersChanged =
      selectedEventTypes.value.length === 9 &&
      selectedSegmentsLength === 4 &&
      isLocationSelected === 0 &&
      isDateStartPicked === 0 &&
      isDateEndPicked === 0 &&
      verticalsCount === 0 &&
      technologiesCount === 0
        ? false
        : true;
    return (
      <div>
        {/* closed filter */}
        <div className="Filter p-0">
          <div
            className="filter-block margin-b-20 row-pad d-block d-sm-block d-md-none"
            style={{ position: "relative" }}
          >
            <div className="row row-pad mobile-filter">
              {this.state.searchOpened ? (
                <>
                  <FaAngleLeft
                    onClick={this.handleCloseFilter}
                    className="display-flex"
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      height: "1.5em",
                      width: "1.5em",
                      margin: "auto",
                    }}
                  />
                  <form
                    onSubmit={handleSearchSubmit}
                    className="col-11 search-box unset-shadow"
                  >
                    <div className="row">
                      <div className="input-group">
                        <span className="search-icon w-100">
                          <Input
                            className="form-control search-border"
                            type="search"
                            onChange={handleInput}
                            value={searchInput}
                            name="searchInput"
                            placeholder="Search events, webinars, hackathons and more"
                            aria-label="Search events, webinars, hackathons and more"
                          />
                        </span>
                        <div className="input-group-prepend">
                          <button
                            className="btn search-btn text-white rounded background-blue py-3 px-4 d-block d-sm-block d-md-none"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div
                    onClick={this.handleSearch}
                    className={`col-4 padding-lr-7 search-mobile`}
                  >
                    <div className="display-flex vertical-align w-100 justify-center">
                      <img alt="" src="/images/zoom.svg" />{" "}
                      <div className="margin-l-10">Search</div>
                    </div>
                  </div>
                  <div
                    onClick={this.toggleNavbar}
                    className={`col-5 p-0 filter ${this.state.dropDown &&
                      "d-none"}`}
                  >
                    <div className="pointer">
                      {this.state.categories ||
                      this.state.type ||
                      this.state.location ||
                      this.state.verticals ||
                      this.state.technologies ||
                      this.state.date ? (
                        <FaTimes
                          onClick={this.handleCloseFilter}
                          style={{
                            color: "grey",
                            height: "1.5em",
                            width: "1.5em",
                          }}
                        />
                      ) : (
                        <img alt="" src="/images/filter-tool.svg" />
                      )}
                      Filters
                      <span className="badge">
                        <span>
                          {isFiltersChanged
                            ? selectedEventTypes.value.length +
                              selectedSegmentsLength +
                              isLocationSelected +
                              isDateStartPicked +
                              isDateEndPicked +
                              verticalsCount +
                              technologiesCount
                            : 0}
                        </span>
                      </span>
                    </div>
                    <a
                      target="_blank"
                      href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    >
                      <div className="add-block-filter">
                        <div className="add-sign-horizontal" />
                        <div className="add-sign-vertical" />
                      </div>
                    </a>
                  </div>
                  <div className="col-3 p-0 view">
                    <div className="col-6 listing-view">
                      <div>
                        <NavLink
                          exact
                          to={{
                            pathname: `/events`,
                            state: { filters: filtersLink },
                          }}
                          className="view"
                          activeClassName="active"
                        >
                          {window.location.pathname === "/events/calendar" ? (
                            <img alt="" src="/images/event_btn.svg" />
                          ) : (
                            <img alt="" src="/images/event_btn_selected.svg" />
                          )}
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-6 listing-view">
                      <div>
                        <NavLink
                          exact
                          to={{
                            pathname: `/events/calendar`,
                            state: { filters: filtersLink },
                          }}
                          className="view"
                          activeClassName="active"
                        >
                          {window.location.pathname === "/events/calendar" ? (
                            <img
                              alt=""
                              src="/images/calendar_btn_selected.svg"
                            />
                          ) : (
                            <img alt="" src="/images/calendar_btn.svg" />
                          )}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* opened filter */}
        {this.state.categories ||
        this.state.type ||
        this.state.location ||
        this.state.verticals ||
        this.state.technologies ||
        this.state.date ||
        this.state.dropDown ? (
          <div className={"top-screen Filter"}>
            <div
              className="filter-block d-block d-sm-block d-md-none"
              style={{ position: "relative" }}
            >
              <div className="row row-pad">
                <div
                  onClick={this.handleBackMainFilter}
                  className="col-8 vertical-align"
                >
                  <div>
                    {this.state.goBackFilter && (
                      <FaAngleLeft
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      />
                    )}
                    {this.state.categories
                      ? "Event Type"
                      : this.state.type
                      ? "Segment Type"
                      : this.state.location
                      ? "Location"
                      : this.state.date
                      ? "Date"
                      : this.state.verticals
                      ? "Verticals"
                      : this.state.technologies
                      ? "Technologies"
                      : "Filters"}
                    {this.state.categories ? (
                      <span className="badge">
                        <span>{selectedEventTypes.value.length}</span>
                      </span>
                    ) : this.state.type ? (
                      <span className="badge">
                        <span>{selectedSegmentsLength}</span>
                      </span>
                    ) : this.state.location ? (
                      <span className="badge">
                        <span>{isLocationSelected}</span>
                      </span>
                    ) : this.state.date ? (
                      <span className="badge">
                        <span>{isDateStartPicked + isDateEndPicked}</span>
                      </span>
                    ) : this.state.verticals ? (
                      <span className="badge">
                        <span>{verticalsCount}</span>
                      </span>
                    ) : this.state.technologies ? (
                      <span className="badge">
                        <span>{technologiesCount}</span>
                      </span>
                    ) : (
                      <span className="badge">
                        <span>
                          {isFiltersChanged
                            ? selectedEventTypes.value.length +
                              selectedSegmentsLength +
                              isLocationSelected +
                              isDateStartPicked +
                              isDateEndPicked
                            : 0}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4 vertical-align text-right">
                  <FaTimes
                    onClick={this.handleCloseFilter}
                    style={{
                      cursor: "pointer",
                      color: "grey",
                      height: "1.5em",
                      width: "1.5em",
                    }}
                  />
                </div>
              </div>
              <div className="row divider" />
              {this.state.categories ||
              this.state.type ||
              this.state.location ||
              this.state.date ||
              this.state.technologies ||
              this.state.searchOpened ||
              this.state.verticals ? (
                ""
              ) : (
                <>
                  <div className="row  row-pad ">
                    <div className="col vertical-align">
                      <form
                        onSubmit={handleSearchSubmit}
                        className="col-md-12 search-box unset-shadow"
                      >
                        <div className="row">
                          <div className="input-group">
                            <span className="search-icon">
                              <Input
                                className="form-control search-border"
                                type="search"
                                onChange={handleInput}
                                value={searchInput}
                                name="searchInput"
                                placeholder="Search events, webinars, hackathons and more"
                                aria-label="Search events, webinars, hackathons and more"
                              />
                            </span>
                            <div className="input-group-prepend">
                              <button
                                className="btn search-btn text-white rounded background-blue py-3 px-4 d-block d-sm-block d-md-none"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row divider" />
                </>
              )}

              {/* goBackFilter */}
              {this.state.categories ||
              this.state.type ||
              this.state.location ||
              this.state.date ||
              this.state.technologies ||
              this.state.verticals ? (
                ""
              ) : this.state.dropDown ? (
                <div isopen={`"${this.state.dropDown}"`}>
                  <div
                    className="row  row-pad pointer"
                    onClick={this.handleCategories}
                  >
                    <div className="col-8 vertical-align">
                      Event type
                      <span className="badge">
                        <span>{selectedEventTypes.value.length}</span>
                      </span>
                    </div>
                    <div className="col-4 text-right vertical-align">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div
                    className="row row-pad pointer"
                    onClick={this.handleType}
                  >
                    <div className="col-8 vertical-align">
                      Segment
                      <span className="badge">
                        <span>{selectedSegmentsLength}</span>
                      </span>
                    </div>
                    <div className="col-4 vertical-align text-right">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div
                    className="row row-pad pointer"
                    onClick={this.handleLocation}
                  >
                    <div className="col-8 vertical-align">
                      Location
                      <span className="badge">
                        <span>{isLocationSelected}</span>
                      </span>
                    </div>
                    <div className="col-4 vertical-align text-right">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div
                    className="row row-pad pointer"
                    onClick={this.handleDate}
                  >
                    <div className="col-8 vertical-align">
                      Date
                      <span className="badge">
                        <span>{isDateStartPicked + isDateEndPicked}</span>
                      </span>
                    </div>
                    <div className="col-4 vertical-align text-right">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div
                    className="row row-pad pointer"
                    onClick={this.handleVerticals}
                  >
                    <div className="col-8 vertical-align">
                      Verticals
                      <span className="badge">
                        <span>{verticalsCount}</span>
                      </span>
                    </div>
                    <div className="col-4 vertical-align text-right">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div
                    className="row row-pad pointer"
                    onClick={this.handleTechnologies}
                  >
                    <div className="col-8 vertical-align">
                      Technologies
                      <span className="badge">
                        <span>{technologiesCount}</span>
                      </span>
                    </div>
                    <div className="col-4 vertical-align text-right">
                      <FaAngleRight
                        className="display-flex"
                        style={{ margin: "0 0 0 auto" }}
                      />
                    </div>
                  </div>
                  <div className="row divider" />
                  <div className="row row-pad">
                    <div className="control-filters vertical-align">
                      <span
                        className="btn clear"
                        onClick={() => {
                          handleSelectLocation();
                          handleClearAllFilters();
                          handleSelectAllSegments();
                          handleShowAllEvents();
                          onDatesChange({
                            startDate: null,
                            endDate: null,
                          });
                        }}
                      >
                        Clear All Filters
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.categories ? (
                <div className="categories-filter">
                  <div className="filters-sidebar bg-white row d-block">
                    <div className="filter px-3">
                      <label
                        className="custom-control-label d-flex row-pad"
                        htmlFor="evenType_all"
                      >
                        <div className="col-12 vertical-align p-0">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              value=""
                              id="evenType_all"
                              name="evenType_all"
                              onChange={handleSelectedAll}
                              checked={selectedAllEventTypes}
                            />
                            <span>Select all</span>
                            {selectedAllEventTypes && (
                              <div className="blue-check">
                                <FaCheck />
                              </div>
                            )}
                          </div>
                        </div>
                      </label>
                      {/* <span>Select all</span>
                                    <CustomCheckBox onChange={handleSelectedAll} checked={selectedAllEventTypes} /> */}
                    </div>
                    {selectedEventTypes.values.map((evenType, key) => (
                      <div key={key} className="filter px-3">
                        <div className="row row-pad">
                          <label
                            className="custom-control-label d-flex row-pad"
                            htmlFor={evenType}
                          >
                            <div className="col-12 vertical-align">
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  value=""
                                  id={evenType}
                                  name={evenType}
                                  onChange={handleSelectedEventTypes}
                                  checked={selectedEventTypes.value.includes(
                                    evenType
                                  )}
                                />

                                <div>{evenType}</div>
                                <img
                                  src={getEventTypeIcon(evenType)}
                                  className="event-type-images"
                                  alt={evenType}
                                />

                                {selectedEventTypes.value.includes(
                                  evenType
                                ) && (
                                  <div className="blue-check">
                                    <FaCheck />
                                  </div>
                                )}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row divider" />
                </div>
              ) : (
                ""
              )}
              {this.state.type ? (
                <div isOpen={this.state.type} className="type-filter">
                  {selectedSegments.values.map((segment, key) => (
                    <div key={key}>
                      <div className="row row-pad">
                        <div className="col-12 vertical-align">
                          <label
                            className="custom-control-label d-flex row-pad"
                            for={segment}
                          >
                            <div className="custom-control custom-checkbox d-flex vertical-align">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                value=""
                                id={segment}
                                name={segment}
                                onChange={handleSelectedSegments}
                                checked={selectedSegments.value.includes(
                                  segment
                                )}
                              />
                              {segment}
                              {selectedSegments.value.includes(segment) && (
                                <div className="blue-check">
                                  <FaCheck />
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="row divider" />
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {this.state.verticals ? (
                <Verticals
                  IsChecked={IsChecked}
                  checkedFilter={checkedFilter}
                  handleFilterCheckbox={handleFilterCheckbox}
                />
              ) : (
                ""
              )}
              {this.state.technologies ? (
                <Technologies
                  IsChecked={IsChecked}
                  checkedFilter={checkedFilter}
                  handleFilterCheckbox={handleFilterCheckbox}
                />
              ) : (
                ""
              )}
              {this.state.location ? (
                <>
                  <div isOpen={this.state.location} className="location-filter">
                    <Select
                      style={{ position: "none" }}
                      options={locationOptions}
                      isClearable={true}
                      name="Location"
                      inputValue={locationFilter}
                      value={locationFilter}
                      onChange={handleLocationChange}
                      onInputChange={handleLocationInput}
                      components={{
                        Option: CustomOption,
                        IndicatorSeparator: false,
                        DropdownIndicator: false,
                      }}
                      placeholder="Location"
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleSelectLocation({
                        value: "ChIJOwg_06VPwokRYv534QaPC8g",
                        label: "New York",
                        longLabel: "USA",
                      })
                    }
                    className="row location-options"
                  >
                    <div className="col-12 vertical-align">
                      <div className="display-flex">
                        <div>New York</div>
                        <div className="grey-text">NY, USA</div>
                      </div>
                    </div>
                    {/* <div className="col-1 vertical-align p-0">
                                    <FaAngleRight className="dark-blue" />
                                </div> */}
                  </div>
                  <div
                    onClick={() =>
                      handleSelectLocation({
                        value: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                        label: "San Francisco",
                        longLabel: "USA",
                      })
                    }
                    className="row location-options"
                  >
                    <div className="col-12 vertical-align">
                      <div className="display-flex">
                        <div>San Francisco</div>
                        <div className="grey-text">CA, USA</div>
                      </div>
                    </div>
                    {/* <div className="col-1 vertical-align p-0">
                                    <FaAngleRight className="dark-blue" />
                                </div> */}
                  </div>
                  <div
                    onClick={() =>
                      handleSelectLocation({
                        value: "ChIJVTPokywQkFQRmtVEaUZlJRA",
                        label: "Seattle",
                        longLabel: "USA",
                      })
                    }
                    className="row location-options"
                  >
                    <div className="col-12 vertical-align">
                      <div className="display-flex">
                        <div>Seattle</div>
                        <div className="grey-text">WA, USA</div>
                      </div>
                    </div>
                    {/* <div className="col-1 vertical-align p-0">
                                    <FaAngleRight className="dark-blue" />
                                </div> */}
                  </div>
                  <div
                    onClick={() =>
                      handleSelectLocation({
                        value: "ChIJGzE9DS1l44kRoOhiASS_fHg",
                        label: "Boston",
                        longLabel: "USA",
                      })
                    }
                    className="row location-options"
                  >
                    <div className="col-12 vertical-align">
                      <div className="display-flex">
                        <div>Boston</div>
                        <div className="grey-text">MA, USA</div>
                      </div>
                    </div>
                    {/* <div className="col-1 vertical-align p-0">
                                    <FaAngleRight className="dark-blue" />
                                </div> */}
                  </div>
                  <div
                    onClick={() =>
                      handleSelectLocation({
                        value: "ChIJLwPMoJm1RIYRetVp1EtGm10",
                        label: "Austin",
                        longLabel: "USA",
                      })
                    }
                    className="row location-options"
                  >
                    <div className="col-12 vertical-align">
                      <div className="display-flex">
                        <div>Austin</div>
                        <div className="grey-text">TX, USA</div>
                      </div>
                    </div>
                    {/* <div className="col-1 vertical-align p-0">
                                    <FaAngleRight className="dark-blue" />
                                </div> */}
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.date ? (
                <div
                  isopen={`"${this.state.dropDown}"`}
                  className="date-filter"
                >
                  <div className={calendarClasses}>
                    <div
                      className={`ClearButton-container small-date vertical-align `}
                    >
                      <ClearButton
                        manualPadding="0 18px"
                        active={focusedInputMobile}
                        clearInfo={() =>
                          onDatesChange({
                            startDate: null,
                            endDate: null,
                          })
                        }
                        hidden={!dateStart && !dateEnd}
                      />
                      <DateRangePicker
                        //showClearDates

                        minimumNights={1}
                        navPrev={
                          <div className="prev">
                            <FaAngleLeft fill="#788ab3" />
                          </div>
                        }
                        navNext={
                          <div className="next">
                            <FaAngleRight fill="#788ab3" />
                          </div>
                        }
                        startDatePlaceholderText="Start: "
                        endDatePlaceholderText="End: "
                        startDate={dateStart}
                        startDateId="start_date_id_1"
                        endDate={dateEnd}
                        endDateId="end_date_id_1"
                        onDatesChange={onDatesChange}
                        focusedInput={focusedInputMobile}
                        onFocusChange={onCalendarFocusChangeMobile}
                        displayFormat="ddd, MMM D, YYYY"
                        orientation="vertical"
                        // numberOfMonths={30}
                        small
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end or filters top */}
      </div>
    );
  }
}
