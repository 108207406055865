import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import { Button, Tooltip } from "reactstrap";
import BlueTag from "./components/BlueTag.jsx";
import PurpuleTag from "./components/PurpuleTag.jsx";
import Highlights from "./components/Highlights.jsx";
import MiddleSwitcher from "./components/MiddleSwitcher.jsx";
import SideSwitcher from "./components/SideSwitcher.jsx";
import CardsTitle from "../components/CardsTitle/CardsTitle";
import { FaShareAlt, FaThumbsUp, FaRegThumbsUp } from "react-icons/fa";
import { StickyContainer } from "react-sticky";
import moment from "moment-timezone";
import getTimezoneNumber from "../utils/getTimezoneNumber";
import "../components/scss/breadcrumbs.scss";
import "./EventId.scss";
import "../Events/labels.scss";
import { Helmet } from "react-helmet";

class PreviewController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      locationAddress: "",
      locationUrl: "",
      showModal: false,
      likeData: null,
      eventData: null,
      loading: true,
      showContent: false,
      showShare: false,
      showSmallShare: false,
      showDiscountCode: false,
      showSmallDiscountCode: false,
      randPicNumber: Math.round(Math.random()),
      tooltipOpen: false,
      tooltipOpenHeader: false,
      tooltipSmallOpenHeader: false,
      showSticky: false,
      copied: false,
      test: false,
      modal: false,
      firstLoad: true,
      thumbsUp: false,
      likeLength: 0,
    };
    this.isBottom = this.isBottom.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
  }
  tooltip = () => this.setState(({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen }));
  tooltipHeader = () => this.setState(({ tooltipOpenHeader }) => ({ tooltipOpenHeader: !tooltipOpenHeader }));
  handleShowContent = () => this.setState(({ showContent }) => ({ showContent: !showContent }));
  closeDiscountCode = () => this.setState({ showDiscountCode: false, showSmallDiscountCode: false });
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  tooltipSmallHeader = () => this.setState(({ tooltipSmallOpenHeader }) => ({ tooltipSmallOpenHeader: !tooltipSmallOpenHeader }));

  async componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);

    function URLToArray(url) {
      var request = {};
      var verticals = [];
      var horizontals = [];
      var Sp = [];
      var organizers = [];
      let array = {};
      array = decodeURIComponent(url)
        .replace("?q=", "")
        .split(",");
      console.log(array);
      for (var i = 0; i < array.length; i++) {
        if (array[i].split("=")[0].includes("verticals")) {
          verticals.push(decodeURIComponent(array[i].split("=")[1]));
        } else if (array[i].split("=")[0].includes("horizontals")) {
          horizontals.push(decodeURIComponent(array[i].split("=")[1]));
        } else if (array[i].split("=")[0].includes("SpI")) {
          let a = decodeURIComponent(array[i].split("=")[1]);
          Sp.push({
            image: a.split("Img")[1].split("FN")[0],
            firstName: a.split("FN")[1].split("LN")[0],
            lastName: a.split("LN")[1].split("LI")[0],
            linkedin: a.split("LI")[1].split("T")[0],
            title: a.split("T")[1].split("B")[0],
            biography: a.split("B")[1].split("CN")[0],
            comapny: { name: a.split("CN")[1].split("L")[0] },
          });
        } else if (array[i].split("=")[0].includes("Organizer")) {
          let a = decodeURIComponent(array[i].split("=")[1]);
          organizers.push({ info: { logo: a.split("logo")[1].split("id")[0], id: a.split("id")[1].split("L")[0] } });
        } else {
          request[array[i].split("=")[0]] = array[i].split("=")[1] ? decodeURIComponent(array[i].split("=")[1]) : "";
        }
      }
      request["verticals"] = verticals;
      request["horizontals"] = horizontals;
      request["speakers"] = Sp;
      request["organizers"] = organizers;

      return request;
    }
    if (this.props.location.search.includes("q=")) {
      this.setState({
        data: URLToArray(this.props.location.search),
        loading: false,
      });
    }
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= 70;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("eventid-buttons");
    if (!this.state.loading) {
      if (this.isBottom(wrappedElement)) {
        if (!this.state.showSticky) {
          this.setState({
            showSticky: true,
          });
        }
      } else {
        if (this.state.showSticky) {
          this.setState({
            showSticky: false,
          });
        }
      }
    }
  };

  render() {
    const linkLocation = this.props.location.state && this.props.location.state;
    const prevQuery = linkLocation ? (linkLocation.prevQuery ? linkLocation.prevQuery : "") : "";
    const filters = linkLocation && linkLocation.filters ? linkLocation.filters : [];
    const { loading, locationAddress, locationUrl, data } = this.state;

    if (!loading) {
      const {
        startTime,
        endTime,
        eventType,
        title,
        speakers,
        website,
        registration,
        organizers,
        sponsors,
        discountCode,
        street1,
        city,
        stateCode,
        zipCode,
        country,
        banner,
        horizontals,
        verticals,
        shortDescription,
        longDescription,
      } = data;

      const isLoggedIn = this.props.user ? true : false;

      const locationType = Object.assign(
        [],
        data.street1 && { 0: `${data.street1}` },
        data.city && data.street1 ? { 1: `, ${data.city}` } : data.city && { 1: `${data.city}` },
        data.stateCode && (data.street1 || data.city) ? { 2: `, ${data.stateCode}` } : data.stateCode && { 2: `${data.stateCode}` },
        data.zipCode && (data.street1 || data.stateCode || data.city) ? { 3: `, ${data.zipCode}` } : data.zipCode && { 3: `${data.zipCode}` },
        data.country && (data.street1 || data.stateCode || data.city || data.zipCode)
          ? { 3: `, ${data.country}` }
          : data.country && { 3: `${data.country}` }
      ).join("");

      let time = "";
      const startTimee = moment.unix(startTime);
      const endTimee = moment.unix(endTime);
      const timeZone = endTimee.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endTimee.format("Z"))}[)]`);
      const timeTabletZone = endTimee.tz(moment.tz.guess()).format(`[GMT]${getTimezoneNumber(endTimee.format("Z"))}[]`);
      const eventInDays = moment.unix(endTimee).format("DD") - moment.unix(startTimee).format("DD");
      const isEventInSameMonth = moment.unix(endTimee).format("MM") === moment.unix(startTimee).format("MM") ? true : false;
      const endTimeMoment = moment.unix(endTimee);
      const tabletTimeStart = <div className="time display-flex vertical-align margin-l-5"> {`${startTimee.format("hh:mmA")} -`} </div>;

      const tabletTimeEnd = (
        <div className="time display-flex vertical-align margin-l-5">
          {endTimee.format("hh:mmA")} {timeTabletZone}{" "}
        </div>
      );

      const tabletDate =
        startTimee && endTimee && eventInDays === 0
          ? moment.unix(startTimee).format("MMMM DD, YYYY")
          : isEventInSameMonth
          ? `${moment.unix(startTimee).format("MMMM DD")}-${endTimeMoment.format("DD")}, ${moment.unix(startTimee).format("YYYY")}`
          : `${moment.unix(startTimee).format("MMMM DD")} - ${endTimeMoment.format("MMMM DD")}, ${moment.unix(startTimee).format("YYYY")}`;

      if (startTimee.isSame(endTimee, "day")) {
        time = (
          <div className="row pb-16 datetime">
            <div className="col-4 col-sm-3">
              <div className="date">
                {startTimee.format("MMM")}
                <br />
                {startTimee.format("DD")}
              </div>
            </div>
            <div className="col-8 time">
              {startTimee.format("hh:mm A")} {timeZone}
              <br />
              {endTimee.format("hh:mm A")} {timeZone}
            </div>
          </div>
        );
      } else if (startTimee.isSame(endTimee, "month")) {
        time = (
          <>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {startTimee.format("MMM")}
                  <br />
                  {startTimee.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {startTimee.format("hh:mm A")} {timeZone}
              </div>
            </div>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date single">
                  {endTimee.format("MMM")}
                  <br />
                  {endTimee.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {endTimee.format("hh:mm A")} {timeZone}
              </div>
            </div>
          </>
        );
      } else {
        time = (
          <>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {startTimee.format("MMM")}
                  <br />
                  {startTimee.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {startTimee.format("hh:mm A")} {timeZone}
              </div>
            </div>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {endTimee.format("MMM")}
                  <br />
                  {endTimee.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {endTimee.format("hh:mm A")} {timeZone}
              </div>
            </div>
          </>
        );
      }

      const extraInfo = (
        <div className="likes mobile-like d-flex">
          <div className="likes">{this.state.likeLength}</div>
          <Button color="link" className="likes">
            {this.state.thumbsUp ? <FaThumbsUp /> : <FaRegThumbsUp />}
          </Button>
        </div>
      );

      return (
        <div className="EventId">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title ? `${title} | IoT Nation` : "IoT Nation"}</title>
          </Helmet>
          <div className={`d-none stickyHeader ${this.state.showSticky ? "visible d-md-block" : ""}`}>
            <div className={`moved-sticky`}>
              <div className={this.state.showSticky ? "container" : ""}>
                <div className="row m-0">
                  <div className="col-3 col-sm-3 col-md-3 col-xl-6 d-flex vertical-align">
                    <p className="title-middle-block ">{title}</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align padding-lr-md-7">
                    <a target="_blank" className="w-100" href={website}>
                      <Button className="website-btn">Website</Button>
                    </a>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align w-100 padding-lr-md-7">
                    <a target="_blank" className="w-100" href={registration}>
                      <Button className="register-btn">Register</Button>
                    </a>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align padding-lr-md-7">
                    <div className="w-100">
                      <Button
                        className="discount-btn"
                        onClick={this.discountPopover}
                        style={discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                        id={"DisabledHover1"}
                      >
                        Get&nbsp;Discount&nbsp;Code
                      </Button>
                      {!discountCode && isLoggedIn && this.state.showSticky ? (
                        <Tooltip
                          placement="top"
                          isOpen={this.state.tooltipOpenHeader}
                          autohide={false}
                          target={"DisabledHover1"}
                          toggle={this.tooltipHeader}
                        >
                          No Discount Code from Organizer
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  {prevQuery.includes("/events/calendar") ? (
                    <Link to={{ pathname: "/events/calendar" }}>Calendar</Link>
                  ) : prevQuery.includes("/events") ? (
                    <Link to={{ pathname: `/events`, state: { filters } }}>Event List</Link>
                  ) : prevQuery.includes("/sector") ? (
                    <Link to={{ pathname: `/sector` }}>Sector</Link>
                  ) : (
                    <Link to="/events">Event List</Link>
                  )}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Event
                </li>
              </ol>
            </nav>
          </div>
          <StickyContainer>
            <div className="container">
              <div className="row mobile-mar">
                <div className="col-3 d-none d-lg-block">
                  <div className="position-fixed">
                    <Highlights
                      locationAddress={locationAddress}
                      locationUrl={locationUrl}
                      eventType={eventType}
                      title={title}
                      startTime={startTime}
                      endTime={endTime}
                      street1={street1}
                      city={city}
                      stateCode={stateCode}
                      zipCode={zipCode}
                      country={country}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-1 col-xl-6 offset-xl-0 mobile-pad">
                  {banner ? (
                    <img className="main-image" src={banner} alt="" />
                  ) : (
                    eventType && (
                      <CardsTitle
                        isExtraInfo={banner ? false : true}
                        extraInfo={extraInfo}
                        image={`/images/${eventType.replace(/\s/g, "")}-white.svg`}
                        titleName={eventType}
                        name={"description"}
                      />
                    )
                  )}
                  <div className={banner && eventType.toLowerCase() !== "webinar" ? `side-block-border first` : `side-block-border first`}>
                    <div className={`p-0-32-16-32`}>
                      <div>
                        <div className="row">
                          {eventType && banner ? <div className="d-flex col-5 vertical-align blue-color2">{eventType && eventType}</div> : ""}
                          {banner && (
                            <div className="likes d-flex">
                              <div className="likes">{this.state.likeLength}</div>
                              <Button color="link" className="likes">
                                {this.state.thumbsUp ? <FaThumbsUp /> : <FaRegThumbsUp />}
                              </Button>
                            </div>
                          )}
                        </div>

                        <p className="title-middle-block">{title}</p>

                        <div className="row social-links position-relitive">
                          <div className="col-12">
                            <span className="float-r pointer d-none d-md-block" to="#" id={"Popover2"} onClick={this.togglePopover}>
                              <FaShareAlt style={{ color: "#1B7BEB", fontSize: "14px", position: "absolute", right: "0px", top: "10px" }} />
                            </span>
                            <span className="float-r pointer d-block d-md-none" to="#" id={"SmallPopver1"} onClick={this.toggleSmallPopover}>
                              <FaShareAlt style={{ color: "#1B7BEB", fontSize: "14px", position: "absolute", right: "15px", top: "10px" }} />
                            </span>
                          </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none ">
                          <p className="section-title">When & Where</p>
                          <div className="event-place">
                            <div className="container pl-24 pr-24 pb-24 pt-24">
                              <p style={{ fontSize: "13px", fontStyle: "italic" }}>Event is shown in your local time.</p>
                              {time}
                              {locationType && (
                                <div className="row pb-16">
                                  <div className="col-1">
                                    <img className="image small-icon" src="/images/pin-3.svg" alt="" />
                                  </div>
                                  <div className="col-10">
                                    {this.state.locationAddress ? (
                                      <a
                                        className="adress"
                                        target="_blank"
                                        href={`https://www.google.com/maps/search/?api=1&query=${this.state.locationUrl}`}
                                      >
                                        <span>{locationType}</span>
                                      </a>
                                    ) : (
                                      locationType
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="button-padding">
                                <button className="view active w-100">+ Add to Calendar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="d-block d-sm-block d-md-none" />
                        <div id="eventid-buttons" className="row mt-24">
                          <div className="col-md-4">
                            <a target="_blank" href={website}>
                              <Button className="website-btn">Website</Button>
                            </a>
                          </div>
                          <div className="col-md-4  d-none d-md-block">
                            <a target="_blank" href={registration}>
                              <Button className="register-btn">Register</Button>
                            </a>
                          </div>
                          <div className="col-md-4 d-none d-md-block p-0">
                            <div className="">
                              <Button
                                className="discount-btn"
                                onClick={this.discountPopover}
                                style={discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                                id={"DisabledHover2"}
                              >
                                Get&nbsp;Discount&nbsp;Code
                              </Button>
                              {!discountCode && isLoggedIn && !this.state.showSticky ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={this.state.tooltipOpenHeader}
                                  autohide={false}
                                  target={"DisabledHover2"}
                                  toggle={this.tooltipHeader}
                                >
                                  No Discount Code from Organizer
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="side-block-border p-16-32">
                    <div className="d-none d-md-block d-lg-none">
                      <hr />
                      <div className="event-place">
                        <div className="container pl-24 pr-24 pb-24 pt-24">
                          <p style={{ fontSize: "13px", fontStyle: "italic" }}>Event is shown in your local time.</p>
                          <div className="row">
                            <div className="col-4 padding-lr-7 display-flex">
                              <img src="/images/calendar-1.svg" alt="" />
                              <div className="date display-flex vertical-align margin-l-5"> {tabletDate} </div>
                            </div>
                            <div className="col-5 padding-lr-7 display-flex">
                              <img src="/images/clock.svg" alt="" />
                              {tabletTimeStart}
                              {tabletTimeEnd}
                            </div>
                            <div className="button-padding col-3 p-0">
                              <button className="view active w-100">+ Add to Calendar</button>
                            </div>
                          </div>
                          {locationType && (
                            <div className="row">
                              <div className="col-12 display-flex vertical-align padding-lr-7">
                                <img className="image small-icon" src="/images/pin-3.svg" alt="" />
                                <div className="margin-l-5">
                                  {this.state.locationAddress ? (
                                    <a
                                      className="adress"
                                      target="_blank"
                                      href={`https://www.google.com/maps/search/?api=1&query=${this.state.locationUrl}`}
                                    >
                                      <span>{locationType}</span>
                                    </a>
                                  ) : (
                                    locationType
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <p className="section-title">Tags</p>
                    <div className="display-flex">
                      {horizontals && horizontals.map((item, key) => <div key={key}>{item === "" ? null : <BlueTag name={item} />}</div>)}
                    </div>
                    <div className="display-flex">
                      {verticals && verticals.map((item, key) => <div key={key}>{item === "" ? null : <PurpuleTag name={item} />}</div>)}
                    </div>
                    <hr />

                    <p className="section-title">Event Description</p>
                    <div className={this.state.showContent ? "toggle" : "toggle hidden"}>
                      <p
                        className="text-style"
                        dangerouslySetInnerHTML={
                          longDescription !== ""
                            ? { __html: longDescription.replace(/--+/g, "<br />") }
                            : { __html: shortDescription.replace(/--+/g, "< br />") }
                        }
                      />
                    </div>
                    <hr />
                    <span onClick={this.handleShowContent} className="justify-center display-flex pointer text-decoration">
                      {this.state.showContent ? "View Less" : "View More"}
                    </span>
                  </div>
                  <MiddleSwitcher {...{ eventType, speakers, organizers, sponsors }} />
                </div>
                <div className="col-3 d-none d-xl-block">
                  <SideSwitcher {...{ eventType, speakers, organizers, sponsors }} />
                </div>
              </div>
            </div>
          </StickyContainer>
          <div className="fixed-bottom d-block d-sm-block d-md-none">
            <div className="row">
              <div className="col-6 pr5">
                <div>
                  <Button
                    className="discount-btn"
                    onClick={this.discountSmallPopover}
                    style={discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                    id={"DisabledHoverSmall1"}
                  >
                    Get Discount Code
                  </Button>
                  {!discountCode && isLoggedIn ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipSmallOpenHeader}
                      autohide={false}
                      target={"DisabledHoverSmall1"}
                      toggle={this.tooltipSmallHeader}
                    >
                      No Discount Code from Organizer
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-6 pl5">
                <a target="_blank" href={registration}>
                  <Button className="register-btn">Register</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
  }
}

export default withRouter(PreviewController);
