import React, { Component } from 'react'
import DonutChart from './DonutChart'

import DonutChartWithLegend from './DonutChartWithLegend'
import SubscriberContent from '../../../../components/SubscriberContent/SubscriberContent';
import './custom.scss'

export default class Scores extends Component {
    render() {
        const {score, components, scoreIsFaked}=this.props
        let { companyProfile, financialData } = this.props
        if(!companyProfile) {
            companyProfile = {};
        }
        if(!financialData) {
            financialData = {};
        }
        return (
            <div className="card-holder">
                <div className={`row scores py-4 px-2 ${scoreIsFaked?'blurred-box':''}`}>
                    <div className="first col-12 col-lg-6 mb-3 mb-lg-0">
                        <DonutChart score={score} colors={['#a485f3','#22c0c2','#e2e6ee']}></DonutChart>
                    </div>
                    <div className="second mx-0 col-12 col-lg-6">
                        <DonutChartWithLegend components={components}></DonutChartWithLegend>
                    </div>
                </div>
                <SubscriberContent target="scores" hidden={scoreIsFaked}/>
            </div>
        )
    }
}