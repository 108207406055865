import React, { Component } from 'react'
import TableRow from './components/TableRow'
import AddCode from  './components/AddCode'
import {Helmet} from "react-helmet";
import './ActivationCode.scss'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
export default class ActivationCode extends Component {
    constructor(props){
        super(props)
        this.state = {
            sorting:{
                type: false,
                sort: false,
                
            },
            propsResults: this.props.results
        }
    }
    handleSortCode = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'code',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.code.localeCompare(a.code))
            })
        }else{
            this.setState({ 
                sorting:{type: 'code',sort: 'asc'},
                propsResults: this.state.propsResults.sort((a,b) => a.code.localeCompare(b.code))
            })
        }   
    }
    handleSortPeriod = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'period',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.period - a.period)
            })
        }else{
            this.setState({ 
                sorting:{type: 'period', sort: 'asc' },
                propsResults: this.state.propsResults.sort((a,b) => a.period - b.period)
            })
        }
    }
    handleSortExpiration = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'expiration',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.expirationDate - a.expirationDate)
            })
        }else{
            this.setState({ 
                sorting:{type: 'expiration',sort: 'asc'},
                propsResults: this.state.propsResults.sort((a,b) => a.expirationDate - b.expirationDate)
            })
        }
    }
    handleSortStatus = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'status',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.expirationDate - a.expirationDate)
            })
        }else{
            this.setState({ 
                sorting:{type: 'status',sort: 'asc'},
                propsResults: this.state.propsResults.sort((a,b) => a.expirationDate - b.expirationDate)
            })
        }
    }
    handleSortLevel = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'level',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.accessLevel.localeCompare(a.accessLevel))
            })
        }else{
            this.setState({ 
                sorting:{type: 'level',sort: 'asc'},
                propsResults: this.state.propsResults.sort((a,b) => a.accessLevel.localeCompare(b.accessLevel))
            })
        }  
    }
    handleSortDescription = () => {
        if (this.state.sorting.sort !== 'desc'){
            this.setState({ 
                sorting:{type: 'description',sort: 'desc'}, 
                propsResults: this.state.propsResults.sort((a,b) => b.description.localeCompare(a.description))
            })
        }else{
            this.setState({ 
                sorting:{type: 'description',sort: 'asc'},
                propsResults: this.state.propsResults.sort((a,b) => a.description.localeCompare(b.description))
            })
        } 
    }
    render() {
        const { formData, handleSubmit, handleChangeDate, handleChange, handleDelete, handleUpdate, update, handleEdit, handleReject} = this.props
        const { sorting, propsResults } = this.state
        return (
            <div className='ActivationCode'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Activation Code</title>
                </Helmet> 
                <div className='container main-table'>
                    <div className="row">
                        <div className={`pointer code col-2 ${sorting.type === 'code' && 'font-weight-bold'}`} onClick={this.handleSortCode}>Code{sorting.type === 'code' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'code' ? <FaChevronUp /> : '' }</div>
                        <div className={`pointer membership col-2 ${sorting.type === 'period' && 'font-weight-bold'}`} onClick={this.handleSortPeriod}>Period of Membership{sorting.type === 'period' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'period' ? <FaChevronUp /> : '' }</div>
                        <div className={`pointer expiration-date col-2 ${sorting.type === 'expiration' && 'font-weight-bold'}`} onClick={this.handleSortExpiration}>Expiration Date{sorting.type === 'expiration' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'expiration' ? <FaChevronUp /> : '' }</div>
                        <div className={`pointer time-left col-1 ${sorting.type === 'status' && 'font-weight-bold'}`} onClick={this.handleSortStatus}>Status{sorting.type === 'status' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'status' ? <FaChevronUp /> : '' }</div>
                        <div className={`pointer level col-1 ${sorting.type === 'level' && 'font-weight-bold'}`} onClick={this.handleSortLevel}>Level{sorting.type === 'level' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'level' ? <FaChevronUp /> : '' }</div>
                        <div className={`pointer description col-2 ${sorting.type === 'description' && 'font-weight-bold'}`} onClick={this.handleSortDescription}>Description{sorting.type === 'description' && sorting.sort === 'desc'  ? <FaChevronDown />: sorting.type === 'description' ? <FaChevronUp /> : '' }</div>
                        <div className="delete col-1">DELETE</div>
                    </div>
                    {
                        propsResults&&propsResults.map((item, key) => 
                            <TableRow
                                key={key}
                                results={item}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        )
                    }
                </div>
                <AddCode
                    handleChange={handleChange}
                    formData={formData}
                    handleSubmit={handleSubmit}
                    handleChangeDate={handleChangeDate}
                    update={update}
                    handleUpdate={handleUpdate}
                    handleReject={handleReject}
                />
            </div>
        )
    }
}
