import React from "react";
import Contacts from "./Contacts.jsx";
import qs from "query-string";
import { isString } from "util";
import _ from "lodash";
import { Redirect } from "react-router";
import * as contactFiltersJSON from "../assets/contactFilters.json";
import * as hiddenFiltersJSON from "../assets/hiddenFilters.json";
import aFC from "../assets/aFC.json";
import { isObject } from "util";
import getSuggestions from "./functions/getSuggestions";
import debounce from "lodash/debounce";
import filterCheckbox from "../components/AdvancedFilters/Functions/filterCheckbox";
import clearFilter from "../components/AdvancedFilters/Functions/clearFilter";
import clearAllFilters from "../components/AdvancedFilters/Functions/clearAllFilters.jsx";
import selectLocation from "../components/AdvancedFilters/Functions/selectLocation.jsx";
import locationChange from "../components/AdvancedFilters/Functions/locationChange.jsx";
import advancedFilterCleaning from "../components/AdvancedFilters/Functions/advancedFilterCleaning.jsx";
import GeneratedApi from "../components/functions/generatedApi.js";
import flattenObject from "../components/AdvancedFilters/Functions/flattenObject.js";

const changeAnd = (item) => {
  return typeof item === "string" ? item.replace("&", "theAnd") : item;
};
class ContactsContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      firstLoad: true,
      noData: false,
      TotalFundingAdded: false,
      locationFilter: [],
      LocationArray: "",
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      locationCount: 0,
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      search: {
        searchResults: [],
        count: 0,
        start: 0,
        limit: 10,
        useCases: [],
        loading: true,
        q: {},
      },
      currentPage: 1,
      contactQuery: "",
      query: "",
      contactAdvancedFilters: [],
      advancedFilters: [],
      fullProfiles: 1,
      limitReached: false,
      sort: {
        attribute: "rank",
        how: "asc",
      },
      advancedFiltersTriggered: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.getSchemas();
    await this.getQueryParameters(true, true);
    const Location = this.state.advancedFilters.find(
      (filter, i) => filter.attribute === "Location"
    );

    if (Location) {
      this.setState({
        locationFilter: { value: Location.value, label: Location.value },
      });
    }
    const { currentPage } = this.state;

    const { limit } = this.state.search;

    let offset = Math.ceil((currentPage - 1) * limit);

    const { query, contactQuery, contactAdvancedFilters } = this.state;
    let { sort } = this.state;
    const advancedFilters = advancedFilterCleaning(
      this.state.advancedFilters,
      this.props.user
    );

    this.setState(advancedFilters.stateSet);
    const q = {
      companyQuery: {
        filters: advancedFilters.dataForPost,
        search: {
          q: query,
          start: 0,
          limit: 20,
        },
      },
      contactQuery: {
        filters: contactAdvancedFilters,
        search: {
          q: contactQuery,
          start: offset,
          limit: limit,
        },
      },
    };
    GeneratedApi("", true, true)
      .post("/contacts/gridView", q)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        if (this._isMounted) {
          this.setState({
            search: response,
            sort,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  }

  async getSchemas() {
    await GeneratedApi("", false, false)
      .get("/schemas/contactFilter")
      .then((res) => {
        if (this._isMounted === true) {
          this.setState({
            contactFilterFields: {
              ...contactFiltersJSON.default,
              schemas: res.data.schemas,
              hiddenFilters: hiddenFiltersJSON.default,
            },
          });
        }
      });

    await GeneratedApi("", false, false)
      .get("/schemas/companyFilter")
      .then((res) => {
        if (this._isMounted === true) {
          const reso = res.data.schemas;
          reso.organizations = [
            { name: " ", years: [""] },
            ...reso.organizations,
          ];
          reso.ecosystems = [{ name: " ", years: [""] }, ...reso.ecosystems];
          reso.conferences = [{ name: " ", years: [""] }, ...reso.conferences];
          reso.technologies = ["", ...reso.technologies];
          reso.verticals = ["", ...reso.verticals];
          reso["private/public"] = ["", ...reso["private/public"]];
          this.setState({
            companyFilterFields: {
              ...aFC,
              schemas: reso,
              classificationArray: flattenObject(reso.classification),
            },
          });
        }
      });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      await this.getQueryParameters();
      if (this.props.location.pathname === "/contacts") {
        await this.setState({
          currentPage: 1,
          search: {
            ...this.state.search,
            limit: this.state.limit,
            start: 0,
          },
          query: "",
          contactAdvancedFilters: [],
        });
      }
      this.handleSearchQuery(this.state);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getQueryParameters = async (unique = false, runCheck = false) => {
    let { attributes, schemas } = this.state.contactFilterFields;

    let queryObj = qs.parse(this.props.location.search);
    if (queryObj.length === 0) {
      return;
    }
    let contactFiltersArr = Object.keys(queryObj)
      .map((key) => {
        if ("contactFilter" === key.replace(/[0-9]/g, "")) {
          let tempArr = queryObj[key].split("_");
          if (tempArr[3]) {
            return {
              attribute: tempArr[0],
              operator: tempArr[1],
              value: String(tempArr[2]).replace(" theAnd ", " & "),
              attribute2: tempArr[3],
              operator2: tempArr[4],
              value2: String(tempArr[5]).replace(" theAnd ", " & "),
            };
          } else {
            return {
              attribute: tempArr[0],
              operator: tempArr[1],
              value: tempArr[2].replace(" theAnd ", " & "),
            };
          }
        }
        return false;
      })
      .filter((val) => {
        if (!runCheck) return val;

        if (val !== null && typeof val === "object") {
          return this.processFilter(val, attributes, schemas);
        }
        return val;
      });

    if (unique) contactFiltersArr = _.uniqWith(contactFiltersArr, _.isEqual);

    let { limit, q = "", currentPage, fullProfiles } = queryObj;

    limit = !isNaN(Number(limit)) ? limit : this.state.search.limit;
    currentPage = !isNaN(Number(currentPage))
      ? currentPage
      : this.state.currentPage;
    fullProfiles = !isNaN(Number(fullProfiles)) ? Number(fullProfiles) : 1;

    let offset = Math.ceil((currentPage - 1) * limit);

    attributes = this.state.companyFilterFields.attributes;
    schemas = this.state.companyFilterFields.schemas;
    let companyFiltersArr = Object.keys(queryObj)
      .map((item) => {
        if ("filter" === item.replace(/[0-9]/g, "")) {
          let tempArr = queryObj[item].split("_");
          let generatedData = {
            attribute: tempArr[0].replace(" theAnd ", " & "),
            operator: tempArr[1].replace(" theAnd ", " & "),
            value: tempArr[2].replace(" theAnd ", " & "),
          };
          if (tempArr[3]) {
            for (let i = 4; i <= tempArr.length; i = i + 3) {
              generatedData = {
                ...generatedData,
                [`attribute${(i + 2) / 3}`]: tempArr[i - 1].replace(
                  " theAnd ",
                  " & "
                ),
                [`operator${(i + 2) / 3}`]: tempArr[i].replace(
                  " theAnd ",
                  " & "
                ),
                [`value${(i + 2) / 3}`]: tempArr[i + 1].replace(
                  " theAnd ",
                  " & "
                ),
              };
            }
          }
          return generatedData;
        }
        return false;
      })
      .filter(
        (val) =>
          val.attribute === "Classification" ||
          val.attribute === "Classification-2" ||
          (val !== null &&
            typeof val === "object" &&
            _.has(attributes, [val.attribute, val.operator]))
      );

    if (unique) companyFiltersArr = _.uniqWith(companyFiltersArr, _.isEqual);

    await this.setState({
      search: {
        ...this.state.search,
        start: offset,
        limit: limit,
      },
      query: q,
      contactAdvancedFilters: contactFiltersArr,
      advancedFilters: companyFiltersArr,
      currentPage: currentPage,
      fullProfiles,
    });
  };

  processFilter = (val, attributes, schemas) => {
    let keys = Object.keys(val).filter((key) => {
      return _.includes(
        ["attribute", "operator", "attribute2", "operator2"],
        key
      );
    });
    if (keys.length === 2) {
      let hasAll = _.has(attributes, [val.attribute, val.operator]);

      if (hasAll) {
        if (attributes[val.attribute][val.operator].value.schemas) {
          let whichSchemas =
            attributes[val.attribute][val.operator].value.schemas;
          return this.validateSchemas(whichSchemas, schemas, val.value);
        }
        return true;
      }
      return false;
    }
    if (keys.length === 4) {
      let hasAll = _.has(attributes, [
        val.attribute,
        val.operator,
        "attributes",
        val.attribute2,
        val.operator2,
      ]);
      if (hasAll) {
        if (
          attributes[val.attribute][val.operator].value.schemas &&
          attributes[val.attribute][val.operator].attributes[val.attribute2][
            val.operator2
          ].value.schemas
        ) {
          let dependsOn = {
            value: val.value,
            parrentAttribute: val.attribute2,
          };
          let whichSchemas1 =
            attributes[val.attribute][val.operator].value.schemas;
          let whichSchemas2 =
            attributes[val.attribute][val.operator].attributes[val.attribute2][
              val.operator2
            ].value.schemas;

          let comp1 = this.validateSchemas(whichSchemas1, schemas, val.value);
          let comp2 = this.validateSchemas(
            whichSchemas2,
            schemas,
            val.value2,
            dependsOn
          );

          return comp1 && comp2;
        } else if (attributes[val.attribute][val.operator].value.schemas) {
          let whichSchemas =
            attributes[val.attribute][val.operator].value.schemas;

          return this.validateSchemas(whichSchemas, schemas, val.value);
        } else if (
          attributes[val.attribute][val.operator].attributes[val.attribute2][
            val.operator2
          ].value.schemas
        ) {
          let whichSchemas =
            attributes[val.attribute][val.operator].attributes[val.attribute2][
              val.operator2
            ].value.schemas;

          return this.validateSchemas(whichSchemas, schemas, val.value2);
        }
        return true;
      }
      return false;
    }
    return false;
  };

  generateQueryParameter = async (resetPage = true) => {
    const { limit } = this.state.search;
    const {
      query,
      contactAdvancedFilters,
      advancedFilters,
      contactQuery,
      fullProfiles,
    } = this.state;

    const currentPage = resetPage ? 1 : this.state.currentPage;
    let res1 = "";
    let res2 = "";

    let contactfilters = await contactAdvancedFilters
      .map((val, i) => {
        let attrNumber = i + 1;
        let prefix = i === 0 ? "" : "&";

        if (val.attribute2 || val.operator2 || val.value2) {
          res1 = isString(val.value)
            ? val.value.replace("&", "theAnd")
            : val.value;
          res2 = isString(val.value2)
            ? val.value2.replace("&", "theAnd")
            : val.value2;
          return `${prefix}filter${attrNumber}=${val.attribute}_${
            val.operator
          }_${res1}_${val.attribute2}_${val.operator2}_${res2}`;
        }

        res1 = isString(val.value)
          ? val.value.replace("&", "theAnd")
          : val.value;
        return `${prefix}contactFilter${attrNumber}=${val.attribute}_${
          val.operator
        }_${res1}`;
      })
      .reduce((prev, curr) => (prev += curr), "");

    let companyFilters = await advancedFilters
      .map((val, i) => {
        let attrNumber = i + 1;
        let prefix = i === 0 ? "" : "&";
        let data = `${prefix}filter${attrNumber}=${val.attribute}_${
          val.operator
        }_${changeAnd(val.value)}`;
        for (let i = 2; i < 6; i++) {
          if (val[`attribute${i}`] || val[`operator${i}`] || val[`value${i}`]) {
            let attribute = changeAnd(val[`attribute${i}`]);
            let operator = changeAnd(val[`operator${i}`]);
            let value = changeAnd(val[`value${i}`]);
            data = `${data}_${attribute}_${operator}_${value}`;
          }
        }
        return data;
      })
      .reduce((prev, curr) => (prev += curr), "");
    this.props.history.push(
      `/contacts/?q=${escape(
        query
      )}&contactQuery=${contactQuery}&${contactfilters}&${companyFilters}&limit=${limit}&currentPage=${currentPage}&fullProfiles=${fullProfiles}`
    );
  };
  handleSearchQuery = (query) => {
    this.setState({
      search: {
        ...this.state.search,
        loading: true,
      },
    });
    let { sort, currentPage } = this.state;

    let { limit } = this.state.search;
    const advancedFilters = advancedFilterCleaning(
      this.state.advancedFilters,
      this.props.user
    );
    this.setState(advancedFilters.stateSet);
    const searchQuery = {
      companyQuery: {
        filters: advancedFilters.advancedFilters,
        search: {
          q: query.query,
          start: 0,
          limit: 20,
        },
      },
      contactQuery: {
        filters: query.contactAdvancedFilters,
        search: {
          q: query.contactQuery,
          start: Math.ceil((currentPage - 1) * limit),
          limit: limit,
        },
      },
    };
    if (query.contactAdvancedFilters.length !== 0 || query.query !== "") {
      sort = {
        attribute: "relevance",
        how: "asc",
      };
      query.sort = sort;
    }
    GeneratedApi("", true, true)
      .post("/contacts/gridView", searchQuery)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        if (this._isMounted) {
          this.setState({
            search: response,
            query: query.query,
            contactQuery: query.contactQuery,
            contactAdvancedFilters: query.contactAdvancedFilters,
            sort,
            currentPage,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  };

  handlePageChange = async (offset, currentPage) => {
    await this.setState({
      currentPage,
    });
    this.generateQueryParameter(false);
  };

  handleSortChange = (attribute, how) => {
    const { limit, start } = this.state.search;
    const { query, contactQuery, contactAdvancedFilters } = this.state;
    const advancedFilters = advancedFilterCleaning(
      this.state.advancedFilters,
      this.state.TotalFundingAdded
    );
    this.setState(advancedFilters.stateSet);
    const searchQuery = {
      companyQuery: {
        filters: advancedFilters.advancedFilters,
        search: {
          q: query,
          start: 0,
          limit: 20,
        },
      },
      contactQuery: {
        filters: contactAdvancedFilters,
        search: {
          q: contactQuery,
          start: start,
          limit: limit,
        },
      },
    };

    if (attribute !== "relevance") {
      searchQuery.contactQuery.sort = {
        attribute,
        how,
      };
    }
    GeneratedApi("", true, true)
      .post("/contacts/gridView", searchQuery)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        this.setState({
          search: response,
          sort: {
            attribute,
            how,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  };

  handleQueryChange = (event) => {
    this.setState({
      contactQuery: event.target.value,
    });
  };

  handleCompanyAdvancedFilterChange = async (filter, key, update = false) => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        { ...filter },
        ...advancedFilters.slice(key + 1),
      ],
    });
    if (!update || update === "timer") {
      this.generateQueryParameter(this.state);
    }
  };

  handleContactAdvancedFilterChange = async (filter, key) => {
    const { contactAdvancedFilters } = this.state;
    await this.setState({
      contactAdvancedFilters: [
        ...contactAdvancedFilters.slice(0, key),
        { ...filter },
        ...contactAdvancedFilters.slice(key + 1),
      ],
    });
    this.generateQueryParameter(this.state);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.generateQueryParameter(this.state);
  };

  handleAddCompanyFilter = async () => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters,
        {
          attribute: "Name",
          operator: "Begins with",
          value: "",
        },
      ],
    });
  };

  handleAddContactFilter = async () => {
    const { contactAdvancedFilters } = this.state;
    await this.setState({
      contactAdvancedFilters: [
        ...contactAdvancedFilters,
        {
          attribute: "Level",
          operator: "includes",
          value: "Associate",
        },
      ],
    });
  };

  handleClearSpecificCompanyFilter = async (key) => {
    const { advancedFilters } = this.state;
    if (advancedFilters[key].attribute === "Location") {
      this.setState({ locationFilter: [] });
    }
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        ...advancedFilters.slice(key + 1),
      ],
    });

    this.generateQueryParameter(this.state);
  };

  handleClearSpecificContactFilter = async (key) => {
    const { contactAdvancedFilters } = this.state;
    await this.setState({
      contactAdvancedFilters: [
        ...contactAdvancedFilters.slice(0, key),
        ...contactAdvancedFilters.slice(key + 1),
      ],
    });
    this.generateQueryParameter(this.state);
  };

  handleClearCompanyFilters = async () => {
    const advancedFilters = [];
    await this.setState({
      advancedFilters,
      locationFilter: [],
    });
    this.generateQueryParameter(this.state);
  };

  handleClearContactFilters = async () => {
    const contactAdvancedFilters = [];
    await this.setState({
      contactAdvancedFilters,
    });
    this.generateQueryParameter(this.state);
  };

  handleFilterTrigger = (advancedFiltersTriggered) => {
    this.setState({
      advancedFiltersTriggered,
    });
  };
  handleLocationInput = (input, { action }) => {
    if (action === "input-change" && input.length > 0) {
      debounce(
        () =>
          getSuggestions(input).then((locationOptions) =>
            this.setState({ locationOptions })
          ),
        500
      )();
    }
  };

  handleLocationChange = async (value) => {
    await this.setState(locationChange(this.state.advancedFilters, value));
    this.generateQueryParameter();
  };

  handleSelectLocation = async (value) => {
    await this.setState(selectLocation(value, this.state.advancedFilters));
    this.generateQueryParameter();
  };

  handleFilterCheckbox = async (event) => {
    const {
      checkedFilter,
      advancedFilters,
      LocationArray,
      totalFcount,
      technologiesCount,
      verticalsCount,
      employeeCount,
      publicPrivateCount,
    } = this.state;
    const counter = {
      totalFcount: totalFcount,
      technologiesCount: technologiesCount,
      verticalsCount: verticalsCount,
      employeeCount: employeeCount,
      publicPrivateCount: publicPrivateCount,
    };
    await this.setState(
      filterCheckbox(
        event,
        checkedFilter,
        advancedFilters,
        LocationArray,
        counter
      )
    );

    this.generateQueryParameter();
  };
  handleClearFilter = async (filter) => {
    const {
      advancedFilters,
      checkedFilter,
      LocationArray,
      locationFilter,
    } = this.state;
    await this.setState(
      clearFilter(
        filter,
        advancedFilters,
        checkedFilter,
        LocationArray,
        locationFilter
      )
    );
    this.generateQueryParameter();
  };
  handleClearAllFilters = async () => {
    await this.setState(clearAllFilters());
    this.generateQueryParameter();
  };
  validateSchemas(whichSchemas, schemas, validatedValue, dependsOn) {
    let schemaValues = [];
    whichSchemas.forEach((schema) => {
      if (!dependsOn || dependsOn.parrentAttribute !== "Year") {
        schemaValues = schemaValues.concat(
          schemas[schema].map((val, i) => {
            if (isObject(val)) {
              return val.name;
            } else {
              return val;
            }
          })
        );
      } else if (dependsOn.parrentAttribute === "Year") {
        const filtered = schemas[schema].filter(
          (val) => val.name === dependsOn.value
        );
        schemaValues = filtered[0] && filtered[0].years;
      }
    });
    return _.includes(schemaValues, validatedValue);
  }

  updateState = (item) => {
    this.setState(item);
  };

  downloadApi = async () => {
    const {
      query,
      contactQuery,
      contactAdvancedFilters,
      advancedFilters,
    } = this.state;
    const q = {
      companyQuery: {
        filters: advancedFilters,
        search: {
          q: query,
          start: 0,
          limit: 100,
        },
      },
      contactQuery: {
        filters: contactAdvancedFilters,
        search: {
          q: contactQuery,
          start: 0,
          limit: 100,
        },
      },
    };

    await GeneratedApi("", true, true)
      .post("/contacts/download", q)
      .then((response) => {
        response = response.data;

        let csv = response;
        let downloadLink = document.createElement("a");
        let blob = new Blob(["\ufeff", csv]);
        let url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Contacts Data.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    const aFHandle = {
      handleAdvancedFilterChange: this.handleCompanyAdvancedFilterChange,
      handleClearFilters: this.handleClearCompanyFilters,
      handleAddFilter: this.handleAddCompanyFilter,
      handleClearSpecificFilter: this.handleClearSpecificCompanyFilter,
      handleLocationChange: this.handleLocationChange,
      handleLocationInput: this.handleLocationInput,
      handleSelectLocation: this.handleSelectLocation,
    };
    const aFValue = {
      advancedFilters: this.state.advancedFilters,
      filterFields: this.state.companyFilterFields,
      user: this.props.user,
      locationOptions: this.state.locationOptions,
      locationFilter: this.state.locationFilter,
    };
    const aFMobileHandle = {
      handleFilterCheckbox: this.handleFilterCheckbox,
      handleClearFilter: this.handleClearFilter,
      handleClearAllFilters: this.handleClearAllFilters,
    };
    const aFMobileValue = {
      checkedFilter: this.state.checkedFilter,
      totalFcount: this.state.totalFcount,
      technologiesCount: this.state.technologiesCount,
      verticalsCount: this.state.verticalsCount,
      employeeCount: this.state.employeeCount,
      publicPrivateCount: this.state.publicPrivateCount,
      locationCount: this.state.locationCount,
    };
    const aFCompanyHandle = {
      handleAdvancedFilterChange: this.handleContactAdvancedFilterChange,
      handleClearFilters: this.handleClearContactFilters,
      handleAddFilter: this.handleAddContactFilter,
      handleClearSpecificFilter: this.handleClearSpecificContactFilter,
      handleLocationChange: this.handleLocationChange,
      handleLocationInput: this.handleLocationInput,
      handleSelectLocation: this.handleSelectLocation,
    };
    const aFCompanyValue = {
      advancedFilters: this.state.contactAdvancedFilters,
      filterFields: this.state.contactFilterFields,
      user: this.props.user,
      locationOptions: this.state.locationOptions,
      locationFilter: this.state.locationFilter,
    };
    if (this.state.limitReached) {
      return <Redirect to="/error" />;
    }
    console.log("adf contacts", this.state.advancedFilters, this.state);
    return (
      <>
        <Contacts
          advancedFiltersHandlers={aFHandle}
          advancedFiltersValues={aFValue}
          aFMobileHandle={aFMobileHandle}
          aFMobileValue={aFMobileValue}
          aFCompanyHandle={aFCompanyHandle}
          aFCompanyValue={aFCompanyValue}
          location={this.props.location}
          history={this.props.history}
          user={this.props.user}
          sort={this.state.sort}
          userType={userType}
          data={{
            ...this.state.search,
            currentPage: this.state.currentPage,
          }}
          updatePropsState={this.updateState}
          handleSubmit={this.handleSubmit}
          handleQueryChange={this.handleQueryChange}
          handleSortChange={this.handleSortChange}
          handlePageChange={this.handlePageChange}
          downloadApi={this.downloadApi}
        />
      </>
    );
  }
}
export default ContactsContainer;
