import React, { Component } from 'react'
import { FaSortUp, FaTwitter, FaLinkedin, FaRegEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import {Collapse} from 'reactstrap'
import './Card.scss'
export default class Card extends Component {
    constructor(props){
        super(props)
        this.state ={
            open: false
        }
    }
    handleCloseOpen = () => {
        this.setState({
            open: !this.state.open
        })
    }
    handleOpen = (status) => {
        this.setState({
            open: status
        })
    }
    render() {
        const {open} = this.state
        const {address, companyName, description, email, expertise, img, linkedinUrl, twitterUrl, user} = this.props
        return (
            <div className="col-12 offset-sm-0 offset-md-2 offset-lg-0 col-md-8 offset-md-2 offset-lg-0 col-lg-6">
                <div className="Card">
                    <div className="row d-block d-md-flex m-0">
                        <img className="card-images" src={img} alt=""/>
                        <div className="card-information grey-border-b grey-border-r">
                            <div className="user">{user}</div>
                            <div className="social-links grey-border-b d-flex">
                                <div className="margin-right">
                                    {linkedinUrl && <FaLinkedin className="linkedin-icon" />}
                                    {twitterUrl && <FaTwitter className="twitter-icon" />}
                                </div>
                            </div>
                            <div className="email d-flex"><FaRegEnvelope /> <div>{email}</div></div>
                            <div className="location d-flex"><FaMapMarkerAlt /> <div> {address} </div></div>
                            <div className="company d-flex"><div className="company-image"></div> <div> {companyName} </div> </div>
                            <div className="expertise"><b>Expertise:</b> {expertise} </div>
                            <div onClick={() => this.handleCloseOpen()} className="view-bio pointer">{open? 'Close Full Bio':'View Full Bio'}</div>
                        </div>
                    </div>
                    <Collapse isOpen={open} >
                        <div className={`card-description grey-border-l grey-border-b grey-border-r`}>
                        {description}
                        <div className="d-flex pointer"> <div  onClick={() => this.handleOpen(false)} className="vertical-align margin-right">Close <FaSortUp /></div> </div>
                        </div>
                    </Collapse>
                </div>               
            </div>
        )
    }
}
