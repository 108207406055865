import React, { Component } from "react";
import CardsTitle from "../../../components/CardsTitle/CardsTitle";
import "./News.scss";
import Spinner from "../../../components/Spinner/Spinner";
import moment from "moment-timezone";
import { FaUserEdit, FaGlobe } from "react-icons/fa";
import GeneratedApi from "../../../components/functions/generatedApi";

export default class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagId: "",
      loaded: false,
      response: [],
    };
  }
  componentDidMount() {
    const { limit, APIcall, tags } = this.props.propsData;
    const q = {
      search: { q: "", start: 0, limit: limit ? limit : 12 },
      filters: tags
        ? tags.map((item) => ({
            attribute: "newsCategory",
            operator: "includes",
            value: item,
          }))
        : [{}],
      sort: {},
    };
    GeneratedApi("", false, true)
      .post(`/${APIcall}/gridView`, q)
      .then((response) => {
        const data = response.data.body.data;
        if (data.searchResults.length > 0) {
          this.setState({
            loaded: true,
            response: data.searchResults.filter((el) => Object.keys(el).length),
          });
        } else {
          this.setState({
            loaded: "no info",
            response: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loaded: "no info",
        });
      });
  }

  render() {
    const { loaded, response } = this.state;
    if (!loaded) {
      return <Spinner height="50vh" />;
    } else if (loaded === true) {
      return (
        <div className="News">
          <CardsTitle image="/images/document.svg" titleName={this.props.propsData.title} name="smart-cities-news" />
          <div className="border border-noTop rounded-bottom bg-white">
            {response.map((item, key) => (
              <div key={key}>
                <div className="row p-4 m-0 border-botom">
                  <div className="rounded-8 image-block mx-auto mx-lg-0 mb-2 mb-lg-0">
                    <a target="_blank" href={item.newsArticleURL}>
                      <img className="rounded-8" src={item.newsArticleImages && item.newsArticleImages.sqaureIcon} alt="" />
                    </a>
                  </div>
                  <div className="col-12 col-lg pr-0">
                    {item.newsArticleURL && (
                      <div className="d-flex pb-2">
                        <FaGlobe size="18px" className="my-auto color-blue-2 mr-2 " />
                        <div className="my-auto">{item.newsArticleSource}</div>
                      </div>
                    )}
                    <a target="_blank" className="font-s-18 color-dark-2 font-archivo font-weight-bold line-h-20" href={item.newsArticleURL}>
                      {item.newsArticleTitle}
                    </a>
                    <div className="row mx-0 pb-2">
                      <div className="d-flex mr-4 pt-2">
                        <img className="mr-2 my-auto" src="/images/calendar-1.svg" alt="" />
                        <div className="calendar-text my-auto">{moment.unix(item.newsArticlePulishedDate).format("MMMM DD, YYYY")}</div>
                      </div>
                      {item.newsArticleAuthors && (
                        <div className="d-flex pt-2">
                          <FaUserEdit size="18px" className="my-auto color-orange mr-2 " />
                          <div className="my-auto">{item.newsArticleAuthors}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 px-0 mt-3">
                    <div className="line-h-15 overflow-hidden close-content">{item.newsArticleContent}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
