import React, { Component } from 'react'
import './GeneralPages.scss'
import { StickyContainer } from 'react-sticky';
export default class Attribution extends Component {
    render() {
        const {simplified}=this.props
        return (
            <div className="Terms-Of-Service">
            { !simplified&&
              <div className="container-fluid">
                    <div className="row justify-content-center align-items-center welcome-screen">
                        <div className="col-auto text-box">
                            <h1>Attribution</h1>
                        </div>
                    </div>
              </div>
            }
              <StickyContainer>
                <div className="container">
                  <div className="row">
                    <div className='col rounded-6 grey-border-2 p-tb-15'>
                      <div className="content">
                        <p align="left">

                        </p>
                        <p align="center">
                          <strong>END OF AGREEMENT</strong>
                        </p>
                        <p>
                          <strong></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </StickyContainer>
            </div>
        )
    }
}
