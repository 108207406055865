import React from "react";
import Cookies from "universal-cookie";
import * as jwtDecode from "jwt-decode";
import { Redirect } from "react-router-dom";
import GeneratedApi from "../../components/functions/generatedApi";

const cookies = new Cookies();

function CustomGroup(props) {
  const { visible, customGroup, handleCustomGroupChange } = props;
  if (visible) {
    return (
      <div className="form-group">
        <input
          value={customGroup}
          name="customGroup"
          required
          type="text"
          className="form-control"
          placeholder="Custom group"
          onChange={handleCustomGroupChange}
        />
      </div>
    );
  }
  return "";
}

export default class AdminGroups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        username: "",
        group: "",
        hours: "",
      },
      customGroup: "",
      loading: false,
      redirect: false,
    };

    const jwtToken = cookies.get("jwtToken");
    if (jwtToken !== undefined) {
      let user = jwtDecode(jwtToken);
      if (!user["cognito:groups"]) {
        this.state.redirect = true;
      } else if (user["cognito:groups"].indexOf("admin") < 0) {
        this.state.redirect = true;
      }
    } else {
      this.state.redirect = true;
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCustomGroupChange = this.handleCustomGroupChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = { ...this.state.formData };

    if (formData.group === "custom") {
      formData.group = this.state.customGroup;
    }
    formData.hours = formData.hours * 24;
    this.setState({
      loading: true,
    });
    GeneratedApi("", true, true)
      .post("addUserGroup", formData)
      .then((response) => {
        this.setState({
          loading: false,
          formData: {
            username: "",
            group: "",
            hours: "",
          },
        });
        if (response.data != null) {
          alert(response.data);
        }
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formData = { ...this.state.formData };
    formData[name] = value;
    this.setState({ formData });
  }
  handleCustomGroupChange(event) {
    const customGroup = event.target.value;
    this.setState({
      customGroup,
    });
  }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    const { formData, customGroup } = this.state;
    return (
      <div className="container">
        <div className="col-8 offset-2">
          <h1>Admin groups form</h1>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                value={formData.username}
                name="username"
                required
                type="text"
                className="form-control"
                placeholder="User name"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <select
                value={formData.group}
                name="group"
                required
                type="text"
                className="form-control"
                placeholder="Group"
                onChange={this.handleInputChange}
              >
                <option disabled value="">
                  Group
                </option>
                <option value="pro">Pro</option>
                <option value="premium">Premium</option>
                <option value="corporate">Corporate</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            <CustomGroup visible={formData.group === "custom"} customGroup={customGroup} handleCustomGroupChange={this.handleCustomGroupChange} />
            <div className="form-group">
              <input
                value={formData.hours}
                name="hours"
                required
                type="number"
                className="form-control"
                placeholder="Days"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <button className={`${this.state.loading ? "disabled" : ""} btn btn-primary`} type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
