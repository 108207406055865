import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FaSortUp } from 'react-icons/fa';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Collapse} from 'reactstrap'
import './Card.scss'
import {NotificationManager} from 'react-notifications';
export default class Card extends Component {
    constructor(props){
        super(props)
        this.state ={
            open: false
        }
    }
    handleCloseOpen = () => {
        this.setState({
            open: !this.state.open
        })
    }
    handleOpen = (status) => {
        this.setState({
            open: status
        })
    }
    render() {
        const {open} = this.state
        const {offerCode, description, companyURL, img, name, redeemLink, offer, companyLink} = this.props
        return (
            <div className="col-12 offset-sm-0 offset-md-2 offset-lg-0 col-md-8 offset-md-2 offset-lg-0 col-lg-6">
                <div className="Campaign-card">
                    <div className="row d-block d-md-flex m-0  grey-border-b grey-border-l">
                        <Link to={`/company/${companyURL}`}>
                            <img className="card-images" src={img} alt=""/>
                        </Link>
                        <div className="card-information grey-border-r">
                            <div className="user">
                                <Link to={`/company/${companyURL}`}>
                                    {name}
                                </Link>
                            </div>
                           { companyLink &&
                            <div className="email"><a target="_blank" href={companyLink.includes('http')? companyLink : `https://${companyLink}`}>{companyLink}</a></div>
                           }
                            <div className="expertise d-flex">
                                <b>Offer code:</b> {offerCode} 
                                <CopyToClipboard 
                                    text={offerCode}
                                    onCopy={() => NotificationManager.info('Copied offer code')}
                                >
                                    <div className="copy-button"><button>Copy</button></div>
                                </CopyToClipboard> 
                            </div>
                            <div className="expertise"><b>Offer:</b> {offer}</div>
                            <div className="expertise"><a target="_blank" href={redeemLink}>Redeem Offer</a></div>
                            <div onClick={() => this.handleCloseOpen()} className="view-bio pointer">{open? 'Close Offer Description':'View Offer Description'}</div>
                        </div>
                    </div>
                    <Collapse isOpen={open} >
                        <div className={`card-description grey-border-l grey-border-b grey-border-r`}>
                        {description}
                        <div className="d-flex pointer"> <div  onClick={() => this.handleOpen(false)} className="vertical-align margin-right">Close <FaSortUp /></div> </div>
                        </div>
                    </Collapse>
                </div>               
            </div>
        )
    }
}
