import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import './ReportDownload.scss'
export default class ReportDownload extends Component {
    render() {
        return (
            <div className="ReportDownload">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Report | IoT Nation</title>
                </Helmet>
                <div className="container info">
                    <div className="small-title">Free download</div>
                    <div className="title">Thanks for your submission!</div>
                    <div className="medium-title">Your report download will begin shortly.</div>
                    <div className="small-title-2">If your download does not start automatically <a href="/">click here.</a> </div>
                </div>
                <div className="navigation-block">
                    <div className="container">
                        <div className="title">Find more information on:</div>
                        <div className="d-flex">
                            <div className="horizontal-align">
                                <div className="navigation">
                                    <img src="/images/company-report.svg" alt=""/>
                                    <a href="/companies">
                                        <button className="btn">Companies</button>
                                    </a>
                                </div>
                            </div>
                            <div className="horizontal-align">
                                <div className="navigation">
                                    <img src="/images/useCase-report.svg" alt=""/>
                                    <a href="/usecases">
                                        <button className="btn">Use Cases</button>
                                    </a>
                                </div>
                            </div>
                            <div className="horizontal-align">
                                <div className="navigation">
                                    <img src="/images/events-report.svg" alt=""/>
                                    <a href="/events">
                                        <button className="btn">Events</button>
                                    </a>
                                </div>
                            </div>
                            {/* <div className="horizontal-align">
                                <div className="navigation">
                                    <img src="/images/competitions-report.svg" alt=""/>
                                    <a href="/competitions">
                                        <button className="btn">Competitions</button>
                                    </a>
                                </div>
                            </div> */}
                            <div className="horizontal-align">
                                <div className="navigation">
                                    <img src="/images/map-report.svg" alt=""/>
                                    <a href="/map">
                                        <button className="btn">Map</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
