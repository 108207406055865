import React from "react";
import Map from "./Map";
import './Map.scss'

 class MapContainer extends React.Component {

	render() {
        const {address, location, name} =this.props.data;
        const { city, zipCode, state, country} =address;
        return(
            <>
                <div className="Map d-block d-md-none d-lg-block">
                    {location &&
                    <> 
                        <p>HQ Location</p>
                        <Map 
                            location={location}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className="map" />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                        <>
                        <p>{city}, {country}</p>
                        <a  className="adress" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}`}>
                            <p>{address.street1}<br/>
                            {city}, {state}, {zipCode}<br/>
                            {country}</p>
                        </a>  
                        <div className="d-flex"><a style={{margin: "0px auto"}} className="view-on-map" href={`/map?q=&filter1=Name_Begins%20with_${encodeURIComponent(name.trim())}&limit=3000`}><button className="view-on-map-btn">View on Map</button></a> </div>
                        </>
                    </>
                    }
                </div>
                <div className="Map d-none d-md-block d-lg-none">
                {location &&
                <div className="row"> 
                    <div className="col-6">
                        <p>HQ Location</p>
                        <>
                        <p>{city}, {country}</p>
                        <a  className="adress" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}`}>
                            <p>{address.street1}<br/>
                            {city}, {state}, {zipCode}<br/>
                            {country}</p>
                        </a>  
                        <div className="d-none d-lg-flex"><a style={{margin: "0px auto"}} className="view-on-map" target="_blank" href={`/map?q=&filter1=Name_Begins%20with_${encodeURIComponent(name.trim())}&limit=3000`}><button className="view-on-map-btn">View on Map</button></a> </div>
                        </>
                    </div>
                    <div className="col-6">
                        <Map 
                            location={location}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className="map" />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </div> 
                </div>
                }
            </div>
            </>
		);
	}
}

export default MapContainer;