import React, { Component } from 'react'

export default class SmartCitiesNews extends Component {
    render() {
        return (
            <div className="Smart-Cities-News row">
                <div className="col">
                <div className="content-header row">
                        <div className="col-1"><div className="document-icon"><img alt="" src="/images/document.svg"/></div></div>
                        <div className="col d-flex align-items-center"><p className="title">Smart Cities News</p></div>
                    </div>
                    <div className="row news">
                        <div className="col-3">
                            <div className="news-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re turning to technology-based strategies including as using the blockchain ledger.
                            </p>
                        </div>
                    </div>    
                    <div className="row news">
                        <div className="col-3">
                            <div className="news-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re turning to technology-based strategies including as using the blockchain ledger.
                            </p>
                        </div>
                    </div>    
                    <div className="row news">
                        <div className="col-3">
                            <div className="news-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re turning to technology-based strategies including as using the blockchain ledger.
                            </p>
                        </div>
                    </div>    
                </div>   
                </div>
        )
    }
}
