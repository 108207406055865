import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"

import './Tags.scss';
export default class Tags extends Component {
    render() {
        const {verticals, horizontals} = this.props.tags
        return (
            <div className="Tags" id="tags">
                <CardsTitle image="/images/tag-2.svg" titleName={'IoT Tags'} name="title" />
                <div className="description">
                    <div className="Verticals">
                        <div className="row">
                            <div className="d-block d-md-none margin-bottom-16 col-12">Verticals</div>
                            <div className="col-2 d-none d-md-block">Verticals</div>
                            <div className="col">
                                <div className="display-flex">
                                    {verticals.map((item,key)=>
                                        <div key={key} className="tag capitalize">{item}</div>  
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Technologies">
                        <div className="row">
                            <div className="d-block d-md-none margin-bottom-16 col-12">Technologies</div>
                            <div className="col-2 d-none d-md-block">Technologies</div>
                            <div className="col">
                                <div className="display-flex">
                                    {horizontals.map((item,key)=>
                                        <div key={key} className="tag capitalize">{item}</div>  
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
