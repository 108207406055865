import React, { Children } from 'react'
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import './AddtoCalendarDropdown.scss'

function ButtonComponent({children, onClick}) {

    return (
        <button onClick={onClick} className="view active w-100">+ Add to Calendar</button>
    )
}

function DropdownComponent({children, isOpen, onRequestClose}) {

    const childrenArr = Children.toArray(children)
    const childrenLength = childrenArr.length
    
    if(childrenLength === 1 && childrenArr[0]){
        window.open(childrenArr[0].props.href, '_blank');
        return false
    }

    return ( 
        <div className={isOpen?"dropdown-container":"d-none"}>
            {childrenArr.map((child, i)=>
                <div className="dropdown-item" onClick={onRequestClose} key={i}>{child}</div>
            )}
        </div>
    )
}



export default AddToCalendarHOC(ButtonComponent, DropdownComponent)