import React, { Component } from 'react'

import './PurpuleTag.scss';

export default class PurpuleTag extends Component {
  render() {
    return (
      <div className="PurpuleTag">
        <div className="tag capitalize">{this.props.name}</div>
      </div>
    )
  }
}
