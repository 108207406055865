import React, { Component } from 'react'
import { FaLinkedin } from 'react-icons/fa';

import './Speaker.scss';
var pixelWidth = require('string-pixel-width');
export default class Speaker extends Component {
    state = {
        showMore:false
    }
    
    handleShowMore = () => this.setState(({showMore})=>({showMore:!showMore}))
    render() {
        const { showMore } = this.state 
        const title = this.props.title && this.props.title
        const companyName = this.props.company && this.props.company.name
        const {
            firstName,
            lastName,
            speaker
          } = this.props
        return (
            <div className="Speaker">
                <div className="row">
                    <div className="speaker-image">
                        <img src={this.props.src !== "" ? this.props.src : '/images/avatar.png'} alt=""/>
                    </div>
                    <div className="speaker-info">
                        <p className="person-name">{firstName} {lastName}{firstName || lastName?<br/> :''}</p>
                        <p className="profesion">{speaker}{speaker?<br/>:''}
                        {title}{title?<br />:''} 
                        {companyName}</p>
                        </div> 
                        <div className="d-flex margin-right-10">
                            {this.props.linkedin && <a target="_blank" href={this.props.linkedin}><FaLinkedin className="icons linkedin-icon m-0-4" /></a>}    
                    </div>
                </div>

                <div className={showMore?'toggle pt-24':'toggle pt-24 hidden'}>
                <p className="content" >{this.props.biography}</p>
                <span onClick={this.handleShowMore} className="pointer text-decoration">{pixelWidth(this.props.biography ,{ size: 14 }) >990? showMore?'Read less':'Read more':''}</span>
                </div>
                
                <hr/>
            </div>

        )
  }
}
