import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';
import './Companies.scss'
export default class Companies extends Component {
    render() {
        const organizations = this.props.organizations
        const clients = this.props.clients
        const user = this.props.user
        const type = !user
        return (
            <div className="Companies-involved" id="companies">
                <div>
                    <CardsTitle image="/images/building-4.svg" titleName={'Companies Involved'} name="companies" />
                    <div className={user?`description`:'description not-login'}>
                        <div className="row">
                            <div className="col-3 d-none d-md-block">Technology Providers</div>
                            <div className="col-12 d-block d-md-none margin-bottom-16 p-0">Technology Providers</div>
                            {organizations.map((item, key)=>
                            <>
                                <div key={key} className="company-block d-none d-md-flex">
                                    <div className="image">
                                        <a className="text" target="_blank" href={`/company/${item.id}`}><img src={item.logo} alt=""/></a>
                                    </div>
                                    <a className="text" target="_blank" href={`/company/${item.id}`}><div className="text">{item.name}</div></a>
                                </div>
                                <div key={key} className="company-block d-flex d-md-none w-100">
                                    <div className="d-flex horizontal-align">
                                        <div className="image">
                                            <a className="text" target="_blank" href={`/company/${item.id}`}><img src={item.logo} alt=""/></a>
                                        </div>
                                    </div>
                                </div>
                            </>
                            )}  
                        </div>
                        
                        <div className="row">
                            <div className="col-3 d-none d-md-block">Clients</div>
                            <div className="col-12 d-block d-md-none margin-bottom-16 p-0">Clients</div>
                            {clients.map((item, key)=>
                            <>
                                <div key={key} className="company-block d-none d-md-flex">
                                    <div className="image">
                                        <a target="_blank" href={`/company/${item.id}`}><img src={item.logo} alt=""/></a>
                                    </div>
                                    <a className="text" target="_blank" href={`/company/${item.id}`}><div >{item.name}</div></a>
                                </div>
                                <div key={key} className="company-block d-flex d-md-none w-100">
                                    <div className="d-flex horizontal-align">
                                        <div className="image">
                                            <a target="_blank" href={`/company/${item.id}`}><img src={item.logo} alt=""/></a>
                                        </div> 
                                    </div>
                                </div>
                            </>
                            )}
                        </div>
                    </div>
                </div>
                <SubscriberContent target="companies1" hidden={type} />
            </div>
        )
    }
}
