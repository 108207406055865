import React from 'react'

function WeeklyCh(props) {
    const result=props.data;
    let string='';
    let cn=''

    if (result>0){
        string=`+${result}`
        cn=' positive'
        return (
            <span className={`weekly-ch${cn}`}>{string}</span>
        )
    }else if (result<0){
        string=result
        cn=' negative'
        return (
            <span className={`weekly-ch${cn}`}>{string}</span>
        )
   
    }
    return (
        <span style={{color:'#3f4d6e'}} className='weekly-ch'>—</span>
    )

}

export default WeeklyCh
