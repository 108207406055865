import React from 'react';
import _ from 'lodash';

export default class IotLinks extends React.PureComponent {
    render() {
        const { link } = this.props;
        if(_.isEmpty(link)) {
            return false;
        }
        return (
            <div className="col-7">
                <p>IoT Specific Links</p>
                <div className="row">
                <span><a target="_blank" href="https://twitter.com"><img alt='logo-twitter' src="/images/logo-twitter-3.svg"/></a></span>
                <span><a target="_blank" href="https://facebook.com"><img alt='logo-facebook' src="/images/logo-facebook-1.svg"/></a></span>
                </div>
            </div>
        )
    }
}