export default function(eventType){

    switch (eventType.toLowerCase()) {
        case 'conference':
           return "/images/conference.svg"
        case 'exhibition':
           return "/images/layout-11.svg"
        case 'course':
            return "/images/paper-diploma.svg"
        case 'fair':
            return "/images/circus-tent.svg"
        case 'hackathon':
            return "/images/ai.svg"
        case 'networking':
            return "/images/users-mm.svg"
        case 'panel':
            return  "/images/b-meeting.svg";
        case 'startup':
            return  "/images/spaceship.svg"
        case 'webinar':
            return "/images/tv.svg"
        case 'virtual conference':
            return "/images/tv.svg"
        default:
            return ''
    }
}