import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import { Button, Tooltip } from "reactstrap";
import AddtoCalendarDropdown from "../components/AddToCalendarDropdown/AddtoCalendarDropdown";
import BlueTag from "./components/BlueTag.jsx";
import PurpuleTag from "./components/PurpuleTag.jsx";
import Highlights from "./components/Highlights.jsx";
import MiddleSwitcher from "./components/MiddleSwitcher.jsx";
import SideSwitcher from "./components/SideSwitcher.jsx";
// import getEventTypeImage from '../Events/functions/getEventTypeImage'
import CardsTitle from "../components/CardsTitle/CardsTitle";
import { FaShareAlt, FaFacebook, FaTwitter, FaLinkedin, FaBlogger, FaYoutube } from "react-icons/fa";
import { StickyContainer } from "react-sticky";
import moment from "moment-timezone";
import getTimezoneNumber from "../utils/getTimezoneNumber";
// import cx from 'classnames';
import PopoverBlock from "./components/PopoverBlock.jsx";
import SmallScreenPopoverBlock from "./components/SmallScreenPopoverBlock.jsx";
import "../components/scss/breadcrumbs.scss";
import "./EventId.scss";
import "../Events/labels.scss";
import { isAndroid, isIOS } from "react-device-detect";
import getSuggestions, { getPlaceLatLong } from "../Events/functions/getSuggestions";
import debounce from "lodash/debounce";
import { Helmet } from "react-helmet";
import GeneratedApi from "../components/functions/generatedApi";

class EventId extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationAddress: "",
      locationUrl: "",
      showModal: false,
      likeData: null,
      eventData: null,
      eventId: props.match.params.eventId,
      loading: true,
      showContent: false,
      showShare: false,
      showSmallShare: false,
      showDiscountCode: false,
      showSmallDiscountCode: false,
      randPicNumber: Math.round(Math.random()),
      tooltipOpen: false,
      tooltipOpenHeader: false,
      tooltipSmallOpenHeader: false,
      showSticky: false,
      copied: false,
      test: false,
      modal: false,
      firstLoad: true,
      thumbsUp: false,
      likeLength: 0,
    };
    this.isBottom = this.isBottom.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
  }
  tooltip = () => this.setState(({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen }));
  tooltipHeader = () => this.setState(({ tooltipOpenHeader }) => ({ tooltipOpenHeader: !tooltipOpenHeader }));
  handleShowContent = () => this.setState(({ showContent }) => ({ showContent: !showContent }));
  discountPopover = () => this.setState(({ showDiscountCode }) => ({ showDiscountCode: !showDiscountCode }));
  closeDiscountCode = () => this.setState({ showDiscountCode: false, showSmallDiscountCode: false });
  togglePopover = () => this.setState(({ showShare }) => ({ showShare: !showShare }));
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  tooltipSmallHeader = () => this.setState(({ tooltipSmallOpenHeader }) => ({ tooltipSmallOpenHeader: !tooltipSmallOpenHeader }));
  discountSmallPopover = () => this.setState(({ showSmallDiscountCode }) => ({ showSmallDiscountCode: !showSmallDiscountCode }));
  toggleSmallPopover = () => this.setState(({ showSmallShare }) => ({ showSmallShare: !showSmallShare }));

  async componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
    GeneratedApi("", false, true)
      .get(`/events/${this.state.eventId}`)
      .then((response) => {
        this.setState({
          eventData: response.data.body.data,
          loading: false,
        });

        const address = response.data.body.data.location.address;
        const location = Object.assign(
          [],
          address.street1 && { 0: `${address.street1}` },
          address.city && address.street1 ? { 1: `, ${address.city}` } : address.city && { 1: `${address.city}` },
          address.stateCode && (address.street1 || address.city)
            ? { 2: `, ${address.stateCode}` }
            : address.stateCode && { 2: `${address.stateCode}` },
          address.zipCode && (address.street1 || address.stateCode || address.city)
            ? { 3: `, ${address.zipCode}` }
            : address.zipCode && { 3: `${address.zipCode}` },
          address.country && (address.street1 || address.stateCode || address.city || address.zipCode)
            ? { 3: `, ${address.country}` }
            : address.country && { 3: `${address.country}` }
        ).join("");
        if (true) {
          debounce(() =>
            getSuggestions(location).then((locationSuggests) => {
              console.log(locationSuggests);
              this.setState({
                firstLoad: false,
                locationUrl: locationSuggests[0].url,
              });
              debounce(() => getPlaceLatLong(locationSuggests[0].value).then((locationAddress) => this.setState({ locationAddress })))();
            })
          )();
        }

        // const q = {"dataId":response.data.body.data.eventId ,"dataType":"event"}

        // GeneratedApi("", true, true).post('/getLikeInfo', q)
        //   .then((response) => {
        //     response = response.data;
        //     this.setState({
        //       likeData: response,
        //       thumbsUp: response.currentUserLike,
        //       likeLength: response.userList.length,
        //     });
        //   })
        // .catch((error) => {
        //     console.log(error.response);
        //     if(error.response) {
        //         if(error.response.status === 429) {
        //           return false;
        //         }
        //     }
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= 70;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("eventid-buttons");
    if (!this.state.loading) {
      if (this.isBottom(wrappedElement)) {
        if (!this.state.showSticky) {
          this.setState({
            showSticky: true,
          });
        }
      } else {
        if (this.state.showSticky) {
          this.setState({
            showSticky: false,
          });
        }
      }
    }
  };
  likeHandle = () => {
    if (!this.props.user) {
      this.setState({ showModal: true });
    } else {
      const like = { dataId: this.state.eventData.eventId, dataType: "event", operation: "LIKE" };
      const unlike = { dataId: this.state.eventData.eventId, dataType: "event", operation: "INDIFFERENT" };
      if (this.state.thumbsUp) {
        if (this.state.likeLength < 1) {
          this.setState({
            thumbsUp: false,
            likeLength: 0,
          });
        } else {
          this.setState({
            thumbsUp: false,
            likeLength: this.state.likeLength - 1,
          });
        }
        GeneratedApi("", true, true)
          .post("/updateLikeInfo", unlike)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            if (this.state.likeLength < 1) {
              this.setState({
                thumbsUp: false,
                likeLength: 0,
              });
            } else {
              this.setState({
                thumbsUp: false,
                likeLength: this.state.likeLength + 1,
              });
            }
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      } else {
        this.setState({
          thumbsUp: true,
          likeLength: this.state.likeLength + 1,
        });
        GeneratedApi("", true, true)
          .post("/updateLikeInfo", like)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength - 1,
            });
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      }
    }
  };

  render() {
    const linkLocation = this.props.location.state && this.props.location.state;
    const prevQuery = linkLocation ? (linkLocation.prevQuery ? linkLocation.prevQuery : "") : "";
    const filters = linkLocation && linkLocation.filters ? linkLocation.filters : [];
    const { eventData, loading, locationAddress, locationUrl } = this.state;
    if (eventData && !loading) {
      const {
        eventType,
        descriptions,
        socialLinks,
        tags,
        title,
        speakers,
        website,
        registration,
        organizers,
        location,
        date,
        sponsors,
        eventId,
        discount,
      } = eventData;
      let socialLinksPresent = false;
      Object.keys(socialLinks).forEach((key) => {
        if (socialLinks[key] !== "") {
          socialLinksPresent = true;
          return false;
        }
      });
      const { banner } = eventData.images;
      const isLoggedIn = this.props.user ? true : false;

      const address = location.address;
      const locationType = Object.assign(
        [],
        address.street1 && { 0: `${address.street1}` },
        address.city && address.street1 ? { 1: `, ${address.city}` } : address.city && { 1: `${address.city}` },
        address.stateCode && (address.street1 || address.city) ? { 2: `, ${address.stateCode}` } : address.stateCode && { 2: `${address.stateCode}` },
        address.zipCode && (address.street1 || address.stateCode || address.city)
          ? { 3: `, ${address.zipCode}` }
          : address.zipCode && { 3: `${address.zipCode}` },
        address.country && (address.street1 || address.stateCode || address.city || address.zipCode)
          ? { 3: `, ${address.country}` }
          : address.country && { 3: `${address.country}` }
      ).join("");
      const startTime = moment.unix(date.startTime);
      const endTime = moment.unix(date.endTime);
      let time = "";
      const timeZone = endTime.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endTime.format("Z"))}[)]`);
      const timeTabletZone = endTime.tz(moment.tz.guess()).format(`[GMT]${getTimezoneNumber(endTime.format("Z"))}[]`);
      const eventInDays = moment.unix(date.endTime).format("DD") - moment.unix(date.startTime).format("DD");
      const isEventInSameMonth = moment.unix(date.endTime).format("MM") === moment.unix(date.startTime).format("MM") ? true : false;
      const endTimeMoment = moment.unix(date.endTime);
      const tabletTimeStart = <div className="time display-flex vertical-align margin-l-5"> {`${startTime.format("hh:mmA")} -`} </div>;

      const tabletTimeEnd = (
        <div className="time display-flex vertical-align margin-l-5">
          {endTime.format("hh:mmA")} {timeTabletZone}{" "}
        </div>
      );

      const tabletDate =
        date.startTime && date.endTime && eventInDays === 0
          ? moment.unix(date.startTime).format("MMMM DD, YYYY")
          : isEventInSameMonth
          ? `${moment.unix(date.startTime).format("MMMM DD")}-${endTimeMoment.format("DD")}, ${moment.unix(date.startTime).format("YYYY")}`
          : `${moment.unix(date.startTime).format("MMMM DD")} - ${endTimeMoment.format("MMMM DD")}, ${moment.unix(date.startTime).format("YYYY")}`;

      if (startTime.isSame(endTime, "day")) {
        time = (
          <div className="row pb-16 datetime">
            <div className="col-4 col-sm-3">
              <div className="date">
                {startTime.format("MMM")}
                <br />
                {startTime.format("DD")}
              </div>
            </div>
            <div className="col-8 time">
              {startTime.format("hh:mm A")} {timeZone}
              <br />
              {endTime.format("hh:mm A")} {timeZone}
            </div>
          </div>
        );
      } else if (startTime.isSame(endTime, "month")) {
        time = (
          <>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {startTime.format("MMM")}
                  <br />
                  {startTime.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {startTime.format("hh:mm A")} {timeZone}
              </div>
            </div>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date single">
                  {endTime.format("MMM")}
                  <br />
                  {endTime.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {endTime.format("hh:mm A")} {timeZone}
              </div>
            </div>
          </>
        );
      } else {
        time = (
          <>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {startTime.format("MMM")}
                  <br />
                  {startTime.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {startTime.format("hh:mm A")} {timeZone}
              </div>
            </div>
            <div className="row pb-16 datetime">
              <div className="col-4 col-sm-3">
                <div className="date">
                  {endTime.format("MMM")}
                  <br />
                  {endTime.format("DD")}
                </div>
              </div>
              <div className="col-8 time">
                {endTime.format("hh:mm A")} {timeZone}
              </div>
            </div>
          </>
        );
      }

      const duration = moment
        .duration(endTime.diff(startTime))
        .asHours()
        .toString();
      const event = {
        description: `For info on this event, click here: \n<a href="https://www.iotnation.com/event/${eventId}">www.iotnation.com/event/${eventId}</a>
         \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events">www.iotnation/events</a>`,
        duration,
        endDatetime: endTime.utc().format("YYYYMMDDTHHmmssZ"),
        locationType,
        startDatetime: startTime.utc().format("YYYYMMDDTHHmmssZ"),
        title: title,
      };
      return (
        <div className="EventId pb-3">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title ? `${title} | IoT Nation` : "IoT Nation"}</title>
          </Helmet>
          <div className={`d-none stickyHeader ${this.state.showSticky ? "visible d-md-block" : ""}`}>
            <div className={`moved-sticky`}>
              <div className={this.state.showSticky ? "container" : ""}>
                <div className="row m-0">
                  <div className="col-3 col-sm-3 col-md-3 col-xl-6 d-flex vertical-align">
                    <p className="title-middle-block ">{title}</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align padding-lr-md-7">
                    <a target="_blank" className="w-100" href={website}>
                      <Button className="website-btn">Website</Button>
                    </a>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align w-100 padding-lr-md-7">
                    <a target="_blank" className="w-100" href={registration}>
                      <Button className="register-btn">Register</Button>
                    </a>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-2 d-flex vertical-align padding-lr-md-7">
                    <div className="w-100">
                      <Button
                        className="discount-btn"
                        onClick={this.discountPopover}
                        style={discount.discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                        id={"DisabledHover1"}
                      >
                        Get&nbsp;Discount&nbsp;Code
                      </Button>
                      {(!discount || !discount.discountCode) && isLoggedIn && this.state.showSticky ? (
                        <Tooltip
                          placement="top"
                          isOpen={this.state.tooltipOpenHeader}
                          autohide={false}
                          target={"DisabledHover1"}
                          toggle={this.tooltipHeader}
                        >
                          No Discount Code from Organizer
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="float-r pointer share-block" to="#" id={"Popover1"} onClick={this.togglePopover}>
                      <FaShareAlt className="share-button" />
                      {this.state.showSticky && (
                        <PopoverBlock
                          title={title}
                          closeDiscountCode={this.closeDiscountCode}
                          togglePopover={this.togglePopover}
                          showShare={this.state.showShare}
                          showDiscountCode={this.state.showDiscountCode}
                          discountPopover={this.discountPopover}
                          filters={filters}
                          prevQuery={prevQuery}
                          discount={discount && discount}
                          isLoggedIn={isLoggedIn}
                          eventId={eventId}
                          showSticky={this.state.showSticky}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  {prevQuery.includes("/events/calendar") ? (
                    <Link to={{ pathname: "/events/calendar" }}>Calendar</Link>
                  ) : prevQuery.includes("/events") ? (
                    <Link to={{ pathname: `/events`, state: { filters } }}>Event List</Link>
                  ) : prevQuery.includes("/sector") ? (
                    <Link to={{ pathname: `/sector` }}>Sector</Link>
                  ) : (
                    <Link to="/events">Event List</Link>
                  )}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Event
                </li>
              </ol>
            </nav>
          </div>
          <StickyContainer>
            <div className="container">
              <div className="row mobile-mar">
                <div className="col-3 d-none d-lg-block">
                  <div className="position-fixed">
                    <Highlights
                      locationAddress={locationAddress}
                      locationUrl={locationUrl}
                      eventId={eventId}
                      eventType={eventType}
                      title={title}
                      location={location}
                      time={date}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-1 col-xl-6 offset-xl-0 mobile-pad">
                  {banner ? (
                    <img className="main-image" src={banner} alt="" />
                  ) : (
                    eventType && (
                      <CardsTitle
                        isExtraInfo={banner ? false : true}
                        image={`/images/${eventType.replace(/\s/g, "")}-white.svg`}
                        titleName={eventType}
                        name={"description"}
                      />
                    )
                  )}
                  <div className={banner && eventType.toLowerCase() !== "webinar" ? `side-block-border first` : `side-block-border first`}>
                    <div className={`p-0-32-16-32`}>
                      <div>
                        <div className="row">
                          {eventType && banner ? <div className="d-flex col-5 vertical-align blue-color2">{eventType && eventType}</div> : ""}
                          {/* {
                        banner &&
                        <div className="likes d-flex">
                          <div className="likes">
                            {this.state.likeLength}
                          </div>
                          <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                          {this.props.user?'':
                              <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={'Like-eventId'} />
                            }
                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                          </Button>
                        </div> 
                      }   */}
                        </div>

                        <p className="title-middle-block">{title}</p>

                        <div className="row social-links position-relitive">
                          <div className="col-12">
                            {socialLinks.linkedin && (
                              <a target="_blank" href={socialLinks.linkedin}>
                                <FaLinkedin className="eventId-icon linkedin-icon" />
                              </a>
                            )}
                            {socialLinks.twitter && (
                              <a target="_blank" href={socialLinks.twitter}>
                                <FaTwitter className="eventId-icon twitter-icon" />
                              </a>
                            )}
                            {socialLinks.facebook && (
                              <a target="_blank" href={socialLinks.facebook}>
                                <FaFacebook className="eventId-icon facebook-icon" />
                              </a>
                            )}
                            {socialLinks.blog && (
                              <a target="_blank" href={socialLinks.blog}>
                                <FaBlogger className="eventId-icon blooger-icon" />
                              </a>
                            )}
                            {socialLinks.youtube && (
                              <a target="_blank" href={socialLinks.youtube}>
                                <FaYoutube className="eventId-icon youtube-icon" />
                              </a>
                            )}

                            <span className="float-r pointer d-none d-md-block" to="#" id={"Popover2"} onClick={this.togglePopover}>
                              <FaShareAlt style={{ color: "#1B7BEB", fontSize: "14px", position: "absolute", right: "0px", top: "10px" }} />
                            </span>
                            <span className="float-r pointer d-block d-md-none" to="#" id={"SmallPopver1"} onClick={this.toggleSmallPopover}>
                              <FaShareAlt style={{ color: "#1B7BEB", fontSize: "14px", position: "absolute", right: "15px", top: "10px" }} />
                            </span>
                          </div>
                        </div>

                        {!this.state.showSticky && (
                          <>
                            <PopoverBlock
                              title={title}
                              closeDiscountCode={this.closeDiscountCode}
                              togglePopover={this.togglePopover}
                              showShare={this.state.showShare}
                              showDiscountCode={this.state.showDiscountCode}
                              discountPopover={this.discountPopover}
                              filters={filters}
                              prevQuery={prevQuery}
                              discount={discount && discount}
                              isLoggedIn={isLoggedIn}
                              eventId={eventId}
                              showSticky={this.state.showSticky}
                            />
                          </>
                        )}
                        <SmallScreenPopoverBlock
                          title={title}
                          closeDiscountCode={this.closeDiscountCode}
                          smallScreens={true}
                          togglePopover={this.toggleSmallPopover}
                          showShare={this.state.showSmallShare}
                          showDiscountCode={this.state.showSmallDiscountCode}
                          discountPopover={this.discountSmallPopover}
                          filters={filters}
                          prevQuery={prevQuery}
                          discount={discount && discount}
                          isLoggedIn={isLoggedIn}
                          eventId={eventId}
                          showSticky={this.state.showSticky}
                        />
                        <div className="d-block d-sm-block d-md-none ">
                          <p className="section-title">When & Where</p>
                          <div className="event-place">
                            <div className="container pl-24 pr-24 pb-24 pt-24">
                              <p style={{ fontSize: "13px", fontStyle: "italic" }}>Event is shown in your local time.</p>
                              {time}
                              {locationType && (
                                <div className="row pb-16">
                                  <div className="col-1">
                                    <img className="image small-icon" src="/images/pin-3.svg" alt="" />
                                  </div>
                                  <div className="col-10">
                                    {this.state.locationAddress ? (
                                      <a
                                        className="adress"
                                        target="_blank"
                                        href={`https://www.google.com/maps/search/?api=1&query=${this.state.locationUrl}`}
                                      >
                                        <span>{locationType}</span>
                                      </a>
                                    ) : (
                                      locationType
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="button-padding">
                                <AddtoCalendarDropdown
                                  event={event}
                                  items={isAndroid ? ["Google"] : isIOS ? ["iCal"] : ["Google", "iCal"]}
                                  className="AddtoCalendarDropdown"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="d-block d-sm-block d-md-none" />
                        <div id="eventid-buttons" className={!socialLinksPresent ? "row mt-24 no-soc" : "row mt-24"}>
                          <div className="col-md-4">
                            <a target="_blank" href={website}>
                              <Button className="website-btn">Website</Button>
                            </a>
                          </div>
                          <div className="col-md-4  d-none d-md-block">
                            <a target="_blank" href={registration}>
                              <Button className="register-btn">Register</Button>
                            </a>
                          </div>
                          <div className="col-md-4 d-none d-md-block p-0">
                            <div className="">
                              <Button
                                className="discount-btn"
                                onClick={this.discountPopover}
                                style={discount.discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                                id={"DisabledHover2"}
                              >
                                Get&nbsp;Discount&nbsp;Code
                              </Button>
                              {(!discount || !discount.discountCode) && isLoggedIn && !this.state.showSticky ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={this.state.tooltipOpenHeader}
                                  autohide={false}
                                  target={"DisabledHover2"}
                                  toggle={this.tooltipHeader}
                                >
                                  No Discount Code from Organizer
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="side-block-border p-16-32">
                    <div className="d-none d-md-block d-lg-none">
                      <hr />
                      <div className="event-place">
                        <div className="container pl-24 pr-24 pb-24 pt-24">
                          <p style={{ fontSize: "13px", fontStyle: "italic" }}>Event is shown in your local time.</p>
                          <div className="row">
                            <div className="col-4 padding-lr-7 display-flex">
                              <img src="/images/calendar-1.svg" alt="" />
                              <div className="date display-flex vertical-align margin-l-5"> {tabletDate} </div>
                            </div>
                            <div className="col-5 padding-lr-7 display-flex">
                              <img src="/images/clock.svg" alt="" />
                              {tabletTimeStart}
                              {tabletTimeEnd}
                            </div>
                            <div className="button-padding col-3 p-0">
                              <AddtoCalendarDropdown
                                event={event}
                                items={isAndroid ? ["Google"] : isIOS ? ["iCal"] : ["Google", "iCal"]}
                                className="AddtoCalendarDropdown"
                              />
                            </div>
                          </div>
                          {locationType && (
                            <div className="row">
                              <div className="col-12 display-flex vertical-align padding-lr-7">
                                <img className="image small-icon" src="/images/pin-3.svg" alt="" />
                                <div className="margin-l-5">
                                  {this.state.locationAddress ? (
                                    <a
                                      className="adress"
                                      target="_blank"
                                      href={`https://www.google.com/maps/search/?api=1&query=${this.state.locationUrl}`}
                                    >
                                      <span>{locationType}</span>
                                    </a>
                                  ) : (
                                    locationType
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {tags.lenght > 0 && (
                      <>
                        <hr />
                        <p className="section-title">Tags</p>
                      </>
                    )}
                    <div className="display-flex">
                      {tags.horizontals && tags.horizontals.map((item, key) => <div key={key}>{item === "" ? null : <BlueTag name={item} />}</div>)}
                    </div>
                    <div className="display-flex">
                      {tags.verticals && tags.verticals.map((item, key) => <div key={key}>{item === "" ? null : <PurpuleTag name={item} />}</div>)}
                    </div>
                    {(descriptions.longDescription !== "" || descriptions.shortDescription !== "") && (
                      <>
                        <hr />
                        <p className="section-title">Event Description</p>
                      </>
                    )}
                    <div className={this.state.showContent ? "toggle" : "toggle hidden"}>
                      <p
                        className="text-style"
                        dangerouslySetInnerHTML={
                          descriptions.longDescription !== ""
                            ? { __html: descriptions.longDescription.replace(/--+/g, "<br />") }
                            : { __html: descriptions.shortDescription.replace(/--+/g, "< br />") }
                        }
                      />
                    </div>
                    <hr />
                    <span onClick={this.handleShowContent} className="justify-center display-flex pointer text-decoration">
                      {this.state.showContent ? "View Less" : "View More"}
                    </span>
                  </div>
                  <MiddleSwitcher {...{ eventType, speakers, organizers, sponsors }} />
                </div>
                <div className="col-3 d-none d-xl-block">
                  <SideSwitcher {...{ eventType, speakers, organizers, sponsors }} />
                </div>
              </div>
            </div>
          </StickyContainer>
          <div className="fixed-bottom d-block d-sm-block d-md-none">
            <div className="row">
              <div className="col-6 pr5">
                <div>
                  <Button
                    className="discount-btn"
                    onClick={this.discountSmallPopover}
                    style={discount.discountCode ? { backgroundColor: "orange" } : { backgroundColor: "grey" }}
                    id={"DisabledHoverSmall1"}
                  >
                    Get Discount Code
                  </Button>
                  {(!discount || !discount.discountCode) && isLoggedIn ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipSmallOpenHeader}
                      autohide={false}
                      target={"DisabledHoverSmall1"}
                      toggle={this.tooltipSmallHeader}
                    >
                      No Discount Code from Organizer
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-6 pl5">
                <a target="_blank" href={registration}>
                  <Button className="register-btn">Register</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
  }
}

export default withRouter(EventId);
