export default function (a, b) {
  if(b.featured&&!a.featured){
    return 1
  }else if(a.featured&&b.featured){
    return 0
  }else if(!b.featured&&a.featured){
    return -1
  }else{
    return 0
  } 
}