/*global AmazonCognitoIdentity*/
import React from "react";
import * as jwtDecode from "jwt-decode";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import intercom from "./utils/intercom";
import { NotificationContainer } from "react-notifications";
import "react-dates/initialize";
import { StickyContainer, Sticky } from "react-sticky";
import "bootstrap/dist/css/bootstrap.css";
import "react-dates/lib/css/_datepicker.css";
import "./App.scss";
import ActivationCodeContainer from "./ActivationCode/ActivationCodeContainer";
import EventId from "./EventId/EventId.jsx";
import Index from "./Index/Index";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import SearchContainer from "./Search/SearchContainer.jsx";
import SmartFinder from "./SmartFinder/SmartFinderContainer";
import CorpEcoStatsContainer from "./CorpEcoStats/CorpEcoStatsContainer";
import CompanyContainer from "./Company/CompanyContainer.jsx";
import ContactsContainer from "./Contacts/ContactsContainer.jsx";
import IndustryGroupStatsContainer from "./IndustryGroupStats/IndustryGroupStatsContainer";
import EventsContainer from "./Events/EventsContainer";
import CalendarContainer from "./Events/CalendarContainer";
import TermsOfService from "./GeneralPages/TermsOfService";
import Attribution from "./GeneralPages/Attribution";
import PrivacyPolicy from "./GeneralPages/PrivacyPolicy";
import SignupContainer from "./Signup/SignupContainer";
import LoginContainer from "./Login/LoginContainer";
import CopyrightNotice from "./GeneralPages/CopyrighNotice";
import Profile from "./Profile/Profile";
import PasswordResetContainer from "./PasswordReset/PasswordResetContainer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AdminGroups from "./Admin/Groups/Groups";
import ErrorPage from "./Error/Error";
import MapContainer from "./Map/MapContainer.jsx";
import CompetitionsContainer from "./Competitions/CompetitionsContainer.jsx";
import UseCaseDetailContainer from "./UseCaseDetail/UseCaseDetailContainer";
import UseCaseListContainer from "./UseCaseList/UseCaseListContainer";
import PartnerContainer from "./Partner/PartnerContainer";
import SectorContainer from "./Sector/SectorContainer";
import Blog from "./Blog/BlogContainer";
import Article from "./Article/ArticleContainer";
import AmbassadorsContainer from "./Ambassadors/AmbassadorsContainer";
import About from "./About/About";
import ReportContainer from "./Report/ReportContainer";
import ReportDownloadContainer from "./Report/ReportDownloadContainer";
import hidePagesJSON from "./content/hidePages.json";
import PartnersContainer from "./Partners/PartnersContainer";
import CampaignContainer from "./CampaignPartners/CampaignContainer";
import PreviewController from "./Preview/PreviewController";
import JobsContainer from "./Jobs/JobsContainer";
import login from "./components/functions/login";
import logout from "./components/functions/logout";
import getSession from "./components/functions/getSession";
import ReportsContainer from "./Reports/ReportsContainer";
import IotAce from "./IotAce/IotAce";
import Pricing from "./Pricing/Pricing";
import SalesContainer from "./Sales/SalesContainer";
import MyTesting from "./MyTesting/MyTesting";
import GeneratedApi from "./components/functions/generatedApi";
import Videos from "./Videos/Videos";
import { createContext } from "react";

export const UserContext = createContext(null);
const cookies = new Cookies();

const WrapRoute = ({ saveState, children, lastLocation }) => {
  const location = window.location;
  if (
    `${lastLocation.pathname}${lastLocation.search}` !==
    `${location.pathname}${location.search}`
  ) {
    saveState({
      lastLocation: { pathname: location.pathname, search: location.search },
    });
  }
  return children;
};

export default class AppRouter extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      userType: null,
      tosModalIsOpen: false,
      lastLocation: { pathname: "/", search: "" },
      tos: {
        agree: false,
        firstName: "",
        lastName: "",
        companyName: "",
      },
      acceptTos: false,
      apiUrl: process.env.REACT_APP_API_URL,
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_KEY
    }&v=3.exp&libraries=geometry,drawing,places`;
    script.async = true;
    document.body.appendChild(script);

    const environment = process.env.REACT_APP_ENV;
    switch (environment) {
      case "legacy":
        this.state.signUpUrl = `https://iot-users-login.auth.us-east-1.amazoncognito.com/signup?response_type=token&client_id=7ga45qqjdq3t7eit8s3n0rdsfu&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        this.state.loginUrl = `https://iot-users-login.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=7ga45qqjdq3t7eit8s3n0rdsfu&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        break;
      case "production":
        this.state.signUpUrl = `https://iot-nation-chalice-backend-users-prod-login.auth.us-east-1.amazoncognito.com/signup?response_type=token&client_id=7e5r2ojsc1f61nhfrdjk4pkaif&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        this.state.loginUrl = `https://iot-nation-chalice-backend-users-prod-login.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=7e5r2ojsc1f61nhfrdjk4pkaif&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        break;
      case "development":
        this.state.signUpUrl = `https://iot-nation-chalice-backend-users-dev-login.auth.us-east-1.amazoncognito.com/signup?response_type=token&client_id=1347i7eb31kq7pqpaoku9np1s8&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        this.state.loginUrl = `https://iot-nation-chalice-backend-users-dev-login.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=1347i7eb31kq7pqpaoku9np1s8&redirect_uri=${
          window.location.origin
        }/auth/callback`;
        break;
      default:
    }
    if (window.location.search.includes("code")) {
      cookies.set(
        "activationCode",
        window.location.search.replace(/\?code=/g, ""),
        {
          path: "/",
          expires: new Date(Date.now() + 1209600000),
          sameSite: false,
        }
      );
    }
    // check is login token exist, not expired and meybe login came from UGC
    const saveState = (getData) => {
      this.state = { ...this.state, ...getData };
    };
    login("", saveState);

    intercom();
  }

  handeSaveState = (data) => {
    this.setState({ ...this.state, ...data });
  };

  handleLoginToken = (result) => {
    if (result && result.idToken && result.idToken.jwtToken) {
      const token = result.idToken.jwtToken;
      cookies.set("refreshToken", result.refreshToken.token, {
        path: "/",
        domain:
          process.env.REACT_APP_JSON_TYPE === "local"
            ? "localhost"
            : "iotnation.com",
        expires: new Date(Date.now() + 86400000),
        sameSite: false,
      });
      cookies.set("jwtToken", token.split("&")[0], {
        path: "/",
        domain:
          process.env.REACT_APP_JSON_TYPE === "local"
            ? "localhost"
            : "iotnation.com",
        expires: new Date(Date.now() + 86400000),
        sameSite: false,
      });
      let key;
      let obj = window.localStorage;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            key.includes("CognitoIdentityServiceProvider") &&
            !key.includes(".refreshToken")
          ) {
            if (key.includes("idToken")) {
              cookies.set(
                process.env.REACT_APP_JSON_TYPE === "production"
                  ? "PSId"
                  : "DSId",
                `${key.split(".")[1]}.${key.split(".")[2]}`,
                {
                  path: "/",
                  domain:
                    process.env.REACT_APP_JSON_TYPE === "local"
                      ? "localhost"
                      : "iotnation.com",
                  expires: new Date(Date.now() + 86400000),
                  sameSite: false,
                }
              );
            } else {
              cookies.set(key, obj[key], {
                path: "/",
                domain:
                  process.env.REACT_APP_JSON_TYPE === "local"
                    ? "localhost"
                    : "iotnation.com",
                expires: new Date(Date.now() + 86400000),
                sameSite: false,
              });
            }
          }
        }
      }
      try {
        this.setState({
          ...this.state,
          user: jwtDecode(token),
          userType:
            jwtDecode(token) &&
            jwtDecode(token)["cognito:groups"] &&
            jwtDecode(token)["cognito:groups"][0],
        });
        GeneratedApi(token, true)
          .get("/userprofile")
          .then((response) => {
            this.setState({
              ...this.state,
              tos: {
                firstName: response.data.account_info.firstName,
                lastName: response.data.account_info.lastName,
                companyName: response.data.account_info.companyName,
              },
              fullProfile: response.data.account_info,
              limits: response.data.usage,
              legalAgreements: response.data.legal_agreements,
            });
          })
          .catch((error) => {
            console.log(error);
          });
        GeneratedApi(token, true)
          .get("/usertos")
          .then((res) => {
            this.setState({
              agreenment: res.data,
              tosModalIsOpen: !res.data.status,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("invalid token format", error);
      }
    }
    setInterval(() => {
      if (cookies.get("jwtToken") && jwtDecode(cookies.get("jwtToken"))) {
        getSession(jwtDecode(cookies.get("jwtToken")), this.handeSaveState);
      } else {
        logout();
      }
    }, (jwtDecode(cookies.get("jwtToken")).exp + 60 - jwtDecode(cookies.get("jwtToken")).iat) * 1000);
    this.handleTOSModal();
  };

  componentDidMount() {
    this.handleTOSModal();
  }

  postTosStatus = (status = null) => {
    const Token = cookies.get("jwtToken");
    if (Token !== undefined) {
      const { tos } = this.state;
      if (status != null) {
        tos.agree = status;
      } else {
        if (tos.agree === "true") {
          tos.agree = true;
        } else if (tos.agree === "false") {
          tos.agree = false;
        }
      }
      GeneratedApi(Token, true)
        .post("/usertos", tos)
        .then((res) => {
          if (tos.agree === false) {
            logout(this.state.user);
          }
        });
    }
  };

  handleTOSModal = (result) => {
    if (result === true || result === false) {
      this.setState({
        tosModalIsOpen: false,
        acceptTos: true,
      });
      if (result === false) {
        this.setState({
          acceptTos: false,
        });
      }
      this.postTosStatus(result);
      return;
    }

    if (this.state.user === null) return;
  };

  handleIntercom = () => {
    intercom();
  };

  render() {
    const { user, userType } = this.state;
    const userTypee =
      this.state.user &&
      this.state.user["cognito:groups"] &&
      this.state.user["cognito:groups"][0];
    return (
      <UserContext.Provider
        value={{ user, userType, handeSaveState: this.handeSaveState }}
      >
        <StickyContainer>
          <BrowserRouter>
            <ScrollToTop>
              <Sticky>
                {({
                  style,
                  isSticky,
                  wasSticky,
                  distanceFromTop,
                  distanceFromBottom,
                  calculatedHeight,
                }) => (
                  <Route
                    path="/"
                    render={(props) => (
                      <Header
                        handleIntercom={this.handleIntercom}
                        distanceFromTop={{ distanceFromTop }}
                        {...props}
                        style={{ ...style }}
                        data={this.state.user}
                        loginUrl={this.state.loginUrl}
                        signUpUrl={this.state.signUpUrl}
                      />
                    )}
                  />
                )}
              </Sticky>
              <Switch>
                <Route
                  path="/casestudies/:id"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <SalesContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/companies/"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <SearchContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/contacts/"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ContactsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/smart-cities-finder"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <SmartFinder {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/corporate-ecosystem-stats"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CorpEcoStatsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/industry-groups-stats"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <IndustryGroupStatsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/events"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <EventsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/events/calendar"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CalendarContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/event/:eventId"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <EventId {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/signup"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <SignupContainer
                        handleLoginToken={this.handleLoginToken}
                        {...props}
                        {...this.state}
                      />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/login"
                  render={(props) => (
                    <LoginContainer
                      handleLoginToken={this.handleLoginToken}
                      {...props}
                      {...this.state}
                    />
                  )}
                />
                <Route
                  path="/usecases/"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <UseCaseListContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/usecase/:usecaseId"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <UseCaseDetailContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                {!hidePagesJSON["calendar"] && (
                  <Route
                    exact
                    path="/events/calendar"
                    render={(props) => (
                      <WrapRoute
                        saveState={this.handeSaveState}
                        lastLocation={this.state.lastLocation}
                      >
                        <CalendarContainer {...props} {...this.state} />
                      </WrapRoute>
                    )}
                  />
                )}
                {/* <Route
                  path="/competitions"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CompetitionsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                /> */}
                <Route
                  path="/partner"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <PartnerContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/blog"
                  exact
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Blog {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/blog/:articleId"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Article {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/ambassadors"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <AmbassadorsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/distancingtogether"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CampaignContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/admin/activationCode"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ActivationCodeContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/jobs"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <JobsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                {(userTypee === "admin" || userTypee === "premium") && (
                  <Route
                    path="/sector/:tagId"
                    render={(props) => (
                      <WrapRoute
                        saveState={this.handeSaveState}
                        lastLocation={this.state.lastLocation}
                      >
                        <SectorContainer {...props} {...this.state} />
                      </WrapRoute>
                    )}
                  />
                )}
                <Route
                  path="/about"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <About {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/partners"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <PartnersContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/report/download"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ReportDownloadContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/report/:reportId"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ReportContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/mvp"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Redirect to="/report/101" />
                    </WrapRoute>
                  )}
                />
                {/* {
                !hidePagesJSON['reports'] && */}
                <Route
                  exact
                  path="/reports"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ReportsContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                {/* } */}
                <Route
                  path="/reset-password"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <PasswordResetContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/events/preview"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <PreviewController {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/videos"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Videos {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/company/:companyId"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CompanyContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/map"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <MapContainer {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/auth/callback/"
                  render={(_) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Redirect to="/companies/" />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/admin/groups"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <AdminGroups {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                {userTypee === "admin" && (
                  <Route
                    exact
                    path="/attribution"
                    render={() => (
                      <WrapRoute
                        saveState={this.handeSaveState}
                        lastLocation={this.state.lastLocation}
                      >
                        <Attribution />
                      </WrapRoute>
                    )}
                  />
                )}
                <Route
                  exact
                  path="/terms-of-service"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <TermsOfService />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <PrivacyPolicy />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/copyright-notice"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <CopyrightNotice />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/iotace"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <IotAce user={this.state.user} />
                    </WrapRoute>
                  )}
                />
                {userTypee === "admin" ||
                  (userTypee === "premium" && (
                    <Route
                      exact
                      path="/subscription-plans"
                      render={() => (
                        <WrapRoute
                          saveState={this.handeSaveState}
                          lastLocation={this.state.lastLocation}
                        >
                          <Pricing user={this.state.user} />
                        </WrapRoute>
                      )}
                    />
                  ))}
                {userTypee === "admin" && (
                  <Route
                    exact
                    path="/mytesting"
                    render={() => (
                      <WrapRoute
                        saveState={this.handeSaveState}
                        lastLocation={this.state.lastLocation}
                      >
                        <MyTesting user={this.state.user} />
                      </WrapRoute>
                    )}
                  />
                )}
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Profile {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
                <Route
                  exact
                  path="/error"
                  render={() => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <ErrorPage />
                    </WrapRoute>
                  )}
                />
                <Route
                  path="/"
                  render={(props) => (
                    <WrapRoute
                      saveState={this.handeSaveState}
                      lastLocation={this.state.lastLocation}
                    >
                      <Index {...props} {...this.state} />
                    </WrapRoute>
                  )}
                />
              </Switch>
              <Footer {...this.state} />
            </ScrollToTop>
          </BrowserRouter>
          <NotificationContainer />
        </StickyContainer>
      </UserContext.Provider>
    );
  }
}
