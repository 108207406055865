import React from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet";
import style from "./Reports.module.scss";

const Reports = (props) => {
  const { data } = props;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IoT Nation Reports | IoT Nation</title>
      </Helmet>
      <Breadcrumbs last="IoT Nation Reports" />
      <div className={`top-panel ${style.top_panel}`}>
        <div className={`title mx-auto ${style.title}`}>IoT Nation™ Reports</div>
      </div>
      <div className="container mt-3">
        {data &&
          data.map((item, key) => (
            <a key={key} className="text-decoration-none" href={item["button-link"]}>
              <div className="mb-3 p-3 border rounded col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <div className="d-block d-md-flex">
                  <div className={`black-border mx-auto mb-3 mr-md-3 mb-md-0 rounded d-flex ${style.image_block}`}>
                    <img
                      className={`m-auto ${style.image}`}
                      src={item.thumbnail && item.thumbnail !== "" ? item.thumbnail : "/images/PDF_report.svg"}
                      alt=""
                    />
                  </div>
                  <div className="w-100">
                    <div className="font-s-18 mb-3 font-weight-bold">{item.title}</div>
                    <div className="mb-3 line-h-15 color-dark">{item.description}</div>
                  </div>
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default Reports;
