import React from "react";
import Reports from "./Reports";
import dataJSON from "../content/reports.json";
import { Redirect } from "react-router-dom";

const ReportsContainer = ({ user }) => {
  // if(user && (user["cognito:groups"][0] === "admin" || user["cognito:groups"][0] === "premium")){
  return <Reports data={dataJSON} />;
  // }
  // return(
  //   <Redirect to="/" />
  // )
};

export default ReportsContainer;
