import React, { Component } from "react";
import { Input } from "reactstrap";
import { Subscription } from "../components/Subscribe/Subscribe";
import { NavLink } from "react-router-dom";
import hidePagesJSON from "../content/hidePages.json";
import "./Events.scss";

export default class Events extends Component {
  render() {
    const { searchInput, handleInput, handleSearchSubmit } = this.props;
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div className="Events">
        <div className="custom-background-2 text-center background-blue-2 p-4 w-100 d-flex d-md-none">
          <div className="font-archivo h4 mb-0 text-align-center text-white mx-auto">
            {window.location.pathname === "/events"
              ? "Events List"
              : window.location.pathname === "/events/calendar"
              ? "Events Calendar"
              : "Events List"}
          </div>
        </div>

        <div className="top-panel d-none d-md-flex mb-32 w-100">
          <div className="container">
            <div className="row">
              <form
                onSubmit={handleSearchSubmit}
                className="col-md-12 col-lg-6 col-xl-6 search-box"
              >
                <div className="d-flex">
                  <div className="input-group">
                    <span className="w-100">
                      <Input
                        className="form-control"
                        type="search"
                        onChange={handleInput}
                        value={searchInput}
                        name="searchInput"
                        placeholder="Search events, webinars, hackathons and more"
                        aria-label="Search events, webinars, hackathons and more"
                      />
                    </span>
                    <div className="input-group-prepend">
                      <button
                        className="btn text-white rounded background-blue py-1 px-4 d-none d-md-block"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="col-12 col-lg-5 col-xl-5 offset-lg-1 mt-md-3 mt-lg-0 d-none d-md-flex d-lg-block">
                <div className="row m-md-auto">
                  <div className="col-7">
                    <div className="row">
                      {!hidePagesJSON["calendar"] && (
                        <div className="view-controls w-100 d-flex align-items-center">
                          <NavLink
                            exact
                            to="/events"
                            className="view d-flex pointer"
                            activeClassName="active"
                          >
                            <div className="m-auto">List View</div>
                          </NavLink>
                          <NavLink
                            exact
                            to="/events/calendar"
                            className="view d-flex pointer"
                            activeClassName="active"
                          >
                            <div className="m-auto">Calendar View</div>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <a
                        target="_blank"
                        href="https://forms.gle/vsCt94LQhSDGbxDx5"
                        className="event-btn"
                      >
                        <button className="post-event font-weight-bold ml-3 background-orange custom-button rounded">
                          Post an Event
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
        {userType !== "admin" && userType !== "corporate" && (
          <div style={{ marginTop: "50px" }}>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
