import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import '../RegisterPopover/RegisterPopover.scss'
export default class RestrictedPopover extends Component {
    render() {
        return (
            <>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}  centered={true} style={{}}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className="center-text">Just visiting? </div>
                        <div className="center-text margin-top-10">Unlock access to this feature and many more when you register</div>
                    </ModalHeader>
                    <ModalBody style={{backgroundColor: "white"}}>
                        <p className="justify-center-block center-text">Plus access to 1,500 companies for free</p>
                        <div className="justify-center-block m-tb-15"><Link  to={{pathname:'/signup',  state:{page:this.props.pathname&&this.props.pathname}}} ><Button className="sign-up">Sign Up</Button></Link></div>
                        <p><Link className="justify-center-block" to={{pathname:'/iotace',  state:{page:this.props.pathname&&this.props.pathname}}} >Learn More</Link></p>
                        <p className="justify-center-block margin-0">Already Registered?</p>
                        <p><Link className="justify-center-block" to={{pathname:'/login',  state:{page:this.props.pathname&&this.props.pathname}}} >Login</Link></p>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}
