import React, { Component } from 'react'
import InfoBlock from './components/InfoBlock.jsx'
import {Helmet} from "react-helmet";
import './Partner.scss'

export default class Partner extends Component {
    render() {
        return (
            <div className="Partner">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partner | IoT Nation</title>
                </Helmet>
                <div className="container p-0">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href='/'>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Partner</li>
                    </ol>
                    </nav>  
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center welcome-screen">
                        <div className="col-auto text-box">
                            <h1>Become an IoT Nation Partner</h1>
                            IoT Nation works with different types of partners to bring you the most relevant <br />
                            data and resources on the Internet of Things ecosystem.
                        </div>
                    </div>
                </div>
                <div className="main-block">
                    <div className="container p-0">
                        <div className="row">
                            <div className="col-6">
                                <InfoBlock img={"/images/search-small.svg"} title={"Advertise on IoT Nation"} description={"Reach thousands of IoT professionals and enthusiats including developers and business professionals. "} btnName={"Get Started"} btnLink={""} topBlock={true} />
                            </div>
                            <div className="col-6">
                                <InfoBlock img={"/images/search-small.svg"} title={"Media Partners"} description={"Would your media site like to benefit from IoT Nation's data?"} btnName={"Get Started"} btnLink={""} topBlock={true} />
                            </div>
                            <div className="col-6">
                                <InfoBlock img={"/images/search-small.svg"} title={"Data Partners"} description={"Want to partner on the data side by offering or leveraging IoT Nation's comprehensive ecosystem data?"} btnName={"Get Started"} btnLink={""} topBlock={false} />
                            </div>
                            <div className="col-6">
                                <InfoBlock img={"/images/search-small.svg"} title={"Looking for someting else?"} description={"We're open to ways to work together. If you don't see what you're looking for here, contact us!"} btnName={"Contact Us"} btnLink={""} topBlock={false} />
                            </div>
                        </div>
                    </div>    
                </div>
                
            </div>
        )
    }
}
