import React from 'react'

const Endorsements = ({ dataJSON, style }) => {
  if(dataJSON.length > 0){
    return (
    <div className="custom-background background-blue-2 py-5">
        <div className="container">
            <div className="text-white font-weight-bold font-s-28 mb-4 text-center">Endorsements</div>
            {
                dataJSON.map((item, key) => (
                    <div key={key} className={`bg-white row mx-auto col rounded mb-3 ${style.endorsment_block}`}>
                        <div className="col-12 font-s-18 col-sm py-3">
                            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                            
                            <div className="text-right text-primary font-italic opacity-6 mt-2">{`-- ${item.author}`}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
    )
  }
  return ('')
}

export default Endorsements
