import React, { Component } from 'react'

export default class RowSubscriptions extends Component {
    render() {
        const {name, registered, pro, enterprise} = this.props
        return (
            <>
                <div className="col-3 pr-md-0 new-line"> <div className="block">{name}</div></div>
                <div className="col-3 pr-md-0"> 
                    <div className={`block center registered`}> <div className={registered}></div>  </div>
                </div>
                <div className="col-3 pr-md-0"> 
                    <div className={`block center pro`}>  <div className={pro}></div>  </div>
                </div>
                <div className="col-3 pr-md-0"> 
                    <div className={`block center enterprise`}>  <div className={enterprise}></div>  </div>
                </div>
            </>
        )
    }
}
