import eventFilters from "../../assets/eventFilters.json";
import isEqual from "lodash/isEqual";
import unionWith from 'lodash/unionWith'
import moment from "moment";
//eventFilters.schemas;
export const schemas = {
        "eventTypes" : ["Competition"],
        "eventSegments" : ["IoT", "AI", "VR/AR", "Blockchain"]
    }
// {
//     "eventTypes" : ["Competition"],
//     "eventSegments" : ["IoT", "AI", "VR/AR", "Blockchain"]
// }
export const attributes = eventFilters.attributes;

export const mainAttributes = [
    "Location",
    "dateEnd",
    "dateStart",
    "eventSegment",
    "eventType"
]

export function getMainFilters(filters = []) {

    const initialAttributes = mainAttributes.map(attribute => {
        const operators = getOperators(attribute);
        const operator = getMainAttributeInfo(attribute).operator
        const values = getValues(attribute, operator);
        const valueType = getType(attribute, operator);
        const value = getMainAttributeInfo(attribute).value
        const mainAttribute = !!getMainAttributeInfo(attribute);
        const useFilter = getMainAttributeInfo(attribute).useFilter;

        return Object.assign(
        {},
        {
            attribute,
            operators,
            operator,
            values,
            value,
            valueType,
            mainAttribute,
            useFilter
        }
        );
    });

    return filters.length > 0
    ? unionWith(filters, initialAttributes ,function(a, b){
        return a.attribute === b.attribute
    })
    : initialAttributes
 
}

export function getFilter(attribute, mainAttribute = false, useFilter = true, initialData = {
    operator:null,
    value:null,
    attribute2:null,
    operator2:null,
    value2:null
}) {

    const operators = getOperators(attribute);
    const operator = mainAttribute?getMainAttributeInfo(attribute).operator:getOperator(attribute, operators, 0);
    const values = getValues(attribute, operator);
    const valueType = getType(attribute, operator);
    const value = initialData.value?initialData.value:mainAttribute?getMainAttributeInfo(attribute).value:getValue(valueType, values);

    return Object.assign(
      {},
      {
        attribute,
        operators,
        operator,
        values,
        value,
        valueType,
        mainAttribute,
        useFilter
      }
    );
}
export function isFiltersChanged(prevFilters, filters) {
    return !isEqual(prevFilters, filters);
}

export function setFiltersQuery(filters) {
    return [
        ...filters
       
    ].filter(({useFilter, value, currentCoordinates})=>{   
        if(useFilter && (value || value === '' || currentCoordinates)){
            // if(value.length === 0){
            // return false
            // }
            return true
        }

        return false
    })
    .map(({ attribute, operator, value, mainAttribute, valueType, currentCoordinates }, i) =>
    Object.assign(
      {},
      {
        attribute,
        operator,
        value:mainAttribute
            ?convertMainAtrributesToQuery(attribute, value, currentCoordinates)
            :convertValuesToQuery(valueType, value)
      }
    )
  );
}

export function getSchema(schemasArr = []) {
  return schemasArr.reduce((data, schema) => data.concat(schemas[schema]), []);
}

export function getAttributesKeys(attributes){
    return Object.keys(attributes)
}

export function getValues(attribute, operator) {
  const schemasArr = attributes[attribute][operator].value.schemas;
  return schemasArr ? getSchema(schemasArr) : null;
}

export function getOperators(attribute) {
    return Object.keys(attributes[attribute]);
}

export function getOperator( operators, index = 0) { 
    return operators[index];
}

export function getType(attribute, operator) {
    return  attributes[attribute][operator].value.type;
}

export function setValue(type, values) {
    switch (type) {
        case "number":
            return 0;
        case "string":
            return "";
        case "mult":
            return values;
        default:
            return null;
    }
}

export function getValue(type, values) {
    return setValue(type, values);
}

export function getMainAttributeInfo(attribute) {
    switch (attribute) {
        case "Location":
            return { value: "", operator: "Is near", useFilter:false };
        case "dateEnd":
            return { value: null, operator: "Greater than or equal to", useFilter:false };
        case "dateStart":
            return { value: null, operator: "Less than", useFilter:true };
        case "eventSegment":
            return { value: getValues(attribute, 'includes'), operator: "includes", useFilter:true };
        case "eventType":
            return { value: getValues(attribute, 'includes'), operator: "includes", useFilter:true };
        default:
            return false;
  }
}

export function convertMainAtrributesToQuery(attribute, value, currentCoordinates){
    switch (attribute) {
        case "Location":
            return currentCoordinates
        case "dateEnd":
            return moment(value,'YYYY-MM-DD hh:mm Z').unix()
        case "dateStart":
            return moment(value).unix()
        case "eventSegment":
            return value.join()
        case "eventType":
            return value.join()
        default:
            return value;
  }
}

export function convertValuesToQuery(valueType, value){
    switch (valueType) {
        case 'mult':
            return value.join()
        default:
            return value;
    }
}
