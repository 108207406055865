import React from "react";
import { Input } from "reactstrap";
import style from "./style.module.scss";

const TopSearchBlock = (props) => {
  const { submit, value, onChange, title, children } = props;
  const { pathname } = window.location;
  return (
    <div
      className={`${style.root} ${
        pathname.split("/")[1] === "map" ? "d-none d-lg-block" : ""
      }`}
    >
      <div className="custom-background-2 text-center background-blue-2 p-4 w-100 d-flex d-lg-none">
        <div className="container">
          <div className="row">
            <div className="font-archivo h4 mb-0 text-align-center text-white mx-auto">
              {title}
            </div>
          </div>
        </div>
      </div>
      <form className="d-none d-lg-block form" onSubmit={submit}>
        <div className="container-fluid d-inline-flex align-items-center background text-center custom-background-2 background-blue-2 height-121">
          <div className="container">
            <div className="d-flex">
              <div className="col-md-6 bg-white rounded search-box my-auto">
                <div className="row">
                  <div className="col-md-8 d-inline-flex align-items-center">
                    <span className="image-wrapper w-100">
                      <Input
                        className="w-100"
                        type="search"
                        value={value}
                        placeholder="Search the IoT ecosystem"
                        aria-label="Search"
                        onChange={onChange}
                      />
                    </span>
                  </div>
                  <div className="col-md-4 text-right pr-0">
                    <button
                      className="btn text-white rounded py-0 px-4 background-blue search"
                      type="submit"
                      onSubmit={submit}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TopSearchBlock;
