export default function locationChanger(advancedFilters, value, id) {
  let array = [];
  let locationFilter = "";
  let dataArray = advancedFilters;
  dataArray[id].value = value.label;

  if (value) {
    locationFilter = { value: value.label, label: value.label };
    array = {
      locationCount: 1,
      LocationArray: {
        attribute: "Location",
        operator: "Is near",
        value: value.label,
        attribute2: "Distances",
        operator2: "Within",
        value2: dataArray[id].value2,
      },
      advancedFilters: dataArray,
    };
  } else {
    locationFilter = "";
    array = {
      locationCount: 0,
      LocationArray: "",
      advancedFilters: dataArray,
    };
  }
  return { locationFilter, data: { ...array } };
}
