import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import style from "./ImageGallery.module.scss"

const ImageGallery = ({ children, noArrows }) => {
  return (
    <Carousel 
        additionalTransfrom={0}
        arrows={noArrows ? false: true}
        autoPlaySpeed={3000}
        centerMode={false}
        dotListClass=""
        draggable
        infinite
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        renderDotsOutside={false}
        className={style.sector_class}
        itemClass="react-multiple-carousel__arrow--right-z-index-300"
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 3,
            paritialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 575,
              min: 0
            },
            items: 1,
            paritialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 576
            },
            items: 2,
            paritialVisibilityGutter: 30
          }
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
    >
        {children}
    </Carousel>
  )
}

export default ImageGallery
