import React, { Component } from 'react'
import { Input } from 'reactstrap';

export default class RangeChange extends Component {
    render() {
        const {initialValue, handleRangeChange, dataRange} = this.props
        return (
            <div className="row margin-0">
                <div className="col-2 range-change">
                    <Input value={initialValue} type="select" name="value" onChange={handleRangeChange}>
                        <option>weekly</option>;
                        <option>monthly</option>;
                    </Input>
                </div>
                <div className="col" style={{"display": "flex", "alignItems": "center"}}>
                    <p>{dataRange}</p>
                </div>
            </div>
        )
    }
}
