import React, { Component } from "react";
import { Input } from "reactstrap";
import { Subscription } from "../components/Subscribe/Subscribe";
//import { Subscription } from "../components/Subscribe/Subscribe";
import { Helmet } from "react-helmet";
import "./Competitions.scss";

export default class Competitions extends Component {
  render() {
    const { searchInput, handleInput, handleSearchSubmit } = this.props;
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div className="Competitions">
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Competitions</title>
        </Helmet>
        <div className="top-panel w-100">
          <div className="container">
            <div>
              <div className="row">
                <div className="d-flex d-md-none events-title-block">
                  Competitions
                </div>
                <form
                  onSubmit={handleSearchSubmit}
                  className="d-none d-md-block d-lg-none col-md-8 search-box"
                >
                  <div className="display-flex">
                    <div className="input-group">
                      <span className="search-icon">
                        <Input
                          className="form-control"
                          type="search"
                          onChange={handleInput}
                          value={searchInput}
                          name="searchInput"
                          placeholder="Search events, webinars, hackathons and more"
                          aria-label="Search events, webinars, hackathons and more"
                        />
                      </span>
                      <div className="input-group-prepend">
                        <button
                          className="btn search d-none d-md-block"
                          type="submit"
                        >
                          Search
                        </button>
                        <button
                          className="btn search d-block d-sm-block d-md-none"
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <form
                  onSubmit={handleSearchSubmit}
                  className="d-none d-lg-block col-md-6 search-box"
                >
                  <div className="display-flex">
                    <div className="input-group">
                      <span className="search-icon">
                        <Input
                          className="form-control"
                          type="search"
                          onChange={handleInput}
                          value={searchInput}
                          name="searchInput"
                          placeholder="Search events, webinars, hackathons and more"
                          aria-label="Search events, webinars, hackathons and more"
                        />
                      </span>
                      <div className="input-group-prepend">
                        <button
                          className="btn search d-none d-md-block"
                          type="submit"
                        >
                          Search
                        </button>
                        <button
                          className="btn search d-block d-sm-block d-md-none"
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-none d-md-flex post-block">
                  <a
                    target="_blank"
                    href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    className="event-btn"
                  >
                    <button className="btn post-event">
                      Post a Competition
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
        {userType !== "admin" && userType !== "corporate" && (
          <div style={{ marginTop: "50px" }}>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
