import React, { Component } from "react";
import "./Map.scss";
import { Input, Button, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import AdvancedFilters from "../components/AdvancedFilters/AdvancedFilters.jsx";
import AdvancedFiltersMobile from "../components/AdvancedFiltersCompaniesMobile/AdvancedFiltersMobile";
import "tippy.js/dist/tippy.css";
import "simplebar";
import "simplebar/dist/simplebar.css";
import "../components/scss/breadcrumbs.scss";
import "../Search/Search.scss";
import { Helmet } from "react-helmet";
import AdvancedFiltero from "../components/AdvancedFiltero/AdvancedFiltero";
export default class Map extends Component {
  render() {
    const {
      handleQueryChange,
      query,
      openFilter,
      handleSubmit,
      history,
      user,
      updatePropsState,
    } = this.props;

    return (
      <div className="Map">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Search the IoT Map</title>
        </Helmet>
        <div className="search-grid">
          <Collapse className="main-filter-block" isOpen={openFilter}>
            <AdvancedFiltero
              {...{
                ...this.props.advancedFiltersValues,
                query,
                history,
                user,
                updatePropsState,
              }}
              whenToShow="d-none d-md-block"
              link1={{ url: "companies", name: "To Companies" }}
            />
          </Collapse>
          <AdvancedFiltersMobile
            whenToShow="d-block d-md-none"
            visible={true}
            aFHandle={{
              ...this.props.advancedFiltersHandlers,
              ...this.props.aFMobileHandle,
              handleSubmit,
              handleQueryChange,
            }}
            aFValue={{
              ...this.props.advancedFiltersValues,
              ...this.props.aFMobileValue,
              value: this.props.query,
            }}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}
