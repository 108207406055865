import React from 'react'

const SuccessfulEngagemanents = ({ dataJSON, style }) => {
  if(dataJSON.length > 0){
    return (
        <div className="container py-5">
            <div className="text-center font-s-18 pb-2 font-weight-bold">Successful Engagements</div>
            <div className="d-flex">
                <div className="custom-scroll d-flex mx-auto overflow-auto pb-2">
                {
                    dataJSON.map((item, key) => (
                        <div key={key} className={`${style.engagement_block} px-3`}>
                            <img className="img-fluid rounded-6 bg-white p-2" src={item} alt={`engagement ${key}`} />
                        </div>
                    ))
                }
                </div>
            </div>

        </div>
    )
  }
  return('')
}

export default SuccessfulEngagemanents
