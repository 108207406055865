import React from 'react';
import Spinner from '../components/Spinner/Spinner';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';

export default class Confirm extends React.PureComponent {

    render() {
        const { confirmAvailable, handleResendCode, handleInputChange, handleSubmit, confirmData, loading, data } = this.props;
        if(loading) {
            return <Spinner height={'calc( 100vh - ( 64px + 67px ) )'}/>;
        }
        let resend = <button disabled={false} className={`btn link`} type="button" onClick={handleResendCode}>Resend confirmation message</button>;
        if(loading || !confirmAvailable) {
            resend = (
                <Tippy 
                    interactive={true} 
                    theme="iot"
                    content={"You need to wait 60 seconds before trying to resend the confirmation message again."
                    }><span><button disabled={true} className={`btn link`} type="button" onClick={handleResendCode}>Resend confirmation message</button></span>
                </Tippy>
            )
        }
        return (
            <div className="container">
                <div className="col-8 offset-sm-2">
                    <h1>Account confirmation</h1>
                    <p>Username: <b>{data.username}</b></p>
                    <p>Phone number: <b>{data.phoneNumber}</b></p>
                    <p>Please type in the 6-digit code sent to you by text message here:</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input value={confirmData.code} name="code" required type="text" className="form-control" placeholder="Confirmation code" onChange={handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <button disabled={loading} className={`btn btn-primary`} type="submit">Submit</button>
                        </div>
                        <div className="form-group">
                            {resend}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}