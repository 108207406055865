import React from 'react'
import {Helmet} from "react-helmet";
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import style from './Jobs.module.scss'

const Jobs = () => {
  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Nation: Companies Hiring | IoT Nation</title>
        </Helmet>
        <Breadcrumbs last="Jobs" />
        <div className="top-panel">
            <div className="container">
                <div className="title">Jobs</div>
            </div>
        </div>
        <div className="container">
          <div className="my-4 font-s-18 line-h-15 text-center">
            This page is a resource <i>for the community by the community</i> <br /> 
            to help job seekers find relevant companies which are actively hiring.<br />
            Companies which are hiring can submit this <a target="_blank" href="https://airtable.com/shrPI54ZqQbPk9lkA">form</a>.
          
          </div>
            <div className="d-flex">
                <iframe 
                    className={`mx-auto my-4 ${style.frame}`}
                    src="https://airtable.com/embed/shr3fBZsp67tslxFh?backgroundColor=purple&viewControls=on"
                    frameborder="0"
                    onmousewheel=""
                    width="100%"
                    height="533"
                />
            </div>
        </div>
    </div>
  )
}

export default Jobs
