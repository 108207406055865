import React, { Component } from 'react'
import Partner from './Partner.jsx'

export default class PartnerContainer extends Component {
    render() {
        return (
            <div>
                <Partner />
            </div>
        )
    }
}
