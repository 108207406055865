import React from 'react';

export default class SortableHeader extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            sort: 'asc'
        }

        this.handleSortingChange = this.handleSortingChange.bind(this);
    }

    handleSortingChange(event) {
        const { sort } = this.state;
        if(sort === 'desc') {
            this.setState({
                sort: 'asc'
            });
            this.props.handleSortChange(this.props.data.type, 'asc');
        } else {
            this.setState({
                sort: 'desc'
            })
            this.props.handleSortChange(this.props.data.type, 'desc');
        }
    }

    render() {
        const { name, active, gridSize } = this.props.data;
        return (
            <th className={gridSize ? gridSize : 'col'}><span className={`header ${active ? this.state.sort : ''}`} onClick={this.handleSortingChange}>{name}</span></th>
        );
    }
}