import React, { Component } from 'react'

export default class CompanyLeaderBoard extends Component {
    render() {
        return (
           <div className="Company-Leaderboard">
            <table className="row">
            <thead className="col-12 table-header">
                    <tr className="row ">        
                        <th className="col-1">
                            <div className="d-inline-flex justify-content-center g-chart-1"><img alt="" src="/images/g-chart-1.svg"/></div>
                        </th>
                        <th className="col d-flex align-items-center"><p className="title">Company Leaderboard</p></th>
                    </tr>
            </thead>
            <tbody className="col-12">
                <tr className="row align-items-center">
                    <td className="col-1">
                        <span className="up rank"></span>
                    </td>
                    <td className="col-1">
                        <div className="company-logo d-inline-flex align-items-center">
                            <img src="http://res.cloudinary.com/iotnation/image/upload/v1504729326/2236.png" alt=""/>
                        </div>
                    </td>
                    <td className="col-3">
                        <a href="/company/3dd9424294b0292b6e89ea2bba2e1144">Intel Corporation</a>
                    </td>
                    <td className="col-7">
                       <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                    </td>
                </tr>
                <tr className="row align-items-center">
                    <td className="col-1">
                        <span className="up rank"></span>
                    </td>
                    <td className="col-1">
                        <div className="company-logo d-inline-flex align-items-center">
                            <img src="http://res.cloudinary.com/iotnation/image/upload/v1504729326/2236.png" alt=""/>
                        </div>
                    </td>
                    <td className="col-3">
                        <a href="/company/3dd9424294b0292b6e89ea2bba2e1144">Intel Corporation</a>
                    </td>
                    <td className="col-7">
                       <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                    </td>
                </tr>
            </tbody>
            </table>   
        </div>
        )
    }
}
