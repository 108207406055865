import React, { PureComponent } from 'react';
import ShowValue from '../ShowValue';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import SubscriberContent from '../../../../components/SubscriberContent/SubscriberContent';
export default class Ecosystem extends PureComponent {
    constructor(props){
        super(props)
        this.state = {
            open: false
        }
    }
    
    handleOpen = () => {
        this.setState({open: !this.state.open})
    }
    render() {
        const {ecosystems, goldTypes, coeIsFaked, total} = this.props;

        const corpEcoRow=ecosystems.map((org,i)=>{
            const cn = goldTypes.find((el)=>el===org.type)?'gold':'silver'
            return (
                <tr className="row" key={i}>
                    <td className="col-5">
                        <a target="_blank" href={`/companies/?q=&filter=Partner_Of_${org.name.replace("&", "and")}&limit=10`}>{org.name}</a>
                    </td>
                    <td className="col-7">
                        <div className={`${cn} ecosystem`}>
                            <span><ShowValue data={org.type} user={this.props.user}/></span>
                        </div>
                    </td>
                </tr>
            );
        })
    
        return (
            <div className="card-holder">
                <div className={`row ecosystems d-none d-md-flex ${coeIsFaked && total === 9?'blurred-box':''}`}>
                    <div className="col-2">
                        <span className="circle red">{total}</span>
                        <p className="margin-negative-r-10">Partnerships</p>
                    </div>
                    <div className={`col-10 ${coeIsFaked?'blurred-box':''}`}>
                        <table className="table">
                            <thead>
                                <tr className="row">
                                    <th className="col-5">Corporate Ecosystem</th>
                                    <th className="col-7">Partner Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {corpEcoRow}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`row ecosystems no-padding d-block d-md-none ${coeIsFaked && total === 9?'blurred-box':''}`}>
                    <div className="col-12 ecosystems-align">
                        <div className="row" style={{width: "100%"}}>
                            <div className="col-4"><span className="circle red">{total}</span></div>
                            <div className="col-6"><p>Partnerships</p></div>
                            <div onClick={this.handleOpen} className="col-2">{this.state.open?  <FaCaretUp /> : <FaCaretDown />}</div>
                        </div>
                    </div>
                    <div className={`col-12 ${coeIsFaked?'blurred-box':''} ${this.state.open? '':'not-display'}`}>
                        <table className="table">
                            <thead>
                                <tr className="row">
                                    <th className="col-5">Corporate Ecosystem</th>
                                    <th className="col-7">Partner Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {corpEcoRow}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <SubscriberContent target="ecosystem" hidden={coeIsFaked}/>
                </div>
            </div>
        )
    }
}

