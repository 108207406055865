import React from 'react'
import Sales from './Sales'
import dataJSON from "../content/sales.json"
import { Redirect } from 'react-router-dom'

const SalesContainer = () => {

  let existPage = dataJSON.filter( item => `/casestudies/${item.slug}` === window.location.pathname)
  console.log(existPage)
  if(existPage.length === 0){
    return(
      <Redirect to="/" />
    )
  }
  return (
    <Sales
      dataJSON={existPage[0]}
    />
  )
}

export default SalesContainer
