import React, { Component } from "react";
import UseCaseDetail from "./UseCaseDetail.jsx";
import Spinner from "../components/Spinner/Spinner";
import GeneratedApi from "../components/functions/generatedApi.js";

export default class UseCaseDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null,
      loading: true,
      usecaseId: props.match.params.usecaseId,
      emptyPage: false,
    };
  }

  componentDidMount() {
    GeneratedApi("", true, true)
      .get(`/usecases/${this.state.usecaseId}`)
      .then((response) => {
        this.setState({
          response: response.data.body.data,
          loading: false,
          emptyPage: false,
        });
      })
      .catch((error) => {
        this.setState({ emptyPage: true });
        console.log(error);
      });
  }
  render() {
    const results = this.state.response && this.state.response;
    if (results) {
      return (
        <div>
          <UseCaseDetail location={this.props.location} results={results} user={this.props} />
        </div>
      );
    } else if (this.state.emptyPage) {
      return "";
    } else {
      return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
    }
  }
}
