import React from "react";
import './GeneralPages.scss'
import { StickyContainer } from 'react-sticky';
export default class TermsOfService extends React.Component {
  
    render() {
      const {simplified}=this.props
      return (
        <div className="Terms-Of-Service">
        { !simplified&&
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center welcome-screen">
                <div className="col-auto text-box">
                    <h1>Terms of service</h1>
                </div>
            </div>
          </div>
        }
          <StickyContainer>
            <div className="container">
              <div className="row">
                <div className='col rounded-6 grey-border-2 p-tb-15'>
                  <div  className="content">
                    <p align="left">
                      BY CLICKING THE <strong>"I ACCEPT" </strong>BUTTON BELOW, OR BY ACCESSING OR USING THIS
                      WEBSITE, THE PLATFORM OR THE LICENSED DATA, YOU (A) ACKNOWLEDGE THAT YOU
                      HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU
                      HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT; AND (C)
                      ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF
                      YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY, ORGANIZATION,
                      GOVERNMENT, OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT (A) YOU
                      ARE AUTHORIZED TO DO SO, (B) THE ENTITY AGREES TO BE LEGALLY BOUND BY THE
                      TERMS, AND (C) NEITHER YOU NOR THE ENTITY ARE BARRED FROM USING THE
                      SERVICES OR ACCEPTING THE TERMS UNDER THE LAWS OF THE APPLICABLE
                      JURISDICTION. LICENSOR RESERVES THE RIGHT TO PROHIBIT ANYONE FROM ACCESSING
                      OR USING THIS WEBSITE, AND TO REJECT ANY REGISTRATION FOR A SUBSCRIPTION.
                      </p>
                      <p align="left">
                      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE <strong>"I DECLINE" </strong> 
                      BUTTON BELOW. IF YOU DO NOT ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THIS
                      WEBSITE.
                    </p>
                    <p align="left">
                      Last modified: February 14, 2020
                    </p>
                    <h4 align="center">
                      TERMS OF SERVICE
                    </h4>
                    <p align="left">
                      These Terms of Service (“ToS” and “Terms”), (together with the Order Form 
                      (if applicable) and the Privacy Policy
                      (<a href="https://www.iotnation.com/privacy-policy">https://www.iotnation.com/privacy-policy</a>), 
                      which documents may be amended from time to time in the future, hereinafter
                      collectively referred to as this “<strong>Agreement</strong>”) is entered into by and
                      between (i) the individual accepting this Agreement on his or her own
                      behalf, or if the individual is acting on behalf of an entity, then between
                      such entity on behalf of whom you are accepting this Agreement (“Licensee”,
                      “<strong>you</strong>” and “<strong>your</strong>”), and (ii) IoT Nation,
                      LLC (“<strong>Licensor”</strong><strong> </strong>and “<strong>we</strong>
                      ”). Licensor and Licensee are sometimes referred to as a “Party” and
                      collectively as the “Parties”.
                    </p>
                    <p align="left">
                      By clicking the acceptance button, or by visiting the Website or using any
                      of the services or benefits offered through the Website, you acknowledge
                      that Licensee has reviewed and accepts all of the terms and conditions of
                      this Agreement, and that, if Licensee is an entity, the entity on
                      behalf of whom such individual is acting hereby represents and warrants
                      that such individual is duly authorized to act on behalf of Licensee and
                      bind Licensee to this Agreement. You must click the acceptance button
                      before Licensee may use any of the services or benefits offered under the
                      Order Form, including, without limitation, use of the Platform and the
                      Licensed Data.
                    </p>
                    <p align="left">
                      1. DEFINITIONS. In addition to the terms defined elsewhere in the
                      Agreement, the terms set forth in this Section 1 shall have the following
                      meanings:
                    </p>
                    <p align="left">
                      1.1. <strong>“Affiliates”</strong> shall mean any entity in which a Party
                      directly or indirectly owns any interest, and any entity which directly or
                      indirectly controls, is controlled by or is under common control with a
                      Party. “Control,” for purposes of this definition, means direct or indirect
                      ownership or control of more than 50% of the voting interests of the
                      subject entity.
                    </p>
                    <p align="left">
                      1.2. “<strong>Apps</strong>” shall mean one or more applications that are
                      downloaded to a computing platform, including, but not limited to, a mobile
                      phone, tablet, laptop, or other computer.<strong></strong>
                    </p>
                    <p align="left">
                      1.3. <strong>“Authorized User(s)”</strong> shall mean (i) the individual
                      accepting this Agreement on his or her own behalf, or (ii) if Licensee is
                      an entity, then the Licensee’s employees and consultants authorized by
                      Licensee to access and use the Platform, in each case who have (y)
                      registered on the Website and created user Credentials (where “Credentials”
                      may include a user’s name, a username, password, multi-factor
                      authentication, Subscription tier information and other elements) and/or
                      (z) have been supplied user Credentials and/or other authenticated sign in
                      access by Licensor.
                    </p>
                    <p align="left">
                      1.4. <strong>“Licensed Data”</strong> shall mean all data made available to
                      Licensee through the Platform and all data provided through other channels
                      (e.g. email, downloads) by Licensor to Licensee.
                    </p>
                    <p align="left">
                      1.5. “<strong>Confidential Information</strong>” shall mean all
                      confidential information of a Party, whether written or oral, and whether
                      in paper or electronic format, disclosed to a receiving Party that is
                      designated in writing or identified as confidential at the time of
                      disclosure or should be reasonably known by the receiving Party to be
                      Confidential Information due to the nature of the information disclosed and
                      the circumstances surrounding the disclosure. Confidential information
                      related to either Party’s customer lists, customer information, products,
                      technical information, pricing information, pricing methodologies, or
                      information regarding the disclosing Party’s business planning or business
                      operations shall be deemed Confidential Information without any marking or
                      further designation.
                    </p>
                    <p align="left">
                      1.6. <strong>“Methods”</strong> shall mean the manual and/or automated
                      processes by which Licensor compiles, organizes, presents and creates the
                      Licensed Data and any other derivative works which may be incorporated into
                      the Platform or offered separately.
                    </p>
                    <p align="left">
                      1.7. <strong>“Order Form”</strong> shall mean, for Premium Users (as
                      hereinafter defined) only, the ordering documents that represent the
                      purchase of a license to the Platform and Licensed Data and which expressly
                      refer to this Agreement. All Order Forms shall be deemed incorporated into
                      this Agreement.
                    </p>
                    <p align="left">
                      1.8. <strong>“Platform”</strong> shall mean the on-demand, web-based
                      platform made available to Licensee by Licensor via a Subscription.
                    </p>
                     <p align="left">
                      1.9. <strong>“Privacy Policy”</strong> shall mean the Licensor’s privacy 
                      policy which can be found at
                      <a href="https://www.iotnation.com/privacy-policy"> https://www.iotnation.com/privacy-policy</a>.
                    </p>
                    <p align="left">
                      1.10. <strong>“Subscription”</strong> shall mean one of the following based
                      on Authorized User(s) permitted right to access, and usage of, the Platform
                      and Licensed Data:
                    </p>
                    <p align="left">
                      (a) “Visitors” are users of the Website who have not yet registered for
                      access. Visitors have limited access to the Platform’s features and
                      Licensed Data in order to explore the Website for the sole purpose of
                      determining whether the person should become a “Registered User” or inquire
                      about subscription and/or partnership options. All of the usage terms
                      including Copyright of the Platform and Licensed Data shall apply.
                    </p>
                    <p align="left">
                      (b) “Registered Users” are Authorized User(s) who have registered with
                      their legal name, company or other valid email address, and any other
                      credential used in Multi-factor Authentication (MFA), such as a phone
                      number. Note that it is in violation of these Terms to register with false
                      information such as an assumed name or fake email address. Registered Users
                      have limited access to the Platform’s features and Licensed Data in order
                      to explore the Website for the purpose of determining whether or not to
                      subscribe to a paid Subscription plan.
                    </p>
                    <p align="left">
                      (c) “Premium Users” are Authorized User(s) who are subject to the same
                      terms as Registered Users, who have subscribed to a paid Subscription plan
                      (e.g. Pro, Premium, Corporate, or any other paid plan) which grants access
                      to a defined (i) set of Platform features, and (ii) subset of Licensed
                      Data, for the Subscription Term. Premium Users subscribe by executing one
                      or more Order Form(s) and may manually renew or automatically renew,
                      depending on the choice made when subscribing.
                    </p>
                    <p align="left">
                      (d) “Trial Users” are Authorized User(s) who are subject to the same terms
                      as Registered Users, and have been granted access to use a paid
                      Subscription plan for a limited period of time, for the sole purpose of
                      evaluating the Platform and Licensed Data in considering whether or not to
                      purchase a paid Subscription plan.
                    </p>
                    <p align="left">
                      1.11. <strong>“Website”</strong> shall mean the Licensor’s website located at&nbsp;
                      <a href="https://www.iotnation.com">https://www.iotnation.com</a>,
                      and is the site which hosts the Platform.
                    </p>
                    <p align="left">
                      2. USE OF THE PLATFORM AND LICENSED DATA
                    </p>
                    <p align="left">
                      2.1. <strong>Licensee Information; Order Forms</strong>. For all Licensees,
                      (i) if an individual, you shall provide your name, phone number and e-mail
                      address, and (ii) if an entity, you shall provide the legal name and state
                      of formation of the entity, the principal business address of the entity,
                      and the name, phone number and e-mail address of the Authorized User. For
                      Premium Users, Licensor shall provide the Platform and Licensed Data to
                      Licensee pursuant to this Agreement and any specific limitations set forth
                      in Order Forms, as executed hereunder from time to time. In the event of a
                      conflict between these Terms and an Order Form, these Terms will have
                      precedence unless the Order Form expressly states that it is intended to
                      take precedence over a contrary provision in these Terms, in which case
                      such conflicting provision will apply only with respect to the Order Form
                      that contains it.
                    </p>
                    <p align="left">
                      2.2. <strong>Proprietary Rights.</strong> This Agreement applies to and
                      governs the Licensee’s use of the Platform and Licensed Data. The 
                      Agreement is not a sale, or assignment and transfer, of any data, software
                      or intellectual property, whether patent, trade secret, trademark, copyright
                      or otherwise. Licensee agrees that, other than as provided herein,
                      Licensor, its licensors or its suppliers retain all right, title and
                      interest (including all patent, copyright, trade secret and other
                      intellectual property rights in and to the Licensed Data, the Platform, and
                      any and all related and underlying software (including product
                      specifications, market requirements definitions, widgets, applets, user
                      interfaces created by Licensor), databases, algorithms, technology,
                      reports, dashboards, and documentation, and any adaptation, modification,
                      derivation, addition or extension to the Platform and Licensed Data. Except
                      for the Subscription granted hereunder, nothing in the Agreement gives the
                      Licensee any right, title or interest in or to the Platform, the Licensed
                      Data or any related documentation.
                    </p>
                    <p align="left">
                      <strong></strong>
                    </p>
                    <p align="left">
                      Licensee further acknowledges that: (a) the Licensed Data is an original
                      compilation protected by United States copyright laws; (b) Licensor has
                      dedicated substantial resources to collect, manage and compile the Licensed
                      Data; (c) Methods constitute trade secrets and/or otherwise legally
                      protectable intellectual property including patentable and/or patent protected
                      intellectual property, of Licensor and (d) the Licensed Data constitutes
                      trade secrets of Licensor. Licensee acknowledges and agrees that it will be
                      considered a material breach by Licensee under this Agreement if Licensee
                      contests any of Licensor’s right, title, or interest in or to the Data,
                      including without limitation, in a judicial proceeding anywhere throughout
                      the world.
                    </p>
                    <p align="left">
                      Licensee understands that Licensor is the owner of certain trademarks,
                      including “IoT Nation” and “IoT Ace” and may have filed for applicable
                      federal and state registration of certain of its trademarks. Nothing herein
                      will grant to Licensee any right, title or interest in Licensor trademarks.
                      At no time during or after the term of this Agreement will the Licensee
                      challenge or assist others to challenge Licensor trademarks or the
                      registration thereof or<strong> </strong>attempt to register any
                      trademarks, marks or trade names confusingly similar to those of Licensor.
                    </p>
                    <p align="left">
                      2.3. <strong>License.</strong> Subject to and conditioned on Licensee’s
                      payment of fees and compliance with all other terms and conditions of this
                      Agreement, Licensor hereby grants to Licensee a nontransferable,
                      non-sublicenseable, non-exclusive license during the Subscription Term, i)
                      to allow Authorized User to access and use the Platform for Licensee’s
                      internal business purposes and ii) to use the Licensed Data solely for
                      Licensee’s internal business purposes. The total number of Authorized Users
                      shall not exceed the limits set forth in each Order Form, or, if there is
                      no Order Form, then there shall be only one (1) Authorized User.
                    </p>
                    <p align="left">
                      2.4. <strong>Support</strong>. Licensor will maintain a customer support
                      team, to assist in the use and operation of the Platform and to handle
                      service requests / inquiries when facing an issue. While general support
                      will be available to Authorized Users by email and/or via the chat
                      interface on the Website, Licensor’s support team will be available, for
                      specific paid Subscription tiers as may described in pricing guides and/or
                      Order Form(s), during regular business hours, which are Monday through
                      Friday 9am-6pm ET (excluding holidays). Support may be accessed via email
                      at <a href="mailto:support@iotnation.com">support@iotnation.com</a>.
                    </p>
                    <p align="left">
                      2.5. <strong>Changes.</strong> Licensor may from time to time develop
                      enhancements, upgrades, updates, improvements, modifications, extensions
                      and other changes to the Platform, Licensed Data, and support (“Changes”).
                    </p>
                    <p align="left">
                      2.6. <strong>Feedback.</strong> If Licensee provides any feedback to
                      Licensor concerning the functionality or performance of the Platform
                      (including identifying potential errors and improvements) or Licensed Data,
                      Licensee hereby assigns to Licensor all right, title, and interest in and
                      to the feedback, and Licensor is free to use the feedback without payment
                      or restriction. However, in connection with its use of feedback, Licensor
                      will not disclose any information that identifies Licensee or any of its
                      users to any third party, and will not use Licensee’s trademarks and logos
                      without Licensee’s prior written consent.
                    </p>
                    <p align="left">
                      2.7. <strong>Access and Use Restrictions</strong>. The Platform and
                      Licensed Data are provided to Licensee for use only as expressly set forth
                      in the Agreement, and Licensee will not use the Platform or Licensed Data
                      in whole or in part for any other use or purpose. In particular, Licensee
                      shall not, and shall not allow any third party to: (i) decompile,
                      disassemble, reverse engineer or attempt to reconstruct, identify or
                      discover any source code, underlying ideas, underlying user interface
                      techniques or algorithms of the Platform by any means, or disclose any of
                      the foregoing; (ii) except as expressly set forth in the Agreement,
                      provide, rent, lease, lend, or use the Platform or Licensed Data for
                      timesharing, subscription, or service bureau purposes; (iii) sublicense,
                      transfer or assign the Platform or Licensed Data or any of the rights or
                      licenses granted under the Agreement; or remove or obscure any trademark,
                      product identification, proprietary marking, copyright or other notices
                      provided with the Platform, Licensed Data, or related documentation; (iv)
                      publish, enhance, or display any compilation or directory based upon
                      information derived from the Licensed Data; (v) use the Licensed Data for
                      the unsolicited mass distribution of email; or (vii) use the Licensed Data
                      in any manner or for any purpose that infringes, misappropriates, or
                      otherwise violates any intellectual property right, rights of privacy,
                      rights of publicity, or other right of any person, or that violates any
                      applicable law.
                    </p>
                    <p align="left">
                      2.8. <strong>Licensee Responsibilities.</strong> Licensee is responsible
                      for all activity occurring under Authorized User accounts and for each
                      Authorized User’s compliance with all terms and conditions of the
                      Agreement. Licensee shall use commercially reasonable efforts to prevent
                      unauthorized access to, or use of, the Platform or Licensed Data and notify
                      Licensor immediately of any unauthorized use of any password, account,
                      third-party authentication, or any other known or suspected breach of
                      security. Licensee is not to share passwords or other user credentials to
                      access the Platform with anyone, as this is considered a Breach of these
                      Terms. Additionally, licensee is not to copy Licensed Data and/or share
                      that data with anyone.
                    </p>
                    <p align="left">
                      2.9. <strong>Authorized Users</strong>. The Subscription to the Platform
                      and Licensed Data is granted solely to the Party(ies) (including any
                      Affiliates) stated in the Order Form and their Authorized Users and shall
                      not be shared with any third parties other than Authorized Users. User
                      subscriptions are for named users and cannot be shared or used by more than
                      one user but may be reassigned from time to time to new Authorized Users
                      who have terminated an employment or some other prior relationship with
                      Licensee, changed job status or function, or otherwise no longer require
                      ongoing use of the Platform; provided that Licensee notify Licensor in
                      writing (email is sufficient) of any such reassignment.
                    </p>
                    <p align="left">
                      2.10. <strong>Audit</strong>. Licensor may conduct an audit of Licensee’s
                      books and records and technical logs and records to ensure compliance with
                      the terms of this Agreement. Any such audit shall be conducted upon
                      reasonable written notice, during business hours, and shall not
                      unreasonably interfere with Licensee’s business activities. Any such audit
                      request shall not occur more than once during any six (6) month period;
                      except in instances where Licensor has a reasonable belief that Licensee’s
                      acts or omissions are in breach of this Agreement or may expose the Licensor
                      to liability or other adverse consequences.
                    </p>
                    <p align="left">
                      3. FEES; PAYMENT
                    </p>
                    <p align="left">
                      3.1. <strong>Summary. </strong>Where you sign up for a paid Subscription
                      through the Website or through an official sales agent of the Licensee, you
                      must provide credit card or other payment information. All fees for
                      Subscriptions (“<strong>Subscription Fees</strong>”) are due in advance,
                      and Subscriptions will automatically renew (and you will be charged)
                      monthly, each six-month period, or annually, depending on the terms of 
                      the Subscription you choose. We charge sales tax where required. By
                      submitting payment, you consent to the terms of use and privacy policy of
                      the Payment Processor (where “Payment Processor” is defined as a third-party
                      which handles transactions from various channels including credit cards and
                      debit cards), as applicable.
                    </p>
                    <p align="left">
                      3.2. <strong>Subscription</strong>. Certain features of the Subscription
                      are only available with a paid Subscription. Subscription levels, features,
                      and pricing are available at <a href="https://www.iotnation.com/iotace"> https://www.iotnation.com/iotace</a>. 
                      Subscription Fees are due in advance for each billing period, with the initial
                      payment due when you start a Subscription (the “Subscription Start Date”). 
                      Subscription Fees are non-refundable and are based on our standard charges
                      and billing terms in effect when payment is due. You must provide us with
                      a valid credit card or other payment method we
                      accept (“Payment Provider”) prior to starting a Subscription and promptly
                      update your payment information if there is any change to it. Your Payment
                      Provider agreement, not these Terms, governs your use of your designated
                      Payment Provider. We may change our fees and billing practices at any time,
                      either by posting notice of such change on the Website, sending a
                      notification by email, or notifying you within the Platform , provided that
                      any such change will not apply to you until the next Subscription period or
                      if you change your Subscription type. If you upgrade your Subscription, you
                      will pay for the full term of the new Subscription level but receive a
                      pro-rata credit for the remaining value of your prior Subscription.
                    </p>
                    <p align="left">
                      3.3. <strong>Automatic</strong><strong> Renewal</strong>. Subscriptions
                      will automatically renew at the rates then in effect, AND YOUR PAYMENT
                      PROVIDER WILL BE CHARGED UPON RENEWAL UNLESS YOU CANCEL YOUR SUBSCRIPTION
                      as set forth in Section 7(c). We will inform you of an upcoming renewal via
                      email (i) seven (7) days before your Subscription expires for monthly
                      subscriptions, and (ii) thirty (30) days before your Subscription expires for
                      six-month or annual subscriptions. By subscribing, you authorize us to charge your
                      Payment Provider at the beginning of any Subscription period if you have
                      not cancelled your Subscription by such time. If we cannot process payment
                      from your Payment Provider, we will reach out to you to update your Payment
                      Provider information or make other arrangements for payment. If we do not
                      receive payment within (i) three (3) days of the renewal for monthly
                      Subscriptions, and (ii) fifteen (15) days of the renewal, for six-month and
                      annual Subscriptions, we may terminate your Subscription.
                    </p>
                    <p align="left">
                      3.4. <strong>Cancellation</strong>. Subscriptions may be cancelled at any
                    time by emailing:	<a href="mailto:support@iotnation.com">support@iotnation.com</a> with the
                      subject line: “Subscription Cancellation” You will be able to use any
                      Subscription you cancel until its scheduled expiration, but you will not be
                      eligible for any pro-rata refund of the current term.
                    </p>
                    <p align="left">
                      3.5. <strong>Taxes</strong>. “Sales Tax” means any sales, use, value added,
                      or other tax based on the Subscription Fee that we are required to pass
                      through to customers. Sales Tax is automatically calculated and added to
                      all orders where applicable. To the extent applicable, all payments for
                      Subscriptions will be made free and clear of, and without reduction for,
                      any withholding taxes. Sales Tax is the sole responsibility of the
                      Licensee.
                    </p>
                    <p align="left">
                      3.6. <strong>Payment</strong><strong> </strong><strong>Services</strong>.
                      We use Payment Processors for payment services (e.g., card acceptance,
                      merchant settlement, and related services) including PayPal. We will notify
                      you of the Payment Processor and provide link to the Payment Processor’s
                      website (which should include their terms and policies) where relevant. By
                      paying for a Subscription, you agree to be bound by the Payment Processor’s
                      terms and policies, as applicable, and authorize us and the Payment
                      Processor to share any information and payment instructions you provide
                      with your Payment Provider and any other Third-Party Service provider(s) as
                      required to complete your transactions.
                    </p>
                    <p align="left">
                      3.7. <strong>Late</strong><strong> Fees</strong>. Any amount not paid when
                      due will be subject to finance charges equal to 1.5% of the unpaid balance
                      per month or the highest rate permitted by applicable usury law, whichever
                      is less, determined and compounded daily from the date due until the date
                      paid. Customer will reimburse any costs or expenses (including, but not
                      limited to, collection agency fees, reasonable attorneys’ fees and court
                      costs) incurred by Licensor to collect any amount that is not paid when
                      due.
                    </p>
                    <p align="left">
                      3.8. <strong>Suspension of Access.</strong> If Licensee shall fail to
                      comply with any of the provisions of this Agreement, in addition to any
                      other rights and remedies (including the termination rights set forth in
                      the Agreement), Licensor reserves the right to immediately and without
                      notice suspend the Subscription to the Platform and provision of Licensed
                      Data without liability to Licensor.
                    </p>
                    <p align="left">
                      4. TERM AND TERMINATION
                    </p>
                    <p align="left">
                      4.1. <strong>Summary</strong>. These Terms will remain in effect for as
                      long as you have a Subscription (or when you visit the Site, use the
                      Platform, or access the Licensed Data). We may terminate your Subscription
                      and all access for any breach of these Terms or where required by law. You
                      may cancel your Subscription at any time. If you cancel a Subscription, it
                      will remain active until the end of the Subscription period. If we
                      terminate your Subscription for breach of the Terms, your Subscription will
                      end immediately. You must delete all content and data captured or
                      downloaded from the Platform, including Licensed Data, and any Apps from
                      your devices following termination in order to remove Licensed Data from
                      your device.
                    </p>
                    <p align="left">
                      4.2. <strong>Term</strong>. These Terms take effect when you accept them as
                      set forth above and will remain in force as long as you have a Subscription
                      (or visit the Website). Subscriptions may be purchased for a one month
                      term, which will automatically renew month to month, a six-month term, which
                      will automatically renew on a six-month period, or a one year term,
                      which will automatically renew annually, unless otherwise provided in an
                      Order Form, or cancelled or terminated earlier as provided herein
                    </p>
                    <p align="left">
                      4.3. <strong>Termination</strong><strong> by Licensor</strong>. If we
                      cannot charge your Payment Provider, if you materially breach these Terms,
                      or if required to do so by law (e.g., where the provision of the
                      Subscription becomes unlawful), we may, immediately and without notice,
                      suspend or terminate your Subscription. Termination under this section is
                      at Licensor’s sole discretion, and we will not be liable to you or any
                      third party for any such termination. For the avoidance of doubt, and
                      without limitation, Licensee shall be in breach of these Terms in the event
                      that Licensee (or its Authorized User(s)) (i) downloads or captures content
                      in an abusive manner, as determined by Licensor, and/or (ii) accesses or
                      uses the Platform or the Licensed Data for the purpose of competing with,
                      or otherwise with the intent of interfering with, the business of Licensor.
                    </p>
                    <p align="left">
                      4.4. <strong>Termination</strong><strong> by You</strong>. You may
                      terminate a Subscription at any time as set herein. If you terminate your
                      Subscription, these Terms will no longer apply to you except to the extent
                      you visit the Website and for any provisions that survive termination specified
                      below.
                    </p>
                    <p align="left">
                      4.5. <strong>Effect</strong><strong> of Termination.</strong>
                    </p>
                    <p align="left">
                      (i) When a Subscription expires or is terminated by Licensor, you will no
                      longer have access to the features and/or data associated with the
                      Subscription. Unless you terminate your Subscription, terminating a paid
                      Subscription will automatically convert your Subscription to an unpaid
                      Subscription, i.e. a “Registered User” Subscription (if we still offer that
                      plan).
                    </p>
                    <p align="left">
                      (ii) If you terminate your Subscription, all licenses granted in these
                      Terms will immediately terminate and you must stop all use of the Platform
                      and Apps. We may delete all data associated with your Subscription from our
                      servers, without liability or obligation to you. Note that, even if you
                      terminate your Subscription, you must delete our Apps to remove Licensed
                      Data from your devices. Even if you do not delete the Apps, you will not be
                      able to access Licensed Data after you terminate your Subscription (though
                      such access may continue for a short period depending on your settings
                      within the App).
                    </p>
                    <p align="left">
                      (iii) Sections 1, 2.2, 2.8, 2.10, 4.6, 6, 7, 8 and 9 will survive
                      termination or expiration of this Agreement for any reason.
                    </p>
                    <p align="left">
                      (iv) If we terminate this Agreement and your Subscription for Licensee’s
                      violation of this Agreement, you may not attempt to create a new
                      Subscription.
                    </p>
                    <p align="left">
                      (v) Upon the termination or expiration of this Agreement (including your
                      Subscription), you shall promptly delete and destroy all content that you
                      have downloaded or otherwise have copied from the Website and/or Platform
                      and/or Apps, including, without limitation, the Licensed Data. At Licensor’s
                      request, Licensee shall promptly certify to Licensor that Licensee has deleted
                      or destroyed all such content.
                    </p>
                    <p align="left">
                      4.6. <strong>Outstanding Fees.</strong> Termination shall not relieve
                      Licensee of the obligation to pay Licensor any outstanding Subscription
                      Fees.
                    </p>
                    <p align="left">
                      5. DISCLAIMERS OF WARRANTY. LICENSOR MAKES NO WARRANTIES REGARDING THE
                      PLATFORM OR LICENSED DATA. LICENSOR SPECIFICALLY DISCLAIMS ANY AND ALL
                      OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION,
                      ANY IMPLIED WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
                      PURPOSE. LICENSOR’S WEBSITE AND/OR PLATFORM MAY INCLUDE OR CONTAIN OBTAINED
                      OR LICENSED DATA FROM THIRD PARTIES.  LICENSOR MAKES NO WARRRANTIES
                      REGARDING THE OWNERSHIP, PRIVACY OR ACCURACY OF ANY OF THIS DATA OBTAINED
                      OR LICENSED FROM ANY THIRD PARTY. LICENSOR DOES NOT WARRANT THAT ACCESS
                      TO THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ALL DEFECTS OR
                      ERRORS IN THE PLATFORM WILL BE CORRECTED, OR THAT THE PLATFORM OR LICENSED
                      DATA WILL MEET LICENSEE’S PARTICULAR REQUIREMENTS OR EXPECTATIONS. LICENSOR
                      DOES NOT PROVIDE ANY WARRANTIES REGARDING THE ACCURACY OF DATA OR INFORMATION
                      PROVIDED BY THIRD PARTIES. LICENSOR SHALL NOT BE LIABLE OR RESPONSIBLE FOR
                      ANY DELAYS, INTERRUPTIONS, FAILURES OR ANY OTHER PROBLEMS ARISING FROM
                      LICENSEE’S USE OF THE INTERNET, ELECTRONIC COMMUNICATIONS OR ANY OTHER
                      SYSTEMS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THE
                      AGREEMENT BETWEEN LICENSOR AND LICENSEE. LICENSOR’S PRICING REFLECTS THIS
                      ALLOCATION OF RISK AND THE LIMITED WARRANTIES SPECIFIED HEREIN.
                    </p>
                    <p align="left">
                      <strong></strong>
                    </p>
                    <p align="left">
                      6. CONFIDENTIAL INFORMATION
                    </p>
                    <p align="left">
                      6.1. <strong>Obligations.</strong> During the term of this Agreement and
                      following the termination or expiration of this Agreement, Licensee: (i)
                      shall treat as confidential all Confidential Information provided by
                      Licensor; (ii) shall not use such Confidential Information except as
                      expressly permitted under the terms of the Agreement or otherwise
                      previously authorized in writing by Licensor; (iii) shall implement
                      reasonable procedures to prohibit the disclosure, unauthorized duplication,
                      reverse engineering, disassembly, decompiling, misuse or removal of such
                      Confidential Information; and (iv) shall not disclose such Confidential
                      Information to any third party. Without limiting the foregoing, Licensee
                      shall use at least the same degree of care to prevent the disclosure of
                      Licensor’s Confidential Information as it uses to prevent the disclosure of
                      its own Confidential Information, and shall in any event use no less than a
                      reasonable degree of care. The provisions hereof are expressly intended to,
                      and shall, survive the termination or expiration of this Agreement.
                    </p>
                    <p align="left">
                      6.2. <strong>Exceptions.</strong> Notwithstanding the definition of
                      Confidential Information, Licensee’s nondisclosure obligations and use
                      restrictions shall not apply to information that: (i) was generally
                      available to the public at the time it was disclosed, or becomes generally
                      available to the public through no fault of Licensee; (ii) was known to
                      Licensee at the time of disclosure as shown by written records in existence
                      at the time of disclosure; (iii) was developed independently by Licensee
                      prior to the disclosure, as shown by written records in existence prior to
                      the disclosure; (iv) is disclosed with the prior written approval of the
                      Licensor; (v) becomes known to Licensee from a source other than Licensor
                      without breach of the Agreement by Licensee and in a manner which is
                      otherwise not in violation of Licensor’s rights; or (vi) is disclosed
                      pursuant to the order or requirement of a court, administrative agency, or
                      other governmental body, provided that Licensee shall provide reasonable
                      advance notice to enable Licensor to seek a protective order.
                    </p>
                    <p align="left">
                      7.
                      <strong>
                        LIMITATIONS OF LIABILITY. OTHER THAN WITH RESPECT TO THE OBLIGATIONS
                        SET FORTH IN SECTION 8 (INDEMNIFICATION) OR BREACHES OF SECTION 4
                        (ACCESS AND USE RESTRICTIONS) OR SECTION 6 (CONFIDENTIAL INFORMATION),
                        NEITHER PARTY, ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS
                        OR CONTRACTORS, SHALL BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT,
                        INCIDENTAL, SPECIAL, CONSEQUENTIAL PUNITIVE OR EXEMPLARY DAMAGES OR
                        LIABILITY (INCLUDING REASONABLE ATTORNEYS’ FEES) THAT RESULT FROM OR
                        ARE RELATED TO THE AGREEMENT, WHETHER IN CONTRACT OR TORT OR UNDER ANY
                        OTHER THEORY OF LIABILITY, EVEN IF THE OTHER PARTY HAS BEEN INFORMED OF
                        THE POSSIBILITY OF SUCH DAMAGES OR LIABILITY. IN ANY EVENT, THE
                        AGGREGATE LIABILITY OF LICENSOR RELATED TO OR ARISING OUT OF THIS
                        AGREEMENT, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
                        LIABILITY, SHALL NOT EXCEED THE AMOUNTS RECEIVED BY LICENSOR FROM
                        LICENSEE IN THE TWELVE MONTHS PRECEDING THE EVENT GIVING RISE TO SUCH
                        DAMAGES.
                      </strong>
                    </p>
                    <p align="left">
                      8. INDEMNIFICATION. Licensee shall indemnify, defend and hold Licensor
                      harmless from any and all damages, liabilities, costs and fees (including
                      reasonable attorneys’ fees) resulting from any third party claims arising
                      out of Licensee’s use of the Licensed Data not in compliance with this
                      Agreement. Licensee will promptly (within 3 business days) notify Licensor
                      in writing of any such claim. Licensee will not settle any such claim
                      without the prior written consent of Licensor, not to be unreasonably
                      withheld.
                    </p>
                    <p align="left">
                      9. GENERAL PROVISIONS.
                    </p>
                    <p align="left">
                      9.1. <strong>Governing Law; Jurisdiction.</strong> The Agreement shall be
                      governed by and construed in accordance with the laws of the State of
                      New York, without application of conflicts of laws principles. Any dispute
                      arising out of or relating to this Agreement shall be adjudicated in the
                      state or federal courts with jurisdiction over New York, New York.
                      However, if there is diversity of citizenship (under the applicable Federal
                      Rules of Civil Procedure) and there is at least $75,000 in dispute, the
                      Parties agree to litigate any matter under the Agreement in federal court.
                      Each party hereby agrees and submits to the personal jurisdiction and venue
                      thereof.
                    </p>
                    <p align="left">
                      9.2. <strong>Severability.</strong> If any provision of the Agreement is
                      held to be invalid or unenforceable for any reason, it shall be deemed
                      omitted and the remaining provisions will continue in full force without
                      being impaired or invalidated in any way. The parties agree to replace any
                      invalid provision with a valid provision that most closely approximates the
                      intent and economic effect of the invalid provision.
                    </p>
                    <p align="left">
                      9.3. <strong>Waiver.</strong> The waiver by either Party of a breach of any
                      provision of the Agreement will not operate or be interpreted as a waiver
                      of any other or subsequent breach.
                    </p>
                    <p align="left">
                      9.4. <strong>Assignment.</strong> The Agreement shall be binding upon the
                      Parties’ respective successors and permitted assigns. Licensee shall not
                      assign the Agreement, and/or any of its rights and obligations hereunder,
                      without the prior written consent of Licensor, which consent may be
                      withheld in Licensor’s sole discretion.
                    </p>
                    <p align="left">
                      9.5. <strong>Publicity.</strong> For Premium Users, Upon Licensor’s
                      request, Licensee shall (i) assist Licensor in its preparation of a press
                      release announcing Licensee as a Licensor customer; (ii) provide a quote
                      from a senior executive for use in Licensor marketing materials; (iii)
                      provide Licensor with a file containing Licensee’s logo(s) for use on
                      Licensor’s web site and in its sales collateral; (iv) assist Licensor in
                      the preparation of a case study for external use that detail Licensee’s use
                      of the Platform and Licensed Data, featuring specific results, with the
                      view to publishing the study within twelve months after the Effective Date;
                      and (v) upon request, serve as a reference to media and/or industry
                      analysts and to Licensor’s potential customers and investors.
                    </p>
                    <p align="left">
                      9.6. <strong>Notices. </strong>All notices, requests, consents, claims,
                      demands, waivers, and other communications hereunder (each, a “<strong>Notice</strong>”)
                      must be in writing and addressed to the parties at the addresses set forth
                      in these Terms and/or Order Form (or to such other address that may be
                      designated by the Party giving Notice from time to time in accordance with
                      this Section). The parties shall deliver Notices by personal delivery,
                      nationally recognized overnight courier (with all fees pre-paid), facsimile,
                      or email (with confirmation of transmission) or certified or registered mail
                      (in each case, return receipt requested, postage pre-paid). Except as otherwise
                      provided in this Agreement, a Notice is effective only: (i) upon receipt by
                      the receiving Party, and (ii) if the Party giving the Notice has complied with
                      the requirements of this Section.
                    </p>
                    <p align="left">
                      9.7. <strong>Survival.</strong> All provisions of the Agreement relating to
                      proprietary rights, payment of fees accrued, confidentiality and
                      non-disclosure, indemnification and limitation of liability shall survive
                      any termination of this Agreement.
                    </p>
                    <p align="left">
                      9.8. <strong>Electronic Signature; Counterparts.</strong> Each Party agrees
                      that the electronic signatures, whether digital or encrypted, of the
                      parties included in this Agreement are intended to authenticate this
                      writing and to have the same force and effect as manual signatures.
                      Electronic signature means any electronic sound, symbol, or process
                      attached to or logically associated with a record and executed and adopted
                      by a Party with the intent to sign such record, including facsimile or
                      electronic signatures. Receipt of any electronic signature shall be deemed
                      delivery of an original. The Agreement (including any Order Form) may be
                      executed in several counterparts each of which when executed shall be
                      deemed to be an original, and such counterparts shall each constitute one
                      and the same instrument and notwithstanding their date of execution shall
                      be deemed to be effective as of the Effective Date.
                    </p>
                    <p align="left">
                      9.9. <strong>Force Majeure.</strong> Except for the obligation to pay
                      money, neither Party will be liable to the other for any failure to meet
                      its obligations under the Agreement where such failure is caused by events
                      beyond its reasonable control including, but not limited to, such as
                      failure of communications networks, inability to timely obtain instructions
                      or information from the other Party, governmental action, fire, storms,
                      floods or other acts of God, provided that the Party seeking to rely on
                      such circumstances gives written notice of such circumstances to the other
                      Party hereto and uses reasonable efforts to overcome such circumstances.
                    </p>
                    <p align="left">
                      9.10. <strong>Subsequent Modifications.</strong> From time to time,
                      Licensor may modify these Terms, including, without limitation, any
                      referenced features, data, guides, policies, and other documents. If a
                      modification would meaningfully reduce Licensee’s rights, then such changes
                      would be posted on the Website, or, at Licensor’s sole option, sent by
                    email or other message notification to the Licensee (each a “	<strong>Modification Notice</strong>”). Any such modifications shall be
                      effective immediately for new Licensees, and thirty (30) days after the
                      posting, sending or other notification of a Modification Notice.
                    </p>
                    <p align="left">
                      9.11. <strong>Entire Agreement.</strong> The Agreement, including these
                      Terms and Conditions, the Order Form(s), the Privacy Policy and any
                      attached and all exhibits attached hereto, constitutes the entire agreement
                      between the Parties in connection with the subject matter hereof, and
                      supersedes all prior and contemporaneous agreements, understandings,
                      negotiations and discussions, whether oral or written, of the Parties, and
                      there are no warranties, representations and/or agreements among the
                      parties in connection with the subject matter hereof except as set forth in
                      the Agreement. In the event of any inconsistency between these Terms and an
                      Order Form, now or hereafter appended hereto, these Terms shall govern.
                    </p>
                    <p align="center">
                      <strong>END OF AGREEMENT</strong>
                    </p>
                    <p>
                      <strong></strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </StickyContainer>
        </div>      
      )
    }
  }
