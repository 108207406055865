import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidemenu from "./components/Sidemenu/Sidemenu";
import Tags from "./components/Tags/Tags";
import Comapnies from "./components/Companies/Companies";
import Situation from "./components/Situation/Situation";
import Approach from "./components/Approach/Approach";
import Obstacles from "./components/Obstacles/Obstacles";
import Outcome from "./components/Outcome/Outcome";
import Contacts from "./components/Contacts/Contacts";
import Description from "./components/Description/Description";
// import UseCaseSteps from './components/UseCasesSteps/UseCasesSteps'
import { Subscription } from "../components/Subscribe/Subscribe";
// import ImageGallery from './components/ImageGallery/ImageGallery'
import "../components/scss/breadcrumbs.scss";
import { Helmet } from "react-helmet";
export default class UseCaseDetails extends Component {
  render() {
    const { user } = this.props.user;
    const {
      approach,
      clients,
      contacts,
      descriptions,
      obstacles,
      organizations,
      outcome,
      situation,
      tags,
      title,
    } = this.props.results;
    //     const galleryImages = [
    //     {'name': "/testing-images/bitmap-copy-4.jpg"},
    //     {'name': "/testing-images/bitmap-copy-5.jpg"},
    //     {'name': "/testing-images/bitmap-copy-6.jpg"},
    //     {'name': "/testing-images/bitmap-copy-5.jpg"},
    //     {'name': "/testing-images/bitmap-copy-6.jpg"},
    // ]
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div>
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {title.mainTitle ? `${title.shortTitle} | IoT Nation` : ""}
            </title>
          </Helmet>
          <div className="UseCaseDetail">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/usecases">Use case List</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Use case
                </li>
              </ol>
            </nav>

            <div className="d-none d-lg-block">
              <div className="row">
                <div className="col-3">
                  <Sidemenu user={user} results={this.props.results} />
                </div>
                <div className="col-9">
                  {/* {this.props.children} */}
                  {descriptions &&
                  (descriptions.longDescription.length > 0 ||
                    descriptions.shortDescription.length > 0) ? (
                    <Description
                      longDescription={descriptions.longDescription}
                      shortDescription={descriptions.shortDescription}
                    />
                  ) : (
                    ""
                  )}
                  {tags && <Tags tags={tags} user={user} />}
                  {(clients && clients.length > 0) ||
                  organizations.length > 0 ? (
                    <Comapnies
                      user={user}
                      clients={clients}
                      organizations={organizations}
                    />
                  ) : (
                    ""
                  )}
                  {situation && situation.length > 0 ? (
                    <Situation situation={situation} user={user} />
                  ) : (
                    ""
                  )}
                  {obstacles && obstacles.length > 0 ? (
                    <Obstacles obstacles={obstacles} user={user} />
                  ) : (
                    ""
                  )}
                  {approach && approach.length > 0 ? (
                    <Approach approach={approach} user={user} />
                  ) : (
                    ""
                  )}
                  {outcome && outcome.length > 0 ? (
                    <Outcome outcome={outcome} user={user} />
                  ) : (
                    ""
                  )}
                  {/* <ImageGallery images={galleryImages} titleImage="/images/g-chart-1-copy-3.svg" titleName={'Image Gallery'} name="iGallery" user={user} /> */}
                  {contacts && contacts.length > 0 ? (
                    <Contacts user={user} contacts={contacts} />
                  ) : (
                    ""
                  )}
                  {/* <RelatedUseCases user={user} /> */}
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col-12">
                  <Sidemenu user={user} results={this.props.results} />
                </div>
                <div className="col-12">
                  {/* {this.props.children} */}
                  {descriptions &&
                  (descriptions.longDescription.length > 0 ||
                    descriptions.shortDescription.length > 0) ? (
                    <Description
                      longDescription={descriptions.longDescription}
                      shortDescription={descriptions.shortDescription}
                    />
                  ) : (
                    ""
                  )}
                  {tags && <Tags tags={tags} user={user} />}
                  {(clients && clients.length > 0) ||
                  organizations.length > 0 ? (
                    <Comapnies
                      user={user}
                      clients={clients}
                      organizations={organizations}
                    />
                  ) : (
                    ""
                  )}
                  {situation && situation.length > 0 ? (
                    <Situation situation={situation} user={user} />
                  ) : (
                    ""
                  )}
                  {obstacles && obstacles.length > 0 ? (
                    <Obstacles obstacles={obstacles} user={user} />
                  ) : (
                    ""
                  )}
                  {approach && approach.length > 0 ? (
                    <Approach approach={approach} user={user} />
                  ) : (
                    ""
                  )}
                  {outcome && outcome.length > 0 ? (
                    <Outcome outcome={outcome} user={user} />
                  ) : (
                    ""
                  )}
                  {/* <ImageGallery images={galleryImages} titleImage="/images/g-chart-1-copy-3.svg" titleName={'Image Gallery'} name="iGallery" user={user} /> */}
                  {contacts && contacts.length > 0 ? (
                    <Contacts user={user} contacts={contacts} />
                  ) : (
                    ""
                  )}
                  {/* <RelatedUseCases user={user} /> */}
                </div>
              </div>
            </div>
            {/* <UseCaseSteps /> */}
          </div>
        </div>
        {userType !== "admin" && userType !== "corporate" && (
          <div>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
