import React, { Component } from 'react'
import Title from '../Title.jsx';
import CompanyBlock from './CompanyBlock.jsx'

import './ExhibitMiddle.scss';

export default class ExhibitMiddle extends Component {
  render() {
    return (
      <div className="ExhibitMiddle" id={this.props.id}>
        <Title src='/images/new-construction.svg' name={"See who's " + this.props.title} />
        <div className="side-block-border main-padding">
          <div className="display-flex ml-mr-15"> 
            {this.props.data.map((item, key) =>
              <div key={key} className=" col-xl-2 col-lg-3 col-md-2 col-sm-4 col-4">
                <CompanyBlock id={item.info.id} src={item.info.logo} />
              </div>  
            )}
          </div>
        </div>
      </div>
    )
  }
}
