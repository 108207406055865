import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function SubscriberDisclaimer(props) {
    if(!props.user) {
        return <div className="d-none d-md-block subscriber-disclaimer orange">
            <FaExclamation/> <Link to="/signup" >Sign up</Link> for free to unlock more content! <Link to="/iotace">Learn More</Link>
            </div>   
    }   else if( !props.user['cognito:groups'] || props.user['cognito:groups'].find((entry) => { return entry === 'registered'}) || props.user['cognito:groups'].find((entry) => { return entry === 'free'})) {
            return <div className="d-none d-md-block subscriber-disclaimer orange">
                <FaExclamation/> Try out the Pro subscription!<a href='mailto:subscriptions@iotnation.com?subject=Pro Subscription trial&body=I’m interested in a Pro subscription trial on IoT Ace.'>Contact Us</a>for a free trial. <Link to="/iotace">Learn More</Link> 
                </div>
    }   else if(props.user['cognito:groups'].find((entry) => { return entry === 'pro'})) {
            return <div className="d-none d-md-block subscriber-disclaimer orange">
                <FaExclamation/> Interested in the Contacts feature?<a href='mailto:subscriptions@iotnation.com?subject=Premium Upgrade&body=I’m interested in upgrading to a Premium subscription on IoT Ace.'>Upgrade</a>to Premium for full access. <Link to="/iotace">Learn More</Link> 
                </div>
    }   else if(props.user['cognito:groups'].find((entry) => { return entry === 'premium'}) || props.user['cognito:groups'].find((entry) => { return entry === 'pro'})) {
            return <div className="d-none d-md-block subscriber-disclaimer orange">
                <FaExclamation/> Want data exporting functionality?<a href='mailto:subscriptions@iotnation.com?subject=Corporate Upgrade&body=I’m interested in upgrading to a Corporate subscription on IoT Ace.'>Upgrade</a>to Corporate for access. <Link to="/iotace">Learn More</Link> 
                </div>
    }   else if(props.user['cognito:groups'].find((entry) => { return entry === 'corporate'})) {
            return <div className="d-none d-md-block subscriber-disclaimer orange">
                <FaExclamation/> Have special data needs?<a href={`mailto:subscriptions@iotnation.com?subject=Corporate Custom Demo Setup&body=I’m interested in a custom demo targeted to my data needs.%0D%0A%0D%0AMy primary data needs are:%0D%0A[enter data needs]%0D%0AMy main objectives for this data are:%0D%0A[enter data objectives]%0D%0A%0D%0AI look forward to setting a date for the demo!`}>Connect</a>with a specialist and setup a custom demo! 
                </div>
    }

    // if(!props.user || !props.user['cognito:groups'] || props.user['cognito:groups'].find((entry) => { return entry === 'registered'}) || props.user['cognito:groups'].find((entry) => { return entry === 'free'})) {
    //     return <div className="subscriber-disclaimer orange"><FaExclamation/> Subscribe to unlock more content! See <Link to="/iotace">Subscription Plans</Link> for details.</div>
    // } else if(props.user['cognito:groups'].find((entry) => { return entry === 'admin'}) || props.user['cognito:groups'].find((entry) => { return entry === 'corporate'})) {
    //     return <div className="subscriber-disclaimer green"><FaExclamation/> Have special data needs? Contact our dedicated data specialist by clicking on chat icon on the bottom right of the screen!</div>
    // } else if(props.user['cognito:groups'].find((entry) => { return entry === 'premium'}) || props.user['cognito:groups'].find((entry) => { return entry === 'pro'})) {
    //     return <div className="subscriber-disclaimer blue"><FaExclamation/> Upgrade to access more data! See <Link to="/iotace">Subscription Plans</Link> for details.</div>
    // } 
    return '';
}