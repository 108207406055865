import React, { Component } from 'react'
import Title from './Title.jsx';
import moment from 'moment-timezone'
import getTimezoneNumber from '../../utils/getTimezoneNumber'
import './Highlights.scss';

export default class Highlights extends Component {

  render() {
    const {street1, city, stateCode, zipCode, country} = this.props

    const location = Object.assign([], street1&&{0:`${street1}`},
      city&&street1?{1:`, ${city}`}:city&&{1:`${city}`}, 
      stateCode&&(street1 || city)?{2:`, ${stateCode}`}:stateCode&&{2:`${stateCode}`},
      zipCode&&(street1 || stateCode || city)?{3:`, ${zipCode}`}:zipCode&&{3:`${zipCode}`},
      country&&(street1 || stateCode || city || zipCode)?{3:`, ${country}`}:country&&{3:`${country}`}).join('')
      
    const startTime = moment.unix(this.props.startTime)
    const endTime = moment.unix(this.props.endTime);
    let time = "";
    const timeZone = endTime.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endTime.format('Z'))}[)]`)
    if(startTime.isSame(endTime, 'day')) {
      time = (
        <div className="row pb-16 datetime">
        <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}<br/>{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
      );
    } else if(startTime.isSame(endTime, 'month')) {
      time = (
        <>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}</div>
        </div>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date single">{endTime.format('MMM')}<br/>{endTime.format('DD')}</div></div>
          <div className="col-8 time">{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
        </>
      );
    } else {
      time = (
        <>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}</div>
        </div>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{endTime.format('MMM')}<br/>{endTime.format('DD')}</div></div>
          <div className="col-8 time">{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
        </>
      );
    }
    
    return (
      <div style={{width: "278px"}}>
        <Title src="/images/calendar-2.svg" name="When & Where" />
        <div className="side-block-border">
          <div className="container pl-24 pr-24 pb-24 pt-24">
            <p style={{fontSize: '13px', fontStyle: 'italic'}}>Event is shown in your local time.</p>
            {time}
            {location&&
            <div className="row pb-16">
              <div className="col-2">
                <img className="image small-icon"  src="/images/pin-3.svg" alt=""/>
              </div>
              <div className="col-10 pl-0">
                {console.log(this.props.locationUrl)}
                {
                  this.props.locationAddress? 
                    <a className="adress" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${this.props.locationUrl}`}> 
                      <span>{location}</span>
                    </a>
                  :location
                }
              </div>
            </div>
            }
            <div className="button-padding">
              <button disabled className="view active w-100">+ Add to Calendar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
