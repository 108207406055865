import React, { Component } from "react";
import { Link } from "react-router-dom";
import CardsTitle from "../../../components/CardsTitle/CardsTitle";
import "./CompanyLeaderboard.scss";
import Spinner from "../../../components/Spinner/Spinner";
import GeneratedApi from "../../../components/functions/generatedApi";

export default class CompanyLeaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      response: "",
    };
  }
  componentDidMount() {
    const { limit, type, title, APIcall } = this.props.propsData;
    const q = {
      search: {
        q: "",
        start: 0,
        limit: limit ? limit : 12,
      },
      filters: [{ attribute: "Company Rank", operator: "Less than", value: "3000" }, { attribute: type, operator: "Include", value: title }],
    };
    GeneratedApi("", false, true)
      .post(`/${APIcall}/gridView`, q)
      .then((response) => {
        response = response.data.body.data;
        this.setState({ response });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ response: "no info" });
        if (error.response) {
          if (error.response.status === 429) {
            return false;
          }
        }
      });
  }
  render() {
    const { response } = this.state;
    if (response === "no info" || !response.searchResults || response.searchResults.length === 0) {
      return "";
    } else if (response && response !== "no info") {
      return (
        <div className="CompanyLeaderboard">
          <CardsTitle image="/images/g-chart-1.svg" titleName={"Company Leaderboard"} name="company-leaderboard" />
          <div className="description">
            {response.searchResults.map((result, key) => (
              <div key={key} className="block-company">
                <div className="display-flex row">
                  <div className="col-xl-3 col-md-4 col-sm-12 display-flex">
                    <div className="vertical-merge">{result.rank}</div>
                    <img className="vertical-merge rank-img" src={result.rankChange >= 1 ? "/images/rank-up.svg" : "/images/rank-down.svg"} alt="" />
                    <a className="vertical-merge" target="_blank" href={`/company/${result.id}`}>
                      <div className="vertical-merge company-img-block">
                        <img className="company-image" src={result.logo} alt="" />
                      </div>
                    </a>
                    <Link className="link" to={`/company/${result.id}`}>
                      {result.name}
                    </Link>
                  </div>
                  <div className="col-1 col-md-2 col-lg-1" />
                  <div className="col-xl-8 col-lg-7 col-md-6 col-sm-6 text">
                    <div className="d-none d-xl-block">{result.description.slice(0, 150) + "..."}</div>
                    <div className="d-none d-lg-block d-xl-none">{result.description.slice(0, 110) + "..."}</div>
                    <div className=" d-none d-md-block d-lg-none">{result.description.slice(0, 80) + "..."}</div>
                  </div>
                  {/* {result.description} */}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
