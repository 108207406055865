import React, { Component } from "react";
import SubscriberContent from "../../../components/SubscriberContent/SubscriberContent";
import CardsTitle from "../../../components/CardsTitle/CardsTitle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ImageGallery.scss";
import Spinner from "../../../components/Spinner/Spinner";
import GeneratedApi from "../../../components/functions/generatedApi";

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagId: "",
      loaded: false,
      response: "",
    };
  }
  componentDidMount() {
    const { limit, type, title, APIcall } = this.props.propsData;
    const q = {
      search: {
        q: "",
        start: 0,
        limit: limit ? limit : 3,
      },
      filters: [{ attribute: "Company Rank", operator: "Less than", value: "3000" }, { attribute: type, operator: "Include", value: title }],
    };
    GeneratedApi("", false, true)
      .post(`/${APIcall}/gridView`, q)
      .then((response) => {
        response = response.data.body.data;
        this.setState({ response });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ response: "no info" });
        if (error.response) {
          if (error.response.status === 429) {
            return false;
          }
        }
      });
  }
  render() {
    const { user, titleImage, titleName, name } = this.props;
    const { response } = this.state;
    const type = !user;
    if (response === "no info") {
      return "";
    } else if (response && response !== "no info") {
      return (
        <div className="ImageGallerySector" id="iGallery">
          <div>
            <CardsTitle image={titleImage} titleName={titleName} name={name} />
            <div className={user ? `description` : "description not-login"}>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                containerClass="container"
                dotListClass=""
                draggable
                infinite
                focusOnSelect={false}
                keyBoardControl
                minimumTouchDrag={80}
                renderDotsOutside={false}
                className="sector-class"
                itemClass="react-multiple-carousel__arrow--right-z-index-300"
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                    paritialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 575,
                      min: 0,
                    },
                    items: 1,
                    paritialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 576,
                    },
                    items: 2,
                    paritialVisibilityGutter: 30,
                  },
                }}
                // showDots={true}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {response.searchResults.map((item, key) => (
                  <div key={key}>
                    {user ? (
                      <>
                        <a href={`/usecase/${item.usecaseId}`} style={{ display: "contents", textDecoration: "underline" }}>
                          <img style={{ padding: "10px" }} src={item.images.mainImage.source} alt="" />
                        </a>

                        <div className="d-flex" style={{ fontWeight: "600", marginBottom: "5px", padding: "10px" }}>
                          <a className="color-black horizontal-align text-center" href={`/usecase/${item.usecaseId}`}>
                            {item.title.mainTitle ? item.title.mainTitle.slice(0, 80) : item.title.shortTitle.slice(0, 80)}
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <img style={{ padding: "10px" }} src={item.images.mainImage.source} alt="" />
                        <div style={{ fontWeight: "600", marginBottom: "5px", padding: "10px" }}>
                          {item.title.mainTitle ? item.title.mainTitle.slice(0, 80) : item.title.shortTitle.slice(0, 60)}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <SubscriberContent target="imageGallery" hidden={type} />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
