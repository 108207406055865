import React, { Component } from 'react'

export default class FeaturedUseCases extends Component {
    render() {
        return (
            <div className="Featured-Use-Cases row">
                <div className="col">
                    <div className="row content-header">
                        <div className="col-1"><div className="cases"><img alt="" src="/images/agenda-bookmark-1.svg"/></div></div>
                        <div className="col d-flex align-items-center"><p className="title">Featured Use Cases</p></div>
                    </div>

                    <div className="row examples">
                        <div className="col-4">
                        <div className="case-img"></div>
                        <h5>Home Mobile Apps</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</p>
                        </div>
                        <div className="col-4">
                        <div className="case-img"></div>
                        <h5>Autonomous Robots</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</p>
                        </div>
                        <div className="col-4">
                        <div className="case-img"></div>
                        <h5>Agricultural Drones</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut erat quis nunc mollis rutrum. In ac sapien ut nunc mollis cursus</p>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}
