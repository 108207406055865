import React, { PureComponent } from 'react'
import './EventsItem.scss';
// import { Button } from 'reactstrap';
// import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa';
import AddtoCalendarDropdownImage from '../../components/AddToCalendarDropdownImage/AddtoCalendarDropdown'
import AddtoCalendarDropdownDate from '../../components/AddToCalendarDropdownDate/AddToCalendarDropdownDate'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment-timezone';
import cx from 'classnames'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import getEventTypeImage from '../functions/getEventTypeImage'
import getTimezoneNumber from '../../utils/getTimezoneNumber';
import "../labels.scss";
// import RestrictedPopover from '../../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
import {isAndroid, isIOS, isMobile} from 'react-device-detect';

class EventsItem extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            likeData:null,
            showModal: false,
            showModalMobile: false,
            thumbsUp: false,
            likeLength: 0,
        }
       
      }
    state = {
        moreInfo: false,
        moreTags: false,
        randPicNumber:Math.round(Math.random())
    }
    async componentDidMount() {
        // const q = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event"}
        // this.props.instance2.post('/getLikeInfo', q)
        //     .then((response) => {  
        //         response = response.data;
        //         this.setState({
        //             likeData: response,
        //             thumbsUp: response.currentUserLike,
        //             likeLength: response.userList.length,
        //         });
                
        //     })
        // .catch((error) => {
        //     console.log(error);
        //     if(error.response) {
        //         if(error.response.status === 429) {
                    
        //             return false;
        //         }
        //     }
        // });
        
      }

    handleMoreInfo = () => this.setState(({moreInfo})=>({moreInfo:!moreInfo}))
    handleMoreTags = () => this.setState()
    toggleModal = () => {this.setState({showModal: !this.state.showModal})}
    toggleModalMobile = () => {this.setState({showModalMobile: !this.state.showModalMobile})}
    likeHandle = () => {
        if(!this.props.user){
            this.setState({
                showModal: true,
                showModalMobile: true
            })
        }else{
          const like = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event", "operation":"LIKE"}
          const unlike = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event", "operation":"INDIFFERENT"}
          if(this.state.thumbsUp ){
            if(this.state.likeLength < 1){
                this.setState({
                    thumbsUp: false,
                    likeLength: 0
                })
            }else{
                this.setState({
                    thumbsUp: false,
                    likeLength: this.state.likeLength - 1
                })
            }
            this.props.instance2.post('/updateLikeInfo', unlike)
            .then((response) => {
                response = response.data;
                this.setState({
                  likeData: response,
                });
                
            })
            .catch((error) => {
                if(this.state.likeLength < 1){
                    this.setState({
                        thumbsUp: false,
                        likeLength: 0
                    })
                }else{
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength + 1
                    })
                }
                console.log(error.response);
                if(error.response) {
                    if(error.response.status === 429) {
                      return false;
                    }
                }
            });
          }else{
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength + 1
            })
            this.props.instance2.post('/updateLikeInfo', like)
              .then((response) => {
                  response = response.data;
                  this.setState({
                    likeData: response,
                  });
                  
              })
              .catch((error) => {
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength - 1
                    })
                  console.log(error.response);
                  if(error.response) {
                      if(error.response.status === 429) {
                          
                          return false;
                      }
                  }
              });
          }
        
       }
      }

    render() {    
        const addresss = this.props.data.location.address
        const locationn = Object.assign([], addresss.street1&&{0:addresss.street1}, addresss.city&&{1:addresss.city} ,addresss.stateCode&&{2:addresss.stateCode}, addresss.zipCode&&{3:addresss.zipCode}).join(', ')
        const startTimee = moment.unix(this.props.data.date.startTime)
        const endTimee = moment.unix(this.props.data.date.endTime)
        const duration = moment.duration(endTimee.diff(startTimee)).asHours().toString()
        
        const { moreInfo, moreTags, randPicNumber } = this.state
        let { 
            date:{ startTime, endTime } = {},
            descriptions:{ shortDescription } = {},
            eventId,
            eventType,
            featured,
			images: { squareIcon } = {},
			location: { address  } = {},
            tags:{ horizontals = [], verticals = [] } = {},
            title,
            filters
         } = this.props.data;
        const tags = [ ...horizontals, ...verticals ].filter(function (el) {
            return el !== '';
          });

        const eventInDays =  moment.unix(endTime).format('DD') - moment.unix(startTime).format('DD')
        const isEventInSameMonth = moment.unix(endTime).format('MM') === moment.unix(startTime).format('MM')? true : false;
        const endTimeMoment = moment.unix(endTime)
        const timezone = endTimeMoment.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endTimeMoment.format('Z'))}[)]`)
        const dateFormated = startTime&&endTime&&eventInDays === 0?
            moment.unix(startTime).format('MMMM DD, YYYY'):
            (isEventInSameMonth ? `${moment.unix(startTime).format('MMMM DD')}-${endTimeMoment.format('DD')}, ${moment.unix(startTime).format('YYYY')}`:  
            `${moment.unix(startTime).format('MMMM DD')} - ${endTimeMoment.format('MMMM DD')}, ${moment.unix(startTime).format('YYYY')}`)
        const timeFormated = startTime&&endTime&&`${moment.unix(startTime).format('hh:mmA')} - ${endTimeMoment.format('hh:mmA ')} ${timezone}`
        
        const event = {
            description: `For info on this event, click here: \n<a href="https://www.iotnation.com/event/${eventId}">www.iotnation.com/event/${eventId}</a>
            \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events/list">www.iotnation/events/list</a>`,
            duration,
            endDatetime: eventInDays > 0 ? endTimee.add(1, 'days').format('YYYYMMDD'): endTimee.utc().format('YYYYMMDDTHHmmssZ'),
            locationn,
            startDatetime: eventInDays > 0 ? startTimee.format('YYYYMMDD'): startTimee.utc().format('YYYYMMDDTHHmmssZ'),
            title: this.props.data.title,
        }
        let now = moment();
        
        const fullDaysLeft = startTime&&moment.unix(startTime).isAfter(now)&&moment.unix(startTime).diff(now, 'days')
        const hoursLeft = startTime&&moment.unix(startTime).isAfter(now)&&moment.unix(startTime).diff(now, 'hours')
        const monthsLeft = startTime&&moment.unix(startTime).isAfter(now)&&moment.unix(startTime).diff(now, 'months')
        const weeksLeft = startTime&&moment.unix(startTime).isAfter(now)&&moment.unix(startTime).diff(now, 'weeks')
        const inProgress = startTime&&moment.unix(startTime).isBefore(now)&&moment.unix(endTime).isAfter(now);
        const isNotEnded = moment.unix(endTime) > now ? true :false
        const classes = {
            label:cx({
                label: true,
                [String(eventType).toLowerCase()]:!!eventType
            })
        }
		const locationString = Object.assign([], address.city&&{0:address.city} ,(address.stateCode || address.countryCode )&&{1:address.stateCode || address.countryCode }).join(', ')
        return (
            <div className={`EventsItem ${featured ? 'featured' : ''}`}>
                {
                    featured&&
                    <div className="featured-header">
                        <div className="img-wrapper">
                            <img src="/images/star.svg" alt=""/>
                        </div>
                        <div className="title">Featured</div>
                    </div>
                }
                <div className="event-body">
                    <div className="row no-gutters">
                        <div className=" d-none d-md-block">
                            {        
                                <Link className="event-image" to={{
                                    pathname:`/event/${eventId}`,
                                    state: { prevQuery: window.location.href, filters, eventId  }
                                }}>
                                    <img src={squareIcon?squareIcon:getEventTypeImage(eventType, randPicNumber)} alt=""/>
                                </Link>
                            }
                        </div>
                        <div className="col-md-2 d-block d-md-none">
                            {        
                                <Link className="event-image" to={{
                                    pathname:`/event/${eventId}`,
                                    state: { prevQuery: window.location.href, filters, eventId  }
                                }}>
                                    <img src={squareIcon?squareIcon:getEventTypeImage(eventType, randPicNumber)} alt=""/>
                                </Link>
                            }
                        </div>
                        <div className="col general-info">
                            <div className="row no-gutters align-items-center">
                                <div className="col-9">
                                    <div className="event-title">
                                        <Link to={{
                                            pathname:`/event/${eventId}`,
                                            state: { prevQuery: window.location.href, filters, eventId },
                                        }}>
                                            {title}
                                        </Link>   
                                    </div>
                                </div>
                                {/* <div className="col-3 d-none d-md-flex mt-0 mr-0 mb-auto ml-auto">
                                    <div className="likes d-flex mt-0 mr-0 mb-auto ml-auto">
                                        <div className="likes">
                                            {this.state.likeLength}
                                        </div>
                                        <Button onClick={(() => this.likeHandle())} color="link" className="likes d-flex">
                                            {this.props.user?'':
                                            <RestrictedPopover classN="d-none d-md-block" showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={`Like-events2${this.props.id}`} />
                                            }
                                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                        </Button>
                                    </div>
                                </div> */}
                                <div className="divider"></div>
                                <div className="d-block d-sm-block d-md-none" style={{width: "100%"}}>
                                    <div className="text-right" style={{display: "inline-flex"}}>
                                        <div className={classes.label}><span>{eventType&&eventType}</span></div>
                                    </div>
                                    <div  style={{display: "inline-flex", float: "right"}}>
                                        <span className="time-left">{inProgress ? 'In Progress' : isNotEnded?
                                            (fullDaysLeft <= 2 && hoursLeft === 1? `In ${hoursLeft} hour`: 
                                                hoursLeft < 1? `In less than an hour`: 
                                                    hoursLeft <= 48? `In ${hoursLeft} hours`: 
                                                        fullDaysLeft <= 14? `In ${fullDaysLeft} days`: 
                                                            fullDaysLeft <= 56? `In ${weeksLeft} weeks`: 
                                                                fullDaysLeft > 56 && monthsLeft === 1? 'In 1 month': 
                                                                    fullDaysLeft > 56 && monthsLeft >1? `In ${monthsLeft} months`: 'In Progress') 
                                            :`Already ended`}</span>
                                    </div>
                                </div>
                                {/* <div className="w-100 text-right">
                                    <div className="likes d-flex">
                                        <div className="likes">
                                            {this.state.likeLength}
                                        </div>{this.props.user?'':
                                                <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.toggleModal} id={`Like-events${this.props.id}`} />
                                            }
                                        <Button onClick={(() => this.likeHandle())} color="link" className="likes d-flex">
                                            
                                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                        </Button>
                                        
                                    </div> 
                                </div> */}
                            </div>
                            <div className="row no-gutters align-items-center">
                                <div className="col">
                                    <div className="row no-gutters">
                                        <div className="col-12 col-md-auto col-auto">
                                            <AddtoCalendarDropdownImage
                                            event={event}
                                            className="AddtoCalendarDropdown"
                                            items={isMobile && isAndroid?['Google']:isMobile && isIOS?['iCal']:['Google', 'iCal', 'Outlook', 'Yahoo']}
                                            buttonProps={{dateFormated}}
                                            linkProps={{target: '_blank'}}
                                            
                                            />
                                            <div className="event-calendar">
                                                <AddtoCalendarDropdownDate
                                                    event={event}
                                                    className="AddtoCalendarDropdown"
                                                    items={isMobile && isAndroid?['Google']:isMobile && isIOS?['iCal']:['Google', 'iCal', 'Outlook', 'Yahoo']}
                                                    buttonProps={{dateFormated}}
                                                    linkProps={{target: '_blank'}}
                                                    style={{padding:"0px 10px"}}
                                                />
                                            </div>
                                        </div>
                                        
                                        {eventInDays === 0 ?
                                            <div className="col-auto info-holder">
                                                <img className="margin-bottom-4" src="/images/clock.svg" alt=""/>
                                                <span>{ timeFormated}</span>
                                            </div>:
                                            null
                                        }
                                    </div>
                                    <div className="row no-gutters">
										{
                                        locationString&&
                                        <div className="col-auto info-holder ">
                                            <img className="margin-bottom-4" src="/images/pin-3.svg" alt=""/>
                                                <span>{locationString}</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="text-right d-none d-md-block">
                                    <div className={classes.label}><span>{eventType&&eventType}</span></div>
                                </div>
                            </div>
                            {/* <div className="row no-gutters">
                                <div className="display-flex">
                                  <img src="/images/building-1.svg" alt=""/> <div className="organizer">Organizer:</div> <div className="self-organizer">No info</div>   
                                </div>
                            </div> */}
                            <div className="row no-gutters">
                                <div className="col">
                                    { tags.length > 0 &&
                                        <div className="row">
                                            <div className="col tag-col d-block d-sm-none">  
                                                <span className="tag-header">Tags:</span>
                                                <span className="tags">
                                                {tags.slice(0, moreTags?(tags.length):3).map((tag, i)=>
                                                    tag&&
                                                    (
                                                        !moreTags && i === 2 && tags.length - (i) > 0
                                                        ?
                                                        
                                                        <Tippy key={i} content={
                                                    <ul data-simplebar>
                                                        {tags.slice(2,tags.length).map((tag, i) => 
                                                            tag&& <li className="capitalize" key={i}>{tag}</li>
                                                        )}
                                                    </ul>
                                                } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i}  className="btn capitalize">{`+${tags.length - (i)}`}</button></Tippy>

                                                        :<button key={i} className="btn capitalize">{tag}</button>
                                                    )
                                                )}
                                                </span>
                                            </div>
                                            <div className="col tag-col d-none d-sm-block">                                              
                                                    <span className="tags">
                                                    {tags.slice(0, moreTags?(tags.length):3).map((tag, i)=>
                                                        tag&&
                                                        (
                                                            !moreTags && i === 2 && tags.length - (i) > 0
                                                            ?
                                                            
                                                            <Tippy key={i} content={
                                                        <ul data-simplebar>
                                                            {tags.slice(2,tags.length).map((tag, i) => 
                                                                tag&& <li className="capitalize" key={i}>{tag}</li>
                                                            )}
                                                        </ul>
                                                    } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i}  className="btn capitalize">{`+${tags.length - (i)}`}</button></Tippy>

                                                            :<button key={i} className="btn capitalize">{tag}</button>
                                                        )
                                                    )}
                                                    </span>      
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className={`col ${moreInfo?'content':'content hidden'}`}>
                                        {shortDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 text-right d-none d-md-block">
                                    <span className="time-left">{inProgress ? 'In Progress' : isNotEnded?
                                        (fullDaysLeft <= 2 && hoursLeft === 1? `In ${hoursLeft} hour`: 
                                            hoursLeft < 1? `In less than an hour`: 
                                                hoursLeft <= 48? `In ${hoursLeft} hours`: 
                                                    fullDaysLeft <= 14? `In ${fullDaysLeft} days`: 
                                                        fullDaysLeft <= 56? `In ${weeksLeft} weeks`: 
                                                            fullDaysLeft > 56 && monthsLeft === 1? 'In 1 month': 
                                                                fullDaysLeft > 56 && monthsLeft >1? `In ${monthsLeft} months`: 'In Progress') 
                                         :`Already ended`}</span>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="d-none d-md-block">
                                {
                                    shortDescription&&
                                    <div onClick={this.handleMoreInfo} className="show-more-info">
                                            Show {moreInfo?'less':'more'} info
                                    </div>
                                }
                            </div>
                            
                            <div className="d-block d-sm-block d-md-none" style={{width: "100%"}}>
                                <div style={{display: "inline-flex"}}>
                                    {
                                        shortDescription&&
                                        <div onClick={this.handleMoreInfo} className="show-more-info">
                                                Show {moreInfo?'less':'more'} info
                                        </div>
                                    }
                                </div>
                                {/* <div className="likes d-flex d-md-none"   style={{display: "inline-flex", float: "right", marginTop: "-4px"}}>
                                    <div className="likes d-block d-md-none">
                                        {this.state.likeLength}
                                    </div>
                                    <Button className="d-block d-md-none likes" onClick={(() => this.likeHandle())}  color="link">
                                        {this.props.user?'':
                                            <RestrictedPopover classN="d-block d-md-none" showModal={this.state.showModalMobile} handleCloseModal={this.toggleModalMobile} id={`Like-events1${this.props.id}`} />
                                        }
                                        {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                    </Button>
                                </div>  */}
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(EventsItem)