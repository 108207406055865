import React from "react";
//import ReactPaginate from 'react-paginate';
import { Link, withRouter } from "react-router-dom";

import Pagination from 'rc-pagination';
import localeInfo from './Pagination/pagination_locale';

import SortableHeader from './SortableHeader/SortableHeader';
import Spinner from '../Spinner/Spinner';

//import './Pagination/pagination.css';
import './ResultsTable.scss';


class ContactResultsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      sort: {
        column: 'rank',
        sort: 'asc'
      }
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handleResultCount = (start, count ,limit)=>{
    return count - start < limit ? count : start > 0 ? start + limit : limit
  }
  handlePageClick = (current, pageSize) => {
    //let selected = data.selected;
    //let offset = Math.ceil(selected * this.props.data.limit);
    let offset = Math.ceil((current-1) * pageSize);
    this.props.handlePageChange(offset, current);
  };
  
  render() {
    const {searchResults, start, count, limit, sort, currentPage, loading} = this.props.data;
    if(loading) {
      return <Spinner height={'50%'}/>;
    }
        return (
        <div className="container results">
          <div className="row">
            <div className="col">
              <div className="result-count">{count===0?start:start+1}-{this.handleResultCount(start, count, limit)} of {count} results</div>
              <table className="row">
                <thead className="col-12">
                  <tr className="row">
                    <th className="col-1"><span>First name</span></th>
                    <SortableHeader data={{type: 'lastName', name: 'Last name', sort: sort, gridSize: 'col-1'}} handleSortChange={this.props.handleSortChange}/>
                    <th className="col"><span>Title</span></th>
                    <th className="col-2"><span>Work email</span></th>
                    <SortableHeader data={{type: 'companyName', name: 'Company name', sort: sort}} handleSortChange={this.props.handleSortChange}/>
                    <th className="col"><span>Location</span></th>
                    <th className="col-1"><span>Profile</span></th>
                  </tr>
                </thead>
                <tbody className="col-12">
                  {searchResults.map((result, index) => {
                    return (
                    <tr className="row" key={index}>
                      <td className="col-1">{result.firstName}</td>
                      <td className="col-1">{result.lastName}</td>
                      <td className="col">{result.title}</td>
                      <td className="col-2 ellipse"><a href={`mailto:${result.email.workEmail}`}>{result.email.workEmail}</a></td>
                      <td className="col"><Link to={{pathname:`/company/${result.company.id}`, state: { prevQuery: `${this.props.location.pathname}${this.props.location.search}` }}}>{result.company.name}</Link></td>
                      <td className="col">{result.address.city ? `${result.address.city}, ${result.address.country}` : ''}</td>
                      <td className="col-1"><a href={result.linkedin} rel="noopener noreferrer" target="_blank">Profile</a></td>
                    </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <nav aria-label="Page navigation example">
                
              <Pagination
                current={Number(currentPage)}
                className={'pages pagination'}
                prevIcon={<div className="pagination-button previous"></div>}
                nextIcon={<div className="pagination-button next"></div>}
                pageSize={Number(limit)}
                onChange={this.handlePageClick}
                total={Number(count)}
                locale={localeInfo}
                showTitle={true}
               />
                </nav>
                
            </div>
          </div>
        </div>
    )
  }
};

export default withRouter(ContactResultsTable);