import React, { Component } from 'react'
import { Popover, PopoverBody } from 'reactstrap';
import { Link } from 'react-scroll';
import { FaArrowDown } from 'react-icons/fa';
import RegisterPopover from '../../../components/Modals/RegisterPopover/RegisterPopover'
import './Sidemenu.scss';

export default class Sidemenu extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          popoverOpen: false,
          showModal: false
        };
      }
    
        toggle() {
            this.setState({
            popoverOpen: !this.state.popoverOpen
            });
        }
        toggleModal = () => {this.setState({showModal: !this.state.showModal})}
        handleModal = () => {
            this.setState({showModal: true})
            return '';
        }
    render() {
        
        const {approach, clients, contacts, descriptions, images, obstacles, organizations, outcome, situation, tags, pdf, title} = this.props.results
        const user = this.props.user
        return (
            <div className="Sidemenu">
                {user?'':
                    <RegisterPopover pathname={window.location.pathname} isOpen={this.state.showModal} toggle={this.toggleModal} />
                }
                <img className="main-image" src={images&&images.mainImage&&images.mainImage.source?images.mainImage.source:"/images/example.jpg"} alt=""/>
                <div className="main">
                    <div className="title">
                        {title? (title.mainTitle? title.mainTitle:title.shortTitle) :''}
                    </div> 
                    <div className="select-block d-none d-lg-block">
                        {descriptions&& (descriptions.longDescription.length > 0 || descriptions.shortDescription.length > 0)?
                            <Link style={{display: "flex"}} className="side-btn description-link-left btn-element" activeClass="active" to="description" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="description" src="/images/paragraph.svg"/> 
                                <p style={{margin: "auto 0px"}}>Description</p>
                            </Link>:''
                        }   
                        {tags && tags.length > 0 ?
                            <Link style={{display: "flex"}} className="side-btn tags-link-left btn-element" activeClass="active" to="tags" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>                    
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="tags" src="/images/tag-1.svg"/>
                                <p style={{margin: "auto 0px"}}>Tags</p>
                            </Link>:'' 
                        } 
                        {((clients && clients.length > 0) || organizations.length > 0) ?
                            <Link style={{display: "flex"}} className="side-btn companies-link-left btn-element" activeClass="active" to="companies" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="companies" src="/images/building-5.svg"/>
                                <p style={{margin: "auto 0px"}}>Companies Involved</p>
                            </Link>:''
                        }
                        {situation && situation.length > 0 ?
                            <Link style={{display: "flex"}} className="side-btn situation-link-left btn-element" activeClass="active" to="situation" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="situation" src="/images/preview-1.svg"/>
                                <p style={{margin: "auto 0px"}}>Situation</p>
                            </Link>:''
                        } 
                        {obstacles && obstacles.length > 0 ?
                            <Link style={{display: "flex"}} className="side-btn obstacles-link-left btn-element" activeClass="active" to="obstacles" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="obstacles" src="/images/journey-04.svg"/>
                                <p style={{margin: "auto 0px"}}>Obstacles</p>
                            </Link>:''
                        }
                        {approach && approach.length > 0 ?
                            <Link style={{display: "flex"}} className="side-btn approach-link-left btn-element" activeClass="active" to="approach" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="aapproach" src="/images/target.svg"/>
                                <p style={{margin: "auto 0px"}}>Approach</p>
                            </Link>:''
                        }
                        {outcome && outcome.length > 0 ?
                            <Link style={{display: "flex"}} className="side-btn outcome-link-left btn-element" activeClass="active" to="outcome" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                                <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="outcome" src="/images/trophy.svg"/>
                                <p style={{margin: "auto 0px"}}>Outcome</p>
                            </Link>:''
                        }
                        {/* <Link style={{display: "flex"}} className="side-btn iGallery-link-left btn-element" activeClass="active" to="iGallery" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                            <FaCameraRetro style={{margin: "auto 0px", color: '#FF4848'}} /><p style={{ width: "15px"}}></p>
                            <p style={{margin: "auto 0px"}}>Image Gallery</p>
                        </Link> */}
                        {contacts && contacts.length > 0 ?
                        <Link style={{display: "flex"}} className="side-btn contacts-link-left btn-element" activeClass="active" to="contacts" spy={true} smooth={true} offset={-67} duration={500} onSetActive={this.handleSetActive}>
                            <img style={{margin: "auto 0px", paddingRight: "15px"}} alt="contacts" src="/images/multiple-1.svg"/>
                            <p style={{margin: "auto 0px"}}>Contacts</p>
                        </Link>:''
                        }
                    </div> 
                    <div className="d-none d-md-flex d-lg-none">
                        <div className="col-6">
                            {user && pdf?
                                <a target="_blank" href={pdf.externalLink}><button className={"download-suggest-btn"}><FaArrowDown /> Download PDF</button></a>:
                                <button onClick={(()=>this.handleModal())} className={"download-suggest-btn-grey"}><FaArrowDown /> Download PDF</button>
                            }
                        </div>
                        <div className="col-6">
                            {
                                user?<button onClick={()=>this.handleModal()} className="download-suggest-btn"><img src="/images/pen-2.svg" alt=""/> Suggest/Request Changes</button>:
                                <button  className="download-suggest-btn-grey"><img src="/images/pen-2.svg" alt=""/> Suggest/Request Changes</button>
                            }  
                        </div>
                    </div> 
                </div>
                {contacts && contacts.length > 0 ?
                  <div className="contacts">
                    <div className="contacts-title">Contacts</div>
                    {contacts.map((item, key) =>
                        <div className="contacts-body">
                            <div className="row">
                                <div className="col-10">
                                    <div>{`${item.firstName} ${item.lastName}`}</div>
                                    <div>{item.title}</div>
                                    <div>{item.company.name}</div> 
                                </div>
                                <div className="col-2 p-0">
                                    <button id="popover" onClick={this.toggle}><img className="single-02-3" src="/images/single-02-3.svg" alt=""/></button>
                                </div>
                            </div>
                            <Popover placement="right" isOpen={this.state.popoverOpen} target="popover" toggle={this.toggle}>
                                <PopoverBody>
                                    email:  {item&&item.email&&item.email.workEmail?`${item.email.workEmail} `:''}
                                </PopoverBody>
                                <PopoverBody>
                                    phone:
                                </PopoverBody>
                            </Popover>   
                        </div>
                    )}
                    </div>:'' 
                }
                <div className="d-none d-lg-block">
                    {user && pdf?
                        <a target="_blank" href={pdf.externalLink}><button className={"download-suggest-btn"}><FaArrowDown /> Download PDF</button></a>:
                        <button onClick={(()=>this.handleModal())} className={"download-suggest-btn-grey"}><FaArrowDown /> Download PDF</button>
                    }
                    {/* {
                        user?<button onClick={()=>this.handleModal()} className="download-suggest-btn"><img src="/images/pen-2.svg" alt=""/> Suggest/Request Changes</button>: */}
                        <button  className="download-suggest-btn-grey"><img src="/images/pen-2.svg" alt=""/> Suggest/Request Changes</button>
                    {/* } */}
                </div>

            </div>
        )
    }
}
