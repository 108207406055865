import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa'
export default class Technologies extends Component {
    render() {

        const { checkedFilter, handleFilterCheckbox, IsChecked } = this.props
        return (
            <div className='side-padding-15'>
                <label className="custom-control-label" htmlFor='technologies-8' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-8' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-8')}  placeholder="Technologies" name="Include" value="Ai & IoT"  type="checkbox" />
                                {'Ai & IoT'}
                            {IsChecked(checkedFilter, 'technologies-8') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-10' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-10' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-10')}  placeholder="Technologies" name="Include" value="Applications"  type="checkbox" />
                                {'Applications'}
                            {IsChecked(checkedFilter, 'technologies-10') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-2' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-2' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-2')}  placeholder="Technologies" name="Include" value="Big Data & Analytics"  type="checkbox" />
                                {'Big Data & Analytics'}
                            {IsChecked(checkedFilter, 'technologies-2') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-11' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-11' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-11')}  placeholder="Technologies" name="Include" value="Business Services"  type="checkbox" />
                                {'Business Services'}
                            {IsChecked(checkedFilter, 'technologies-11') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-7' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-7' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-7')}  placeholder="Technologies" name="Include" value="Cloud Services"  type="checkbox" />
                                {'Cloud Services'}
                            {IsChecked(checkedFilter, 'technologies-7') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-12' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-12' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-12')}  placeholder="Technologies" name="Include" value="Compliance"  type="checkbox" />
                                {'Compliance'}
                            {IsChecked(checkedFilter, 'technologies-12') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-5' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-5' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-5')}  placeholder="Technologies" name="Include" value="Connectivity"  type="checkbox" />
                                {'Connectivity'}
                            {IsChecked(checkedFilter, 'technologies-5') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-14' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-14' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-14')}  placeholder="Technologies" name="Include" value="Cyber Security"  type="checkbox" />
                                {'Cyber Security'}
                            {IsChecked(checkedFilter, 'technologies-14') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-9' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-9' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-9')}  placeholder="Technologies" name="Include" value="Hardware"  type="checkbox" />
                                {'Hardware'}
                            {IsChecked(checkedFilter, 'technologies-9') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-16' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-16' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-16')}  placeholder="Technologies" name="Include" value="Infrastructure"  type="checkbox" />
                                {'Infrastructure'}
                            {IsChecked(checkedFilter, 'technologies-16') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-4' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-4' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-4')}  placeholder="Technologies" name="Include" value="Memory & Storage"  type="checkbox" />
                                {'Memory & Storage'}
                            {IsChecked(checkedFilter, 'technologies-4') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-6' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-6' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-6')}  placeholder="Technologies" name="Include" value="Modules"  type="checkbox" />
                                {'Modules'}
                            {IsChecked(checkedFilter, 'technologies-6') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-13' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-13' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-13')}  placeholder="Technologies" name="Include" value="Platforms"  type="checkbox" />
                                {'Platforms'}
                            {IsChecked(checkedFilter, 'technologies-13') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-1' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-1' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-1')}  placeholder="Technologies" name="Include" value="Power & Batteries"  type="checkbox" />
                                {'Power & Batteries'}
                            {IsChecked(checkedFilter, 'technologies-1') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-15' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-15' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-15')}  placeholder="Technologies" name="Include" value="Sensors"  type="checkbox" />
                                {'Sensors'}
                            {IsChecked(checkedFilter, 'technologies-15') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-17' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-17' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-17')}  placeholder="Technologies" name="Include" value="Software"  type="checkbox" />
                                {'Software'}
                            {IsChecked(checkedFilter, 'technologies-17') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='technologies-3' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='technologies-3' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'technologies-3')}  placeholder="Technologies" name="Include" value="Testing & Quality Assurance"  type="checkbox" />
                                {'Testing & Quality Assurance'}
                            {IsChecked(checkedFilter, 'technologies-3') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}
