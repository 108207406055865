import React from "react";
import FilterEntry from './FilterEntry/FilterEntry.jsx';
import RestrictedPopover from '../../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
import { Collapse } from 'reactstrap'
import './AdvancedFilters.scss'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
export default class AdvancedFilters extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            arrowDown: true,
            showModal: false
        }
    }
    handleCollapseFilters = () => {
        this.setState({
            arrowDown: !this.state.arrowDown
        })
    }
    handleOpenFilters = () => {
        this.setState({
            arrowDown: true
        })
    }
    handleOpenModal = () => {
        this.setState({showModal: !this.state.showModal})
    }
    render() { 
        
        const { 
             handleAdvancedFilterChange, handleClearFilters, handleAddFilter, handleClearSpecificFilter, handleLocationChange, handleLocationInput, handleSelectLocation 
        } = this.props.aFHandle;
        const { 
            advancedFilters, filterFields,  user, locationOptions, locationFilter, id 
        } = this.props.aFValue;
        const {
            visible, title
        } = this.props
        if(visible === false) {
            return '';
        }
        const location = window.location.pathname
        const isLoggedIn = user ? true : false;
        const sectionName = title ? <h6>{title}</h6> : '';
        const {arrowDown} = this.state

        return (
            <div className="AdvancedFilters">
                <div className={`container-fluid advanced-form d-none d-md-block ${location.includes('/map') &&  'position-absolute' }`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {sectionName}

                                <Collapse isOpen={arrowDown} >
                                    {
                                    advancedFilters.map((filter, index) => {
                                    return <FilterEntry user={user} filterFields={filterFields} filterData={{filter, index}} handleAdvancedFilterChange={handleAdvancedFilterChange} handleClearSpecificFilter={handleClearSpecificFilter} key={index}
                                    locationOptions={locationOptions} locationFilter={locationFilter} handleLocationChange={handleLocationChange} handleLocationInput={handleLocationInput} handleSelectLocation={handleSelectLocation}   
                                    />
                                    })
                                    }
                                </Collapse>
                                {(advancedFilters.length > 1  && !isLoggedIn) || (advancedFilters.length > 0 && location.includes('/map') && !isLoggedIn)?
                                    <div className="row">
                                        <div className="col-11 control-filters d-flex">
                                            <span id="PopoverAdvanced"  className="btn add" onClick={() => { this.handleOpenModal(); this.handleOpenFilters();}}>
                                                Add mo
                                                {user?'':
                                                    <RestrictedPopover classN="top--10" showModal={this.state.showModal} handleCloseModal={this.handleOpenModal}  id={`AdvancedFilters-${id}`} />
                                                }re filters
                                            </span>
                                            <span className="btn clear" onClick={handleClearFilters}>Clear All Filters</span>
                                        </div>
                                        <div style={{color: "#1b7beb"}} className="display-flex align-center pointer justify-center col-1" onClick={this.handleCollapseFilters}>
                                            {
                                                arrowDown ?
                                                <FaArrowUp />: <FaArrowDown  />
                                            }
                                        </div>
                                    </div>
                                :
                                    <div className="row">
                                        <div className="col-11 control-filters d-flex">
                                            <span className="btn add" onClick={() => { handleAddFilter(); this.handleOpenFilters(); }}>Add more filters</span>
                                            <span className="btn clear" onClick={handleClearFilters}>Clear All Filters</span>
                                        </div>
                                        <div style={{color: "#1b7beb"}} className="display-flex align-center pointer justify-center col-1" onClick={this.handleCollapseFilters}>
                                            {
                                                arrowDown ?
                                                <FaArrowUp />: <FaArrowDown  />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}