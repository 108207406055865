import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './AddCode.scss'
export default class AddCode extends Component {
    constructor(props){
        super(props)
        this.state= {
            input:null,
        }
    }
    render() {
        
        const {formData, handleChange, handleSubmit, handleChangeDate, update, handleUpdate, handleReject} = this.props
        console.log(formData.expiration, 's')
        return (
            <div className="AddCode">
                <div className="container">
                    <div className="row addCode-title">
                        <div className="col-2">
                            Code
                        </div>
                        <div className="col-2">
                            Period of Membership
                        </div>
                        <div className="col-2">
                            Expiration Date
                        </div>
                        <div className="col-2">
                            Level
                        </div>
                        <div className="col-2">
                            Description
                        </div>
                    </div> 

                    <form onSubmit={update? handleUpdate :handleSubmit}>
                    <div className="row">
                        <div className="col-2">
                            <input value={formData.code} name="code" className="form-control" placeholder="Code" onChange={handleChange}/>
                        </div>
                        <div style={{display: 'flex'}} className="col-2">
                            <input value={formData.membership['days']} name="days" required  className="form-control" placeholder="days" onChange={handleChange}/>
                            <input value={formData.membership['hours']} name="hours" required  className="form-control" placeholder="hours" onChange={handleChange}/>
                        </div>
                        <div className="col-2">
                        <DatePicker
                            selected={formData.expiration}
                            onChange={handleChangeDate}
                            className="form-control"
                        />
                        </div>
                        <div className="col-2">
                            <select name="level"  placeholder="Level" required value={formData.level} className="form-control" onChange={handleChange}>
                                <option  value="corporate" >corporate</option>
                                <option  value="premium" >premium</option>
                                <option  value="pro" >pro</option>
                                <option  value="registered" >registered</option>
                                <option  value="" ></option>
                            </select>
                        </div>
                        <div className="col-2">
                            <input value={formData.description} name="description" required  className="form-control" placeholder="Description" onChange={handleChange}/>
                        </div>
                        <div className="col-2">
                            {
                                update? <div className="display-flex"> <button className={`btn btn-success add-code-button`} type="submit">Update</button> <button className={`btn btn-danger add-code-button`} onClick={handleReject}>Reject</button> </div>:
                                <button className={`btn btn-primary add-code-button`} haha={formData.code} type="submit">Add</button>
                            }
                            
                        </div>
                    </div>
                    </form>   
                </div>
            </div>
        )
    }
}
