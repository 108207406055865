import React from 'react';
import { FaTwitter } from 'react-icons/fa';
import FakeData from '../../../components/FakeData/FakeData';
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';
import moment from 'moment-timezone'
function TwitterPost(props) {
    var stillUtc = moment.utc(props.data.created_at).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return (
        <a className={`twitter-link position-relitive`} href={props.data.tweet_link} rel="noopener noreferrer" target="_blank">
            <div className={`${props.className} card twitter-card`}>
                <div className="card-body">
                    <p className="card-text">{props.data.text_full}</p>
                    
                    <span><b>{ local}</b></span>
                </div>
            </div>
            {
                
                props.show && <SubscriberContent  target={`twitter${props.id}`} hidden={!props.twitter}/> 
            }
            
        </a>  
    )
}

function renderFakePosts(){
    let arr=[];
    for(let i = 0; i < 10; i++){
        arr.push({
            created_at:FakeData({toFake:'random.words', returnJustValue: true}),
            text_full:FakeData({toFake:'random.words', returnJustValue: true}),
        })
    }
    return arr;
}

export default class TwitterPosts extends React.PureComponent{
    render() {
        let twitter = this.props.twitter ? this.props.twitter : renderFakePosts();
        return (
            <div className="card twitter">
                <div className="card-header">
                <div className="icon twitter"><FaTwitter style={{fontSize: '24px', color: 'white'}}/></div>
                <div className="title-holder"><p id="twitter" >Latest Tweets</p></div>
                </div>
                <div className="menu-divider"></div>
                {twitter.map((element, i) => {
                    return i % 2 && (i + 1 !== twitter.length)?
                        <TwitterPost key={i} id={i} show={true} twitter={this.props.twitter} className={!this.props.twitter ? 'blurred-box' : ''} data={element}/>:
                        <TwitterPost key={i} id={i} show={false} className={!this.props.twitter ? 'blurred-box' : ''} data={element}/>
                })}
            </div>
        )
    }
}