export default function advancedFilterUrlGenerator(
  query,
  advancedFilters,
  currentPage,
  history
) {
  const url = window.location.pathname.split("/")[1];
  const page = currentPage ? `&currentPage=${currentPage}` : "";
  let res1 = "";
  let res2 = "";
  const changeAnd = (item) => {
    return typeof item === "string" ? item.replace("&", "theAnd") : item;
  };
  let filters = advancedFilters
    .map((val, i) => {
      let attrNumber = i + 1;
      let prefix = i === 0 ? "" : "&";
      let data = `${prefix}filter${attrNumber}=${val.attribute}_${
        val.operator
      }_${changeAnd(val.value)}`;
      for (let i = 2; i < 6; i++) {
        if (val[`attribute${i}`] || val[`operator${i}`] || val[`value${i}`]) {
          let attribute = changeAnd(val[`attribute${i}`]);
          let operator = changeAnd(val[`operator${i}`]);
          let value = changeAnd(val[`value${i}`]);
          data = `${data}_${attribute}_${operator}_${value}`;
        }
      }
      return data;
    })
    .reduce((prev, curr) => (prev += curr), "");

  if (!filters && !query) {
    history.push(`/${url}`);
  } else {
    history.push(`/${url}/?q=${escape(query)}&${filters}${page}`);
  }

  return "";
}
