import React, { Component } from 'react'

import './Button.scss'

export default class Button extends Component {
    render() {
        const {name, name2, type, handlePlans, selected, bottom} = this.props
        const buttonType = bottom? type === "selected-registered" ? "background-orange" : "background-blue" : `con-${type}`
        return (
            <div className="Button">
               <button onClick={() => handlePlans(type)}  className={`button py-3 ${buttonType}`}><p className="p-0 m-0 h6">{name}</p></button> 
            </div>
        )
    }
}
