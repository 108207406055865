import React, { Component } from "react";
import CorpEcoStats from "./CorpEcoStats";
import "./CorpEcoStats.scss";
import fakeEcoData from "../assets/fakeEcoData";
import { Redirect } from "react-router";
import GeneratedApi from "../components/functions/generatedApi";

export default class CorpEcoStatsContainer extends Component {
  state = {
    title: "",
    subTitle: "",
    dataRange: "",
    results: [],
    range: "monthly",
    fakeData: false,
    limitReached: false,
    loading: false,
    sort: {
      attribute: "rank",
      how: "asc",
    }, // not used
  };
  handleRangeChange = (event) => {
    const query = {
      reportType: "ecosystems",
      range: event.target.value,
    };
    this.setState({
      range: event.target.value,
    });
    this.getData(query);
  };

  getData = (query) => {
    if (this.props.user) {
      this.setState({ loading: true });

      GeneratedApi("", true, true)
        .post("/coereports", query)
        .then((res) => {
          let { title, subTitle, results, dataRange } = res.data.body.data;
          if (results.length === 5) {
            results = results.concat(fakeEcoData.slice(0, 5));
          }
          this.setState({
            title,
            subTitle,
            dataRange,
            results,
            fakeData: false,
            loading: false,
          });
        })
        .catch((error) => {
          if (error.response.status === 429) {
            this.setState({ limitReached: true });
            return false;
          }
          console.log(error);
        });
    } else {
      this.setState({
        title: "Company Ecosystem Stats",
        subTitle: "",
        dataRange: "",
        results: fakeEcoData,
        fakeData: true,
      });
    }
  };
  handleSortChange = (attribute, how) => {
    //    const { limit, start } = this.state.search;
    //    const { q } = this.state;
    //     GeneratedApi("", true, true).post('/companies/gridView', {
    //         "search": {
    //           "q": q,
    //           "start": start,
    //           "limit": limit
    //         },
    //         "filters": this.state.filters,
    //         "sort": {
    //             attribute, how
    //         }
    //     })
    //     .then((response) => {
    //       response = response.data.body.data;
    //       response.loading = false;
    //       this.setState(
    //           {
    //               search: response
    //           }
    //       )
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //     this.setState({
    //         sort: {
    //             attribute, how
    //         }
    //     });
    // }
  };
  handlePageChange = (offset) => {
    // const { limit } = this.state.search;
    // const { q, sort, filters } = this.state;
    // GeneratedApi("", true, true).post('/companies/gridView', {
    //     "search": {
    //       "q": q,
    //       "start": offset,
    //       "limit": limit
    //     },
    //     filters,
    //     sort
    // })
    // .then((response) => {
    //   response = response.data.body.data;
    //   response.loading = false;
    //   this.setState(
    //       {
    //           search: response
    //       }
    //   )
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };
  componentDidMount() {
    const query = {
      reportType: "ecosystems",
      range: "monthly",
    };
    this.getData(query);
  }
  render() {
    if (this.state.limitReached) {
      return <Redirect to="/error" />;
    }
    return (
      <>
        <CorpEcoStats
          loading={this.state.loading}
          user={this.props.user}
          data={this.state}
          handleSortChange={this.handleSortChange}
          handleRangeChange={this.handleRangeChange}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}
