import React, { PureComponent } from 'react';
import SubscriberContent from '../../../../components/SubscriberContent/SubscriberContent';
import ShowValue from './ShowValue';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import moment from 'moment-timezone'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';

export default class Conferences extends PureComponent {
    constructor(props){
        super(props)
        this.state = {
            years : moment().year() - 1,
            open: false
        }
    }

    handleOpen = () => {
        this.setState({open: !this.state.open})
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        
        this.setState({years: value})
    }

    render() {
        const thisYears = moment().format('YYYY')
        const {conferences, coeIsFaked, total} = this.props;
        let confTableRows=conferences.map((conf,i)=>{
            let typeWyear=()=>{
                const index_2years = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears - 2));
                const index_1years = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears - 1));
                const index_thisyears = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears));
                let cn_2years = '';
                let cn_1years = '';
                let cn_thisyears = '';
                if(index_2years > -1) {
                    if(conf.typeWithYear[index_2years].type && conf.typeWithYear[index_2years].year) {
                        cn_2years = conf.typeWithYear[index_2years].type;
                    } else if(conf.typeWithYear[index_2years].year) {
                        cn_2years = 'plain';
                    }
                }
                if(index_1years > -1) {
                    if(conf.typeWithYear[index_1years].type && conf.typeWithYear[index_1years].year) {
                        cn_1years = conf.typeWithYear[index_1years].type;
                    } else if(conf.typeWithYear[index_1years].year) {
                        cn_1years = 'plain';
                    }
                }
                if(index_thisyears > -1) {
                    if(conf.typeWithYear[index_thisyears].type && conf.typeWithYear[index_thisyears].year) {
                        cn_thisyears = conf.typeWithYear[index_thisyears].type;
                    } else if(conf.typeWithYear[index_thisyears].year) {
                        cn_thisyears = 'plain';
                    }
                }
                let block_2years = null;
                if(cn_2years === 'plain') {
                    block_2years = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_2years>-1?cn_2years==='plain'?'plain':'diamond':''}><span>{index_2years>-1?<ShowValue data={cn_2years} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_2years = <td className="text-center"><div className={index_2years>-1?cn_2years==='plain'?'plain':'diamond':''}><span>{index_2years>-1?<ShowValue data={cn_2years} user={this.props.user}/>:''}</span></div></td>;
                }
                let block_1years = null;
                if(cn_1years === 'plain') {
                    block_1years = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_1years>-1?cn_1years==='plain'?'plain':'diamond':''}><span>{index_1years>-1?<ShowValue data={cn_1years} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_1years = <td className="text-center"><div className={index_1years>-1?cn_1years==='plain'?'plain':'diamond':''}><span>{index_1years>-1?<ShowValue data={cn_1years} user={this.props.user}/>:''}</span></div></td>;
                }
    
                let block_thisyears = null;
                if(cn_thisyears === 'plain') {
                    block_thisyears = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_thisyears>-1?cn_thisyears==='plain'?'plain':'diamond':''}><span>{index_thisyears>-1?<ShowValue data={cn_thisyears} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_thisyears = <td className="text-center"><div className={index_thisyears>-1?cn_thisyears==='plain'?'plain':'diamond':''}><span>{index_thisyears>-1?<ShowValue data={cn_thisyears} user={this.props.user}/>:''}</span></div></td>;
                }
                
                
                return(
                    <>
                        {block_2years}
                        {block_1years}
                        {block_thisyears}
                    </>
                )
               
                
    
            }
            return (
                <tr key={i}>
                <td><a target="_blank" href={`/companies/?q=&filter=Participant_Of_${conf.name.replace("&", "and")}_Year_Equals_${conf.typeWithYear[conf.typeWithYear.length-1].year}&limit=10`}>{conf.name}</a></td>
                 {typeWyear()}
                </tr>
            )
        })

        let confTableRowsMobile=conferences.map((conf,i)=>{

            let typeWyear=()=>{
                const index_2years = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears - 2));
                const index_1years = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears - 1));
                const index_thisyears = conf.typeWithYear.findIndex(x=>Number(x.year) === Number(thisYears));
                let cn_2years = '';
                let cn_1years = '';
                let cn_thisyears = '';
                if(index_2years > -1) {
                    if(conf.typeWithYear[index_2years].type && conf.typeWithYear[index_2years].year) {
                        cn_2years = conf.typeWithYear[index_2years].type;
                    } else if(conf.typeWithYear[index_2years].year) {
                        cn_2years = 'plain';
                    }
                }
                if(index_1years > -1) {
                    if(conf.typeWithYear[index_1years].type && conf.typeWithYear[index_1years].year) {
                        cn_1years = conf.typeWithYear[index_1years].type;
                    } else if(conf.typeWithYear[index_1years].year) {
                        cn_1years = 'plain';
                    }
                }
                if(index_thisyears > -1) {
                    if(conf.typeWithYear[index_thisyears].type && conf.typeWithYear[index_thisyears].year) {
                        cn_thisyears = conf.typeWithYear[index_thisyears].type;
                    } else if(conf.typeWithYear[index_thisyears].year) {
                        cn_thisyears = 'plain';
                    }
                }
                let block_2years = null;
                if(cn_2years === 'plain') {
                    block_2years = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_2years>-1?cn_2years==='plain'?'plain':'diamond':''}><span>{index_2years>-1?<ShowValue data={cn_2years} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_2years = <td className="text-center"><div className={index_2years>-1?cn_2years==='plain'?'plain':'diamond':''}><span>{index_2years>-1?<ShowValue data={cn_2years} user={this.props.user}/>:''}</span></div></td>;
                }
                let block_1years = null;
                if(cn_1years === 'plain') {
                    block_1years = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_1years>-1?cn_1years==='plain'?'plain':'diamond':''}><span>{index_1years>-1?<ShowValue data={cn_1years} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_1years = <td className="text-center"><div className={index_1years>-1?cn_1years==='plain'?'plain':'diamond':''}><span>{index_1years>-1?<ShowValue data={cn_1years} user={this.props.user}/>:''}</span></div></td>;
                }
    
                let block_thisyears = null;
                if(cn_thisyears === 'plain') {
                    block_thisyears = <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Company did not exhibit or sponsor this event according to our records."
                  }><td className="text-center"><div className={index_thisyears>-1?cn_thisyears==='plain'?'plain':'diamond':''}><span>{index_thisyears>-1?<ShowValue data={cn_thisyears} user={this.props.user}/>:''}</span></div></td></Tippy>
                } else {
                    block_thisyears = <td className="text-center"><div className={index_thisyears>-1?cn_thisyears==='plain'?'plain':'diamond':''}><span>{index_thisyears>-1?<ShowValue data={cn_thisyears} user={this.props.user}/>:''}</span></div></td>;
                }
                
                if(Number(this.state.years) === Number(thisYears - 2)){
                    return (
                        block_2years
                    )
                }else if(Number(this.state.years) === Number(thisYears - 1)){
                    return(
                       block_1years
                    )
                }else if(Number(this.state.years) === Number(thisYears)){
                    return(
                       block_thisyears
                    )
                }else{
                    return (
                        block_1years
                    )
                }
               
                
    
            }
            return (
                <tr key={i}>
                <td><a target="_blank" href={`/companies/?q=&filter=Participant_Of_${conf.name.replace("&", "and")}_Year_Equals_${conf.typeWithYear[conf.typeWithYear.length-1].year}&limit=10`}>{conf.name}</a></td>
                 {typeWyear()}
                </tr>
            )
        })


    return(
        <div className="card-holder">
            <div className={`row conferences d-none d-md-flex ${coeIsFaked && total === 9?'blurred-box':''}`}>
                <div className="col-2">
                    <span className="circle blue">{total}</span>
                    <p className="margin-negative-r-10">Conferences</p>
                </div>
                <div className={`col-10 ${coeIsFaked?'blurred-box':''}`}>
                    <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Conference</th>
                        <th className="text-center" scope="col">{thisYears - 2}</th>
                        <th className="text-center" scope="col">{thisYears - 1}</th>
                        <th className="text-center" scope="col">{thisYears}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {confTableRows}
                    </tbody>
                    </table>
                </div>
            </div>
            <div className={`row conferences no-padding d-block d-md-none ${coeIsFaked && total === 9?'blurred-box':''}`}>
                <div className="col-12 iotIndustry-align">
                    <div className="row" style={{width: "100%"}}>
                        <div className="col-4"><span className="circle blue">{total}</span></div>
                        <div className="col-6"><p>Conferences</p></div>
                        <div onClick={this.handleOpen} className="col-2">{this.state.open?  <FaCaretUp /> : <FaCaretDown />}</div>
                    </div>
                </div>
                <div className={`col-12 no-padding ${coeIsFaked?'blurred-box':'' } ${this.state.open? '':'not-display'}`}>
                    <table className="table no-border">
                    <thead>
                        <tr className="d-flex w-100">
                        <th scope="col" className="vertical-align">Conference</th>
                        <th scope="col" className="margin-right">
                            <select name="level"  placeholder="Level" required value={this.state.years} className="form-control" onChange={this.handleChange}>
                                <option name={thisYears - 2}  value={thisYears - 2}>{thisYears - 2}</option>
                                <option name={thisYears - 1} value={thisYears - 1} >{thisYears - 1}</option>
                                <option name={thisYears} value={thisYears} >{thisYears}</option>
                            </select>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {confTableRowsMobile}
                    </tbody>
                    </table>
                </div>
            </div>
            <div className="subs-top-30" >
                <SubscriberContent  target="conference" hidden={coeIsFaked}/>
            </div>
            
        </div>
    )
    }
}

