import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa'
export default class TotalFounding extends Component {
    render() {

        const { checkedFilter, handleFilterCheckbox, IsChecked } = this.props
        return (
            <div className='side-padding-15'>
                <label className="custom-control-label" htmlFor='founding-1' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-1' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-1')}  placeholder="Total Funding" name="Less than" value="< $1M"  type="checkbox" />
                                {'< $1M'}
                            {IsChecked(checkedFilter, 'founding-1') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-2' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-2' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-2')} placeholder="Total Funding" name="Equal to" value="$1M - $10M"  type="checkbox" />
                                {'$1M - $10M'}
                            {IsChecked(checkedFilter, 'founding-2') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-3' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-3' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-3')} placeholder="Total Funding" name="Equal to" value="$11M - $25M"  type="checkbox" />
                                {'$11M - $25M'}
                            {IsChecked(checkedFilter, 'founding-3') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-4' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-4' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-4')}  placeholder="Total Funding" name="Equal to" value="$26M - $100M"  type="checkbox" />
                                {'$26M - $100M'}
                            {IsChecked(checkedFilter, 'founding-4') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-5' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-5' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-5')} placeholder="Total Funding" name="Equal to" value="$101M - $250M"  type="checkbox" />
                                {'$101M - $250M'}
                            {IsChecked(checkedFilter, 'founding-5') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-6' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-6' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-6')} placeholder="Total Funding" name="Equal to" value="$251M - $1B"  type="checkbox" />
                                {'$251M - $1B'}
                            {IsChecked(checkedFilter, 'founding-6') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='founding-7' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='founding-7' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'founding-7')} placeholder="Total Funding" name="Greater than or equal to" value="> $251M"  type="checkbox" />
                                {'> $1B'}
                            {IsChecked(checkedFilter, 'founding-7') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}
