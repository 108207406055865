import React, { Component } from 'react'
import Title from './Title.jsx';
import moment from 'moment-timezone'
import AddtoCalendarDropdown from '../../components/AddToCalendarDropdown/AddtoCalendarDropdown'
import getTimezoneNumber from '../../utils/getTimezoneNumber'
import './Highlights.scss';

export default class Highlights extends Component {

  render() {
    const address = this.props.location.address;

    const location = Object.assign([], address.street1&&{0:`${address.street1}`},
      address.city&&address.street1?{1:`, ${address.city}`}:address.city&&{1:`${address.city}`}, 
      address.stateCode&&(address.street1 || address.city)?{2:`, ${address.stateCode}`}:address.stateCode&&{2:`${address.stateCode}`},
      address.zipCode&&(address.street1 || address.stateCode || address.city)?{3:`, ${address.zipCode}`}:address.zipCode&&{3:`${address.zipCode}`},
      address.country&&(address.street1 || address.stateCode || address.city || address.zipCode)?{3:`, ${address.country}`}:address.country&&{3:`${address.country}`}).join('')
      
    const startTime = moment.unix(this.props.time.startTime)
    const endTime = moment.unix(this.props.time.endTime);
    let time = "";
    const timeZone = endTime.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endTime.format('Z'))}[)]`)
    if(startTime.isSame(endTime, 'day')) {
      time = (
        <div className="row pb-16 datetime">
        <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}<br/>{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
      );
    } else if(startTime.isSame(endTime, 'month')) {
      time = (
        <>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}</div>
        </div>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date single">{endTime.format('MMM')}<br/>{endTime.format('DD')}</div></div>
          <div className="col-8 time">{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
        </>
      );
    } else {
      time = (
        <>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{startTime.format('MMM')}<br/>{startTime.format('DD')}</div></div>
          <div className="col-8 time">{startTime.format('hh:mm A')} {timeZone}</div>
        </div>
        <div className="row pb-16 datetime">
          <div className="col-4"><div className="date">{endTime.format('MMM')}<br/>{endTime.format('DD')}</div></div>
          <div className="col-8 time">{endTime.format('hh:mm A')} {timeZone}</div>
        </div>
        </>
      );
    }
    const duration = moment.duration(endTime.diff(startTime)).asHours().toString()
    const event = {
      description: `For info on this event, click here: \n<a href="https://www.iotnation.com/event/${this.props.eventId}">www.iotnation.com/event/${this.props.eventId}</a>
       \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events">www.iotnation/events</a>`,
      duration,
      endDatetime: startTime.isSame(endTime, 'day')? endTime.utc().format('YYYYMMDDTHHmmssZ'): endTime.add(1, 'days').format('YYYYMMDD'),
      location,
      startDatetime: startTime.isSame(endTime, 'day')? startTime.utc().format('YYYYMMDDTHHmmssZ'): startTime.utc().format('YYYYMMDD'),
      title: this.props.title,
    }
    return (
      <div style={{width: "278px"}}>
        <Title src="/images/calendar-2.svg" name="When & Where" />
        <div className="side-block-border">
          <div className="container pl-24 pr-24 pb-24 pt-24">
            <p style={{fontSize: '13px', fontStyle: 'italic'}}>Event is shown in your local time.</p>
            {time}
            {location&&
            <div className="row pb-16">
              <div className="col-2">
                <img className="image small-icon"  src="/images/pin-3.svg" alt=""/>
              </div>
              <div className="col-10 pl-0">
                {
                  this.props.locationAddress? 
                    <a className="adress" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${this.props.locationUrl}`}> 
                      <span>{location}</span>
                    </a>
                  :location
                }
              </div>
            </div>
            }
            <div className="button-padding">
              <AddtoCalendarDropdown
                event={event}
                className="AddtoCalendarDropdown"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
