
export default function selectLocation(value, advancedFilters) {
    let array = []
    const Location = advancedFilters.find((filter, i)=> filter.attribute ==='Location' &&filter.value )
        
        if(value){
            const newArrayValue = {attribute: "Location", operator: "Is near", value: value, attribute2: 'Distances', operator2: 'Within', value2: '100'} 
            array = {
                locationFilter: { value: value, label: value },
                locationCount: 1,
                LocationArray: newArrayValue
            }

            if(Location){
                array = {
                    ...array,
                    advancedFilters: advancedFilters.map(
                        obj => (obj.attribute === 'Location')? {...obj, value: value.label, value2: obj.value2 && obj.value2 !== 'undefined' ? obj.value2 : '100' }: obj
                    )
                }
            }else{
                array = {
                    ...array,
                    advancedFilters: [...advancedFilters, newArrayValue]
                } 
            }
        }else{
            array = {
                locationFilter:'',
                LocationArray: '',
                locationCount: 0,
                advancedFilters: advancedFilters.map( obj => (obj.attribute !== 'Location') && obj ).filter(Boolean)
            }
        }
    return (
        {...array}
    )
}
