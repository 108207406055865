import React, { PureComponent } from 'react'
import './EventsItem.scss';
import {  FaSortDown, FaSortUp } from 'react-icons/fa';
import { Link, withRouter } from 'react-router-dom'
// import moment from 'moment-timezone';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import "../labels.scss";
// import RestrictedPopover from '../../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
var pixelWidth = require('string-pixel-width');
class EventsItem extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            likeData: null,
            showContent: false,
            showMore: false,
            showModal: false,
            showModalMobile: false,
            thumbsUp: false,
            likeLength: 0,
        }
       
      }
    state = {
        moreInfo: false,
        moreTags: false,
        randPicNumber:Math.round(Math.random())
    }
    async componentDidMount() {
        // const q = {"dataId":this.props.data&&this.props.data.usecaseId ,"dataType":"event"}
        // this.props.instance2.post('/getLikeInfo', q)
        //     .then((response) => {  
        //         response = response.data;
        //         this.setState({
        //             likeData:response,
        //             thumbsUp: response.currentUserLike,
        //             likeLength: response.userList.length,
        //         });
                
        //     })
        // .catch((error) => {
        //     console.log(error);
        //     if(error.response) {
        //         if(error.response.status === 429) {
                    
        //             return false;
        //         }
        //     }
        // });
        
      }
    handleShowMore = () => this.setState(({showMore})=>({showMore:!showMore}))
    handleMoreInfo = () => this.setState(({moreInfo})=>({moreInfo:!moreInfo}))
    handleMoreTags = () => this.setState()
    handleShowContent = () => this.setState(({showContent})=>({showContent:!showContent}))
    toggleModal = () => {this.setState({showModal: !this.state.showModal})}
    toggleModalMobile = () => {this.setState({showModalMobile: !this.state.showModalMobile})}
    likeHandle = () => {
        if(!this.props.user){
          this.setState({
              showModal: true,
              showModalMobile: true
            })
        }else{
          const like = {"dataId":this.props.data&&this.props.data.usecaseId ,"dataType":"event", "operation":"LIKE"}
          const unlike = {"dataId":this.props.data&&this.props.data.usecaseId ,"dataType":"event", "operation":"INDIFFERENT"}
          if(this.state.thumbsUp ){
            if(this.state.likeLength < 1){
                this.setState({
                    thumbsUp: false,
                    likeLength: 0
                })
            }else{
                this.setState({
                    thumbsUp: false,
                    likeLength: this.state.likeLength - 1
                })
            }
            this.props.instance2.post('/updateLikeInfo', unlike)
            .then((response) => {
                response = response.data;
                this.setState({
                  likeData: response,
                });
                
            })
            .catch((error) => {
                if(this.state.likeLength < 1){
                    this.setState({
                        thumbsUp: false,
                        likeLength: 0
                    })
                }else{
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength + 1
                    })
                }
                console.log(error.response);
                if(error.response) {
                    if(error.response.status === 429) {
                      return false;
                    }
                }
            });
          }else{
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength + 1
            })
            this.props.instance2.post('/updateLikeInfo', like)
              .then((response) => {
                  response = response.data;
                  this.setState({
                    likeData: response,
                  });
                  
              })
              .catch((error) => {
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength - 1
                    })
                  console.log(error.response);
                  if(error.response) {
                      if(error.response.status === 429) {
                          
                          return false;
                      }
                  }
              });
          }
        
       }
      }
      
    render() {    
        // const addresss = this.props.data.location&&this.props.data.location.address
        // const locationn = addresss&&Object.assign([], addresss.street1&&{0:addresss.street1}, addresss.city&&{1:addresss.city} ,addresss.stateCode&&{2:addresss.stateCode}, addresss.zipCode&&{3:addresss.zipCode}).join(', ')
        // const startTimee = this.props.data.date&&moment.unix(this.props.data.date.startTime)
        // const endTimee = this.props.data.date&&moment.unix(this.props.data.date.endTime)
        // const duration = this.props.data.date&&moment.duration(endTimee.diff(startTimee)).asHours().toString()
        
        const {  moreTags } = this.state
        let { 
            descriptions:{ shortDescription, longDescription } = {},
            usecaseId,
            images: { mainImage } = {},
            tags:{ horizontals = [], verticals = [] } = {},
            title,
            filters,
            organizations 
         } = this.props.data;
         const description = shortDescription ? shortDescription : longDescription;
        //  const event = {
        //     description: `For info on this event, click here: \n<a href="https://www.iotnation.com/usecase/${usecaseId}">www.iotnation.com/usecase/${usecaseId}</a>
        //     \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events/list">www.iotnation/events/list</a>`,
        //     duration,
        //     endDatetime: endTimee&&endTimee.utc().format('YYYYMMDDTHHmmssZ'),
        //     locationn,
        //     startDatetime: startTimee&&startTimee.utc().format('YYYYMMDDTHHmmssZ'),
        //     title: this.props.data.title,
        //   }
        const tags = [ ...horizontals, ...verticals ].filter(function (el) {
            return el !== '';
          });
        return (
            <div className={`EventsItem position-relitive`}>
                {/* {this.props.user?'':
                    <RegisterPopover pathname={window.location.pathname} isOpen={this.state.showModal} toggle={this.toggleModal} />
                }
                <SubscriberContent  target="UseCaseListEventItem" hidden={!this.props.user}/> */}
                <div className='event-body'>
                    <div className="row no-gutters">
                        


                        <div className=" d-none d-md-block">
                            {        
                                <Link className="event-image" to={{
                                    pathname:`/usecase/${usecaseId}`,
                                    state: { prevQuery: window.location.href, filters, usecaseId  }
                                }}>
                                    <img src={mainImage&&mainImage.source?mainImage.source:''} alt=""/>
                                </Link>
                            }
                        </div>
                        <div className="col-md-2 d-block d-md-none">
                            {        
                                <Link className="event-image" to={{
                                    pathname:`/usecase/${usecaseId}`,
                                    state: { prevQuery: window.location.href, filters, usecaseId  }
                                }}>
                                    <img src={mainImage&&mainImage.source?mainImage.source:''} alt=""/>
                                </Link>
                            }
                        </div>


                        
                        <div className="col general-info">
                            <div className="row no-gutters align-items-center">
                                <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                <div className="event-title margin-bottom-8">
                                    <Link to={{
                                        pathname:`/usecase/${usecaseId}`,
                                        state: { prevQuery: window.location.href, filters, usecaseId },
                                    }}>
                                        {title&&title.mainTitle}
                                    </Link>   
                                </div>
                                </div>
                                {/* <div className="d-none d-md-block col-2 text-right">
                                    <div className="likes" style={{display: "flex"}}>
                                        <div className="likes">
                                            {this.state.likeLength}
                                        </div>
                                        <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                                        {this.props.user?'':
                                            <RestrictedPopover classN="d-none d-md-block" showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={`Like-events2${this.props.id}`} />
                                            }
                                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                        </Button>
                                    </div> 
                                </div> */}
                            </div>
                            <div className="d-none d-md-flex row no-gluters align-items-center">
                                <div className="col-md-9 tag-col">
                                    <span className="tags">
                                    {tags.slice(0, moreTags?(tags.length):3).map((tag, i)=>
                                        tag&&
                                        (
                                            !moreTags && i === 2 && tags.length - (i) > 0
                                            ?
                                            
                                            <Tippy key={i} content={
                                        <ul data-simplebar>
                                            {tags.slice(2,tags.length).map((tag, i) => 
                                                tag&& <button key={i} className="btn tag capitalize" style={{marginBottom: '8px'}}>{tag}</button>
                                            )}
                                        </ul>
                                    } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i} onClick={this.handleMoreTags} className="btn tag capitalize more">{`+${tags.length - (i)}`}</button></Tippy>

                                            :<button key={i} className="btn tag capitalize">{tag}</button>
                                        )
                                    )}
                                    </span>     
                                </div>
                                <div className="col-md-3  company-icon d-flex">   
                                    <div className="d-flex margin-right">
                                    {organizations.slice(0, moreTags?(organizations.length):2).map((tag, i)=>
                                        tag&&
                                        (
                                            !moreTags && i === 1 && organizations.length - (i) > 0
                                            ?
                                            ''

                                            :<a key={i} target="_blank" href={`/company/${tag.id}`}><button className="btn tag-companies"><img src={tag.logo} alt=""/></button></a> 
                                        )
                                    )}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex d-md-none row no-gluters align-items-center">
                                <div className="col-12">
                                    <span className="tags display-flex">
                                        <div style={{color: "#1b7beb", marginRight: "5px"}}>Tags:</div> 
                                        {tags.slice(0, moreTags?(tags.length):3).map((tag, i)=>
                                            i>0?
                                            <span className="capitalize" style={{color: "#838da4"}} key={i}>{`, ${tag}`}</span>:
                                            <span className="capitalize" style={{color: "#838da4"}} key={i}>{`${tag}`}</span>
                                        )}
                                    </span>  
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" style={{margin: "auto 0", paddingRight: '10px'}}>
                                    <div className={this.state.showContent?'toggle':'toggle hidden'}><p className="text-style">
                                        {description&&description}
                                    </p></div>
                                    
                                    {pixelWidth(description&&description ,{ size: 14 }) >1100?
                                        <span onClick={this.handleShowContent} className="pointer text-decoration display-flex arrow">{this.state.showContent? <FaSortUp style={{marginTop: "3px"}} />:<FaSortDown />}</span>
                                    :''}
                                </div>
                                
                                <div className="col-3 pl-36 flex-end d-none d-md-flex">   
                                    <div className="d-flex margin-right">
                                    {organizations.slice(0, moreTags?(organizations.length):2).map((tag, i)=>
                                        tag&&
                                        (
                                            !moreTags && i === 1 && organizations.length - (i) > 0
                                            ?
                                            
                                            <Tippy placement="bottom" key={i} content={
                                                <ul data-simplebar>
                                                    {organizations.slice(1,organizations.length).map((tag, i) => 
                                                        tag&& <a key={i} target="_blank" href={`/company/${tag.id}`}><button className="btn tag-companies margin-right-10"><img height="48px" src={tag.logo} alt=""/></button></a> 
                                                    )}
                                                </ul>
                                            } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i} onClick={this.handleMoreTags} className="btn tag-companies">{`+${tags.length - (i)}`}</button></Tippy>

                                            :'' 
                                        )
                                    )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex d-md-none row no-gluters align-items-center">
                                <div className="col-12">   
                                    {organizations.slice(0, moreTags?(organizations.length):2).map((tag, i)=>
                                        tag&&
                                        (
                                            !moreTags && i === 2 && organizations.length - (i) > 0
                                            ?
                                            
                                            <Tippy placement="bottom" key={i} content={
                                                <ul data-simplebar>
                                                    {organizations.slice(1,organizations.length).map((tag, i) => 
                                                        tag&& <a key={i} target="_blank" href={`/company/${tag.id}`}><button className="btn tag-companies margin-right-10"><img height="48px" src={tag.logo} alt=""/></button></a> 
                                                    )}
                                                </ul>
                                            } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i} onClick={this.handleMoreTags} className="btn tag-companies">{`+${tags.length - (i)}`}</button></Tippy>

                                            :<a key={i} target="_blank" href={`/company/${tag.id}`}><button className="btn tag-companies mr-10"><img src={tag.logo} alt=""/></button></a> 
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="border"></div>
                            <div className="display-flex" style={{paddingTop: "10px", alignItems: "center"}}>
                                <div className="show-more-info">
                                <Link to={{
                                    pathname:`/usecase/${usecaseId}`,
                                    state: { prevQuery: window.location.href, filters, usecaseId  }
                                }}>
                                    Show More Info
                                </Link>
                                </div>
                                {/* <div className="likes d-flex d-md-none">
                                    <div className="likes">
                                        {this.state.likeLength}
                                    </div>
                                    <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                                        {this.props.user?'':
                                            <RestrictedPopover classN="d-block d-md-none" showModal={this.state.showModalMobile} handleCloseModal={this.toggleModalMobile} id={`Like-events1${this.props.id}`} />
                                        }
                                        {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                    </Button>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(EventsItem)