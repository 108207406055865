import React, { Component } from 'react'
import Campaign from './Campaign'
import Card from './components/Card'
import array from '../content/distancingtogether.json';
import './Campaign.scss'
export default class CampaignContainer extends Component {
    render() {
        return (
            <div className="Campaign">
                <Campaign>
                    <div className="row p-t-64">
                        {
                            array.map((item, key) =>
                                <Card 
                                    key={key} description={item.offerDescription} companyURL={item.companyURL} offerCode={item.offerCode} img={item.img} 
                                     name={item.companyName} redeemLink={item.offerURL}
                                    companyLink={item.companyLink} offer={item.offer}
                                />
                            )
                        }
                    </div>
                </Campaign>
            </div>
        )
    }
}
