import React from "react";
// import './Index.scss'
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";
import style from './Index.module.scss';
import indexJSON from "../content/homepage.json"
import SuccessfulEngagemanents from "./components/SuccessfulEngagemanents";
import Endorsements from "./components/Endorsements";
import SwapGallery from "./components/SwapGallery";

export default class Index extends React.Component {

  render() {

    return (
	<div className={style.root}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>IoT Nation’s IoT Ace Market Intelligence Tools</title>
		</Helmet>
		<div className="position-relative">
			<div className="custom-background background-blue-2">
				<div className="container row mx-auto pb-5">
					<div className="col-12 col-md-7 text-white my-3 d-flex">
						<div className="grid-h1 font-roboto my-auto">{indexJSON.title}</div>
					</div>
					<div className="col-12 col-sm-10 col-md-5 mx-auto d-flex">
						<img className={`my-3 w-100 ${style.main_img}`} src="/images/pc_with_phone.png" alt="main-img"/>
					</div>
				</div>
			</div>
			<div className={`w-100 ${style.suggestion_blocks}`}>
				<div className="d-flex container px-0">
					<div className="col-12 col-md-12 col-lg-12 col-xl-8 row mx-auto px-0">
						<div className="col-6 col-md-3 mb-3 mb-md-0">
							<Link className="custom-button color-black" to="/casestudies/sales">
								<div className={`${style.custom_color} rounded text-center py-2`}>
									<img height="40" width="40" src="/images/marketing.svg" alt="Sales"/>
									<div className="mt-2 font-weight-bold">Sales</div>
								</div>
							</Link>
						</div>
						<div className="col-6 col-md-3 mb-3 mb-md-0">
							<Link className="custom-button color-black" to="/casestudies/marketing">
								<div className={`${style.custom_color} rounded text-center py-2`}>
									<img height="40" width="40" src="/images/sales.svg" alt="Marketing"/>
									<div className="mt-2 font-weight-bold">Marketing</div>
								</div>
							</Link>
						</div>
						<div className="col-6 col-md-3 mb-3 mb-md-0">
							<Link className="custom-button color-black" to="/casestudies/businessdevelopment">
								<div className={`${style.custom_color} rounded text-center py-2`}>
									<img height="40" width="40" src="/images/people.svg" alt="Business Development"/>
									<div className="mt-2 font-weight-bold">Business Development</div>
								</div>
							</Link>
						</div>
						<div className="col-6 col-md-3 mb-3 mb-md-0">
							<Link className="custom-button color-black" to="/casestudies/investing">
								<div className={`${style.custom_color} rounded text-center py-2`}>
									<img height="40" width="40" src="/images/cash-hand.svg" alt="Investing and M&A"/>
									<div className="mt-2 font-weight-bold">Investing and M&A</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div className="container-fluid pt-2 pt-md-5">
			<div className="row mb-116 d-none d-md-block">
				<div className="col-12">
					<div className="row align-items-center">
						<div className="col-4 mt-3 offset-1">
							<div>
								<h3 className="font-archivo font-s-34 line-h-15 mb-3 color-dark-2">Identify & Prioritize Prospects</h3>
								<p className="font-roboto line-h-20 color-dark mb-5 h6">Quickly land your next deal with a major customer, client, partner, investor, or acquisition. Find prospects across verticals, use cases, and geographies. Boost your organization’s productivity and ROI to deliver great outcomes.</p>
							</div>
							<div>
							<Link to="/companies" className="custom-button background-blue d-inline-flex font-s-14 rounded-2 py-3 px-4">
								<div className="mx-3">Find Companies</div>
							</Link>
							</div>
						</div>
						<div className="col-6 offset-1 text-align-end mx-auto pr-0">
							<img className="w-100" src="/images/group-4.svg" alt="group-4"/>
						</div>
					</div>
				</div>
			</div>
			{/* shows only small screens (1st block)*/}
			<div className="row mb-116 d-md-none mt-3">
				<div className="col-12">
					<div className="row align-items-center">
						<div className="col-10 col-sm-6 text-align-end mx-auto pr-0">
							<img className="w-100" src="/images/group-4.svg" alt="group-4"/>
						</div>
						<div className="col-11 col-sm-5 offset-1">
							<div>
								<h3 className="font-archivo font-s-34 line-h-15 mb-3 color-dark-2">Identify & Prioritize Prospects</h3>
								<p className="font-roboto line-h-20 color-dark mb-5 h6">Quickly land your next deal with a major customer, client, partner, investor, or acquisition. Find prospects across verticals, use cases, and geographies. Boost your organization’s productivity and ROI to deliver great outcomes.</p>
							</div>
							<Link to="/companies" className="custom-button background-blue d-inline-flex font-s-14 rounded-2 py-3 px-4">
								<div className="mx-3">Find Companies</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
			{/* middle block */}
			<div className="row mb-116 find-investment-comparables">
				<div className="col-12">
					<div className="row align-items-center">
						<div className="col-10 col-sm-6 mx-auto pl-0">
							<img className="w-100" src="/images/group-7.svg" alt="group-5"/>
						</div>
						<div className="col-11 col-sm-5 offset-1">
							<div>
								<h3 className="font-archivo font-s-34 line-h-15 mb-3 color-dark-2">Research Contextual Info</h3>
								<p className="font-roboto line-h-20 color-dark mb-5 h6">Research prospects for the latest information on company statistics, news, events, ecosystem partnerships, conference participation, and organization memberships.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* shows only from md (3rd block)*/}
			<div className="row d-none d-md-block">
				<div className="col-12">
					<div className="row align-items-center">
						<div className="col-4 offset-1">
							<div>
								<h3 className="font-archivo font-s-34 line-h-15 mb-3 color-dark-2">Find Key Stakeholders & Contact Info</h3>
								<p className="font-roboto line-h-20 color-dark mb-5 h6">Quickly mobilize your deal by leveraging the integrated and continuously updated contacts database.</p>
							</div>
						</div>
						<div className="col-6 offset-1 mx-auto pl-0">
							<img className="w-100" src="/images/group-6.svg" alt="group-6"/>
						</div>
					</div>
				</div>
			</div>
			{/* shows only small screens (3rd block)*/}
			<div className="row d-md-none">
				<div className="col-12">
					<div className="row align-items-center">
						<div className="col-10 col-sm-6 offset-1 mx-auto pl-0">
							<img className="w-100" src="/images/group-6.svg" alt="group-6"/>
						</div>
						<div className="col-11 col-sm-5 offset-1">
							<div>
								<h3 className="font-archivo font-s-34 line-h-15 mb-3 color-dark-2">Find Key Stakeholders & Contact Info</h3>
								<p className="font-roboto line-h-20 color-dark mb-5 h6">Quickly mobilize your deal by leveraging the integrated and continuously updated contacts database.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<SwapGallery style={style} dataJSON={indexJSON.gallery} />

		<SuccessfulEngagemanents style={style} dataJSON={indexJSON.successfulEngagements} />
		<Endorsements style={style} dataJSON={indexJSON["endorsment-text"]} />
	</div>
    )
  }
}