import React, { Component } from 'react'
import Ambassadors from './Ambassadors'
import Card from './components/Card'
import array from '../content/ambassadors.json';
import './Ambassadors.scss'

export default class AmbassadorsContainer extends Component {
    render() {
        return (
            <div className="Ambassadors">
                <Ambassadors>
                    <div className="row p-t-64">
                        {
                            array.map((item, key) =>
                                <Card key={key} address={item.address} companyName={item.companyName}
                                    description={item.description} email={item.email} expertise={item.expertise} img={item.img} linkedinUrl={item.linkedinUrl}
                                    twitterUrl={item.twitterUrl} user={item.user}
                                />
                            )
                        }
                    </div>
                </Ambassadors>
            </div>
        )
    }
}
