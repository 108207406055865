import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Competitions from "./Competitions";
import ListView from "./components/ListView.jsx";
import EventsItem from "./components/EventsItem.jsx";
import Spinner from "../components/Spinner/Spinner";
import {
  getMainFilters,
  setFiltersQuery,
  isFiltersChanged,
} from "./functions/eventFilters";
import sortByFeatured from "./functions/sortByFeatured";
import { animateScroll as scroll } from "react-scroll";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import "../components/scss/breadcrumbs.scss";
import ScrollUpButton from "react-scroll-up-button";
import Filter from "./components/Filter.jsx";
import RegisterPopover from "../components/Modals/RegisterPopover/RegisterPopover";
import GeneratedApi from "../components/functions/generatedApi";

export default class CompetitionsContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      start: 0,
      limit: 10,
      count: 0,
      filters: [],
      searchInput: "",
      selectedAllEventTypes: true,
      focusedInputMobile: null,
      calendarChanged: false,
      focusedInput: null,
      limitReached: false,
      moreFilters: false,
      loading: true,
      dataLoaded: false,
      firstLoad: false,
      increasing: 0,
      modal: false,
      focusedInputTablet: null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onCalendarFocusChange = this.onCalendarFocusChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSelectedSegments = this.handleSelectedSegments.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleClearAllSegments = this.handleClearAllSegments.bind(this);
    this.handleSelectAllSegments = this.handleSelectAllSegments.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  loadData(data) {
    const filters = data && data.filters;
    this.setState(
      Object.assign(
        { dataLoaded: true },
        {
          limit: this.state.limit,
          filters:
            filters && filters.length > 0
              ? [
                  ...getMainFilters(filters),
                  ...filters.filter((filter) => !filter.mainAttribute),
                ]
              : getMainFilters(),
        }
      )
    );
    filters &&
      this.props.history.replace({ ...this.props.location, state: undefined });
  }

  onCalendarFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }
  onDatesChange({ startDate: dateEnd, endDate: dateStart }) {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute &&
        (filter.attribute === "dateStart" || filter.attribute === "dateEnd")
          ? {
              ...filter,
              useFilter: true,
              value:
                filter.attribute === "dateStart"
                  ? dateStart
                    ? dateStart.endOf("day").format()
                    : null
                  : dateEnd
                  ? dateEnd.startOf("day").format()
                  : null,
            }
          : filter
      ),
    }));
  }

  handleInput({ target: { value, name } }) {
    this.setState({ [name]: value });
  }

  handleSelectedSegments({ target: { checked, name } }) {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: checked
                ? filter.value.includes(name)
                  ? filter.value
                  : filter.value.concat([name])
                : filter.value.filter((item) => item !== name),
            }
          : filter
      ),
    }));
  }

  handleClearAllSegments() {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: [],
            }
          : filter
      ),
    }));
  }
  handleSelectAllSegments() {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: ["IoT", "AI", "VR/AR", "Blockchain"],
            }
          : filter
      ),
    }));
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.getEventsData();
    scroll.scrollToTop();
  }

  getEventsData = debounce(() => {
    if (!this._isMounted) return false;

    const { searchInput, start, limit, filters } = this.state;

    const query = {
      search: {
        q: searchInput,
        start,
        limit,
      },
      filters: setFiltersQuery(filters),
      sort: {},
    };
    GeneratedApi("", false, true)
      .post("/competitions/gridView", query)
      .then((response) => {
        const { searchResults, count } = response.data.body.data;
        if (this._isMounted) {
          this.setState({
            searchResults,
            count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 429) {
            console.log("limit reached");
            this._isMounted && this.setState({ limitReached: true });
            return false;
          }
        }
      });
  }, 300);

  componentDidMount() {
    if (!this.props.user && this.state.firstLoad) {
      setTimeout(() => {
        this.setState({ modal: true, firstLoad: false });
      }, 45000);
    }
    this._isMounted = true;

    this.loadData(this.props.location.state);
    this.setState(({ filters, selectedAllEventTypes }) => ({
      selectedAllEventTypes: filters.find((filter, i) =>
        filter.attribute === "eventType" && filter.value.length < 9
          ? false
          : filter.attribute === "eventType"
          ? true
          : null
      ),
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    isFiltersChanged(prevState.filters, this.state.filters) &&
      this.getEventsData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchMoreData = () => {
    this.setState(
      ({ count }) => ({ limit: this.state.limit + 10 }),
      this.getEventsData
    );
  };
  onCalendarFocusChangeMobile = (focusedInputMobile) => {
    this.setState({ focusedInputMobile });
  };
  onCalendarFocusChangeTablet = (focusedInputTablet) => {
    this.setState({ focusedInputTablet });
  };

  render() {
    const {
      searchInput,
      filters,
      moreFilters,
      count,
      limit,
      locationOptions,
      focusedInput,
      calendarChanged,
      selectedAllEventTypes,
      searchResults,
      loading,
      focusedInputMobile,
      focusedInputTablet,
      dataLoaded,
    } = this.state;
    // const Increase = () =>{
    //   this.setState({increasing: limit});
    //   return(true);
    // }

    if (!dataLoaded) return false;

    const selectedEventTypes = filters.find(
      (filter, i) => filter.attribute === "eventType" && filter.mainAttribute
    );

    const selectedSegments = filters.find(
      (filter, i) => filter.attribute === "eventSegment" && filter.mainAttribute
    );

    const locationFilter = filters.find(
      (filter, i) => filter.attribute === "Location" && filter.mainAttribute
    ).value;

    const dateStart = filters.find(
      (filter, i) => filter.attribute === "dateStart" && filter.mainAttribute
    ).value;

    const dateEnd = filters.find(
      (filter, i) => filter.attribute === "dateEnd" && filter.mainAttribute
    ).value;

    const linkLocation = this.props.location.state && this.props.location.state;
    const filtersLink =
      linkLocation && linkLocation.filters ? linkLocation.filters : filters;

    return (
      <>
        {this.props.user ? (
          ""
        ) : (
          <RegisterPopover
            pathname={window.location.pathname}
            isOpen={this.state.modal}
            toggle={this.toggleModal}
          />
        )}
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Events List
              </li>
            </ol>
          </nav>
        </div>
        <Competitions
          user={this.props.user}
          handleInput={this.handleInput}
          searchInput={searchInput}
          handleSearchSubmit={this.handleSearchSubmit}
        >
          <Filter
            data={{
              moreFilters,
              dateStart: dateEnd ? moment(dateEnd) : null,
              dateEnd: dateStart ? moment(dateStart) : null,
              selectedEventTypes,
              selectedSegments,
              locationFilter,
              locationOptions,
              focusedInput,
              focusedInputMobile,
              calendarChanged,
              selectedAllEventTypes,
              filters,
              count,
              limit,
              loading,
              filtersLink,
            }}
            handleInput={this.handleInput}
            searchInput={searchInput}
            handleSearchSubmit={this.handleSearchSubmit}
            handleMoreFilters={this.handleMoreFilters}
            onCalendarFocusChangeMobile={this.onCalendarFocusChangeMobile}
            onDatesChange={this.onDatesChange}
            onDateChangeStart={this.onDateChangeStart}
            onDateChangeEnd={this.onDateChangeEnd}
            handleSelectedEventTypes={this.handleSelectedEventTypes}
            handleSelectedAll={this.handleSelectedAll}
            handleSelectedSegments={this.handleSelectedSegments}
            handleSelectAllSegments={this.handleSelectAllSegments}
            handleLocationChange={this.handleLocationChange}
            handleShowAllEvents={this.handleShowAllEvents}
            handleLocationInput={this.handleLocationInput}
            handleSelectLocation={this.handleSelectLocation}
          />
          <div className="container">
            <p>Total results: {count}</p>
          </div>
          <ListView
            data={{
              moreFilters,
              dateStart: dateEnd ? moment(dateEnd) : null,
              dateEnd: dateStart ? moment(dateStart) : null,
              selectedEventTypes,
              selectedSegments,
              locationFilter,
              focusedInputTablet,
              locationOptions,
              focusedInput,
              calendarChanged,
              selectedAllEventTypes,
              filters,
              count,
              limit,
              loading,
            }}
            onCalendarFocusChange={this.onCalendarFocusChange}
            onDatesChange={this.onDatesChange}
            onCalendarFocusChangeTablet={this.onCalendarFocusChangeTablet}
            handleSelectedSegments={this.handleSelectedSegments}
            handleClearAllSegments={this.handleClearAllSegments}
            handleSelectAllSegments={this.handleSelectAllSegments}
          >
            {loading ? (
              <Spinner />
            ) : searchResults.length === 0 ? (
              <p className="mt-5 h5 text-center">
                No results found. Consider removing filters or modifying the
                date range.
              </p>
            ) : (
              <InfiniteScroll
                style={{
                  height: "100%",
                  overflow: "unset",
                }}
                dataLength={searchResults.length}
                next={this.fetchMoreData}
                hasMore={count >= limit ? true : false}
                loader={
                  searchResults.length < limit ? <Spinner /> : "No more results"
                }

                // scrollThreshold={'0.6'}
              >
                {searchResults.sort(sortByFeatured).map((item, i) => (
                  <EventsItem
                    id={i}
                    data={{ ...item, filters }}
                    user={this.props.user}
                    instance2={GeneratedApi("", true, true)}
                    key={i}
                  />
                ))}
              </InfiniteScroll>
            )}
            <ScrollUpButton
              ToggledStyle={{ top: "400px", backgroundColor: "#1B7BEB" }}
              style={{ top: "400px", backgroundColor: "#1B7BEB" }}
            />
          </ListView>
        </Competitions>
      </>
    );
  }
}
