import React from 'react'
import './CustomCheckBox.scss'

function CustomCheckBox(props) {
    return (
        <div className="CustomCheckBox">
            <input {...props} className="checkbox" type="checkbox"/>
            <div className="custom-overlay"/>
        </div>
    )
}

export default CustomCheckBox

