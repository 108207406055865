import React from "react";
import CompanyLeaderboard from './components/CompanyLeaderboard/CompanyLeaderboard';
import MarketTrend from './components/MarketTrend/MarketTrend';
import MarketResearchReports from './components/MarketResearchReports/MarketResearchReports';
import './SmartFinder.scss';
import FeaturedUseCases from './components/FeaturedUseCases/FeaturedUseCases';
import SmartCitiesNews from './components/SmartCitiesNews/SmartCitiesNews';
import SmartCitiesEvents from './components/SmartCitiesEvents/SmartCitiesEvents'
import { Link } from 'react-router-dom';

class SmartFinder extends React.Component {
    render() {
        return (
            <div className="Smart-Finder">
                <div className="w-100 d-inline-flex align-items-center welcome-screen">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-inline-flex align-items-center">
                                <div className="text-box">
                                    <h1>Smart Cities Solution Finder</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit vulputate eu pharetra nec, mattis ac neque.</p>
                                </div>
                            </div>             
                            <div className="col-6">
                                <img src="/images/iot-illustration-1.svg" alt=""/>
                            </div>            
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row  align-items-center justify-content-center  ad-full-w">
                        <div className="col-auto"><p>Ad</p></div>
                    </div>
                </div>
                <div className="container">        
                <div className="row">
                    <div className="col-9">
                        <CompanyLeaderboard></CompanyLeaderboard>
                        <div className="row align-items-center find-investments">
                            <div className="col-2 d-flex justify-content-start" ><img className="globe" src="/images/globe-1.svg" alt=""/></div>     
                            <div className="col-7"><div className="text-content">
                                <p className="title">Get access the full IoT Nation Database</p>
                                <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut </p>
                            </div> 
                            </div>     
                            <div className="col-3 d-flex justify-content-end"><button className="find-btn">Find Investments</button></div>          
                        </div>
                        <FeaturedUseCases></FeaturedUseCases>
                        <SmartCitiesNews></SmartCitiesNews>
                        <SmartCitiesEvents></SmartCitiesEvents>
                        <MarketResearchReports></MarketResearchReports>
                    </div>
                    <div className="col-3">
                        <MarketTrend></MarketTrend>
                        <div className="row ml-0 align-items-center justify-content-center  ad-mini-w">
                            <div className="col-auto"><p>Ad</p></div>
                        </div>
                        <div className="row ml-0 align-items-center justify-content-center  ad-mini-w">
                            <div className="col-auto"><p>Ad</p></div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="w-100 d-inline-flex align-items-center subscribe">
                    <div className="container">
                        <div className="row">
                        <div className="col-8 offset-sm-2">
                            <h2>Subscribe now free!</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <Link to="/iotace" className="btn">Subscribe</Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SmartFinder