import React from "react";

import Select from './Select.jsx';
import Inputs from './Inputs.jsx';

export default class AdditionalInput extends React.Component {
    

    render() {
        const { fieldOptions, setValue, setOperator, setAttribute, filterFields, dependsOn, isShowing } = this.props;
        const {attribute2, operator2,  value2} = this.props.initialValues
       
        if(fieldOptions.attributes) {
            const attribute=Object.keys(fieldOptions.attributes)[0]
            const operator=Object.keys(fieldOptions.attributes[attribute])[0]
            const value=fieldOptions.attributes[attribute][operator]
            return (
                <>
                    <Select fieldOptions={fieldOptions.attributes} initialValue={attribute2} isShown={isShowing.attribute2}  setQuery={setAttribute}/>
                    <Select fieldOptions={fieldOptions.attributes[attribute]} initialValue={ operator2 } isShown={isShowing.operator2}  setQuery={setOperator}/>
                    <Inputs filterFields={filterFields} dependsOn={dependsOn} fieldOptions={value} initialValue={value2} setQuery={setValue}/>
                </>
            )
        }
        return false;
    }
}