import React from "react";

export default class Rank extends React.PureComponent {
  render() {
    const { rank, rankChange, user } = this.props.data;
    let position = "stable";
    if (rankChange > 0) {
      position = "up";
    } else if (rankChange < 0) {
      position = "down";
    }
    position += " rank";
    const userType =
      user && user["cognito:groups"] && user["cognito:groups"][0];
    return (
      <>
        <p>IoT Ace™ MVP Rank</p>
        {userType || (!userType && rank <= 5) ? (
          <span className={position}>{rank}</span>
        ) : (
          <div className="blurred-box d-inline">
            <span className={position}>rank</span>
          </div>
        )}
      </>
    );
  }
}
