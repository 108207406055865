import React from 'react'
import ImageGallery from "../../components/ImageGallery/ImageGallery";

const SwapGallery = ({ style, dataJSON }) => {
  if(dataJSON.length > 0 ){
    return (
    <div className="custom-background background-blue-2 py-4">
        <div className="container">
            <div className={`mx-auto ${style.image_gallery_block}`}>
                <div className="rounded border bg-white py-3">
                    <ImageGallery noArrows>
                        {
                            dataJSON.map((item, key) => (
                                <div key={key}>
                                    <div className={`${style.image_gallery} row mx-auto`}>
                                        <div className="h6 font-weight-bold w-100 mb-2">{item.title}</div>
                                        <a href={item.link}>
                                            <img height="125" className="rounded-6 p-0 w-100" src={item.image} alt={`gallery-${key}`} />
                                        </a>
                                    </div>
                                    <div className="d-flex px-3 py-3">
                                        <a className="font-s-16 mx-auto black-link font-weight-normal" href={item.link}>{item.text}</a>
                                    </div>
                                </div>
                            ))
                        }
                    </ImageGallery>
                </div>
            </div>
            
        </div>
    </div>
    )
  }
  return ('')
}

export default SwapGallery
