import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class Article extends Component {
    render() {
        const {articlesArray} = this.props
        return (
            <div>
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item"><Link to='/blog'>Blog</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Article</li>
                        </ol>
                    </nav>
                </div>

                {this.props.children}
                
                <hr />
                <div className="container">
                    <div className="RelatedArticles">
                        <div className="title">Related Articles</div>
                            <div className="row articles-row horizontal-align d-flex d-md-none">
                            {
                                articlesArray && articlesArray.map((item, key) =>
                                    key < 3 &&
                                    <div key={key} className="col-12 col-sm-12 col-md-6  articles-item">
                                        <a className="d-flex" href={`/blog/${item.slug}`}>
                                            <div className="image">
                                                {!item.img && <div className="no-image grey-border rounded-6"></div>}
                                                {item.img && <img src={item.img} className="rounded-6" alt=""/> }
                                            </div>
                                            <div className="description-article">
                                                <div  dangerouslySetInnerHTML={{__html: item.text}} className="text">
                                                
                                                </div>
                                                <div className="date">
                                                    {item.date}
                                                </div>
                                            </div>
                                        </a> 
                                    </div>    
                                )  
                            }
                            </div>
                            <div className="row articles-row horizontal-align d-none d-md-flex">
                            {
                                articlesArray && articlesArray.map((item, key) =>
                                    key < 6 && 
                                    <div key={key} className="col-12 col-sm-12 col-md-6 articles-item">
                                        <a className=" d-flex" href={`/blog/${item.slug}`}>
                                            <div className="image">
                                                {!item.img && <div className="no-image grey-border rounded-6"></div>}
                                                {item.img && <img src={item.img} className="rounded-6" alt=""/> }  
                                            </div>
                                            <div className="description-article">
                                                <div dangerouslySetInnerHTML={{__html: item.text}} className="text">
                                                </div>
                                                <div className="date">
                                                    {item.date}
                                                </div>
                                            </div>
                                        </a>
                                    </div>    
                                )  
                            }
                            </div>
                    </div>
                </div>
                            
            </div>
        )
    }
}
