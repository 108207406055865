import React from "react";

  import Select from './Select.jsx';
  import Inputs from './Inputs.jsx';
  import AdditionalInput from './AdditionalInput.jsx';
  import ClearButton from './ClearButton';

import { isObject } from "util";
export default class FilterEntry extends React.PureComponent {

  _isMounted = false;

  constructor() {
  
    super();

    this.fieldOptions = this.fieldOptions.bind(this);
    this.setAttribute = this.setAttribute.bind(this);
    this.setOperator = this.setOperator.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setAdditionalFilter = this.setAdditionalFilter.bind(this);
    this.setAdditionalFilterAttribute = this.setAdditionalFilterAttribute.bind(this);
    this.setAdditionalFilterOperator = this.setAdditionalFilterOperator.bind(this);
    this.setAdditionalFilterValue = this.setAdditionalFilterValue.bind(this);
    this.isShown = this.isShown.bind(this);
  }
  
  fieldOptions(labels) {
    const fieldOptions = Object.entries(labels).map(([key, value]) => {
      return <option value={key}>{value}</option>;
    });
    return fieldOptions;
  }

  isShown(parent, hidden){
    let showing=true;

    if(!this.props.filterFields)return true

    const filters=this.props.filterFields.hiddenFilters
    filters.forEach((val, i)=>{
      if(val.parentAttribute===parent){
        showing = val.hidden.attribute2 === hidden || val.hidden.operator2 === hidden ? false : true;
      }
    })
    return showing;
  }

  setAttribute(attribute, update = false) { 
      const { filterFields } = this.props;
      const operator = Object.keys(filterFields.attributes[attribute])[0] === "label" ? Object.keys(filterFields.attributes[attribute])[1] : Object.keys(filterFields.attributes[attribute])[0];
      const filter = {
        attribute,
        operator,
        value: ''
      };
      if ((filterFields.attributes[attribute][operator].value.type==='dropdown') || (filterFields.attributes[attribute][operator].value.type==='mult')){

        filter.value = isObject(filterFields.schemas[filterFields.attributes[attribute][operator].value.schemas[0]][0])?
        filterFields.schemas[filterFields.attributes[attribute][operator].value.schemas[0]][0].name:
        filterFields.schemas[filterFields.attributes[attribute][operator].value.schemas[0]][0]
      }

      if(filterFields.attributes[attribute][operator].attributes != null) {
        filter.attribute2=Object.keys(filterFields.attributes[attribute][operator].attributes)[0];
        filter.operator2=Object.keys(filterFields.attributes[attribute][operator].attributes[filter.attribute2])[0];
        if(filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.type === 'dropdown' ||
        filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.type === 'mult') {
          
          if( filter.attribute2 === 'Year'){
            const years = filterFields.schemas[filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0].years
            filter.value2 = years[years.length - 1]
          }else{
            filter.value2 = isObject(filterFields.schemas[filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0])?filterFields.schemas[filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0].name:
            filterFields.schemas[filterFields.attributes[attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0]
          }
          
        }
      }
      this.props.handleAdvancedFilterChange(filter, this.props.filterData.index, update);
  }

  setOperator(operator) {
    const { filterFields } = this.props; 
    const { filter } = this.props.filterData; 
    filter.operator = operator;
    //filter.value = '';
    delete(filter.attribute2);
    delete(filter.operator2);
    // delete(filter.value2);
    // if ((filterFields.attributes[filter.attribute][operator].value.type==='dropdown')){
    //   filter.value = isObject(filterFields.schemas[filterFields.attributes[filter.attribute][operator].value.schemas[0]][0])?
    //   filterFields.schemas[filterFields.attributes[filter.attribute][operator].value.schemas[0]][0].name:
    //   filterFields.schemas[filterFields.attributes[filter.attribute][operator].value.schemas[0]][0]
    // }

    if(filterFields.attributes[filter.attribute][operator].attributes != null) {
      filter.attribute2=Object.keys(filterFields.attributes[filter.attribute][operator].attributes)[0];
      filter.operator2=Object.keys(filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2])[0];
      
      if(filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2][filter.operator2].value.type === 'dropdown' ||
      filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2][filter.operator2].value.type === 'mult') {
          
        if( filter.attribute2 === 'Year' && (filter.value2 === '' || filter.value2 === 'undefined')){
          const years = filterFields.schemas[filterFields.attributes[filter.attribute][filter.operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]].map( obj => (obj.name === filter.value) && obj ).filter(Boolean)[0].years[0]
          filter.value2 = years
        }else if(filter.value2 === '' || filter.value2 === 'undefined'){
          filter.value2 = isObject(filterFields.schemas[filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0])?
          filterFields.schemas[filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0].name:filterFields.schemas[filterFields.attributes[filter.attribute][operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]][0]
        }       
      }
    }
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index);
  }

  setValue(value, update = false) {
    
    const { filterFields } = this.props;
    const { filter } = this.props.filterData;

    filter.value = value;
    if( filter.attribute2 === 'Year' && filter.value){ 
      const years = filterFields.schemas[filterFields.attributes[filter.attribute][filter.operator].attributes[filter.attribute2][filter.operator2].value.schemas[0]].map( obj => (obj.name === value) && obj ).filter(Boolean)[0].years[0]
      filter.value2 = years
    }
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index, update);
  }

  setAdditionalFilter(attribute, operator, value) {
    const { filter } = this.props.filterData;
    filter.attribute2 = attribute;
    filter.operator2 = operator;
    filter.value2 = value;
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index);
   
  }

  setAdditionalFilterAttribute(attribute) {
    const { filter } = this.props.filterData;
    filter.attribute2 = attribute;
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index);
  }

  setAdditionalFilterOperator(operator) {
    const { filter } = this.props.filterData;
    filter.value2 = operator;
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index);

  }

  setAdditionalFilterValue(value) {
    
    const { filter } = this.props.filterData;
    filter.value2 = value;
    this.props.handleAdvancedFilterChange(filter, this.props.filterData.index);

  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render() {
    const { filterFields, user } = this.props; 
    if ( !filterFields || !("schemas" in filterFields) ) return false;
    const { index } = this.props.filterData;
    const { filter } = this.props.filterData;
    let { attribute2, operator2, value2='' } = filter;

    let isShowing = {
      attribute:this.isShown(filter.attribute, filter.attribute ),
      operator:this.isShown(filter.attribute, filter.operator ),
      value:filter.value!==''?this.isShown(filter.attribute, filter.value ):true,
      attribute2:this.isShown(filter.attribute, attribute2 ),
      operator2:this.isShown(filter.attribute, operator2 ),
      value2:value2!==''?this.isShown(filter.attribute, value2 ):true,
    }
    const clear = ((isShowing)=>{
      for(var key in isShowing) {
        if (!isShowing[key])return false
      }
      return true;
    })(isShowing)
    const fullRow = filterFields.attributes[filter.attribute][filter.operator].attributes != null ? 'full' : '';
    if(filterFields.attributes[filter.attribute]["label"]){
      delete filterFields.attributes[filter.attribute]["label"]
    }

    return (
        <div className={`row filter-entry ${fullRow}`}>
          <Select canGetMoreWidth={ !clear || !attribute2 || !operator2 } user={user} mainDropdown={true} fieldOptions={filterFields} initialValue={filter.attribute} setQuery={this.setAttribute} key={`attribute_${index}`}/>
          <Select fieldOptions={filterFields.attributes[filter.attribute]} initialValue={filter.operator} setQuery={this.setOperator} key={`operator_${index}`}/>
          <Inputs dependsOn={{parrentAttribute: filter.attribute}} filterFields={filterFields} canGetMoreWidth={!clear || !attribute2 || !operator2 } fieldOptions={filterFields.attributes[filter.attribute][filter.operator]} initialValue={filter.value} setQuery={this.setValue} key={`value_${index}`}
            locationOptions={this.props.locationOptions} locationFilter={this.props.locationFilter} handleLocationChange={this.props.handleLocationChange} handleLocationInput={this.props.handleLocationInput} handleSelectLocation={this.props.handleSelectLocation}
          />
          <>
            <AdditionalInput
              filterFields={filterFields}
              isShowing={isShowing}
              dependsOn={{value:filter.value, parrentAttribute: attribute2}}
              initialValues={{attribute2, operator2, value2}}
              fieldOptions={filterFields.attributes[filter.attribute][filter.operator]} 
              setQuery={this.setAdditionalFilter} 
              setAttribute={this.setAdditionalFilterAttribute} 
              setOperator={this.setAdditionalFilterOperator} 
              setValue={this.setAdditionalFilterValue} 
              key={`additional_filters_${index}`}/>
          </>
          <ClearButton clear={clear} index={index} handleClearSpecificFilter={this.props.handleClearSpecificFilter}/>
        </div>
    )
  }
};