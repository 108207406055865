import React from 'react'

import SpeakerSide from './Speaker/SpeakerSide.jsx';
import ExhibitSide from './Exhibit/ExhibitSide.jsx';

function SideSwitcher({eventType, speakers, organizers, sponsors}){
    switch (eventType.toLowerCase().replace(/\s/g, '')) {
      case 'conference':
        return(
          <>
            {organizers.length===0?null: <ExhibitSide title="Exhibiting" data={organizers} id="organizers"/>}
            {speakers.length===0?null: <SpeakerSide title="See who's Speaking" data={speakers} id="speakers"/>}
          </>
        )
        case 'webinar':
        return(
          <>
            {speakers.length===0?null: <SpeakerSide title="See who's Speaking" data={speakers} id="speakers"/> }
          </>
        )
        case 'hackathon':
        return(
          <>
            {sponsors.length > 0? <ExhibitSide title="Sponsoring" data={sponsors} id="sponsors"/>:null}
          </>
        )
        case 'panel':
        return(
          <>
            {speakers.length > 0? <SpeakerSide title="See who's Speaking" data={speakers} id="speakers" />:null }
          </>
        )
        case 'networking':
        return(
          <>
            {organizers.length > 0? <ExhibitSide title="Exhibiting" data={organizers} id="organizers"/>:null}
            {sponsors.length > 0? <ExhibitSide title="Sponsoring" data={sponsors} id="sponsors"/>:null}
          </>
        )
        case 'course':
        return(
          <>
            {speakers.length > 0? <SpeakerSide  title="Course Instructors" data={speakers} id="speakers"/>:null }
          </>
        )
        case 'fair':
        return(
          <>
            {organizers.length > 0? <ExhibitSide title="Exhibiting" data={organizers} id="organizers"/>:null}
            {sponsors.length > 0? <ExhibitSide title="Sponsoring" data={sponsors} id="sponsors"/>:null}
          </>
        )
        case 'startup':
        case 'exhibition':
        return(
          <>
            {speakers.length > 0? <SpeakerSide title="See who's Speaking" data={speakers} id="speakers" />:null }
            {organizers.length > 0? <ExhibitSide title="Exhibiting" data={organizers} id="organizers"/>:null}
            {sponsors.length > 0? <ExhibitSide title="Sponsoring" data={sponsors} id="sponsors"/>:null}
          </>
        )
        
      default:
        return false;
    }
  }
  export default SideSwitcher