/*global Intercom*/

import React from 'react';
import Button from './components/Button.jsx'
import SubscriptionPlans from './components/SubscriptionPlans.jsx'
import '../components/scss/breadcrumbs.scss'
import './Pricing.scss'
import {Helmet} from "react-helmet";
export default class Pricing extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            type: 'selected-registered'
        }
    }

    handleContactButtonClick = () => {
        Intercom('show');
    }
    handlePlans = (val) => {
        this.setState({type: val})
    }
    render() {
        const { type } = this.state
        return (
            <div className="Corp-Eco-Stats">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>IoT Ace Subscription Plans</title>
                </Helmet>
                <div className="container">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href='/'>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Pricing</li>
                    </ol>
                    </nav>  
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center welcome-screen">
                        <div className="col-auto text-box">
                            <h1>Subscription Plans</h1>
                        </div>
                    </div>
                </div>

                <div className="container m-b-40 d-none d-md-block">
                    <div className="d-flex">
                        <div className="col pr-md-0"></div>

                        <div className="col-3 pr-md-0">
                            <a href="/signup">
                                <Button handlePlans={this.handlePlans} selected={true} type="selected-registered" name={'Registered'} />
                            </a>
                        </div>
                        <div className="col-3 pr-md-0">
                            <a className="margin-auto" target="_blank" href='mailto:subscriptions@iotnation.com?subject=IoT Ace Pro Subscription'>
                                <Button handlePlans={this.handlePlans} selected={true} type="selected-pro" name={'Pro'} />
                            </a>
                        </div>
                        <div className="col-3 pr-md-0">
                            <a className="margin-auto" target="_blank" href='mailto:subscriptions@iotnation.com?subject=IoT Ace Enterprise Subscription'>
                                <Button handlePlans={this.handlePlans} selected={true} type="selected-enterprise" name={'Enterprise'} />
                            </a>
                        </div>
                    </div>
                    <SubscriptionPlans type={this.state.type} />
                    <div className="display-flex info-half-mark">
                       <div className={`half-mark ${this.state.type}-mark`}></div> <div className="info"> - Indicates partial data access</div>
                    </div>  
                </div>

                <div className="container m-b-40 d-md-none">
                    <div className="row">
                        <div className="col-4 pr-md-0">
                            <Button handlePlans={this.handlePlans} selected={true} type="selected-registered" name={'Registered'} />
                        </div>
                        <div className="col-4 pr-md-0">
                            <Button handlePlans={this.handlePlans} selected={true} type="selected-pro" name={'Pro'} />
                        </div>
                        <div className="col-4 pr-md-0">
                            <Button handlePlans={this.handlePlans} selected={true} type="selected-enterprise" name={'Enterprise'} />
                        </div>
                    </div>

                    <SubscriptionPlans type={this.state.type} />
                    <div className="display-flex info-half-mark">
                       <div className={`half-mark ${this.state.type}-mark`}></div> <div className="info"> - Indicates partial data access</div>
                    </div>
                    
                </div>

                <div className="container m-b-40 d-none d-md-block">
                    <div className="d-flex">
                        <div className="col pr-md-0"></div>

                        <div className="col-3 pr-md-0">
                            <a href="/signup">
                                <Button handlePlans={this.handlePlans} bottom type="selected-registered" name={'Free Trial'} />
                            </a>
                        </div>
                        <div className="col-3 pr-md-0">
                            <a className="margin-auto" target="_blank" href='mailto:subscriptions@iotnation.com?subject=IoT Ace Pro Subscription'>
                                <Button handlePlans={this.handlePlans} bottom type="selected-pro" name={'Subscribe'} />
                            </a>
                        </div>
                        <div className="col-3 pr-md-0">
                            <a className="margin-auto" target="_blank" href='mailto:subscriptions@iotnation.com?subject=IoT Ace Enterprise Subscription'>
                                <Button handlePlans={this.handlePlans} bottom type="selected-enterprise" name={'Contact Us'} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container d-md-none">
                    <div className="row">
                        <div className="col-8"></div>
                        <div className="col-4">
                            <Button handlePlans={this.handlePlans} bottom type={type} name={type === 'selected-registered'?' Free Trial':type === 'selected-pro'?'Subscribe': 'Contact Us'} />
                        </div>
                    </div>
                </div>

                <hr className="mt-5"/>
            </div>
        )
    }
}