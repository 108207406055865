
export default function locationChange(advancedFilters, value) {
    let array = []
    const Location = advancedFilters.find((filter, i)=> filter.attribute ==='Location'  )
        if(value){ 
            const newArrayValue = {attribute: "Location", operator: "Is near", value: value.label, attribute2: 'Distances', operator2: 'Within', value2: '100'}
            array = {
                locationFilter: { value: value.label, label: value.label },
                locationCount: 1,
                LocationArray: newArrayValue
            }
            if(Location){
                array = {
                    ...array,
                    advancedFilters: advancedFilters.map(
                        obj => (obj.attribute === 'Location')? {...obj, value: value.label, value2: obj.value2 && obj.value2 !== 'undefined' ? obj.value2 : '100' }: obj
                    )
                }
            }else{
                array = {
                    advancedFilters: [...array, ...advancedFilters, newArrayValue]
                }
            }
        }else{
            array = {
                locationFilter:'',
                locationCount: 0,
                LocationArray: '',
                advancedFilters: (advancedFilters.map( obj => (obj.attribute !== 'Location') ? obj :
                {attribute:obj.attribute, attribute2:obj.attribute2, operator:obj.operator, operator2: obj.operator2, value2:obj.value2, value:''} ).filter(Boolean))
            }
        }
    return (
        {...array}
    )
}
