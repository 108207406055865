import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function StatsDisclaimer(props) {
    if(!props.user || !props.user['cognito:groups']) {
        return (
            <>
                <div className="subscriber-disclaimer orange">
                    <FaExclamation/> Register or subscribe to see more real time reports! See <Link to="/iotace">Subscription Plans</Link> for details.
                </div>
                
            </>
        
        )
    } else if(props.user['cognito:groups'].find((entry) => { return entry === 'registered'}) || props.user['cognito:groups'].find((entry) => { return entry === 'free'})) {
        return (
            <>
            <div className="subscriber-disclaimer blue">
            <FaExclamation/> Subscribe to see more company ecosystems and industry groups stats! See <Link to="/iotace">Subscription Plans</Link> for details.
            </div>
            
            </>
        )
    }
    return '';
}