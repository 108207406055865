import React, { Component } from "react";
import EventsItem from "./components/EventsItem.jsx";
import Spinner from "../components/Spinner/Spinner";
import sortByFeatured from "./functions/sortByFeatured";
import ScrollUpButton from "react-scroll-up-button";
import UseCaseList from "./UseCaseList";
import qs from "query-string";
import { isString } from "util";
import _ from "lodash";
import * as advancedFiltersJSON from "../assets/advancedFiltersUsecase.json";
import * as hiddenFiltersJSON from "../assets/hiddenFilters.json";
import { isObject } from "util";
import AdvancedFilters from "../components/AdvancedFilters/AdvancedFilters.jsx";
import AdvancedFiltersMobile from "../components/AdvancedFiltersCompaniesMobile/AdvancedFiltersMobile";
import filterCheckbox from "../components/AdvancedFilters/Functions/filterCheckbox";
import clearFilter from "../components/AdvancedFilters/Functions/clearFilter";
import clearAllFilters from "../components/AdvancedFilters/Functions/clearAllFilters.jsx";
import GeneratedApi from "../components/functions/generatedApi.js";

export default class UseCaseListContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      search: {
        searchResults: [],
        count: 0,
        start: 0,
        limit: 1000,
        useCases: [],
        loading: true,
        q: {},
      },
      fullProfiles: 1,
      currentPage: 1,
      limitReached: false,
      query: "",
      advancedFilters: [],
      sort: {
        attribute: "rank",
        how: "asc",
      },
      advancedFiltersTriggered: true,
      profileToggleVisible: false,
    };

    if (props.user && props.user["cognito:groups"]) {
      if (
        props.user["cognito:groups"].find((entry) => {
          return entry === "corporate";
        }) ||
        props.user["cognito:groups"].find((entry) => {
          return entry === "admin";
        })
      ) {
        this.state.profileToggleVisible = true;
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.getSchemas();
    await this.getQueryParameters(true, true);

    const { currentPage, fullProfiles } = this.state;
    const { user } = this.props;
    const { limit } = this.state.search;

    let offset = user ? Math.ceil((currentPage - 1) * limit) : 0;

    const { query } = this.state;
    const advancedFilters = this.state.advancedFilters.map((obj) => (obj.value === "" && obj.attribute === "Location" ? "" : obj)).filter(Boolean);
    let { sort } = this.state;
    const q = {
      search: {
        q: query,
        start: offset,
        limit: limit,
      },
      filters: advancedFilters,
    };
    if (fullProfiles === 1) {
      q.filters = advancedFilters.concat([{ attribute: "Company Rank", operator: "Less than", value: "3000" }]);
    }
    if (advancedFilters.length !== 0 || query !== "") {
      q.sort = sort;
    }
    GeneratedApi("", true, true)
      .post("/usecases/gridView", q)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        this.setState({
          search: response,
          sort,
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          if (error.response.status === 429) {
            this.setState({ limitReached: true });
            return false;
          }
        }
      });
  }

  async getSchemas() {
    await GeneratedApi("", false, false)
      .get("/schemas/companyFilter")
      .then((res) => {
        if (this._isMounted === true) {
          const reso = res.data.schemas;
          reso.organizations = [{ name: " ", years: [""] }, ...reso.organizations];
          reso.ecosystems = [{ name: " ", years: [""] }, ...reso.ecosystems];
          reso.conferences = [{ name: " ", years: [""] }, ...reso.conferences];
          reso.technologies = ["", ...reso.technologies];
          reso.verticals = ["", ...reso.verticals];
          this.setState({
            filterFields: {
              ...advancedFiltersJSON.default,
              schemas: reso,
              hiddenFilters: hiddenFiltersJSON.default,
            },
          });
        }
      });
  }

  async componentDidUpdate(prevProps) {
    if (this._isMounted && this.props.location !== prevProps.location) {
      await this.getQueryParameters();
      if (this.props.location.pathname === "/companies") {
        await this.setState({
          currentPage: 1,
          search: {
            ...this.state.search,
            limit: this.state.limit,
            start: 0,
          },
          query: "",
          advancedFilters: [],
        });
      }
      this.handleSearchQuery();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getQueryParameters = async (unique = false, runCheck = false) => {
    if (!this._isMounted) return false;

    const { attributes, schemas } = this.state.filterFields;

    let queryObj = qs.parse(this.props.location.search);
    if (queryObj.length === 0) {
      return;
    }

    let filtersArr = Object.keys(queryObj)
      .map((key) => {
        if ("filter" === key.replace(/[0-9]/g, "")) {
          let tempArr = queryObj[key].split("_");
          if (tempArr[3]) {
            return {
              attribute: tempArr[0],
              operator: tempArr[1],
              value: String(tempArr[2]).replace(" theAnd ", " & "),
              attribute2: tempArr[3],
              operator2: tempArr[4],
              value2: tempArr[5],
            };
            // value2: tempArr[5] !== 'null' ?String(tempArr[5]).replace(" and ", " & "):'2019'
          } else {
            return {
              attribute: tempArr[0],
              operator: tempArr[1],
              value: tempArr[2].replace(" theAnd ", " & "),
            };
          }
        }
        return false;
      })
      .filter((val) => {
        if (!runCheck) return val;

        if (isObject(val)) {
          let keys = Object.keys(val).filter((key) => {
            return _.includes(["attribute", "operator", "attribute2", "operator2"], key);
          });

          if (keys.length === 2) {
            let hasAll = _.has(attributes, [val.attribute, val.operator]);

            if (hasAll) {
              if (attributes[val.attribute][val.operator].value.schemas) {
                let whichSchemas = attributes[val.attribute][val.operator].value.schemas;
                return this.validateSchemas(whichSchemas, schemas, val.value);
              }
              return true;
            }
            return false;
          }
          if (keys.length === 4) {
            let hasAll = _.has(attributes, [val.attribute, val.operator, "attributes", val.attribute2, val.operator2]);
            if (hasAll) {
              if (
                attributes[val.attribute][val.operator].value.schemas &&
                attributes[val.attribute][val.operator].attributes[val.attribute2][val.operator2].value.schemas
              ) {
                let dependsOn = {
                  value: val.value,
                  parrentAttribute: val.attribute2,
                };
                let whichSchemas1 = attributes[val.attribute][val.operator].value.schemas;
                let whichSchemas2 = attributes[val.attribute][val.operator].attributes[val.attribute2][val.operator2].value.schemas;

                let comp1 = this.validateSchemas(whichSchemas1, schemas, val.value);
                let comp2 = this.validateSchemas(whichSchemas2, schemas, val.value2, dependsOn);

                return comp1 && comp2;
              } else if (attributes[val.attribute][val.operator].value.schemas) {
                let whichSchemas = attributes[val.attribute][val.operator].value.schemas;

                return this.validateSchemas(whichSchemas, schemas, val.value);
              } else if (attributes[val.attribute][val.operator].attributes[val.attribute2][val.operator2].value.schemas) {
                let whichSchemas = attributes[val.attribute][val.operator].attributes[val.attribute2][val.operator2].value.schemas;

                return this.validateSchemas(whichSchemas, schemas, val.value2);
              }
              return true;
            }
            return false;
          }
          return false;
        }
        return val;
      });

    if (unique) filtersArr = _.uniqWith(filtersArr, _.isEqual);

    let { limit, q = "", currentPage, fullProfiles } = queryObj;
    limit = !isNaN(Number(limit)) ? limit : this.state.search.limit;
    currentPage = !isNaN(Number(currentPage)) ? currentPage : this.state.currentPage;
    fullProfiles = !isNaN(Number(fullProfiles)) ? Number(fullProfiles) : 1;

    let offset = Math.ceil((currentPage - 1) * limit);

    await this.setState({
      search: {
        ...this.state.search,
        start: offset,
        limit: limit,
      },
      query: q,
      advancedFilters: filtersArr,
      currentPage: currentPage,
      fullProfiles,
    });
  };

  generateQueryParameter = async (resetPage = true) => {
    const { limit } = this.state.search;

    const { query, advancedFilters } = this.state;
    let res1 = "";
    let res2 = "";
    let filters = await advancedFilters
      .map((val, i) => {
        let attrNumber = i + 1;
        let prefix = i === 0 ? "" : "&";

        if (val.attribute2 || val.operator2 || val.value2) {
          res1 = isString(val.value) ? val.value.replace("&", "theAnd") : val.value;
          res2 = isString(val.value2) ? val.value2.replace("&", "theAnd") : val.value2;
          return `${prefix}filter${attrNumber}=${val.attribute}_${val.operator}_${res1}_${val.attribute2}_${val.operator2}_${res2}`;
        }

        res1 = isString(val.value) ? val.value.replace("&", "theAnd") : val.value;
        return `${prefix}filter${attrNumber}=${val.attribute}_${val.operator}_${res1}`;
      })
      .reduce((prev, curr) => (prev += curr), "");

    this.props.history.push(`/usecases/?q=${escape(query)}&${filters}&limit=${limit}`);
  };
  handleSearchQuery = () => {
    let { query, sort, currentPage, fullProfiles } = this.state;
    const advancedFilters = this.state.advancedFilters.map((obj) => (obj.value === "" && obj.attribute === "Location" ? "" : obj)).filter(Boolean);
    let performSearch = true;
    advancedFilters.every((filter) => {
      if (filter.value2) {
        if (filter.value2 === "" && filter.value === "") {
          performSearch = false;
          return false;
        }
      } else {
        if (filter.value === "") {
          performSearch = false;
          return false;
        }
      }
      return true;
    });
    if (!performSearch) {
      return false;
    }

    this.setState({
      search: {
        ...this.state.search,
        loading: true,
      },
    });

    let { limit } = this.state.search;

    const searchQuery = {
      search: {
        q: query,
        start: Math.ceil((currentPage - 1) * limit),
        limit: limit,
      },
      filters: advancedFilters,
    };
    if (fullProfiles === 1) {
      searchQuery.filters = advancedFilters.concat([{ attribute: "Company Rank", operator: "Less than", value: "3000" }]);
    }
    if (advancedFilters.length !== 0 || query !== "") {
      sort = {
        attribute: "relevance",
        how: "asc",
      };
    }
    GeneratedApi("", true, true)
      .post("/usecases/gridView", searchQuery)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        if (this._isMounted) {
          this.setState({
            search: response,
            query,
            sort,
            currentPage,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  };

  handlePageChange = async (offset, currentPage) => {
    await this.setState({
      currentPage,
    });
    this.generateQueryParameter(false);
  };

  handleSortChange = (attribute, how) => {
    const { limit, start, fullProfiles } = this.state.search;
    const advancedFilters = this.state.advancedFilters.map((obj) => (obj.value === "" && obj.attribute === "Location" ? "" : obj)).filter(Boolean);
    const { query } = this.state;
    const q = {
      search: {
        q: query,
        start: start,
        limit: limit,
      },
      filters: advancedFilters,
    };
    if (attribute !== "relevance") {
      q.sort = {
        attribute,
        how,
      };
    }
    if (fullProfiles === 1) {
      q.filters = advancedFilters.concat([{ attribute: "Company Rank", operator: "Less than", value: "3000" }]);
    }
    GeneratedApi("", true, true)
      .post("/usecases/gridView", q)
      .then((response) => {
        response = response.data.body.data;
        response.loading = false;
        this.setState({
          search: response,
          sort: {
            attribute,
            how,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  };

  handleQueryChange = async (event) => {
    event.preventDefault();
    const query = event.target.value;
    await this.setState({ query: query });
    if (query === "") {
      await this.generateQueryParameter();
    }
  };

  handleAdvancedFilterChange = async (filter, key) => {
    const { advancedFilters } = this.state;
    //advancedFilters[key] = filter;
    await this.setState({
      advancedFilters: [...advancedFilters.slice(0, key), { ...filter }, ...advancedFilters.slice(key + 1)],
    });
    this.generateQueryParameter();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.generateQueryParameter();
  };

  handleAddFilter = async () => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters,
        {
          attribute: "Title",
          operator: "Begins with",
          value: "",
        },
      ],
    });
    this.generateQueryParameter();
  };

  handleClearSpecificFilter = async (key) => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [...advancedFilters.slice(0, key), ...advancedFilters.slice(key + 1)],
    });
    this.generateQueryParameter();
  };

  handleClearFilters = async () => {
    const advancedFilters = [];
    await this.setState({
      advancedFilters,
    });
    this.generateQueryParameter();
  };

  handleFilterTrigger = (advancedFiltersTriggered) => {
    this.setState({
      advancedFiltersTriggered,
    });
  };

  handleFilterCheckbox = async (event) => {
    const {
      checkedFilter,
      advancedFilters,
      LocationArray,
      totalFcount,
      technologiesCount,
      verticalsCount,
      employeeCount,
      publicPrivateCount,
    } = this.state;
    const counter = {
      totalFcount: totalFcount,
      technologiesCount: technologiesCount,
      verticalsCount: verticalsCount,
      employeeCount: employeeCount,
      publicPrivateCount: publicPrivateCount,
    };
    await this.setState(filterCheckbox(event, checkedFilter, advancedFilters, LocationArray, counter));

    this.generateQueryParameter();
  };
  handleClearFilter = async (filter) => {
    const { advancedFilters, checkedFilter, LocationArray, locationFilter } = this.state;
    await this.setState(clearFilter(filter, advancedFilters, checkedFilter, LocationArray, locationFilter));
    this.generateQueryParameter();
  };
  handleClearAllFilters = async () => {
    await this.setState(clearAllFilters());
    this.generateQueryParameter();
  };
  validateSchemas(whichSchemas, schemas, validatedValue, dependsOn) {
    let schemaValues = [];
    whichSchemas.forEach((schema) => {
      if (!dependsOn || dependsOn.parrentAttribute !== "Year") {
        schemaValues = schemaValues.concat(
          schemas[schema].map((val, i) => {
            if (isObject(val)) {
              return val.name;
            } else {
              return val;
            }
          })
        );
      } else if (dependsOn.parrentAttribute === "Year") {
        const filtered = schemas[schema].filter((val) => val.name === dependsOn.value);
        schemaValues = filtered[0] && filtered[0].years;
      }
    });

    return _.includes(schemaValues, validatedValue);
  }

  handleFullProfilesChange = async (fullProfiles) => {
    await this.setState({
      fullProfiles: Number(fullProfiles),
    });
    this.generateQueryParameter();
  };

  render() {
    const { filters, search } = this.state;
    const aFHandle = {
      handleAdvancedFilterChange: this.handleAdvancedFilterChange,
      handleClearFilters: this.handleClearFilters,
      handleAddFilter: this.handleAddFilter,
      handleClearSpecificFilter: this.handleClearSpecificFilter,
      handleLocationChange: this.handleLocationChange,
      handleLocationInput: this.handleLocationInput,
      handleSelectLocation: this.handleSelectLocation,
    };
    const aFValue = {
      advancedFilters: this.state.advancedFilters,
      filterFields: this.state.filterFields,
      user: this.props.user,
      locationOptions: this.state.locationOptions,
      locationFilter: this.state.locationFilter,
    };
    const aFMobileHandle = {
      handleFilterCheckbox: this.handleFilterCheckbox,
      handleClearFilter: this.handleClearFilter,
      handleClearAllFilters: this.handleClearAllFilters,
    };
    const aFMobileValue = {
      checkedFilter: this.state.checkedFilter,
      totalFcount: this.state.totalFcount,
      technologiesCount: this.state.technologiesCount,
      verticalsCount: this.state.verticalsCount,
      employeeCount: this.state.employeeCount,
      publicPrivateCount: this.state.publicPrivateCount,
      locationCount: this.state.locationCount,
    };
    const userType = this.props.user && this.props.user["cognito:groups"] && this.props.user["cognito:groups"][0];
    return (
      <UseCaseList handleQueryChange={this.handleQueryChange} handleSubmit={this.handleSubmit} query={this.state.query} user={this.props.user}>
        {userType === "admin" && (
          <AdvancedFiltersMobile
            whenToShow="d-block d-md-none"
            visible={true}
            aFHandle={{ ...aFHandle, ...aFMobileHandle, ...this.handleSubmit, ...this.handleQueryChange }}
            aFValue={{ ...aFValue, ...aFMobileValue, value: this.props.query }}
          />
        )}
        {userType === "admin" && <AdvancedFilters aFHandle={aFHandle} aFValue={{ ...aFValue }} />}
        <div className="container">
          <div className="row special-row">
            <div className="col-md-12 col-lg-10">
              <div className="main">
                <div className="container results" style={{ padding: 0 }}>
                  <div className="result-count">Total results: {search.count}</div>
                </div>
                {search.loading ? (
                  <Spinner />
                ) : search.searchResults.length === 0 ? (
                  <p className="mt-5 h5 text-center">No results found. Consider removing filters or modifying the date range.</p>
                ) : (
                  search.searchResults
                    .sort(sortByFeatured)
                    .map((item, i) => (
                      <EventsItem
                        id={i}
                        data={{ ...item, filters }}
                        user={this.props.user}
                        instance2={GeneratedApi("", true, true)}
                        pdfHandle={this.pdfHandle}
                        key={i}
                      />
                    ))
                )}
                <div className="d-none d-md-block">
                  <ScrollUpButton ToggledStyle={{ top: "400px", backgroundColor: "#1B7BEB" }} style={{ top: "400px", backgroundColor: "#1B7BEB" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UseCaseList>
    );
  }
}
