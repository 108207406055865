
export default function clearAllFilters() {
    let array = {
        advancedFilters: [],
        checkedFilter: [],
        totalFcount: 0,
        technologiesCount: 0,
        verticalsCount: 0,
        employeeCount: 0,
        publicPrivateCount: 0,
        locationCount: 0,
        LocationArray: '',
        locationFilter: ''
    }
    
    return (
        {...array}
    )
}
