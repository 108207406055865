import React from 'react';

export default class SortableHeader extends React.PureComponent {

    handleSortingChange = () => {
        this.props.handleSortChange(this.props.data.type);
    }

    render() {
        const {textAlign} = this.props
        const { name, type, gridSize, sort } = this.props.data;
        return (
            <th className={gridSize && textAlign ? `${gridSize}` : textAlign?'text-align-end col':gridSize?gridSize:'col'}>
                <span className={`header ${sort.attribute === type ? sort.how : ""}`} onClick={this.handleSortingChange}>{name}</span>
            </th>
        );
    }
}