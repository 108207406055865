import React from 'react';
import IotLinks from './IotLinks';
import GeneralLinks from './GeneralLinks';

const styles = {
        overflow:'hidden',
        transition: 'all 0.5s'
}
export default class CompanyInfo extends React.PureComponent {
    state={
        readMoreCollapsed:true
    }
    handleReadMore = () =>{
        this.setState({readMoreCollapsed:!this.state.readMoreCollapsed})
    }
    render() {
        
        const { companyProfile } = this.props;
        const { readMoreCollapsed } = this.state;
    
        return (
        <>
            <div className="row">
                <div className="col-12 corp">
                    <p>{companyProfile.name}</p>
                    <p style={{...styles, maxHeight: readMoreCollapsed?'12em':'100%'}}>{companyProfile.description}</p>
                    <p className="more" onClick={this.handleReadMore}>Read {readMoreCollapsed?'more':'less'}<img style={{transform:readMoreCollapsed?'none':'rotate(180deg)'}} alt="read-more" src={`/images/small-down-copy-8.svg`}/></p>
                </div>                        
            </div>
            <>
                <div className="menu-divider"></div>
                <div className="d-none d-lg-flex row links">
                    <GeneralLinks links={companyProfile.generalLinks}/>
                    <IotLinks links={companyProfile.iotLinks}/>
                </div>
            </>

        </>
        )
    }
}