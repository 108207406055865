import React from 'react';
import faker from 'faker/locale/en_US';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

const TooltipWithProps = (props) => {

    const { tags, lost, type } = props;
    
    let content = tags.slice(0,3);
    if(content.length === 0) {
        content = [{name: faker.fake("{{company.companyName}}")},{name: faker.fake("{{company.companyName}}")},{name: faker.fake("{{company.companyName}}")}];

    }
    if (type === 'pc'){
        return (
            <Tippy content={
                <ul data-simplebar>
                    {tags.map((tag, i) => {
                        return <li key={i}><a href={tag.url} target="_blank" >{tag.name}</a></li>
                    })}
                </ul>
            } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true}>
                {lost.change > 0? <img alt="" src="/images/3-dots-circle.svg" className="dots-3 pointer d-none d-md-flex vertical-align" />:''}
            </Tippy>
        )
    }
    return (
        <Tippy content={
            <ul data-simplebar>
                {tags.map((tag, i) => {
                    return <li key={i}><a href={tag.url} target="_blank" >{tag.name}</a></li>
                })}
            </ul>
        } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true}>
            <span className="memberships negative pointer d-flex d-md-none">-{lost.change}</span>
        </Tippy>
    )
}

export default class MembershipsLost extends React.PureComponent {
    render() {
        const { lost }  = this.props;

        if(lost === undefined || lost === null || lost.change === 0) {
            return <>
            <span style={{color:'#3f4d6e'}} className="memberships">—</span>
            </>
        }
        return (
            <>

            <span className="memberships negative pointer d-none d-md-flex">-{lost.change}</span>
            <TooltipWithProps className="d-none d-md-block" type="pc" lost={lost} tags={lost.companies || []}/>
            <TooltipWithProps className="d-block d-md-none" type="mobile" lost={lost} tags={lost.companies || []}/>
            </>
        )
    }
}