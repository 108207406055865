import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import videosJSON from "../content/videos.json";
import style from "./Videos.module.scss";

const ImageBlock = ({ colClass, url, text, key = 0 }) => {
  return (
    <div key={key} className={colClass}>
      {url ? (
        <ReactPlayer className={style["image-design"]} width="100%" height="258px" url={url} />
      ) : (
        <div className={`${style["fake-img"]} border`} />
      )}
      <div className="p-3 border-bottom border-left border-right rounded-bottom">
        <p className={`${style["img-bootom-text"]} h6 margin-0`}>{text}</p>
      </div>
    </div>
  );
};

const Videos = () => {
  const [showData, setData] = useState({ secondBlock: 6, thirdBlock: 6 });

  const secondBlockHandle = () => {
    setData({ ...showData, secondBlock: showData.secondBlock + 6 });
  };

  const thirdBlockHandle = () => {
    setData({ ...showData, thirdBlock: showData.thirdBlock + 6 });
  };

  if (videosJSON.length > 0) {
    return (
      <div className="container mb-4">
        <div className="font-s-30 mt-5 mb-4">{videosJSON[0].title}</div>
        <div className="row">
          <div className="col-12 offset-xl-0 col-xl-8 mb-4 mb-xl-0">
            <ReactPlayer
              className={`${style["image-design"]} ${style["main-image"]}`}
              width="100%"
              url={videosJSON[0].data[0] && videosJSON[0].data[0].url}
            />
            <div className="p-3 border-bottom border-left border-right rounded-bottom">
              <p className={`${style["img-bootom-text"]} h6 m-0`}>{videosJSON[0].data[0] && videosJSON[0].data[0].text}</p>
            </div>
          </div>
          <div className="col-12 col-xl row mx-0 px-0 ">
            <ImageBlock
              colClass="col-12 col-md-6 col-xl-12 mb-4"
              url={videosJSON[0].data[1] && videosJSON[0].data[1].url}
              text={videosJSON[0].data[1] && videosJSON[0].data[1].text}
            />
            <ImageBlock
              colClass="col-12 col-md-6 col-xl-12 mb-4"
              url={videosJSON[0].data[2] && videosJSON[0].data[2].url}
              text={videosJSON[0].data[2] && videosJSON[0].data[2].text}
            />
          </div>
        </div>
        <hr className="my-5" />
        {videosJSON[1] && <div className="font-s-30 mt-5 mb-4">{videosJSON[1].title}</div>}
        {videosJSON[1] && (
          <div className="row">
            {videosJSON[1].data.map(
              (item, key) =>
                key + 1 <= showData.secondBlock && <ImageBlock colClass="col-12 col-md-6 col-lg-4 mb-4" url={item.url} text={item.text} key={key} />
            )}
            {showData.secondBlock < videosJSON[1].data.length && (
              <div className="col-12 d-flex">
                <button onClick={() => secondBlockHandle()} className="custom-blue-button py-3 px-5 h6 radius mx-auto">
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
        <hr className="my-5" />
        {videosJSON[2] && <div className="font-s-30 mt-5 mb-4">{videosJSON[2].title}</div>}
        {videosJSON[2] && (
          <div className="row">
            {videosJSON[2].data.map(
              (item, key) =>
                key + 1 <= showData.thirdBlock && <ImageBlock colClass="col-12 col-md-6 col-lg-4 mb-4" url={item.url} text={item.text} key={key} />
            )}
            {showData.thirdBlock < videosJSON[2].data.length && (
              <div className="col-12 d-flex">
                <button onClick={() => thirdBlockHandle()} className="custom-blue-button py-3 px-5 h6 radius mx-auto">
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <div />;
  }
};

export default Videos;
