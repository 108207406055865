import React, { Component } from "react";
import moment from "moment-timezone";

export default class Information extends Component {
  render() {
    const { poster, text, title, img } = this.props;
    return (
      <div className="Information">
        <div className="container">
          <div className="max-width horizontal-align">
            {poster && (
              <div className="d-flex pb-24">
                {poster.img && (
                  <img className="porster-img" src={poster.img} alt="" />
                )}
                <div>
                  <div className="poster-name">{poster.poster}</div>
                  <div className="poster-date">
                    {moment(poster.date).format("MMMM DD, YYYY")}
                  </div>
                </div>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: title }}
              className="main-title"
            />
          </div>
        </div>

        <div className="container">
          <div className="d-flex mb-4">
            {img && (
              <img className="main-image horizontal-align" src={img} alt="" />
            )}
          </div>

          <div
            className="context horizontal-align"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    );
  }
}
