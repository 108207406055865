import React, { useState, useEffect } from "react";
import Select from "react-select";

const CustomSelect = ({
  data,
  advancedFilters,
  index,
  handleValueChange,
  options,
}) => {
  const [shownOptions, setShownOptions] = useState([{ value: "", label: "" }]);
  const [selected, setSelected] = useState([{ value: "", label: "" }]);

  const CustomOption = ({ innerProps, isDisabled, data: { label } }) =>
    !isDisabled ? (
      <div className="option" {...innerProps}>
        <div className="label">{label}</div>
      </div>
    ) : null;
  const handleChangeSelect = (val) => {
    setSelected(val ? [val] : [{ value: "", label: "" }]);
    let filter = advancedFilters;
    filter[index] = {
      ...filter[index],
      value: val ? val.value : "",
    };
    handleValueChange(val ? val.value : "");
  };
  const handleChangeInput = (input, { action }) => {
    if (action === "input-change" && input.length > 0) {
      setShownOptions(
        options
          .map(
            (item) =>
              item.toLowerCase().includes(input.toLowerCase()) && {
                value: item,
                label: item,
              }
          )
          .filter((item) => item)
          .filter((_item, key) => key < 6)
      );
    }
  };

  useEffect(
    () => {
      if (data.value !== "" && data.value !== " ") {
        setSelected(data);
      }
    },
    [data]
  );
  return (
    <Select
      options={shownOptions}
      isClearable={true}
      name="Classification"
      value={selected}
      onChange={handleChangeSelect}
      onInputChange={handleChangeInput}
      components={{
        Option: CustomOption,
        IndicatorSeparator: false,
        DropdownIndicator: false,
      }}
      // placeholder={placeholder}
      className="react-select-container w-100"
      classNamePrefix="react-select"
    />
  );
};

export default CustomSelect;
