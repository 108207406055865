import React, { Component } from "react";
import Article from "./Article";
import Information from "./components/Information";
// import Quote from './components/Quote'
import moment from "moment-timezone";
import "./Article.scss";
import Spinner from "../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import GeneratedApi from "../components/functions/generatedApi";

export default class ArticleContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      getResults: [],
      loaded: false,
      noInfo: false,
      articlesArray: [],
      id: false,
      authorId: false,
      author: false,
    };
    if (props.user && props.user["cognito:groups"]) {
      if (
        props.user["cognito:groups"].find((entry) => {
          return entry === "corporate";
        }) ||
        props.user["cognito:groups"].find((entry) => {
          return entry === "admin";
        })
      ) {
        this.state.profileToggleVisible = true;
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    const location = this.props.history.location;
    if (location.pathname.split("/blog/")[1] !== "") {
      await GeneratedApi("", false, false)
        .get(`https://community.iotnation.com/wp-json/wp/v2/posts?slug=${location.pathname.split("/blog")[1]}`)
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({
              getResults: res.data[0],
              loaded: true,
              id: res.data[0].id,
              authorId: res.data[0].author,
            });
          } else {
            this.setState({
              noInfo: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            noInfo: true,
          });
        });

      if (this.state.id) {
        GeneratedApi("", false, false)
          .get("https://community.iotnation.com/wp-json/wp/v2/posts?tags=61")
          .then((res) => {
            this.setState({
              articlesArray: res.data
                .map(
                  (obj) =>
                    obj.id !== this.state.id && {
                      img: obj.jetpack_featured_media_url,
                      text: obj.title.rendered,
                      date: obj.date && `ON ${moment(obj.date).format("MMMM DD, YYYY")}`,
                      slug: obj.slug,
                    }
                )
                .filter(Boolean),
            });
          })
          .catch((error) => {
            console.log(error);
          });
        GeneratedApi("", false, false)
          .get(`https://community.iotnation.com/wp-json/wp/v2/users/${this.state.authorId}`)
          .then((res) => {
            this.setState({
              author: res.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.setState({
        noInfo: true,
      });
    }
  }

  render() {
    const { loaded, getResults, noInfo, articlesArray, author } = this.state;
    const poster = {
      img: author && author.avatar_urls[48],
      poster: author && author.name,
      date: getResults && getResults.date,
    };
    if (loaded) {
      return (
        <div className="Article">
          <Article articlesArray={articlesArray}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{getResults && getResults.title.rendered ? `${getResults.title.rendered} | IoT Nation` : "IoT Nation"}</title>
            </Helmet>
            <Information
              img={getResults && getResults.jetpack_featured_media_url}
              title={getResults.title.rendered}
              poster={poster}
              text={getResults && getResults.content && getResults.content.rendered}
            />
          </Article>
        </div>
      );
    } else if (noInfo) {
      return "";
    } else {
      return <Spinner />;
    }
  }
}
