import React, { useState } from 'react';
import Button from './components/Button.jsx'
import SubscriptionPlans from './components/SubscriptionPlans.jsx'
import '../components/scss/breadcrumbs.scss'
import style from "./IotAce.module.scss"
import {Helmet} from "react-helmet";
import iotAceJSON from '../content/iotAce.json'

const IotAce = () => {
  const [ type, setType ] = useState("selected-registered")

  return (
    <div className="IotAce">
        <Helmet>
            <meta charSet="utf-8" />
            <title>IoT Ace (TM) Market Intelligence</title>
        </Helmet>
        <div className="container">
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href='/'>Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Pricing</li>
            </ol>
            </nav>  
        </div>

        <div className="top-panel mb-4">
            <h1 className="m-auto text-white font-weight-bold">IoT Ace™ Market Intelligence</h1>
        </div>
        <div className="container">
            <p className="mb-4 line-h-17 font-weight-bold h4">IoT Ace™ helps scale your IoT business with B2B intelligence and data-driven prospecting for marketing, sales, business development, and investment professionals.</p>
            <p className="mb-4 line-h-17">IoT Ace™ comprehensively tracks the Internet of Things (IoT) ecosystem, aggregating data in near real-time from a multitude of sources. IoT Ace™ tracks the activity of 25,000+ IoT-related global companies. Its proprietary IoT Ace™ Market Visibility Performance (MVP) Ranking scores companies by their measured industry activity, a proxy for marketing spend. Find a company’s...</p>

            <div className="row">
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Conference Sponsorships & Attendance by Year</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Vertical segment footprint</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Industry Partnerships</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Technologies implemented</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Organization Memberships</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Conference Sponsorship & Exhibition history</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Use Cases across verticals</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Social Media Engagement</li>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-inline-block mr-2 custom-list-dot" /><li className={`d-inline-block ${style.list}`}>Contact data - including job title, location and email</li>
                </div>
            </div>
        </div>
        <div className="d-flex mt-5 mb-4 top-panel">
            <h1 className="mx-auto font-weight-bold text-white">Subscriptions</h1>
        </div>
        <div className="container m-b-40 d-none d-md-block">
            <div className="d-flex">
                <div className="col pr-md-0"></div>

                <div className="col-3 pr-md-0">
                    <a href="/signup">
                        <Button handlePlans={setType} selected={true} type="selected-registered" name={'Registered'} />
                    </a>
                </div>
                <div className="col-3 pr-md-0">
                    <a
                       className="margin-auto"
                      target="_blank"
                      href={iotAceJSON["pro-button"] ? iotAceJSON["pro-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Pro Subscription"}
                    >
                        <Button handlePlans={setType} selected={true} type="selected-pro" name={'Pro'} />
                    </a>
                </div>
                <div className="col-3 pr-md-0">
                    <a
                      className="margin-auto"
                      target="_blank"
                      href={iotAceJSON["enterprise-button"] ? iotAceJSON["enterprise-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Enterprise Subscription"}
                    >
                        <Button handlePlans={setType} selected={true} type="selected-enterprise" name={'Enterprise'} />
                    </a>
                </div>
            </div>
            <SubscriptionPlans type={type} />
            <div className="d-flex pt-3">
               <div className={`${style.half_mark} ${style[`${type}-mark`]}`}></div> <div className="ml-2"> - Indicates partial data access</div>
            </div>  
        </div>

        <div className="container m-b-40 d-md-none">
            <div className="row">
                <div className="col-6 col-sm-4 pr-md-0">
                    <Button handlePlans={setType} selected={true} type="selected-registered" name={'Registered'} />
                </div>
                <div className="col-6 col-sm-4 pr-md-0">
                    <Button handlePlans={setType} selected={true} type="selected-pro" name={'Pro'} />
                </div>
                <div className="col-6 col-sm-4 offset-3 offset-sm-0 pr-md-0">
                    <Button handlePlans={setType} selected={true} type="selected-enterprise" name={'Enterprise'} />
                </div>
            </div>

            <SubscriptionPlans type={type} />
            <div className="d-flex pt-3">
               <div className={`${style.half_mark} ${style[`${type}-mark`]}`}></div> <div className="ml-2"> - Indicates partial data access</div>
            </div>
            
        </div>

        <div className="container m-b-40 d-none d-md-block">
            <div className="d-flex mt-2">
                <div className="col pr-md-0"></div>

                <div className="col-3 pr-md-0">
                    <a href="/signup">
                        <Button handlePlans={setType} bottom type="selected-registered" name={'Free Trial'} />
                    </a>
                </div>
                <div className="col-3 pr-md-0">
                    <a
                      className="margin-auto"
                      target="_blank"
                      href={iotAceJSON["pro-button"] ? iotAceJSON["pro-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Pro Subscription"}
                    >
                        <Button handlePlans={setType} bottom type="selected-pro" name={'Subscribe'} />
                    </a>
                </div>
                <div className="col-3 pr-md-0">
                    <a
                      className="margin-auto"
                      target="_blank"
                      href={iotAceJSON["enterprise-button"] ? iotAceJSON["enterprise-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Enterprise Subscription"}
                    >
                        <Button handlePlans={setType} bottom type="selected-enterprise" name={'Contact Us'} />
                    </a>
                </div>
            </div>
        </div>

        <div className="container d-md-none">
            <div className="row">
                <div className=" mt-2 col-6 col-sm-4 offset-6 offset-sm-8">
                    <a
                      className="margin-auto"
                      target={type === 'selected-registered' ? "_self" : "_blank"}
                      href={
                        type === 'selected-registered' ?
                          "/signup"
                        : type === 'selected-pro'?
                          iotAceJSON["pro-button"] ? iotAceJSON["pro-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Pro Subscription"
                        : iotAceJSON["enterprise-button"] ? iotAceJSON["enterprise-button"] : "mailto:subscriptions@iotnation.com?subject=IoT Ace Enterprise Subscription"
                      }
                    >
                        <Button handlePlans={setType} bottom type={type} name={type === 'selected-registered'?' Free Trial':type === 'selected-pro'?'Subscribe': 'Contact Us'} />
                    </a>
                </div>
            </div>
        </div>
        <hr className="mt-5"/>
    </div>
  )
}

export default IotAce
