// /*global intlTelInput*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Subscription } from '../components/Subscribe/Subscribe';
import {Helmet} from "react-helmet";
import "./Signup.scss";

function PasswordErrors(props) {
    const { password } = props;
    if(password.length === 0) {
        return false;
    }
    let errors = [];
    if(!password.match(/\d+/g)) {
        errors.push('Password must contain a number');
    }else if(!password.match(/[A-Z]/g)) {
        errors.push('Password must contain uppercase');
    }else if(!password.match(/[a-z]/g)) {
        errors.push('Password must contain lowercase');
    }else if(password.length < 8) {
        errors.push('Password too short');
    }
    return (
        <div className="validation-error">
            {errors.map((entry, key) => {
                return <span key={key}>{entry}</span>
            })}
        </div>
    )
}

var iti;

export default class Signup extends React.PureComponent {
    
    componentDidMount() {
        var input = document.querySelector("#phoneNumber");
        iti = window.intlTelInput(input, {separateDialCode: true, utilsScript: '/js/utils.js'});
    }

    render() {
        
        const { formData, handleInputChange, handleChangePhone, handleSubmit, loading, user, opacity } = this.props;
        return (
            <div style={opacity&&{opacity:opacity}} className="Signup">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Sign up | IoT Nation</title>
                </Helmet>
                <div className="container">
                <div className="col-sm-10 offset-md-1 col-md-8 offset-lg-2">
                        <div className="Rectangle">
                            <div className="row">
                                <div className="col-6">
                                    <Link to="/login"  className="login-btn"><p style={{margin: "auto"}}>Login</p></Link>
                                </div>
                                <div className="col-6">
                                    <div className="signup-btn"><p style={{margin: "auto"}}>Sign up</p></div> 
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <form onSubmit={handleSubmit}>                        
                                        <div className="form-group">
                                            <input value={formData.username}  name="username" required type="email" className="form-control" placeholder="Email" onChange={handleInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input value={formData.password} name="password" required type="password" className="form-control" placeholder="Password" onChange={handleInputChange}/>
                                            <div>
                                                <PasswordErrors password={formData.password}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-6">
                                                    <input value={formData.firstName} name="firstName" required type="text" className="form-control" placeholder="First Name" onChange={handleInputChange}/>
                                                </div>
                                                <div className="col-6">
                                                    <input value={formData.lastName} name="lastName" required type="text" className="form-control" placeholder="Last Name" onChange={handleInputChange}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input value={formData.companyName} name="companyName" required type="text" className="form-control" placeholder="Company Name" onChange={handleInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input value={formData.phoneNumber} name="phoneNumber" id="phoneNumber" required type="phone" className="form-control" placeholder="Mobile Number" onChange={() => {handleChangePhone(iti)}}/>
                                        </div>
                                        <div className="form-group">
                                            <input value={formData.activationCode === 'BLANK_STRING' ? '':formData.activationCode} name="activationCode" type="text" className="form-control" placeholder="Sign Up Code (Optional)" onChange={handleInputChange}/>
                                        </div>
                                        <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                            <button disabled={loading} className={`btn btn-primary`} type="submit">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>  
                            
                        </div>                         
                    </div>
                </div>
                <div style={{marginTop: '50px'}}>
                    <Subscription />
                </div>
            </div>        
        )
    }
}