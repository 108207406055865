import React from 'react';
import Spinner from '../components/Spinner/Spinner';

export class NewPasswordInput extends React.PureComponent {
    render() {
        const { handleInputChange, handleSubmit, data, loading } = this.props;
        if(loading) {
            return <Spinner height={'calc( 100vh - ( 64px + 67px ) )'}/>;
        }
        return (
            <div className="container">
                <div className="col-8 offset-sm-2">
                    <h1>Input new password</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input value={data.password} name="password" required type="password" className="form-control" placeholder="New password" onChange={handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <input value={data.passwordAgain} name="passwordAgain" required type="password" className="form-control" placeholder="Retype new password" onChange={handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <button className={`${loading ? 'disabled' : ''} btn btn-primary`} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )        
    }
}

export class PasswordReset extends React.PureComponent {
    render() {
        const { handleInputChange, handleSubmit, formData, loading } = this.props;
        if(loading) {
            return <Spinner height={'calc( 100vh - ( 64px + 67px ) )'}/>;
        }
        return (
            <div className="container">
                <div className="col-8 offset-sm-2">
                    <h1>Password Reset</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input value={formData.username} name="username" required type="email" className="form-control" placeholder="Email address" onChange={handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <button className={`${loading ? 'disabled' : ''} btn btn-primary`} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}