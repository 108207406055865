import React, { Component } from 'react'

import './SpeakerSide.scss';

export default class SpeakerSideBlock extends Component {
  render() {
    const {
      firstName,
      lastName,
      speaker,
      title,
      companyName
    } = this.props
    return (
      <div className="">
        <div className="side-block-border-no-radius block-padding" > 
            <div className="row no-gutters border-buttom">
                <div className="col-auto img-wrap">
                    <img src={this.props.src !== "" ? this.props.src : '/images/avatar.png'} alt=""/>
                </div>
                <div className="col">
                  <p className="person-name">{firstName} {lastName}{firstName || lastName?<br/> :''}</p>
                  <p className="profesion">{speaker}{speaker?<br/>:''}
                  {title}{title?<br />:''} 
                  {companyName}</p>
                </div>
            </div>
        </div>
      </div>
    )
  }
}