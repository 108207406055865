import React from 'react'
import styles from './GoogleMapStyles.json'
import { withGoogleMap, GoogleMap } from "react-google-maps";
import MapMarker from "./MapMarker"
const Map = (withGoogleMap((props) =>{
const {lon:lng, lat} = props.location   

  const markers =  <MapMarker location={{lat, lng}}/>;
  return (     
      <>
        <GoogleMap
          defaultZoom={-1}
          center={ { lat, lng } }
          defaultOptions={{ styles: styles }}
          >
          {markers}
        </GoogleMap>       
      </> 
    );
  }
))

export default Map;
