import React, { Component } from 'react'
import { Input } from "reactstrap";
import { DateRangePicker } from "react-dates";
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa'
import ClearButton from "../../components/ClearButton/ClearButton";
import { getMainFilters } from '../functions/eventFilters'
import cx from 'classnames'
import './Filter.scss';



export default class Filter extends Component {
    constructor(props){
        super(props)

        this.state = {
            dropDown: false,
            activeIndex: 0,
            type: false,
            date: false,
            goBackFilter: false,
            focused: false,
            focusedEnd: false,
            searchOpened: false
        };
    }
    loadData(data){
        const filters = data&&data.filters
        this.setState(Object.assign(
          {dataLoaded: true}, 
          {
            limit:this.state.limit,
            filters: filters&&filters.length > 0
            ?[
              ...getMainFilters(filters),
              ...filters.filter(filter=>!filter.mainAttribute)
            ]
            :getMainFilters()
          }))
          filters&&this.props.history.replace({ ...this.props.location, state:undefined});
          
      }
    handleBackMainFilter = () => {
        this.setState({
          type: false,
          date: false,
          goBackFilter: false,
          dropDown: true,
        })
      }
    toggleNavbar = () => {
        if(!this.state.categories && !this.state.type && !this.state.location && !this.state.date){
            this.setState({
            dropDown: !this.state.dropDown
            });
        }
    }
    handleCloseFilter = () => {
        this.setState({ 
            dropDown: false,
            type: false,
            date: false,
            goBackFilter: false,
            searchOpened: false
        });
    }
    handleType = () => {
        this.setState({ 
            type: !this.state.type,
            goBackFilter: true,
        });
    }
    handleDate = () => {
        this.setState({ 
            date: !this.state.date,
            goBackFilter: true,
        });
    }
    handleSearch = () => {
        this.setState({
            searchOpened: !this.state.searchOpened
        })
    }
    render() {
        const {
            handleSelectedSegments,
            handleSelectAllSegments,
            onDatesChange,
            onCalendarFocusChangeMobile,
            handleShowAllEvents,
            handleInput,
            searchInput,
            handleSearchSubmit,
          } = this.props;
          const {
            dateStart,
            dateEnd,
            focusedInput,
            focusedInputMobile,
            selectedEventTypes,
            selectedSegments,
            locationFilter,
          } = this.props.data;
          const selectedSegmentsLength = selectedSegments.value.length
      
          const calendarClasses = cx({
            "col-lg-4 vertical-align":true,
            "initial-calendar":!dateStart && !dateEnd,
            "active-calendar":focusedInput
          })

        const isLocationSelected = locationFilter?1:0
        const isDateStartPicked = dateStart?1:0
        const isDateEndPicked = dateEnd?1:0
        const isFiltersChanged =selectedSegmentsLength === 4 === 0 && isDateStartPicked === 0 && isDateEndPicked === 0? false: true
        return (
            <div>
                {/* closed filter */}
                <div className="Filter container">
                    <div className="filter-block d-block d-sm-block d-md-none" style={{position: 'relative', padding: '0px'}}>
                        <div  className="row mobile-filter">
                            {
                                this.state.searchOpened? 
                                    <>
                                        <FaAngleLeft onClick={this.handleCloseFilter} className="display-flex" style={{cursor: 'pointer' ,marginRight: "10px", height: '1.5em', width: '1.5em', margin: 'auto'}} />
                                        <form onSubmit={handleSearchSubmit} className="col-11 vertical-align search-box unset-shadow">
                                            <div className="row">
                                            <div className="input-group">
                                                <span className="search-icon">
                                                <Input className="form-control search-border" 
                                                type="search"
                                                onChange={handleInput}
                                                value={searchInput}
                                                name="searchInput"
                                                placeholder="Search events, webinars, hackathons and more"
                                                aria-label="Search events, webinars, hackathons and more"/>
                                                </span>                    
                                                <div className="input-group-prepend">
                                                <button className="btn search d-none d-md-block" type="submit">
                                                    Search
                                                </button>
                                                <button className="btn search d-block d-sm-block d-md-none" type="submit"></button>
                                                </div>
                                            </div>
                                            </div>
                                        </form>
                                    </>
                                
                                :
                                <>
                                    <div onClick={this.handleSearch} className={`col-6 vertical-align padding-lr-7 search-mobile`}>
                                        <div className="display-flex vertical-align w-100 justify-center">
                                            <img alt='' src="/images/zoom.svg" /> <div className="margin-l-10">Search</div>
                                        </div>              
                                    </div>
                                    <div onClick={this.toggleNavbar} className={`col-6 vertical-align padding-lr-7 filter ${this.state.dropDown && 'hide'}`}>
                                    <div className="pointer"> 
                                        {this.state.type || this.state.date?<FaTimes onClick={this.handleCloseFilter} style={{color: 'grey', height: '1.5em', width: '1.5em'}} />:<img alt="" src="/images/filter-tool.svg"/>}                             
                                        Filters
                                        <span className="badge"><span>{isFiltersChanged?selectedEventTypes.value.length + selectedSegmentsLength + isLocationSelected + isDateStartPicked + isDateEndPicked:0 }</span></span>
                                    </div>
                                    <a target="_blank" href="https://forms.gle/vsCt94LQhSDGbxDx5">
                                        <div className="add-block-filter">
                                            <div className="add-sign-horizontal"></div>
                                            <div className="add-sign-vertical"></div>
                                        </div>  
                                    </a>
                                    </div>
                                </>
                            }
                            
                        </div>
                    </div> 
                    
                </div>
                {/* opened filter */}
                {this.state.categories || this.state.type || this.state.location || this.state.date || this.state.dropDown?
                    <div className={'top-screen Filter'}>
                    <div className="filter-block d-block d-sm-block d-md-none" style={{position: 'relative'}}>
                        <div className="row row-pad">
                            <div onClick={this.handleBackMainFilter} className="col-8 vertical-align">
                                <div>                                  
                                {this.state.goBackFilter&&<FaAngleLeft style={{cursor: 'pointer' ,marginRight: "10px"}} />}{this.state.type?'Segment Type':this.state.date?'Date':'Filters'}
                                {
                                    this.state.categories? <span className="badge"><span>{selectedEventTypes.value.length}</span></span>:
                                    this.state.type? <span className="badge"><span>{selectedSegmentsLength}</span></span>:
                                        this.state.location? <span className="badge"><span>{isLocationSelected}</span></span>:
                                        this.state.date? <span className="badge"><span>{isDateStartPicked + isDateEndPicked}</span></span>:''
                                }
                                </div>
                            </div>
                            <div className="col-4 vertical-align text-right">
                                <FaTimes onClick={this.handleCloseFilter} style={{cursor: 'pointer', color: 'grey', height: '1.5em', width: '1.5em'}} />
                            </div>
                        </div>
                        <div className="row divider"></div>
                        {
                          this.state.categories || this.state.type || this.state.location || this.state.date || this.state.searchOpened ? '':
                          <>
                          <div className="row  row-pad ">
                            <div className="col vertical-align">
                                <form onSubmit={handleSearchSubmit} className="col-md-12 vertical-align search-box unset-shadow">
                                    <div className="row">
                                    <div className="input-group">
                                        <span className="search-icon">
                                        <Input className="form-control search-border" 
                                        type="search"
                                        onChange={handleInput}
                                        value={searchInput}
                                        name="searchInput"
                                        placeholder="Search events, webinars, hackathons and more"
                                        aria-label="Search events, webinars, hackathons and more"/>
                                        </span>                    
                                        <div className="input-group-prepend">
                                        <button className="btn search d-none d-md-block" type="submit">
                                            Search
                                        </button>
                                        <button className="btn search d-block d-sm-block d-md-none" type="submit"></button>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                            <div className="row divider"></div>  
                            </>
                        }
                        
                        {/* goBackFilter */}
                        {this.state.categories || this.state.type || this.state.location || this.state.date?'':
                        this.state.dropDown?
                        <div isopen={ `"${this.state.dropDown}"` }> 
                        <div className="row divider"></div>
                        <div className="row row-pad pointer" onClick={this.handleType}>
                            <div className="col-8 vertical-align">
                            Segment
                            <span className="badge"><span>{selectedSegmentsLength}</span></span>
                            </div>
                            <div className="col-4 vertical-align text-right">
                            <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                            </div>
                        </div>
                        <div className="row divider"></div>
                        <div className="row divider"></div>
                        <div className="row row-pad pointer" onClick={this.handleDate}>
                            <div className="col-8 vertical-align">
                            Date
                            <span className="badge"><span>{isDateStartPicked + isDateEndPicked }</span></span>
                            </div>
                            <div className="col-4 vertical-align text-right">
                            <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                            </div>
                        </div>
                        <div className="row divider"></div>
                        <div className="row row-pad">
                            <div className="control-filters vertical-align">
                            <span className="btn clear" onClick={() => {handleSelectAllSegments(); handleShowAllEvents(); onDatesChange({
                                startDate:null,
                                endDate:null
                                })} }>Clear All Filters</span>
                            </div>
                        </div>
                        </div> :''} 
                        {this.state.type?
                        <div isOpen={ this.state.type } className="type-filter">                
                            {
                            selectedSegments.values.map((segment, key)=>(
                                <div key={key}>
                                    <div className="row row-pad">
                                        <div className="col-12 vertical-align">
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" value="" id={segment} name={segment}
                                            onChange={handleSelectedSegments}
                                            checked={
                                            selectedSegments.value.includes(segment)
                                            }/>
                                            <label className="custom-control-label" for={segment}>
                                            {segment}
                                            </label>
                                        </div>
                                        </div>
                                    </div> 
                                    <div className="row divider"></div>
                                </div>                   
                            ))
                            }
                        </div>              
                        :''}
                        {this.state.date?
                        <div isopen={ `"${this.state.dropDown}"` } className="date-filter">
                            <div className={calendarClasses}>
                            <div className={`ClearButton-container small-date `}>
                                <ClearButton 
                                manualPadding="0 18px" 
                                active={focusedInputMobile} 
                                clearInfo={()=>onDatesChange({
                                startDate:null,
                                endDate:null
                                })} 
                                hidden={!dateStart && !dateEnd}  />
                                <DateRangePicker
                                //showClearDates
                                
                                minimumNights={1}
                                navPrev={<div className="prev"><FaAngleLeft fill="#788ab3"/></div>}
                                navNext={<div className="next"><FaAngleRight fill="#788ab3" /></div>}
                                startDatePlaceholderText="Start: "
                                endDatePlaceholderText="End: "
                                startDate={dateStart}
                                startDateId="start_date_id_1"
                                endDate={dateEnd}
                                endDateId="end_date_id_1"
                                onDatesChange={onDatesChange}
                                focusedInput={focusedInputMobile}
                                onFocusChange={onCalendarFocusChangeMobile}
                                displayFormat="ddd, MMM D, YYYY"
                                orientation="vertical"
                                small
                                />
                            </div> 
                            </div>
                        </div>
                        :''}
                        </div>
                    </div>:''}
                    {/* end or filters top */}
                    
            </div>
        )
    }
}
