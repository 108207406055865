import React from 'react'
import faker from 'faker/locale/en_US'

const FakeData = ({data, toFake, fakeData, returnJustValue,value}) =>{
    if(data){

        return <span>{data}</span>
    
    }else{

       if(toFake) return !returnJustValue?<span className='faked-data'>{faker.fake(`{{${toFake}}}`)}</span>:faker.fake(`{{${toFake}}}`);
       if(fakeData) return !returnJustValue? <span className='faked-data'>{fakeData()}</span>:fakeData();
       if(value)  return !returnJustValue? <span className='faked-data'>{value}</span>:value
       return false
       
    }
    
}

export default FakeData