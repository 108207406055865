/*global AmazonCognitoIdentity*/
import Cookies from "universal-cookie";
import * as jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import logout from "./logout";

const cookies = new Cookies();

export default async function getSession(user, saveState) {
  const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: cookies.get("refreshToken") });

  if (refreshToken.token && user.auth_time + 43200 > moment().unix()) {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    const cognitoUser = await userPool.getCurrentUser();

    await cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        logout(user);
      }
      if (session) {
        const newToken = session.idToken.jwtToken;
        cookies.set("jwtToken", newToken.split("&")[0], {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
        saveState({ user: jwtDecode(newToken) });
      }
    });
  } else {
    logout(user);
  }

  return "";
}
