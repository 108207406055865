import React from 'react'
import './Spinner.scss'

function Spinner({ height, position, addedClass }) {
    return (
        <div style={position?{position: 'absolute', top: position, left: "42%"}:{height}} className={`justify-content-center align-items-center Spinner ${addedClass? addedClass : 'd-flex'}`}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Spinner
