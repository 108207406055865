import React from 'react'
import {Link} from 'react-router-dom'
import ResultsTable from './components/ResultsTable/ResultsTable'
import RangeChange from './components/RangeChange/RangeChange'
import StatsDisclaimer from '../components/SubscriberDisclaimer/StatsDisclaimer';
import Spinner from '../components/Spinner/Spinner';
import {Helmet} from "react-helmet";

export default class CorpEcoStats extends React.PureComponent {

    render() {
        const {dataRange,results, sort, range, fakeData} = this.props.data;
        const {handlePageChange, handleSortChange, handleRangeChange, user, loading} = this.props;
        let show = '';
            if(loading) {
                show = <Spinner height={'calc( 100vh - ( 64px + 67px ) )'}/>
            } else {
                show = (
                    <>
                    <RangeChange initialValue={range} handleRangeChange={handleRangeChange} dataRange={dataRange}/>
                    <ResultsTable data={{results, sort, fakeData}} handleSortChange={handleSortChange} handlePageChange={handlePageChange}></ResultsTable>
                    <div className="row align-items-center inquire-eco-systems">
                        <div className="col-2 d-none d-md-block"><img className="networking-2" src="/images/networking-2.svg" alt=""/></div>
                        <div className="col-sm-9 col-md-7 col-lg-7 col-xl-7 text-content">
                            <h2 className="title">Don't see your Corporate Ecosystem here?</h2>
                            <p className="text">Find out how you can add new information or suggest additions to our database.</p>
                        </div>
                        <div className="button-wrap col-3 d-none d-md-flex justify-content-end">
                            <a className="inquire-btn"  href="mailto:support@iotnation.com" rel="noopener noreferrer" target="_blank">Inquire here</a>
                        </div>
                        <div className="button-wrap d-flex w-100 d-md-none">
                            <a className="inquire-btn"  href="mailto:support@iotnation.com" rel="noopener noreferrer" target="_blank">Inquire here</a>
                        </div>
                    </div> 
                    </>
                )
            }
        return (
            <div className="Corp-Eco-Stats"> 
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>IoT Corporate Ecosystems Leaderboard</title>
                </Helmet>   
                <StatsDisclaimer user={user}/>
                <div className="container">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Ecosystems</li>
                    </ol>
                    </nav>  
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center welcome-screen">
                        <div className="col-auto text-box">
                            <h1>Corporate Ecosystem Stats</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {show}
                </div>
            </div>
            

        )
    }
}
