import React from "react";
import SmartFinder from "./SmartFinder";

class SmartFinderContainer extends React.Component {
  render() {
    return <SmartFinder />;
  }
}

export default SmartFinderContainer;
