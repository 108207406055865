import React from 'react'
import Modal from 'react-modal';
import TermsOfService from '../../../GeneralPages/TermsOfService';
import { withRouter} from 'react-router-dom'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';

import './TOSModal.scss';

const customStyles = {
    content : {
      top                   : '2px',
      left                  : '50%',
      right                 : 'auto',
      bottom                : '2%',
      marginRight           : '-50%',
      transform             : 'translate(-50%, 0%)',
      zIndex                : '999',
    },
    overlay : {
        top                   : '67px',
        left                  : '0px',
        right                 : '0px',
        bottom                : '0%'
    }
  };

  function ConfirmButtons(props) {
      const {visible, handleInputChange, toggleTOSModal, handleResetScrollToBottom} = props;
      if(!visible) {
        return (
        <>
            
            <div className="d-none d-md-block">
                <span>Do you agree to these Terms of Service?</span>
                <button onClick={() => {toggleTOSModal(false); handleResetScrollToBottom()}} key="button" type="button" name="agree" value={false} className="negative">I DECLINE</button>
                <Tippy 
                    interactive={true} 
                    theme="iot" 
                    content={"Scroll to the bottom to proceed."
                }><span><button key="submit" disabled onClick={handleInputChange} type="submit" name="agree" value={true}>I ACCEPT</button></span></Tippy>
            </div>
            <div className="d-block d-md-none m-t-10">
                <span>Do you agree to these Terms of Service?</span>
                <div className="display-flex m-t-10">
                    <button onClick={() => {toggleTOSModal(false); handleResetScrollToBottom()}} key="button" type="button" name="agree" value={false} className="negative">I DECLINE</button>
                    <Tippy 
                        interactive={true} 
                        theme="iot" 
                        content={"Scroll to the bottom to proceed."
                    }><span><button key="submit" disabled onClick={handleInputChange} type="submit" name="agree" value={true}>I ACCEPT</button></span></Tippy>  
                </div>
                
            </div>
            
        </>
        );
      }
      return (
        <>
            <div className="d-none d-md-block">
                <span>Do you agree to these Terms of Service?</span>
                <button onClick={() => {toggleTOSModal(false); handleResetScrollToBottom()}} key="button" type="button" name="agree" value={false} className="negative">I DECLINE</button>
                <button key="submit" onClick={handleInputChange} type="submit" name="agree" value={true}>I ACCEPT</button>  
            </div>
            <div className="d-block d-md-none m-t-10">
                <div className="display-flex m-t-10">
                    <button onClick={() => {toggleTOSModal(false); handleResetScrollToBottom()}} key="button" type="button" name="agree" value={false} className="negative">I DECLINE</button>
                    <button key="submit" onClick={handleInputChange} type="submit" name="agree" value={true}>I ACCEPT</button>  
                </div>
                
            </div>
            
        </>
      )
  }

 class TOSModal extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            confirmButtonsVisible: false
        }
        this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
        this.paneDidMount = this.paneDidMount.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResetScrollToBottom = this.handleResetScrollToBottom.bind(this);
    }

    componentDidMount(){
        Modal.setAppElement('body')
    }

    handleScrollToBottom() {
        this.setState({
            confirmButtonsVisible: true
        });
    }

    handleResetScrollToBottom() {
        this.setState({
            confirmButtonsVisible: false
        });
    }

    paneDidMount = (node) => {    
        if(node) {      
          node.addEventListener("scroll", this.handleScroll.bind(this));      
        }
      }
    
      handleScroll = (event) => {
        var node = event.target;
        const bottom = node.scrollHeight - node.scrollTop - 10 <= node.clientHeight;
        if (bottom) {      
          this.handleScrollToBottom();
        }    
      }


    render() {
        const {tosModalIsOpen, handleTOSModal, tos, handleInputChange, toggleTOSModal} = this.props;
        return (
            <div className="Main-tos">
                <Modal
                    isOpen={tosModalIsOpen && this.props.location.pathname !== '/terms-of-service'}
                    style={customStyles}
                >
                    <div className="TOS-Modal">
                        <h1 className="d-none d-md-block">Terms of Service</h1>
                        <h3 className="d-block d-md-none margin-0">Terms of Service</h3>
                        <div className="container terms-description">
                            <div className="row">
                                <div className="col">
                                    <div className='tos' ref={this.paneDidMount}>
                                        <TermsOfService simplified /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container tos-sign">
                            <form onSubmit={handleTOSModal}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                        <label className="header d-none d-md-block">Authorized user</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group margin-0">
                                                    <input disabled value={tos.firstName} required id="firstName" name="firstName" type="text" className="form-control" placeholder="First Name" />
                                                    <label htmlFor="firstName" className="margin-0-sm">First Name</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group margin-0">
                                                    <input disabled value={tos.lastName} required id="lastName" name="lastName" type="text" className="form-control" placeholder="Last Name" />
                                                    <label htmlFor="lastname" className="margin-0-sm">Last Name</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <label className="header d-none d-md-block">Licensee</label>
                                        <div className="form-group margin-0">
                                            <input disabled value={tos.companyName} required name="companyName" type="text" className="form-control" placeholder="Company Name" />
                                            <label htmlFor="companyName" className="margin-0-sm">Company Name</label>
                                        </div>
                                    </div>
                                    <div className="col-12 agreement agreement-right d-none d-md-flex">
                                        <div className="wrapper">
                                            <ConfirmButtons handleResetScrollToBottom={this.handleResetScrollToBottom} toggleTOSModal={toggleTOSModal} handleInputChange={handleInputChange} handleTOSModal={handleTOSModal} visible={this.state.confirmButtonsVisible}/>
                                        </div>
                                    </div>
                                    <div className="col-12 agreement d-flex d-md-none justify-center">
                                        <div className="wrapper">
                                            <ConfirmButtons handleResetScrollToBottom={this.handleResetScrollToBottom} toggleTOSModal={toggleTOSModal} handleInputChange={handleInputChange} handleTOSModal={handleTOSModal} visible={this.state.confirmButtonsVisible}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>  
                    </div>
                </Modal>    
            </div>
        )
    }
}

export default withRouter(TOSModal);
