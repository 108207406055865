/*global TradingView*/
import React from 'react';

export default class StockGraph extends React.PureComponent {
    componentDidMount() {
        const { companyProfile, stockSymbol } = this.props;
        if(stockSymbol && TradingView && TradingView.MediumWidget) {
        new TradingView.MediumWidget(
            {
            "container_id": "tv-medium-widget",
            "symbols": [
              [
                escape(companyProfile.name) || '',
                stockSymbol || ''
              ],
            ],
            "greyText": "Quotes by",
            "gridLineColor": "#e9e9ea",
            "fontColor": "#83888D",
            "underLineColor": "#dbeffb",
            "trendLineColor": "#4bafe9",
            "height": "400",
            "width": "100%",
            "locale": "en"
          }
            );
        }
    }
    render() {
        const { isFinDataFaked, companyProfile, stockSymbol } = this.props;
        if(!stockSymbol || !TradingView || !TradingView.MediumWidget) {
            return '';
        }
        return (
            <div className={`row fin-data ${isFinDataFaked?'blurred-box':''}`}>
                <div className="col">
                    <div id="tv-medium-widget"></div>
                    <div className="tradingview-widget-copyright">
                        <span className="blue-text">{companyProfile.name}</span> by TradingView
                    </div>
                </div>
            </div>
        )
    }
}