import React from 'react';

function Link(props) {
    const { type, link } = props;
    if (!link) {
        return <span><img className={'grey-out'} alt={type} src={`/images/logo-${type}.svg`}/></span>
    }
    return <span><a target="_blank" href={link}><img alt={type} src={`/images/logo-${type}.svg`}/></a></span>;
}

function GeneralLinks(props) {
    const {twitter, facebook, linkedin, blog} = props.links?props.links:'';
    return (
        <>
            <div className="d-none d-md-block col-5 right-bord">
                <p>General Links</p>
                <div className="row">  
                    <Link link={twitter} type={'twitter'}/>   
                    <Link link={facebook} type={'facebook'}/>   
                    <Link link={linkedin} type={'linkedin'}/>   
                    <Link link={blog} type={'blog'}/>    
                </div>
            </div>
            <div className="d-block d-md-none col-12">
                <p>General Links</p>
                <div className="row">  
                    <Link link={twitter} type={'twitter'}/>   
                    <Link link={facebook} type={'facebook'}/>   
                    <Link link={linkedin} type={'linkedin'}/>   
                    <Link link={blog} type={'blog'}/>    
                </div>
            </div>
        </>
    )
}

export default GeneralLinks
