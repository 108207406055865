import React, { Component } from "react";
import RowSubscriptions from "./RowSubscriptions.jsx";
import RowSubscriptionsTablet from "./RowSubscriptionsTablet.jsx";
import "./SubscriptionPlans.scss";

export default class SubscriptionPlans extends Component {
  render() {
    const { type } = this.props;
    return (
      <>
        {/* for pc screens */}
        <div className={`SubscriptionPlansIot mb-4 d-none d-md-block ${type}`}>
          <div className="row blue-line">
            <div className="col-3 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Intelligently Identify & Prioritize Prospects
              </div>
            </div>
            <div className="col-9 w-100 h-100" />
          </div>
          <div className="row">
            <div className="col-3">
              {" "}
              <div className="block">Number of Companies Covered</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center registered">1,500</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center pro">25,000+</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center enterprise">25,000+</div>{" "}
            </div>
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="IoT Ace™ MVP Score"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Advanced Searching & Filtering"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-none d-md-block ${type}`}>
          <div className="row blue-line">
            <div className="col-3 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Research Relevant Info about Prospects
              </div>
            </div>
            <div className="col-9 w-100 h-100" />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="Industry Vertical (Application) Tags"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Technology Tags"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="Real-time Company Twitter Feeds"
              registered="mark"
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Real-time IoT Ecosystem Tracking"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="Real-time IoT Industry Groups Tracking"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Conference Participation Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="Organization Membership Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Corporate Ecosystem Partnership Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-none d-md-block ${type}`}>
          <div className="row blue-line">
            <div className="col-3 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Find Key Stakeholders and Contact Info
              </div>
            </div>
            <div className="col-9 w-100 h-100" />
          </div>
          <div className="row grey-background-color">
            <div className="col-3">
              {" "}
              <div className="block">Number of Contacts</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center registered" />{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center pro">50,000</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center enterprise">50,000+</div>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              {" "}
              <div className="block">License</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block font-weight-bold center registered">
                Trial
              </div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block font-weight-bold center pro">
                1 User
              </div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block font-weight-bold center enterprise">
                5+ Users
              </div>{" "}
            </div>
          </div>
          <div className="row grey-background-color">
            <RowSubscriptions
              name="Dedicated Support"
              registered=""
              pro=""
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptions
              name="Data Exporting"
              registered=""
              pro=""
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-none d-md-block ${type}`}>
          <div className="row blue-line">
            <div className="col-3 font-weight-bold">
              <div className="d-flex h-100 align-center">Pricing</div>
            </div>
            <div className="col-9 w-100 h-100" />
          </div>
          <div className="row">
            <div className="col-3">
              {" "}
              <div className="block">Annual</div>{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center registered" />{" "}
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              <div className="block row pro px-3">
                <div className="w-100 underline">$11,988 / yr</div>
                <div className="d-block d-lg-flex">
                  <div>$9,990 / yr (limited time special)</div>{" "}
                </div>
              </div>
            </div>
            <div className="font-weight-bold col-3 pr-md-0">
              {" "}
              <div className="block center enterprise">Contact Us</div>{" "}
            </div>
          </div>
        </div>
        {/* for small screens */}

        <div className={`SubscriptionPlansIot mb-4 d-md-none ${type}`}>
          <div className="row blue-line">
            <div className="col-12 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Intelligently Identify & Prioritize Prospects
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-md-0">
              {" "}
              <div className="block">Number of Companies Covered</div>
            </div>
            <div className="col-6 pr-0 font-weight-bold">
              <div
                className={`block center ${
                  type === "selected-registered"
                    ? "registered"
                    : type === "selected-pro"
                    ? "pro"
                    : type === "selected-enterprise"
                    ? "enterprise"
                    : ""
                }`}
              >
                {type === "selected-registered" ? "1,500" : "25,000+"}
              </div>
            </div>
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="IoT Ace™ MVP Score"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Advanced Searching & Filtering"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-md-none ${type}`}>
          <div className="row blue-line">
            <div className="col-12 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Research Relevant Info about Prospects
              </div>
            </div>
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="Industry Vertical (Application) Tags"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Technology Tags"
              registered=""
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="Real-time Company Twitter Feeds"
              registered="mark"
              pro="mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Real-time IoT Ecosystem Tracking"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="Real-time IoT Industry Groups Tracking"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Conference Participation Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="Organization Membership Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Corporate Ecosystem Partnership Data"
              registered=""
              pro="half-mark"
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-md-none ${type}`}>
          <div className="row blue-line">
            <div className="col-12 font-weight-bold">
              <div className="d-flex h-100 align-center">
                Find Key Stakeholders and Contact Info
              </div>
            </div>
          </div>
          <div className="row grey-background-color">
            <div className="col-6 pr-md-0">
              {" "}
              <div className="block">Number of Contacts</div>
            </div>
            <div className="col-6 pr-0 font-weight-bold">
              <div
                className={`block center ${
                  type === "selected-registered"
                    ? "registered"
                    : type === "selected-pro"
                    ? "pro"
                    : type === "selected-enterprise"
                    ? "enterprise"
                    : ""
                }`}
              >
                {type === "selected-registered"
                  ? ""
                  : type === "selected-pro"
                  ? "50,000"
                  : type === "selected-enterprise"
                  ? "50,00+"
                  : "50,000"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-md-0">
              {" "}
              <div className="block">License</div>
            </div>
            <div className="col-6 pr-0 font-weight-bold">
              <div
                className={`block center font-weight-bold ${
                  type === "selected-registered"
                    ? "registered"
                    : type === "selected-pro"
                    ? "pro"
                    : type === "selected-enterprise"
                    ? "enterprise"
                    : ""
                }`}
              >
                {type === "selected-registered"
                  ? "Trial"
                  : type === "selected-pro"
                  ? "1 User"
                  : type === "selected-enterprise"
                  ? "5+ User"
                  : "Trial"}
              </div>
            </div>
          </div>
          <div className="row grey-background-color">
            <RowSubscriptionsTablet
              type={type}
              name="Dedicated Support"
              registered=""
              pro=""
              enterprise="mark"
            />
          </div>
          <div className="row">
            <RowSubscriptionsTablet
              type={type}
              name="Data Exporting"
              registered=""
              pro=""
              enterprise="mark"
            />
          </div>
        </div>
        <div className={`SubscriptionPlansIot mb-4 d-md-none ${type}`}>
          <div className="row blue-line">
            <div className="col-12 font-weight-bold">
              <div className="d-flex h-100 align-center">Pricing</div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-md-0">
              {" "}
              <div className="block">Annual</div>
            </div>
            <div className="col-6 pr-0 font-weight-bold">
              <div
                className={`block center ${
                  type === "selected-registered"
                    ? "registered"
                    : type === "selected-pro"
                    ? "pro"
                    : type === "selected-enterprise"
                    ? "enterprise"
                    : ""
                }`}
              >
                {type === "selected-registered" ? (
                  ""
                ) : type === "selected-pro" ? (
                  <div className="row px-3">
                    <div className="w-100 underline">$11,988 / yr</div>
                    <div className="d-block d-lg-flex">
                      <div>$9,990 / yr (limited time special) </div>
                    </div>
                  </div>
                ) : type === "selected-enterprise" ? (
                  "Contact Us"
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
