
export default function filterCheckbox(event, checkedFilter, advancedFilters, LocationArray, counter) {

    const target = event.target
    const newArrayValue = {attribute: target.placeholder.replace(/ \/ /g, '/'), operator: target.name, value: target.value}
    const keyId = target.id
    let array = []
    if (target.checked){
        if(target.placeholder === 'Total Funding'){
            array = {...array, totalFcount: counter.totalFcount + 1}
        }else if(target.placeholder === 'Technologies'){
            array = {...array, technologiesCount: counter.technologiesCount + 1}
        }else if(target.placeholder === 'Verticals'){
            array = {...array, verticalsCount: counter.verticalsCount + 1}
        }else if(target.placeholder === 'Number Of Employees'){
            array = {...array, employeeCount: counter.employeeCount + 1}
        }else if(target.placeholder === 'Private / Public'){
            array = {...array, publicPrivateCount: counter.publicPrivateCount + 1}
        }
        array = {...array, checkedFilter: [...checkedFilter, {_id:keyId,checked: newArrayValue }]}
         
    }else{
        if(target.placeholder === 'Total Funding'){
            array = {...array, totalFcount: counter.totalFcount - 1}
        }else if(target.placeholder === 'Technologies'){
            array = {...array, technologiesCount: counter.technologiesCount - 1}
        }else if(target.placeholder === 'Verticals'){
            array = {...array, verticalsCount: counter.verticalsCount - 1}
        }else if(target.placeholder === 'Number Of Employees'){
            array = {...array, employeeCount: counter.employeeCount - 1}
        }else if(target.placeholder === 'Private / Public'){
            array = {...array, publicPrivateCount: counter.publicPrivateCount - 1}
        }
        array = {
            ...array,
            checkedFilter: checkedFilter.map(
                obj => ((obj._id !== keyId) && obj)
                ).filter(Boolean)
        } 
    }
    if(LocationArray){
            array = {
                ...array,
                advancedFilters: [...checkedFilter.map(
                    obj => ({attribute: obj.checked.attribute, operator: obj.checked.operator, value: obj.checked.value})
                ), LocationArray]
            }
    }else{
            array = {
                ...array,
                advancedFilters: array.checkedFilter.map(
                    obj => ({attribute: obj.checked.attribute, operator: obj.checked.operator, value: obj.checked.value})
                )
        }
    }
    return (
       {...array}
    )
}
