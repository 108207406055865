import React, { Component } from 'react'
import TotalFouding from './components/TotalFounding'
import RestrictedPopover from '../../components/Modals/RestrictedPopover/RestrictedPopover'
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa'
import { Input, Collapse } from "reactstrap";
import './AdvancedFiltersMobile.scss'
import './components/Verticals'
import Location from './components/Location'
import Technologies from './components/Technologies'
import Verticals from './components/Verticals'
import Employee from './components/Employee'
import PublicPrivate from './components/PublicPrivate';
// import OutsideClickHandler from 'react-outside-click-handler';

function IsChecked (checkedFilter, id){
    if( checkedFilter.map(filter => (filter['_id'] === id ? true:'')).filter(Boolean)[0]){
        return true
    }else{
        return false
    }  
}


export default class AdvancedFiltersMobile extends Component {
    constructor(props){
        super(props)
        this.state={
            isSearchOpen: false,
            totalFouding: false,
            dropDown: false,
            goBackFilter: false,
            technologies: false,
            verticals: false,
            employee: false,
            publicPrivate: false,
            location: false,
            showModal: false
        }
    }
    handleTotalFounding = () => {
        this.setState({
            totalFouding: true,
            goBackFilter: true
        }) 
    }
    handleBackMainFilter = () => {
        this.setState({
            goBackFilter: false,
            totalFouding: false,
            technologies: false,
            verticals: false,
            employee: false,
            publicPrivate: false,
            location: false
        })
    }
    handleTechnologies = () => {
        this.setState({
            technologies: true,
            goBackFilter: true
        }) 
    }
    handleVerticals = () => {
        this.setState({
            verticals: true,
            goBackFilter: true
        }) 
    }
    handleLocation = () => {
        this.setState({
            location: true,
            goBackFilter: true
        }) 
    }
    handleEmployee = () => {
        this.setState({
            employee: true,
            goBackFilter: true
        }) 
    }
    handlePublicPrivate = () => {
        this.setState({
            publicPrivate: true,
            goBackFilter: true
        }) 
    }
    handleCloseFilter = () => {
        this.setState({
            goBackFilter: false,
            totalFouding: false,
            technologies: false,
            verticals: false,
            employee: false,
            dropDown: false,
            isSearchOpen: false,
            publicPrivate: false,
            location: false
        })
    }
    handleOpenFilter = () =>{
        this.setState({
            dropDown: true
        })
    }
    onSearch = () => {
        this.setState({
            isSearchOpen: true
        })
    }
    handleOpenModal = () => {
        this.setState({showModal: !this.state.showModal})
    }
    render() {
        const { 
            handleFilterCheckbox, handleQueryChange, handleClearFilter, handleClearAllFilters, handleLocationChange, handleLocationInput, handleSelectLocation, handleSubmit
        } = this.props.aFHandle;
        const { 
            checkedFilter,  value, totalFcount, technologiesCount, verticalsCount, employeeCount, publicPrivateCount, user, locationCount, locationOptions, locationFilter
        } = this.props.aFValue;
        const {whenToShow, visible} = this.props
        if(visible === false) {
            return '';
        }
        const Fcount = typeof(totalFcount) !== 'undefined' ?totalFcount:0;
        const Tcount = typeof(technologiesCount) !== 'undefined' ?technologiesCount:0;
        const Vcount = typeof(verticalsCount) !== 'undefined' ?verticalsCount:0;
        const Ecount = typeof(employeeCount) !== 'undefined' ?employeeCount:0;
        const Pcount = typeof(publicPrivateCount) !== 'undefined' ?publicPrivateCount:0;
        const Lcount = typeof(locationCount) !== 'undefined' ?locationCount:0;
        const totalCount = Fcount + Tcount + Vcount + Ecount + Pcount +Lcount;
        return (
            <div className={`AdvancedFiltersMobile ${whenToShow}`}>
                <RestrictedPopover pathname={window.location.pathname} isOpen={this.state.showModal} toggle={this.handleOpenModal} />
                <div className={this.state.dropDown?"top-screen open-top-screen index-1049" : "top-screen index-1048"}>
                    <div className="filter-block " style={{position: 'relative'}}>
                        {
                            this.state.isSearchOpen ?
                            <div className="row row-pad vertical-align both-filter-block">
                                <FaAngleLeft onClick={this.handleCloseFilter} className="display-flex" style={{cursor: 'pointer' ,marginRight: "10px", height: '1.5em', width: '1.5em', margin: 'auto'}} />
                                <div className='col-11'>
                                    <div className="close-search-block">
                                        <div className="row  row-pad vertical-align">
                                            <div className="col vertical-align">
                                            <form onSubmit={handleSubmit} className="col-12 search-box unset-shadow">
                                                <div className="row">
                                                <div className="input-group">
                                                    <span className="search-icon">
                                                    <Input className="form-control search-border" 
                                                    type="search"
                                                    onChange={handleQueryChange}
                                                    value={value}
                                                    name="searchInput"
                                                    placeholder="Search the IoT ecosystem"
                                                    aria-label="Search the IoT ecosystem"/>
                                                    </span>                    
                                                    <div className="input-group-prepend">
                                                    <button className="btn search d-block  d-lg-none" type="submit"onSubmit={handleSubmit}></button>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:
                            <div className="row row-pad vertical-align both-filter-block">
                                {
                                    !this.state.dropDown &&
                                    <div onClick={this.onSearch} className="col-5 display-flex search-filer-block pointer">
                                        <img alt='' src="/images/zoom.svg" />
                                        <div className="search-text">Search</div>
                                    </div>
                                }
                                <div className={this.state.dropDown ? "col-12 pointer" :"col-7 pointer"}>
                                    <div className="row row-pad vertical-align">
                                        {!this.state.dropDown &&
                                            <div className="col-2 text-right margin-horizontal">
                                                <img className="pointer" onClick={this.handleOpenFilter} alt="" src="/images/filter-tool.svg"/>
                                            </div> 
                                        }
                                        <div onClick={this.state.dropDown? this.handleBackMainFilter:this.handleOpenFilter} className={this.state.dropDown ?"margin-horizontal col-8 ":"margin-horizontal col-6 reduse-padding"}>
                                            <div className={this.state.dropDown?"filer-name display-flex width-100":"display-flex width-100"}>                                  
                                                {this.state.goBackFilter&&<FaAngleLeft style={{height: "2em", cursor: 'pointer', marginRight: "10px"}} />}
                                                <div className="margin-horizontal">
                                                {this.state.totalFouding?'Total Funding':this.state.technologies?'Technologies':this.state.verticals?'Verticals':this.state.employee?'Employee Count':this.state.publicPrivate?'Public / Private':'Filters'}
                                                </div>
                                                <div className="margin-horizontal">
                                                {
                                                    this.state.totalFouding? <span className="badge"><span>{totalFcount}</span></span>:
                                                    this.state.technologies? <span className="badge"><span>{technologiesCount}</span></span>:
                                                    this.state.verticals? <span className="badge"><span>{verticalsCount}</span></span>:
                                                    this.state.employee? <span className="badge"><span>{employeeCount}</span></span>:
                                                    this.state.publicPrivate? <span className="badge"><span>{publicPrivateCount}</span></span>:
                                                    this.state.location? <span className="badge"><span>{locationCount}</span></span>
                                                    :<span className="badge"><span>{totalCount}</span></span>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.dropDown ?
                                            this.state.totalFouding || this.state.technologies || this.state.verticals || this.state.employee || this.state.publicPrivate || this.state.location?
                                                <>
                                                    <div className="col-3 p-0 text-center margin-horizontal">
                                                        <div className="pointer" onClick={() => handleClearFilter(this.state.totalFouding?'Financial - Total Funding':this.state.location?'Location':this.state.technologies?'Technologies':this.state.verticals?'Verticals':this.state.employee?'Number Of Employees':this.state.publicPrivate?'Private / Public':'Filters')} style={{color: "grey", textDecoration: "underline"}}>clear filter</div> 
                                                    </div>
                                                    <div className="col-1 p-0 text-left margin-horizontal">
                                                        <FaTimes className="pointer" onClick={this.handleCloseFilter} style={{color: 'grey', height: '1.5em', width: '1.5em'}} />
                                                    </div>
                                                </>
                                                :
                                                <div className="offset-3 col-1 p-0 text-left margin-horizontal">
                                                    <FaTimes className="pointer" onClick={this.handleCloseFilter} style={{color: 'grey', height: '1.5em', width: '1.5em'}} />
                                                </div>

                                            :
                                            <div className="col-4 vertical-align">
                                                <a target="_blank" href={window.location.pathname.includes('/usecases')?"https://forms.gle/bbkpbH3X7o1zg84PA":"https://forms.gle/ufzcGCmmftHcvAbv7"}>
                                                    <div className="add-block-filter">
                                                        <div className="add-sign-horizontal"></div>
                                                        <div className="add-sign-vertical"></div>
                                                    </div>  
                                                </a>
                                            </div>  
                                        } 
                                    </div>
                                </div>
                            </div>
                        }

                        
                        {/* goBackFilter */}
                        {this.state.totalFouding || this.state.technologies || this.state.verticals || this.state.employee || this.state.publicPrivate || this.state.location?'':
                        <Collapse isOpen={this.state.dropDown}>

                            <div className="row divider"></div>
                            <div isopen={ `"${this.state.dropDown}"` }>
                                {
                                    totalFcount >= 0 && user?
                                    <>
                                    <div className="row  row-pad vertical-align pointer" onClick={this.handleTotalFounding}>
                                        <div className="col-8 vertical-align">
                                        Total Funding 
                                        <span className="badge"><span>{totalFcount}</span></span>
                                        </div>
                                        <div className="col-4 vertical-align text-right">
                                        <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                        </div> 
                                    </div>
                                    <div className="row divider"></div>
                                    </>:totalFcount >= 0 ? 
                                    <>
                                    <div className="row  row-pad vertical-align pointer opacity-3" onClick={this.handleOpenModal}>
                                        <div className="col-8 vertical-align">
                                        Total Funding 
                                        <span className="badge"><span>{totalFcount}</span></span>
                                        </div>
                                        <div className="col-4 vertical-align text-right">
                                        <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                        </div> 
                                    </div>
                                    <div className="row divider"></div>
                                    </>:''
                                } 
                            
                            {
                                technologiesCount >= 0 && user?
                                <>
                                <div className="row row-pad vertical-align pointer" onClick={this.handleTechnologies}>
                                    <div className="col-8 vertical-align">
                                    Technologies
                                    <span className="badge"><span>{technologiesCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>: technologiesCount >= 0?
                                <>
                                <div className="row row-pad vertical-align pointer opacity-3" onClick={this.handleOpenModal}>
                                    <div className="col-8 vertical-align">
                                    Technologies
                                    <span className="badge"><span>{technologiesCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>:''
                            }
                            {
                                verticalsCount >= 0 ? 
                                <>
                                <div className="row row-pad vertical-align pointer" onClick={this.handleVerticals}>
                                    <div className="col-8 vertical-align">
                                    Verticals
                                    <span className="badge"><span>{verticalsCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>:''
                            }
                            {
                                locationCount >= 0 ? 
                                <>
                                <div className="row row-pad vertical-align pointer" onClick={this.handleLocation}>
                                    <div className="col-8 vertical-align">
                                    Location
                                    <span className="badge"><span>{locationCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>:''
                            }
                            {
                                employeeCount >= 0 && user ?
                                <>
                                <div className="row row-pad vertical-align pointer" onClick={this.handleEmployee}>
                                    <div className="col-8 vertical-align">
                                    Employee Count
                                    <span className="badge"><span>{employeeCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>: employeeCount >= 0?
                                <>
                                <div className="row row-pad vertical-align pointer opacity-3" onClick={this.handleOpenModal}>
                                    <div className="col-8 vertical-align">
                                    Employee Count
                                    <span className="badge"><span>{employeeCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                <div className="row divider"></div>
                                </>:''
                            }
                            {
                                publicPrivateCount >= 0 ?
                                <>
                                <div className="row row-pad vertical-align pointer" onClick={this.handlePublicPrivate}>
                                    <div className="col-8 vertical-align">
                                    Public / Private
                                    <span className="badge"><span>{publicPrivateCount}</span></span>
                                    </div>
                                    <div className="col-4 vertical-align text-right">
                                    <FaAngleRight className="display-flex" style={{margin: "0 0 0 auto"}} />
                                    </div>
                                </div>
                                </>:''
                            }
                            <div className="row row-pad">
                                <div className="control-filters vertical-align">
                                <span className="btn clear" onClick={handleClearAllFilters}>Clear All Filters</span>
                                </div>
                            </div>
                            </div>  
                        </Collapse>}
                    </div> 
                         
                    {this.state.totalFouding && 
                        <TotalFouding
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                    {this.state.technologies &&
                        <Technologies 
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                    {this.state.verticals &&
                        <Verticals 
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                    {this.state.employee &&
                        <Employee 
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                    {this.state.publicPrivate &&
                        <PublicPrivate 
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                    {this.state.location &&
                        <Location 
                        locationOptions={locationOptions} locationFilter={locationFilter}
                        handleLocationChange={handleLocationChange} handleLocationInput={handleLocationInput} 
                        handleSelectLocation={handleSelectLocation}
                        IsChecked={IsChecked}
                        checkedFilter={checkedFilter}
                        handleFilterCheckbox={handleFilterCheckbox}
                        />
                    }
                              
                    
                </div>
            </div>
        )
    }
}
