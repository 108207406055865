import React from 'react'
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent'
import StockGraph from './StockGraph';

import FakeData from '../../../components/FakeData/FakeData';


export default class FinancialData extends React.PureComponent {
    componentDidMount() {
        let { companyProfile, financialData } = this.props;
        if(!companyProfile) {
            companyProfile = {};
        }
        if(!financialData) {
            financialData = {};
        }
    }

    render() {
        const { companyProfile } = this.props;

        let isFinDataFaked = false;

        const fakeFinancialData = () =>{
            isFinDataFaked = true;
            return  {
                    description: FakeData({returnJustValue:true, toFake:'lorem.words'}),
                    lastFundingRound: FakeData({returnJustValue:true, toFake:'lorem.word'}),
                    publicPrivate: "Public",
                    stockSymbol: 'NASDAQ: INTC',
                    totalFunding: FakeData({returnJustValue:true, toFake:'finance.amount'})
            }
        }

    const fd=this.props.financialData?this.props.financialData:fakeFinancialData()
   
    return (
        <div className={"card finance"}>
            <div className="card-header">
                <div className="icon finance"><img src="/images/g-chart-1-copy-3.svg" alt="chart"/></div>
                <div className="title-holder"><p id="finance">Financial Data</p></div>
            </div>
            <div className="menu-divider"></div>
            <div className={`row fin-data ${isFinDataFaked?'blurred-box':''}`}>
                { 
                    fd.publicPrivate&&
                    <div className="data-cards">
                        <p>Public / Private</p>
                        <p>{fd.publicPrivate}</p>
                    </div>
                }
                {   
                    fd.stockSymbol&&
                    <div className="data-cards">
                        <p>Stock Ticker Symbol</p>
                        <p>{fd.stockSymbol}</p>
                    </div>
                } 
                { 
                    fd.lastFundingRound&&
                    <div className="data-cards">
                        <p>Latest Founding Round</p>
                        <p>{fd.lastFundingRound}</p>
                    </div> 
                }
                {
                    fd.totalFunding&&
                    <div className="data-cards">
                        <p>Funding</p>
                        <p>{fd.totalFunding / 1000000}M</p>
                    </div>
                }                 
            </div>
            <div className="d-none d-md-block">
                <StockGraph isFinDataFaked={isFinDataFaked} companyProfile={companyProfile} stockSymbol={fd.stockSymbol}/>
            </div>
            
            <SubscriberContent target="finacialData" hidden={isFinDataFaked}/>
    </div>
  )
    }
}