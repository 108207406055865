import React, { Component } from "react";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { DateRangePicker } from "react-dates";
import { FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import ClearButton from "../../components/ClearButton/ClearButton";
import cx from 'classnames'


export default class ListView extends Component {
  render() {
    const {
      handleSelectedSegments,
      handleClearAllSegments,
      handleSelectAllSegments,
      onCalendarFocusChange,
      onDatesChange,
      children,
    } = this.props;
    const {
      dateStart,
      dateEnd,
      focusedInput,
      selectedSegments,
    } = this.props.data;
    
    const selectedSegmentsLength = selectedSegments.value.length
    const selectedSegmentsHeaderText = selectedSegments.value.join(', ')

    const calendarClasses = cx({
      "col-md-6 col-lg-4":true,
      "initial-calendar":!dateStart && !dateEnd,
      "active-calendar":focusedInput
    })
    return (
      <div className="container">
        <div className="row special-row filters-panel d-none d-md-flex">
          <div className="col-lg-2">
            
          </div>
          <div className="col-md-4 col-lg-2 vertical-align">
            <CustomSelect 
              onClear={handleClearAllSegments}
              headerText={selectedSegmentsLength > 0
                ?selectedSegmentsLength === 4
                  ? 'Type'
                  : selectedSegmentsHeaderText
                :'Select Type'
              }
              hiddenClearBtn={selectedSegmentsLength === 0 || selectedSegmentsLength === 4} 
              className="filter">
            {
              selectedSegments.values.map((segment, key)=>(
                <div key={key} className="option">
                <CustomCheckBox
                name={segment}
                onChange={handleSelectedSegments}
                checked={selectedSegments.value.includes(segment)}
                />
                <span>{segment}</span>
              </div>
              ))
            }
            </CustomSelect>
          </div>
          
          <div className={calendarClasses}>
            <div className="ClearButton-container">
              <ClearButton 
              manualPadding="0 18px" 
              active={focusedInput} 
              clearInfo={()=>onDatesChange({
                startDate:null,
                endDate:null
                })} 
              hidden={!dateStart && !dateEnd}  />
              <DateRangePicker
                //showClearDates
                
                minimumNights={1}
                navPrev={<div className="prev"><FaAngleLeft fill="#788ab3"/></div>}
                navNext={<div className="next"><FaAngleRight fill="#788ab3" /></div>}
                startDatePlaceholderText="Start Date"
                endDatePlaceholderText="End Date"
                startDate={dateStart}
                startDateId="start_date_id_1"
                endDate={dateEnd}
                endDateId="end_date_id_1"
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={onCalendarFocusChange}
                displayFormat="ddd, MMM D, YYYY"
                small
              />
            </div>   
          </div>
          <div className="col-2 control-filters">
            <span className="btn clear" onClick={() => {handleSelectAllSegments(); onDatesChange({
              startDate:null,
              endDate:null
              })} }>Clear All Filters</span>
          </div>
        </div>
        <div className="row special-row">
          <div className="col-lg-2">
            
          </div>
          <div className="col">
            <div className="main">
              {children}
            </div>
            
          </div>
          <div className="col-lg-2 righter">
          </div>
        </div>
      </div>
    );
  }
}
