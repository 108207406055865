export default function advancedFilterCleaning(advancedFiltersProp, user) {
  let advancedFilters = advancedFiltersProp;
  let dataForPost = [];
  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];
  if (!user) {
    advancedFilters = advancedFilters.filter(
      (item) =>
        item.attribute !== "Total Funding" &&
        item.attribute !== "Company Rank" &&
        item.attribute !== "Participation In" &&
        item.attribute !== "Industry Activity Score" &&
        item.attribute !== "Number of Employees" &&
        item.attribute !== "Private/Public" &&
        item.attribute !== "Technologies" &&
        item.attribute !== "Classification"
    );
    advancedFilters = advancedFilters.filter((_item, key) => key < 2);
  } else if (userType !== "admin" && userType !== "corporate") {
    advancedFilters = advancedFilters.filter(
      (item) => item.attribute !== "Classification"
    );
  } else if (userType !== "admin") {
    advancedFilters = advancedFilters.filter(
      (item) => item.attribute !== "Total Funding"
    );
  } else if (
    advancedFilters.filter((obj) => obj.attribute === "Total Funding").length >
    0
  ) {
    advancedFilters = advancedFilters.filter(
      (item) => item.attribute !== "Private/Public"
    );
    advancedFilters = [
      ...advancedFilters,
      { attribute: "Private/Public", operator: "Is", value: "Private" },
    ];
  }
  dataForPost = advancedFilters
    .map((obj) =>
      obj.value === ""
        ? ""
        : obj.attribute === "Location" &&
          (obj.value2 === "" || obj.value2 === "undefined")
        ? { ...obj, value2: "100" }
        : obj
    )
    .filter(Boolean);

  return { advancedFilters, dataForPost };
}
