import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa'
export default class Employee extends Component {
    render() {
        const { checkedFilter, handleFilterCheckbox, IsChecked } = this.props

        return (
            <div className='side-padding-15'>
            <label className="custom-control-label" htmlFor='employee-1' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-1' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-1')}  placeholder="Number Of Employees" name="Equal to" value="1 - 25"  type="checkbox" />
                            {'1 - 25'}
                        {IsChecked(checkedFilter, 'employee-1') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-2' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-2' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-2')}  placeholder="Number Of Employees" name="Equal to" value="26 - 50"  type="checkbox" />
                            {'26 - 50'}
                        {IsChecked(checkedFilter, 'employee-2') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-3' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-3' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-3')}  placeholder="Number Of Employees" name="Equal to" value="51 - 200"  type="checkbox" />
                            {'51 - 200'}
                        {IsChecked(checkedFilter, 'employee-3') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-4' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-4' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-4')}  placeholder="Number Of Employees" name="Equal to" value="201 - 500"  type="checkbox" />
                            {'201 - 500'}
                        {IsChecked(checkedFilter, 'employee-4') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-5' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-5' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-5')}  placeholder="Number Of Employees" name="Equal to" value="501 - 1000"  type="checkbox" />
                            {'501 - 1,000'}
                        {IsChecked(checkedFilter, 'employee-5') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-6' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-6' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-6')}  placeholder="Number Of Employees" name="Equal to" value="1001 - 5000"  type="checkbox" />
                            {'1,001 - 5,000'}
                        {IsChecked(checkedFilter, 'employee-6') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
            <div className="row divider"></div>
            <label className="custom-control-label" htmlFor='employee-7' >
                <div className="display-flex row-pad">
                    <div className="custom-control custom-checkbox vertical-align display-flex">
                        <input className="custom-control-input" id='employee-7' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'employee-7')}  placeholder="Number Of Employees" name="Greater than" value="> 5000"  type="checkbox" />
                            {'> 5,000'}
                        {IsChecked(checkedFilter, 'employee-7') && <div className="blue-check"><FaCheck /></div>}
                    </div>
                </div>
            </label>
        </div>
        )
    }
}
