import React, { useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const MyTesting = () => {
  const [count, setCount] = useState(1);
  const handleClick = (e) => {
    e.preventDefault();
    // cookies.set('testSomeAPI', count, {path: '/', domain: 'iotnation.com', sameSite: false})

    cookies.set("dev-testSomeAPI", `dev-${count}`, { path: "/", domain: "content-dev.iotnation", sameSite: false });
    cookies.set("og-testSomeAPI", `og-${count}`, { path: "/", sameSite: false });
    setCount(count + 1);
    console.log("he");
  };
  return (
    <div className="d-flex">
      <div className="col-3" />
      <div className="col-2" />
      <div className="col-6" />
      <button className="mx-auto mt-5 btn btn-info" onClick={(e) => handleClick(e)}>
        press
      </button>
    </div>
  );
};

export default MyTesting;
