import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import './RegisterPopover.scss'
export default class RegisterPopover extends Component {
    render() {
        return (
            <>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}  centered={true} style={{}}>
                    <ModalHeader toggle={this.props.toggle}><div className="center-text">Are you registered? </div><div className="center-text">Sign up today for free. </div></ModalHeader>
                    <ModalBody style={{backgroundColor: "white"}}>
                    <div className="justify-center-block m-tb-15 pb-24"><Link  to={{pathname:'/signup',  state:{page:this.props.pathname&&this.props.pathname}}} ><Button className="sign-up">Sign Up</Button></Link></div>
                        <p className="justify-center-block center-text"><b className="d-block d-md-flex">Access more companies & features. <Link className="justify-center-block text-underline" to={{pathname:'/iotace',  state:{page:this.props.pathname&&this.props.pathname}}} >Learn More</Link></b></p>
                        <p className="justify-center-block margin-0">Already Registered?</p>
                        <p><Link className="justify-center-block" to={{pathname:'/login',  state:{page:this.props.pathname&&this.props.pathname}}} >Login</Link></p>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}
