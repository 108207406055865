const generateArray = (value, filterFields, filter, newFilter) => {
  const valueName = newFilter ? newFilter : filter.value;
  let item = "";
  if (value.schemas) {
    if (value.filter) {
      item = filterFields.schemas[value.schemas[0]].filter(
        (item) => item.name === (valueName === "" ? " " : valueName)
      );
      item = item.length > 0 ? item[0] : item;
      item = item.years ? item.years : item;
    } else {
      item = filterFields.schemas[value.schemas[0]];
    }
  } else if (value.supportArray) {
    item = filterFields.supportArray[value.supportArray];
  } else {
    item = Object.getOwnPropertyNames(value);
  }
  return item;
};

const generateForm = (target, index, filterFields, filter) => {
  const { value, id } = target;
  const attribute =
    id === `attribute${index}`
      ? value
      : document.getElementById(`attribute${index}`).value;
  const operator =
    id === `attribute${index}`
      ? Object.getOwnPropertyNames(filterFields.attributes[value])[0]
      : id === `operator${index}`
      ? value
      : document.getElementById(`operator${index}`).value;
  const third = filterFields.attributes[attribute][operator];

  let value1 =
    id === `attribute${index}`
      ? third.value
        ? ""
        : generateArray(third.attributes, filterFields, filter)[0]
      : third.value
      ? document.getElementById(`valueInput${index}`)
        ? document.getElementById(`valueInput${index}`).value
        : ""
      : document.getElementById(`valueArray${index}`).value;
  value1 = value1.name ? value1.name : value1;

  const fourth = third.value
    ? third.value
    : third.attributes
    ? third.attributes
    : "";
  const attribute2 =
    id === `attribute${index}`
      ? fourth.value
        ? ""
        : fourth.attributes
        ? generateArray(fourth.attributes, filterFields, filter)[0]
        : ""
      : fourth.value
      ? document.getElementById(`attribute2Input${index}`)
        ? document.getElementById(`attribute2Input${index}`).value
        : ""
      : fourth.attributes
      ? document.getElementById(`attribute2Array${index}`)
        ? document.getElementById(`attribute2Array${index}`).value
        : generateArray(fourth.attributes, filterFields, filter)[0]
      : "";

  const fifth = fourth.value
    ? fourth.value
    : fourth.attributes
    ? fourth.attributes
    : "";
  const operator2 =
    id === `attribute${index}`
      ? fifth.value
        ? ""
        : fifth.attributes
        ? generateArray(fifth.attributes, filterFields, filter)[0]
        : ""
      : fifth.value
      ? document.getElementById(`operator2Input${index}`)
        ? document.getElementById(`operator2Input${index}`).value
        : ""
      : fifth.attributes
      ? document.getElementById(`operator2Array${index}`)
        ? document.getElementById(`operator2Array${index}`).value
        : generateArray(fifth.attributes, filterFields, filter)[0]
      : "";

  const sixth = fifth.value
    ? fifth.value
    : fifth.attributes
    ? fifth.attributes
    : "";
  const value2 =
    id === `attribute${index}` || id === `valueArray${index}`
      ? sixth.value
        ? ""
        : sixth.attributes
        ? generateArray(sixth.attributes, filterFields, filter, value1)[0]
        : ""
      : sixth.value
      ? attribute === "Location"
        ? document.getElementById(`value2Input${index}`)
          ? document.getElementById(`value2Input${index}`).value
          : 100
        : 100
      : sixth.attributes
      ? document.getElementById(`value2Array${index}`)
        ? document.getElementById(`value2Array${index}`).value
        : generateArray(sixth.attributes, filterFields, filter, value1)[0]
      : "";

  if (fourth.attributes || fourth.value) {
    return {
      attribute,
      operator,
      value: value1,
      attribute2,
      operator2,
      value2,
    };
  }
  return {
    attribute,
    operator,
    value: value1,
  };
};

export default generateForm;
