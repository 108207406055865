import React, { Component } from 'react'

import './BlueTag.scss';

export default class BlueTag extends Component {
  render() {
    return (
      <div className="BlueTag">
        <div className="tag capitalize">{this.props.name}</div>
      </div>
    )
  }
}
