import React from "react";
import ReactPlayer from "react-player/lazy";
import { Subscription } from "../components/Subscribe/Subscribe";
import style from "./Sales.module.scss";

const Sales = ({ dataJSON }) => {
  return (
    <div>
      <div className="top-panel">
        <h1 className="m-auto text-white">{dataJSON["main-title"]}</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h4 className="mt-4 mb-3 font-s-30">{dataJSON["first-title"]}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7">
            {dataJSON["first-description"] &&
              dataJSON["first-description"].length > 0 &&
              dataJSON["first-description"].map((item, key) => (
                <p
                  key={key}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="line-h-15 mb-3 h6"
                />
              ))}
            <h4 className="mt-2 mb-3">{dataJSON["second-title"]}</h4>
            {dataJSON["second-description"] &&
              dataJSON["second-description"].length > 0 &&
              dataJSON["second-description"].map((item, key) => (
                <p
                  key={key}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="line-h-15 mb-3 h6"
                />
              ))}
            <h4 className="mt-2 mb-3">{dataJSON["third-title"]}</h4>
            {dataJSON["third-description"] &&
              dataJSON["third-description"].length > 0 &&
              dataJSON["third-description"].map((item, key) => (
                <p
                  key={key}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="line-h-15 mb-3 h6"
                />
              ))}
          </div>
          {dataJSON.video && (
            <div className="col-12 mb-4 mb-lg-0 col-lg-5">
              <ReactPlayer width="100%" height="300px" url={dataJSON.video} />
            </div>
          )}
        </div>
      </div>
      {dataJSON["images-blocks"] &&
        dataJSON["images-blocks"].length > 0 &&
        dataJSON["images-blocks"].map((item, key) =>
          key % 2 === 0 ? (
            <div className="pl-3 w-100">
              <div className="w-100 border border-right-0 py-4">
                <div className="container row mx-auto">
                  <h5 className="d-md-none w-100 mb-4 text-center font-weight-bold">
                    {item.title}
                  </h5>
                  <div
                    className={`border mx-auto mr-md-auto ml-md-0 ${
                      style.img_block
                    }`}
                  >
                    {item.image && (
                      <img
                        className={`mx-auto ${style.img}`}
                        src={item.image}
                        alt=""
                      />
                    )}
                  </div>
                  <h4 className="m-auto d-none d-md-block d-xl-none font-weight-bold">
                    {item.title}
                  </h4>
                  <h3 className="m-auto d-none d-xl-block font-weight-bold">
                    {item.title}
                  </h3>
                </div>
              </div>
            </div>
          ) : (
            <div className="container my-4 row mx-auto">
              <h3 className="my-auto d-none d-xl-block font-weight-bold">
                {item.title}
              </h3>
              <h4 className="my-auto d-none d-md-block d-xl-none font-weight-bold">
                {item.title}
              </h4>
              <h5 className="w-100 mb-4 text-center d-block d-md-none font-weight-bold">
                {item.title}
              </h5>
              <div
                className={`border mx-auto mr-md-0 ml-md-auto ${
                  style.img_block
                }`}
              >
                {item.image && (
                  <img
                    className={`mx-auto ${style.img}`}
                    src={item.image}
                    alt=""
                  />
                )}
              </div>
            </div>
          )
        )}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h4 className="mt-2 mb-3">{dataJSON["bottom-title"]}</h4>
            {dataJSON["bottom-description"] &&
              dataJSON["bottom-description"].length > 0 &&
              dataJSON["bottom-description"].map((item, key) => (
                <p
                  key={key}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="line-h-15 mb-3 h6"
                />
              ))}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="top-panel">
          <a
            href="/iotace"
            className="background-orange text-decoration-none font-weight-bold custom-link py-3 px-5 rounded mx-auto font-s-18"
          >
            Subscribe
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sales;
