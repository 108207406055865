import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import RestrictedPopover from "../Modals/RestrictedPopoverSmall/RestrictedPopover";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import TopSearchBlock from "../TopSearchBlock/TopSearchBlock";
import FilterLine from "./components/FilterLine";
import advancedFilterUrlGenerator from "./components/functions/advancedFilterUrlGenerator";
import style from "./AdvancedFilters.module.scss";
import ClassificationBlock from "./components/ClassificationBlock";
import ClassificationBlock2 from "./components/ClassificationBlock2";

const AdvancedFiltero = ({
  advancedFilters,
  filterFields,
  whenToShow,
  query,
  currentPage,
  history,
  link1,
  link2,
  user,
  updatePropsState,
  title,
  hideTopSearch,
  downloadApi,
}) => {
  const [filtersOpen, handleOpenFilters] = useState(false);
  const [modal, setModal] = useState(false);

  const handleQueryChange = (event) => {
    event.preventDefault();
    updatePropsState({ query: event.target.value });
    if (event.target.value === "") {
      advancedFilterUrlGenerator(
        event.target.value,
        advancedFilters,
        currentPage,
        history
      );
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    advancedFilterUrlGenerator(query, advancedFilters, currentPage, history);
  };

  const handleClearFilters = () => {
    updatePropsState({ advancedFilters: [] });
    advancedFilterUrlGenerator(query, [], currentPage, history);
  };

  const handleAddFilter = () => {
    updatePropsState({
      advancedFilters: [
        ...advancedFilters,
        { attribute: "Name", operator: "Begins with", value: "" },
      ],
    });
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(
    () => {
      if (advancedFilters.length > 0) {
        handleOpenFilters(true);
      }
    },
    [advancedFilters]
  );
  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];
  const urlSearch = window.location.search;
  const { pathname } = window.location;
  return (
    <div
      className={`${
        pathname.includes("/map") ? style.z_index_1 : ""
      } position-relative`}
    >
      {!hideTopSearch && (
        <TopSearchBlock
          submit={handleSubmit}
          value={query}
          onChange={handleQueryChange}
          title={pathname.split("/")[1] === "companies" ? "Companies" : ""}
        >
          <div className={"col mr-0 ml-auto d-flex"}>
            <div className="d-flex my-auto mr-0 ml-auto">
              {link1 && (
                <Link
                  className="tag btn text-white rounded background-blue py-auto px-3 m-0"
                  to={{ pathname: `/${link1.url}/`, search: `${urlSearch}` }}
                >
                  {link1.name}
                </Link>
              )}
              {(link2 &&
                link2.url === "contacts" &&
                user &&
                user.email !== "mitchelldemo@iotnation.com") ||
              (link2 && link2.url !== "contacts") ? (
                <Link
                  className="tag btn text-white rounded background-blue py-auto px-3 ml-3 m-0"
                  to={{ pathname: `/${link2.url}/`, search: `${urlSearch}` }}
                >
                  {link2.name}
                </Link>
              ) : (
                ""
              )}
            </div>
            {userType === "admin" && downloadApi && (
              <div className="ml-2 d-flex">
                <img
                  style={{
                    height: "70px",
                  }}
                  className="mt-3 mt-md-1 ml-auto mr-auto mr-md-0 pointer"
                  onClick={() => {
                    downloadApi();
                  }}
                  src="/images/download_csv.png"
                  alt=""
                />
              </div>
            )}
          </div>
        </TopSearchBlock>
      )}
      <div className={`bg-white py-3 ${whenToShow}`}>
        <div className="container">
          {title && <h6 className="px-2">{title}</h6>}
          <Collapse isOpen={filtersOpen}>
            {filterFields &&
              advancedFilters.map((filter, index) =>
                filter.attribute === "Classification" ? (
                  <ClassificationBlock
                    key={index}
                    {...{
                      filter,
                      filterFields,
                      user,
                      updatePropsState,
                      index,
                      advancedFilters,
                      history,
                      query,
                    }}
                  />
                ) : filter.attribute === "Classification-2" ? (
                  <ClassificationBlock2
                    key={index}
                    {...{
                      filter,
                      filterFields,
                      user,
                      updatePropsState,
                      index,
                      advancedFilters,
                      history,
                      query,
                    }}
                  />
                ) : (
                  <FilterLine
                    key={index}
                    data={{
                      filter,
                      filterFields,
                      advancedFilters,
                      user,
                      index,
                      query,
                      currentPage,
                      history,
                    }}
                    handle={{
                      updatePropsState,
                    }}
                  />
                )
              )}
          </Collapse>
          {(advancedFilters.length > 1 && !user) ||
          (advancedFilters.length > 0 && pathname.includes("/map") && !user) ? (
            <div className="row">
              <div className="col-11 control-filters d-flex">
                <span
                  id="PopoverAdvanced"
                  className="btn add"
                  onClick={() => {
                    handleOpenFilters(true);
                    setModal(true);
                  }}
                >
                  Add mo
                  {user ? (
                    ""
                  ) : (
                    <RestrictedPopover
                      classN="top--10"
                      showModal={modal}
                      handleCloseModal={handleCloseModal}
                      id={`AdvancedFilters-u`}
                    />
                  )}
                  re filters
                </span>
                <span
                  className="btn clear"
                  onClick={() => handleClearFilters()}
                >
                  Clear All Filters
                </span>
              </div>
              <div
                style={{ color: "#1b7beb" }}
                className="display-flex align-center pointer justify-center col-1"
                onClick={() => handleOpenFilters(!filtersOpen)}
              >
                {filtersOpen ? <FaArrowUp /> : <FaArrowDown />}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-11 control-filters d-flex">
                <span
                  className="btn add"
                  onClick={() => {
                    handleAddFilter();
                    handleOpenFilters(true);
                  }}
                >
                  Add more filters
                </span>
                <span
                  className="btn clear"
                  onClick={() => handleClearFilters()}
                >
                  Clear All Filters
                </span>
              </div>
              <div
                style={{ color: "#1b7beb" }}
                className="display-flex align-center pointer justify-center col-1"
                onClick={() => handleOpenFilters(!filtersOpen)}
              >
                {filtersOpen ? <FaArrowUp /> : <FaArrowDown />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedFiltero;
