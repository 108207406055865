import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";

import './FirstLogin.scss';

export default class FirstLogin extends Component {
    render() {
        return (
            <div className="FirstLogin">
                <Modal modalClassName="modal-first-login" isOpen={this.props.isOpen} toggle={this.props.toggle} className={`${this.props.className} first-login`}  centered={true} style={{}}>
                    <ModalHeader toggle={this.props.toggle}><div className="center-text"></div></ModalHeader>
                    <ModalBody style={{backgroundColor: "white", display: 'flex', padding: '0% 10%'}}>
                        <div style={{margin: "0 auto", textAlign: 'center', fontSize: '14px', width: '100%'}}>
                            <h2 className="d-none d-md-block">Welcome to IoT Nation™ !</h2>
                            <h5 className="d-md-none">Welcome to IoT Nation™ !</h5>
                            <p>
                                With your new account on the IoT Ace™ ecosystem database, you now have access to a wide variety and depth of information related to the Internet of Things.
                            </p>
                            <p className="d-none d-md-block">
                                IoT Ace™ is the go-to resource for IoT professionals. If you have any questions about IoT Ace™, feel free to contact us via the messaging icon in the lower right hand corner.
                            </p>
                            <p>
                                Ready to dive in?
                            </p>
                            <p className="d-none d-md-block">
                                Start exploring by clicking on any of the links below!
                            </p>
                            <p className="d-block d-md-none">
                                Get started exploring here:
                            </p>
                            <div className="row " style={{marginBottom: '25px'}}>
                                <div className="col horizontal-align max-width-250" style={{overflowWrap: 'break-word'}}>
                                    <Link to="/map" style={{margin: '3px'}} onClick={this.props.toggle} className="sign-up">Map</Link>
                                    <Link to="/companies" style={{margin: '3px'}} onClick={this.props.toggle} className="sign-up">Companies</Link>
                                    <Link to="/events" style={{margin: '3px'}} onClick={this.props.toggle} className="sign-up">Events</Link>
                                    {/* <Link to="/competitions" style={{margin: '3px'}} onClick={this.props.toggle} className="sign-up">Competitions</Link> */}
                                    <Link to="/contacts" style={{margin: '3px'}} onClick={this.props.toggle} className="d-none display-inline-md sign-up">People</Link>
                                </div>
                            </div>
                        </div>
                        
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
