import React from 'react'

export default function ContactRow(props) {
    const { contact } = props;
    return (
        <tr className="row">
            <td className="col-2">{contact.name}</td>
            <td className="col-3">{contact.title}</td>
            <td className="col-3">{contact.email ? <a target="_blank" href={`mailto:${contact.email.workEmail}`}>{contact.email.workEmail}</a> : ''}</td>
            <td className="col-2">{contact.address ? <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${contact.address.city}`}>{contact.address.city}</a> : ''}</td>
            <td className="col"><a className="rectangle" target="_blank" href={contact.linkedin}><img className="single-02-3" src="/images/single-02-3.svg" alt=""/></a></td>
        </tr>
    )
}