export default function(eventType, randPicNumber = Math.round(Math.random())){
    switch (eventType.toLowerCase()) {
        case 'competition':
            return  [
                '/images/events/conference.jpg'
            ][randPicNumber];
        
        default:
            return ''
    }
}