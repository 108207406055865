import React from "react";

import "./IotRank.css";

export default class IotRank extends React.Component {
  constructor() {
    super();
    this.renderLine = this.renderLine.bind(this);
  }
  renderLine(score) {
    score = score * 0.1;
    return <div className="iot-rank-line" style={{ width: score + "%" }} />;
  }

  render() {
    const { rank, user, score } = this.props;
    const userType =
      user && user["cognito:groups"] && user["cognito:groups"][0];
    return (
      <span className="iot-rank">
        {userType || (!userType && rank <= 5) ? (
          score
        ) : (
          <span className="blurred-box">{score}</span>
        )}
        <span className="d-inline-block w-50">{this.renderLine(score)}</span>
      </span>
    );
  }
}
