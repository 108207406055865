import React from "react";
import Rank from "./Rank";
import FakeData from "../../../components/FakeData/FakeData";
import { Link } from "react-scroll";
import { FaTwitter } from "react-icons/fa";
import * as employeeCountRanges from "../../../assets/employeeCountRanges.json";
import { isNumber } from "util";
// import { Button } from 'reactstrap';
// import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa';
import RestrictedPopover from "../../../components/Modals/RestrictedPopoverSmall/RestrictedPopover";
import GeneratedApi from "../../../components/functions/generatedApi";

export default class Sidemenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      likeData: null,
      showModal: false,
      showModalMobile: false,
      thumbsUp: false,
      likeLength: 0,
      mobileMenuOpen: false,
      scroll: 0,
      suggestModal: false,
    };

    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.getRange = this.getRange.bind(this);
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toggleModalMobile = () => {
    this.setState({ showModalMobile: !this.state.showModalMobile });
  };

  componentDidMount() {
    // window.addEventListener('scroll',() => this.handleScroll(this.state.scroll), { passive: true })
    // const q = {"dataId":this.props.data.companyProfile&&this.props.data.companyProfile.id ,"dataType":"companies"}
    // GeneratedApi("", true, true).post('/getLikeInfo', q)
    //     .then((response) => {
    //         response = response.data;
    //         this.setState({
    //             likeData:response,
    //             thumbsUp: response.currentUserLike,
    //             likeLength: response.userList.length,
    //         });
    //     })
    // .catch((error) => {
    //     console.log(error);
    //     if(error.response) {
    //         if(error.response.status === 429) {
    //             return false;
    //         }
    //     }
    // });
  }
  likeHandle = () => {
    if (!this.props.user) {
      this.setState({
        showModal: true,
        showModalMobile: true,
      });
    } else {
      const like = {
        dataId:
          this.props.data.companyProfile && this.props.data.companyProfile.id,
        dataType: "companies",
        operation: "LIKE",
      };
      const unlike = {
        dataId:
          this.props.data.companyProfile && this.props.data.companyProfile.id,
        dataType: "companies",
        operation: "INDIFFERENT",
      };
      if (this.state.thumbsUp) {
        if (this.state.likeLength < 1) {
          this.setState({
            thumbsUp: false,
            likeLength: 0,
          });
        } else {
          this.setState({
            thumbsUp: false,
            likeLength: this.state.likeLength - 1,
          });
        }

        GeneratedApi("", true, true)
          .post("/updateLikeInfo", unlike)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            if (this.state.likeLength < 1) {
              this.setState({
                thumbsUp: false,
                likeLength: 0,
              });
            } else {
              this.setState({
                thumbsUp: false,
                likeLength: this.state.likeLength + 1,
              });
            }
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      } else {
        this.setState({
          thumbsUp: true,
          likeLength: this.state.likeLength + 1,
        });
        GeneratedApi("", true, true)
          .post("/updateLikeInfo", like)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            this.setState({
              thumbsUp: false,
              likeLength: this.state.likeLength - 1,
            });
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      }
    }
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getRange(number) {
    let range = "";
    if (!number) return;
    if (number >= 500000) {
      return "500,000+";
    }
    employeeCountRanges.default.forEach((val, i) => {
      if (number >= val.from && number <= val.to) {
        range = `${this.numberWithCommas(val.from)} - ${this.numberWithCommas(
          val.to
        )}`;
      }
    });

    return range;
  }
  handleClickMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };
  handleClickMobileMenuClose = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };
  handleSuggest = () => {
    if (!this.props.user) {
      this.setState({
        suggestModal: !this.state.suggestModal,
      });
    }
  };

  render() {
    const { mobileMenuOpen } = this.state;
    const { companyProfile, financialData, contacts } = this.props.data;
    const { useCases } = this.props.data || [];
    const { style, distanceFromBottom, user } = this.props;
    const { rank, rankChange } = companyProfile;
    const userType =
      user && user["cognito:groups"] && user["cognito:groups"][0];
    const styling = {
      ...style,
      top:
        distanceFromBottom > 180
          ? `${132}px`
          : isNumber(distanceFromBottom)
          ? distanceFromBottom - 32
          : 0,
    };

    return (
      <div style={styling} className="side-menu">
        <div className="menu">
          <div className="company">
            <img
              src={
                companyProfile.logo
                  ? companyProfile.logo
                  : "/images/gray-box.png"
              }
              alt={companyProfile.name}
              className="logo"
            />
            <p>{companyProfile.name}</p>
          </div>
          <div className="menu-divider" />
          <div className="data">
            {/* <div className="d-none d-lg-flex">
                    <div style={{margin: 'auto 0'}}> <p>Likes</p> </div>
                    <div className="likes d-flex">
                        <div className="length">
                            <p>
                                {this.state.likeLength}
                            </p>
                            
                        </div>
                        <Button className="position-relitive" onClick={(() => this.likeHandle())} color="link">
                            {this.props.user?'':
                                <RestrictedPopover classN="d-none d-lg-block" showModal={this.state.showModal} handleCloseModal={this.toggleModal} id={`Like-companyProfile1${this.props.id}`} />
                            }
                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                        </Button>
                    </div> 
                </div> */}
            <div className="d-block d-md-none d-lg-block">
              <div>
                <Rank data={{ rank, rankChange, user }} />
              </div>
              <div>
                <p>IoT Ace™ MVP Score</p>
                {userType || (!userType && rank <= 5) ? (
                  <FakeData
                    data={companyProfile.score && companyProfile.score.value}
                    value={1000}
                  />
                ) : (
                  <div className="d-inline blurred-box">
                    <FakeData data="hide" value={1000} />
                  </div>
                )}
              </div>
              <div>
                <p>Website</p>
                <span>
                  <a
                    target="_blank"
                    href={
                      companyProfile.url.includes("https://") ||
                      companyProfile.url.includes("http://")
                        ? companyProfile.url
                        : `https://${companyProfile.url}`
                    }
                  >
                    Link
                  </a>
                </span>
              </div>
              <div>
                <p>HQ Location</p>
                <span>{companyProfile.hqLocation}</span>
              </div>
              <div>
                <p>Company Size</p>
                <span>{this.getRange(companyProfile.exmployeeCount)}</span>
              </div>
            </div>
            <div className="d-none d-md-block d-lg-none">
              <div className="row">
                <div className="col-6">
                  <div>
                    <Rank data={{ rank, rankChange, user }} />
                  </div>
                  <div>
                    <p>IoT Ace™ MVP Score</p>
                    <FakeData
                      user={user}
                      data={companyProfile.score && companyProfile.score.value}
                      value={1000}
                    />
                  </div>
                  <div>
                    <p>Website</p>
                    <span>
                      <a target="_blank" href={companyProfile.url}>
                        Link
                      </a>
                    </span>
                  </div>
                  <div>
                    <p>HQ Location</p>
                    <span>{companyProfile.hqLocation}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <p>Company Size</p>
                    <span>{this.getRange(companyProfile.exmployeeCount)}</span>
                  </div>
                  <div>
                    <p>Public/Private</p>
                    <span>{financialData && financialData.publicPrivate}</span>
                  </div>
                  <div>
                    <p>Stock Symbol</p>
                    <span>{financialData && financialData.stockSymbol}</span>
                  </div>
                  <div>
                    <p>Total Funding</p>
                    <span>{financialData && financialData.totalFunding}</span>
                  </div>
                  <div>
                    <p>Last Fund. Round</p>
                    <span>
                      {financialData && financialData.lastFundingRound}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {contacts &&
              contacts.length > 0 &&
              user &&
              user.email !== "mitchelldemo@iotnation.com" && (
                <Link
                  className="d-none d-lg-block contacts-btn"
                  activeClass="active"
                  to="contacts"
                  spy={true}
                  smooth={true}
                  offset={-57}
                  duration={500}
                >
                  <img alt="contacts-btn" src="/images/multiple-1.svg" />
                  Contacts
                </Link>
              )}
          </div>
          <div className="menu-divider" />

          <div className="d-none d-lg-flex menu-links justify-content-center">
            <Link
              className="icon-btn company-link"
              activeClass="active"
              to="company"
              spy={true}
              smooth={true}
              offset={-62.5}
              duration={500}
            >
              <img alt="company" src="/images/paragraph.svg" />
            </Link>
            <Link
              className="icon-btn tags-link"
              activeClass="active"
              to="tags"
              spy={true}
              smooth={true}
              offset={-57}
              duration={500}
            >
              <img alt="tags" src="/images/tag-1.svg" />
            </Link>
            <Link
              className="icon-btn activity-link"
              activeClass="active"
              to="activity"
              spy={true}
              smooth={true}
              offset={-57}
              duration={500}
            >
              <img alt="activity" src="/images/building-3.svg" />
            </Link>
            {useCases && useCases.length ? (
              <Link
                className="icon-btn cases-link"
                activeClass="active"
                to="cases"
                spy={true}
                smooth={true}
                offset={-62.5}
                duration={500}
              >
                <img alt="cases" src="/images/agenda-bookmark-2.svg" />
              </Link>
            ) : (
              ""
            )}
            <Link
              className="icon-btn contacts-link"
              activeClass="active"
              to="finance"
              spy={true}
              smooth={true}
              offset={-57}
              duration={500}
            >
              <img alt="contacts" src="/images/chart-bar-32-1.svg" />
            </Link>
            <Link
              className="icon-btn twitter-link"
              activeClass="active"
              to="twitter"
              spy={true}
              smooth={true}
              offset={-57}
              duration={500}
            >
              <FaTwitter style={{ color: "#1dcaff", fontSize: "18px" }} />
            </Link>
          </div>

          <div className="d-block d-lg-none mobile-menu-list menu-links">
            {mobileMenuOpen ? (
              <>
                <div className="menu-list-top">
                  <div className="row">
                    <div className="col-12 company-link icon-btn p-0">
                      <Link
                        onClick={this.handleClickMobileMenuClose}
                        className="d-flex  w-100 margin-left p-20-15"
                        activeClass="active"
                        to="company"
                        spy={true}
                        smooth={true}
                        offset={-62.5}
                        duration={500}
                      >
                        <img alt="company" src="/images/paragraph.svg" />{" "}
                        Company Info
                      </Link>
                    </div>
                    <div className="col-12 icon-btn tags-link p-0">
                      <Link
                        onClick={this.handleClickMobileMenuClose}
                        className="d-flex  w-100 margin-left  p-20-15"
                        activeClass="active"
                        to="tags"
                        spy={true}
                        smooth={true}
                        offset={-57}
                        duration={500}
                      >
                        <img alt="tags" src="/images/tag-1.svg" /> IoT Tags
                      </Link>
                    </div>
                    <div className="col-12 icon-btn activity-link p-0">
                      <Link
                        onClick={this.handleClickMobileMenuClose}
                        className="d-flex  w-100 margin-left  p-20-15"
                        activeClass="active"
                        to="activity"
                        spy={true}
                        smooth={true}
                        offset={-57}
                        duration={500}
                      >
                        <img alt="activity" src="/images/building-3.svg" />{" "}
                        Industry Activity
                      </Link>
                    </div>
                    {useCases && useCases.length ? (
                      <div className="col-12 icon-btn cases-link p-0">
                        <Link
                          onClick={this.handleClickMobileMenuClose}
                          className="d-flex  w-100 margin-left  p-20-15"
                          activeClass="active"
                          to="cases"
                          spy={true}
                          smooth={true}
                          offset={-62.5}
                          duration={500}
                        >
                          <img
                            alt="cases"
                            src="/images/agenda-bookmark-2.svg"
                          />{" "}
                          Use Cases
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-12 icon-btn financial-link p-0">
                      <Link
                        onClick={this.handleClickMobileMenuClose}
                        className="d-flex  w-100 margin-left  p-20-15"
                        activeClass="active"
                        to="finance"
                        spy={true}
                        smooth={true}
                        offset={-57}
                        duration={500}
                      >
                        <img alt="contacts" src="/images/chart-bar-32-1.svg" />{" "}
                        Financial Data
                      </Link>
                    </div>
                    <div className="col-12 icon-btn contacts-link p-0">
                      <Link
                        onClick={this.handleClickMobileMenuClose}
                        className="d-flex  w-100 margin-left  p-20-15"
                        activeClass="active"
                        to="contacts"
                        spy={true}
                        smooth={true}
                        offset={-57}
                        duration={500}
                      >
                        <img alt="contacts-btn" src="/images/multiple-1.svg" />{" "}
                        Contacts
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mobile-open-menu-list display-flex">
                  <div className="d-none d-md-flex d-lg-none company-info-mb-menu">
                    <img
                      src={
                        companyProfile.logo
                          ? companyProfile.logo
                          : "/images/gray-box.png"
                      }
                      alt={companyProfile.name}
                      className="logo"
                    />
                    <p>{companyProfile.name}</p>
                  </div>
                  <div
                    onClick={this.handleClickMobileMenu}
                    className="dropdown-list-button"
                  >
                    <img src="/images/small-down-copy-8.svg" alt="" />
                  </div>
                </div>
              </>
            ) : (
              <div className="display-flex closed-mobile-menu-link">
                <div className="d-none d-md-flex d-lg-none company-info-mb-menu">
                  <img
                    src={
                      companyProfile.logo
                        ? companyProfile.logo
                        : "/images/gray-box.png"
                    }
                    alt={companyProfile.name}
                    className="logo"
                  />
                  <p>{companyProfile.name}</p>
                </div>
                <Link
                  className="icon-btn company-link"
                  activeClass="active"
                  to="company"
                  spy={true}
                  smooth={true}
                  offset={-62.5}
                  duration={500}
                >
                  <img alt="company" src="/images/paragraph.svg" />
                </Link>
                <Link
                  className="icon-btn tags-link"
                  activeClass="active"
                  to="tags"
                  spy={true}
                  smooth={true}
                  offset={-57}
                  duration={500}
                >
                  <img alt="tags" src="/images/tag-1.svg" />
                </Link>
                <Link
                  className="icon-btn activity-link"
                  activeClass="active"
                  to="activity"
                  spy={true}
                  smooth={true}
                  offset={-57}
                  duration={500}
                >
                  <img alt="activity" src="/images/building-3.svg" />
                </Link>
                {useCases && useCases.length ? (
                  <Link
                    className="icon-btn cases-link"
                    activeClass="active"
                    to="cases"
                    spy={true}
                    smooth={true}
                    offset={-62.5}
                    duration={500}
                  >
                    <img alt="cases" src="/images/agenda-bookmark-2.svg" />
                  </Link>
                ) : (
                  ""
                )}
                <Link
                  className="icon-btn financial-link"
                  activeClass="active"
                  to="finance"
                  spy={true}
                  smooth={true}
                  offset={-57}
                  duration={500}
                >
                  <img alt="contacts" src="/images/chart-bar-32-1.svg" />
                </Link>
                {contacts && contacts.length > 0 && (
                  <Link
                    className="icon-btn contacts-link"
                    activeClass="active"
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={-57}
                    duration={500}
                  >
                    <img
                      style={{ color: "#1dcaff", fontSize: "18px" }}
                      alt="contacts"
                      src="/images/multiple-1.svg"
                    />
                  </Link>
                )}
                <div
                  onClick={this.handleClickMobileMenu}
                  className="dropdown-list-button"
                >
                  <img src="/images/small-down-copy-12.svg" alt="" />
                </div>
              </div>
            )}
          </div>

          {/* <div className="d-flex d-lg-none menu-links justify-content-center">
                    <div className="d-flex d-lg-none">
                        <div style={{padding: "15px 0", display: "flex"}} className="likes">
                            <div className="length">
                                {this.state.likeLength}
                            </div>
                            <Button className="position-relitive" onClick={(() => this.likeHandle())} color="link">
                            {this.props.user?'':
                                <RestrictedPopover classN="d-block d-lg-none" showModal={this.state.showModalMobile} handleCloseModal={this.toggleModalMobile} id={`Like-companyProfile2${this.props.id}`} />
                            }
                                {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                            </Button>
                        </div> 
                    </div>
                </div> */}
        </div>
        <RestrictedPopover
          showModal={this.state.suggestModal}
          handleCloseModal={this.handleSuggest}
          id={`GetAccess-companyProfile3${this.props.id}`}
        />
        <button
          onClick={this.handleSuggest}
          className={
            this.props.user
              ? `suggest-btn d-none d-lg-block`
              : `suggest-btn-grey d-none d-lg-block`
          }
        >
          <img alt="suggest-btn" src="/images/pen-2.svg" />
          Suggest / Request Changes
        </button>
      </div>
    );
  }
}
