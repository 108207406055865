import React, { Component } from "react";
// import HorizontalAd from './components/HorizontalAd/HorizontalAd'
import CompanyLeaderboard from "./components/CompanyLeaderboard/CompanyLeaderboard";
// import VerticalAd from './components/VerticalAd/VerticalAd'
import InvestmentsBlock from "./components/InvestmentsBlock/InvestmentsBlock";
import News from "./components/News/News";
import SmartCitiesEvents from "./components/SmartCitiesEvents/SmartCitiesEvents";
import MarketResearchReports from "./components/MarketResearchReports/MarketResearchReports";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import { Subscription } from "../components/Subscribe/Subscribe";
import { Helmet } from "react-helmet";
import "./Sector.scss";

export default class Sector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usecaseResults: [],
      firstLoad: true,
    };
  }
  render() {
    const { user, api, data } = this.props;
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div className="Sector">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {data.title ? `${data.title} | IoT Nation` : "IoT Nation"}
          </title>
        </Helmet>
        <div className="top-panel">
          <div className="container">
            <div className="title">{data.title}</div>
          </div>
        </div>
        {/* <HorizontalAd /> */}
        <div className="container">
          <div className="row m-0">
            <div className="col-sm-12 col-lg-9">
              {data.info.map((item, key) => (
                <div key={key}>
                  {item.name === "news" ? (
                    <News
                      api={api}
                      propsData={{ ...item, title: data.title }}
                    />
                  ) : item.name === "events" ? (
                    <SmartCitiesEvents
                      propsData={{
                        ...item,
                        title: data.title,
                        type: data.type,
                      }}
                      user={user}
                      api={api}
                    />
                  ) : item.name === "company" ? (
                    <CompanyLeaderboard
                      api={api}
                      propsData={{
                        ...item,
                        title: data.title,
                        type: data.type,
                      }}
                    />
                  ) : item.name === "use case" ? (
                    <ImageGallery
                      api={api}
                      propsData={{
                        ...item,
                        title: data.title,
                        type: data.type,
                      }}
                      titleImage="/images/agenda-bookmark-1.svg"
                      titleName={"Featured Use Cases"}
                      name="featured-use-cases"
                      user={user}
                    />
                  ) : item.name === "investment" ? (
                    <InvestmentsBlock />
                  ) : item.name === "reports" ? (
                    <MarketResearchReports />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
            <div className="col-3">
              {/* <VerticalAd /> 
                        <VerticalAd />     */}
            </div>
          </div>
        </div>
        {userType !== "admin" && userType !== "corporate" && (
          <div style={{ marginTop: "50px" }}>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
