import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import './Blog.scss'
import moment from 'moment-timezone'

export default class Blog extends Component {
    render() {
        const { propsData } = this.props
        return (
            <div className="Blog">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>IoT Nation Blog</title>
                </Helmet> 
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>
                <div className="top-selector grey-border-b grey-border-l grey-border-r grey-border-t d-none d-lg-block">
                    <div className="container d-flex">
                        <div className="title">IoT Nation Blog</div>
                    </div>
                    <div className="container">
                        {
                            propsData ?
                            <div className="header-block rounded-6">
                                <div className="row margin-l-0">
                                    <div className="col-lg-6 col-xl-5 header-info-side">
                                        <div className="tag">
                                            <div className="text">
                                                FEATURED
                                            </div>
                                        </div>
                                        <div className="header-title"> <a className="custom-link" href={`/blog/${propsData.slug}`} dangerouslySetInnerHTML={{__html: propsData.title ? propsData.title.rendered : ""}} /> </div>
                                        <div dangerouslySetInnerHTML={{__html: propsData.content ? propsData.content.rendered : ""}} className="header-description" />
                                        <div className="header-date">{propsData.date && `ON ${moment(propsData.date).format('MMMM DD, YYYY')}`}</div>
                                    </div>
                                    <div className="offset-xl-1 col-6">
                                        <a href={`/blog/${propsData.slug}`}>
                                            <img src={propsData.jetpack_featured_media_url} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div> : ""
                        }
                    </div>
                </div>
                <div className="top-panel d-flex d-lg-none">
                    <div className="container">
                        <div className="title">IoT Nation Blog</div>
                    </div>
                </div>
                <div className="container">
                {
                    this.props.children
                }   
                </div>
                
            </div>
        )
    }
}
