import React, { PureComponent } from 'react'
import './EventsItem.scss';
import axios from 'axios';
import { Button } from 'reactstrap';
import AddtoCalendarDropdownImage from '../../components/AddToCalendarDropdownImage/AddtoCalendarDropdown'
import AddtoCalendarDropdownDate from '../../components/AddToCalendarDropdownDate/AddToCalendarDropdownDate'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone';
import cx from 'classnames'
import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa';
import Tippy from '@tippy.js/react'
import RestrictedPopover from '../../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
import 'tippy.js/dist/tippy.css'
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import getEventTypeImage from '../functions/getEventTypeImage'
import getTimezoneNumber from '../../utils/getTimezoneNumber';
import "../labels.scss";
axios.defaults.headers.post['Content-Type'] = 'application/json';


class EventsItem extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            likeData:null,
            showModal: false,
            showModalMobile: false,
            thumbsUp: false,
            likeLength: 0,
        }
       
      }
    state = {
        moreInfo: false,
        moreTags: false,
        randPicNumber:Math.round(Math.random())
    }
    async componentDidMount() {

        // const q = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event"}

        //    this.props.instance2.post('/getLikeInfo', q)
        //     .then((response) => {
        //         response = response.data;
        //         this.setState({
        //             likeData:response,
        //             thumbsUp: response.currentUserLike,
        //             likeLength: response.userList.length,
        //         });
                
        //     })
        //     .catch((error) => {
        //         console.log(error.response);
        //         if(error.response) {
        //             if(error.response.status === 429) {
                        
        //                 return false;
        //             }
        //         }
        //     }); 
      }

    handleMoreInfo = () => this.setState(({moreInfo})=>({moreInfo:!moreInfo}))
    handleMoreTags = () => this.setState()
    toggleModal = () => {this.setState({showModal: !this.state.showModal})}
    toggleModalMobile = () => {this.setState({showModalMobile: !this.state.showModalMobile})}

    likeHandle = () => {
        if(!this.props.user){
          this.setState({
                showModal: true,
                showModalMobile: true
            })
        }else{
          const like = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event", "operation":"LIKE"}
          const unlike = {"dataId":this.props.data&&this.props.data.eventId ,"dataType":"event", "operation":"INDIFFERENT"}
          if(this.state.thumbsUp ){
            if(this.state.likeLength < 1){
                this.setState({
                    thumbsUp: false,
                    likeLength: 0
                })
            }else{
                this.setState({
                    thumbsUp: false,
                    likeLength: this.state.likeLength - 1
                })
            }
            this.props.instance2.post('/updateLikeInfo', unlike)
            .then((response) => {
                response = response.data;
                this.setState({
                  likeData: response,
                });
                
            })
            .catch((error) => {
                if(this.state.likeLength < 1){
                    this.setState({
                        thumbsUp: false,
                        likeLength: 0
                    })
                }else{
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength + 1
                    })
                }
                console.log(error.response);
                if(error.response) {
                    if(error.response.status === 429) {
                      return false;
                    }
                }
            });
          }else{
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength + 1
            })
            this.props.instance2.post('/updateLikeInfo', like)
              .then((response) => {
                  response = response.data;
                  this.setState({
                    likeData: response,
                  });
                  
              })
              .catch((error) => {
                    this.setState({
                        thumbsUp: false,
                        likeLength: this.state.likeLength - 1
                    })
                  console.log(error.response);
                  if(error.response) {
                      if(error.response.status === 429) {
                          
                          return false;
                      }
                  }
              });
          }
        
       }
      }

    render() {
        
        const addresss = this.props.data.location.address
        const locationn = Object.assign([], addresss.street1&&{0:addresss.street1}, addresss.city&&{1:addresss.city} ,addresss.stateCode&&{2:addresss.stateCode}, addresss.zipCode&&{3:addresss.zipCode}).join(', ')
        const startRegistere = moment.unix(this.props.data.date.startRegister)
        const endRegistere = moment.unix(this.props.data.date.endRegister)
        const duration = moment.duration(endRegistere.diff(startRegistere)).asHours().toString()
        
        const { moreInfo, moreTags, randPicNumber } = this.state
        let { 
            date:{ endRegister, startRegister } = {},
            descriptions:{ shortDescription } = {},
            eventType,
            featured,
            images: { squareIcon } = {},
            location: { address } = {},
            tags:{ horizontals = [], verticals = [] } = {},
            title,
            prize,
            website
         } = this.props.data;
         const allDay = endRegistere.utc().format('YYYYMMDDZ') === moment().utc().format('YYYYMMDDZ')? false: true
         const event = {
            // description: `For info on this competition: <a href="${website}">Link to website</a>
            // \nSee more competitions on IoT Nation. Visit \n<a href="https://www.iotnation/competitions">www.iotnation/competitions</a>
            // \nIoT Nation is a go-to resource for all things IoT!\nFind companies, events, competitions, and more.`,
            description: `For info on this competition: <a href="${website}">Link to website</a>`,
            duration,
            endDatetime: allDay?endRegistere.utc().format('YYYYMMDD'): endRegistere.utc().format('YYYYMMDDTHHmmssZ'),
            locationn,
            startDatetime: allDay?startRegistere.utc().format('YYYYMMDD'): startRegistere.set('hour', 0).set('minutes', 0).set('seconds', 1).utc().format('YYYYMMDDTHHmmssZ'),
            title: this.props.data.title,
          }
        const tags = [ ...horizontals, ...verticals ].filter(function (el) {
            return el !== '';
          });

        const eventInDays =  moment.unix(endRegister).format('DD') - moment.unix(startRegister).format('DD')
        const endRegisterMoment = moment.unix(endRegister)
        const timezone = endRegisterMoment.tz(moment.tz.guess()).format(`z [(GMT]${getTimezoneNumber(endRegisterMoment.format('Z'))}[)]`)
        const dateFormated = endRegister&&moment.unix(endRegister).format('MMMM DD, YYYY')
        const timeFormated = endRegister&&`${endRegisterMoment.format('hh:mmA ')} ${timezone}`
        
        let now = moment();
        
        const fullDaysLeft = startRegister&&moment.unix(startRegister).isAfter(now)&&moment.unix(startRegister).diff(now, 'days')
        const hoursLeft = startRegister&&moment.unix(startRegister).isAfter(now)&&moment.unix(startRegister).diff(now, 'hours')
        const monthsLeft = startRegister&&moment.unix(startRegister).isAfter(now)&&moment.unix(startRegister).diff(now, 'months')
        const weeksLeft = startRegister&&moment.unix(startRegister).isAfter(now)&&moment.unix(startRegister).diff(now, 'weeks')
        const inProgress = startRegister&&moment.unix(startRegister).isBefore(now)&&moment.unix(endRegister).isAfter(now);
        const isNotEnded = moment.unix(endRegister) > now ? true :false
        const locationString = Object.assign([], address.city&&{0:address.city} ,(address.stateCode || address.countryCode )&&{1:address.stateCode || address.countryCode }).join(', ')
        
        const classes = {
            label:cx({
                label: true,
                [String(eventType).toLowerCase()]:!!eventType
            })
        }
        let prizes = '';
        if(prize.prizes) {
            prizes = (
                
                <div className='prize-block'>
                    <div className='price-text'><b>{prize.prizeDescription} in total prizes</b></div>
                    <div  style={{margin: "0 0 0 auto"}}>
                        <Tippy content={
                            <>
                                <h4 className="title-popup">{title}</h4>
                                <ul data-simplebar>
                                {prize.prizes.map((prizeItem, key) =>
                                    <li key={key} className="prize-list"><p className="prize-title">{prizeItem.prizeTitle + ': '} </p> <p className="prize-description">{prizeItem.prizeDescription}</p></li>
                                )}
                                </ul>
                            </>
                            
                                        
                        } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click">
                            <div  className="price-btn">See prizes</div>
                        </Tippy>
                        <a target="_blank" href={website}  className="price-btn">Register</a>
                    </div>                                 
                </div>
            )
        }else{
            prizes = (
                
                <div className='prize-block height-24'>
                    <div className='price-text'></div>
                    <div  style={{margin: "0 0 0 auto"}}>
                            
                        <a target="_blank" href={website}  className="price-btn">Register</a>
                    </div>                                 
                </div>
            ) 
        }
        return (
            <div className={`EventsItem ${featured ? 'featured' : ''}`}>
                {prizes}
                {
                    featured&&
                    <div className="featured-header">
                        <div className="img-wrapper">
                            <img src="/images/star.svg" alt=""/>
                        </div>
                        <div className="title">Featured</div>
                    </div>
                }
                <div className="event-body">
                    <div className="row no-gutters">
                        <div className="d-none d-md-block col-auto">
                            {        
                                <div className="event-image" >
                                    <img src={squareIcon?squareIcon:getEventTypeImage(eventType, randPicNumber)} alt=""/>
                                </div>
                            }
                        </div>
                        <div className="d-md-none col-12">
                            {        
                                <div className="event-image w-100" >
                                    <img src={squareIcon?squareIcon:getEventTypeImage(eventType, randPicNumber)} alt=""/>
                                </div>
                            }
                        </div>
                        <div className="col general-info">
                            <div className="row no-gutters align-items-center">
                                <div className="col-10">
                                <div className="event-title">
                                    <a target="_blank" href={website?website:''}>{title}</a>
                                </div>
                                </div>
                                {/* <div className="col-2 text-right d-none d-md-block">
                                    <div className="likes" style={{display: "flex"}}>
                                        <div className="likes">
                                            {this.state.likeLength}
                                        </div>
                                        <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                                            {this.props.user?'':
                                                <RestrictedPopover classN="d-none d-md-block" showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={`Like-competitions2${this.props.id}`} />
                                            }
                                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                        </Button>
                                    </div> 
                                </div> */}
                            </div>
                            <div className="d-md-none w-100 grey-border-2 margin-tb"></div>
                            <div className="row m-0 p-8">
                                <div className="d-md-none">
                                    <div className={classes.label}><span>{eventType&&eventType}</span></div>
                                </div>
                                <div className="d-md-none margin-right">
                                    <span className="time-left">{inProgress ? 'In Progress' : isNotEnded?
                                        (fullDaysLeft <= 2 && hoursLeft === 1? `In ${hoursLeft} hour`: 
                                            hoursLeft < 1? `In less than an hour`: 
                                                hoursLeft <= 48? `In ${hoursLeft} hours`: 
                                                    fullDaysLeft <= 14? `In ${fullDaysLeft} days`: 
                                                        fullDaysLeft <= 56? `In ${weeksLeft} weeks`: 
                                                            fullDaysLeft > 56 && monthsLeft === 1? 'In 1 month': 
                                                                fullDaysLeft > 56 && monthsLeft >1? `In ${monthsLeft} months`: 'In Progress') 
                                         :`Already ended`}</span>
                                </div>
                            </div>
                            
                            <div className="row no-gutters align-items-center">
                                <div className="col">
                                    <div className="row no-gutters">
                                        <AddtoCalendarDropdownImage
                                            event={event}
                                            className="AddtoCalendarDropdown margin-bottom-4"
                                            style={{width: "100px"}}
                                            buttonProps={{dateFormated}}
                                            linkProps={{target: '_blank'}}
                                            
                                        />
                                        <div style={{padding: "0px 10px"}}><b>Registration ends:</b> </div> 
                                        <div className="info-holder">
                                        <AddtoCalendarDropdownDate
                                            event={event}
                                            className="AddtoCalendarDropdown margin-bottom-4"
                                            buttonProps={{dateFormated}}
                                            linkProps={{target: '_blank'}}
                                            
                                        />
                                        </div>
                                        {eventInDays === 0 ?
                                            <div className=" info-holder margin-bottom-4">
                                                <img src="/images/clock.svg" alt=""/>
                                                <span>{ timeFormated}</span>
                                            </div>:
                                            null
                                        }
                                        
                                        
                                    </div>
                                    <div className="row no-gutters">
                                        {
                                        locationString&&
                                        <div className="col-auto info-holder margin-bottom-4">
                                            <img src="/images/pin-3.svg" alt=""/>
                                            <span>{locationString}</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-none d-md-block text-right">
                                    <div className={classes.label}><span>{eventType&&eventType}</span></div>
                                </div>
                            </div>
                            {/* <div className="row no-gutters">
                                <div className="display-flex">
                                  <img src="/images/building-1.svg" alt=""/> <div className="organizer">Organizer:</div> <div className="self-organizer">No info</div>   
                                </div>
                            </div> */}
                            <div className="row no-gutters margin-0">
                                <div className="col">
                                    <div className="row">
                                        <div className="d-none d-md-block col tag-col">
                                            <span className="tags">
                                            {tags.slice(0, moreTags?(tags.length):3).map((tag, i)=>
                                                tag&&
                                                (
                                                    !moreTags && i === 2 && tags.length - (i) > 0
                                                    ?
                                                    
                                                    <Tippy key={i} content={
                                                <ul data-simplebar>
                                                    {tags.slice(2,tags.length).map((tag, i) => 
                                                        tag&& <li key={i} className="capitalize">{tag}</li>
                                                    )}
                                                </ul>
                                            } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i} onClick={this.handleMoreTags} className="btn tag capitalize">{`+${tags.length - (i)}`}</button></Tippy>

                                                    :<button key={i} className="btn tag capitalize">{tag}</button>
                                                )
                                            )}
                                            </span>
                                        </div>
                                        <div className="d-md-none col tag-col">
                                        <span className="tag-header">Tags:</span>                                            
                                            <span className="tags">
                                            {tags.slice(0, moreTags?(tags.length):2).map((tag, i)=>
                                                tag&&
                                                (
                                                    !moreTags && i === 1 && tags.length - (i) > 0
                                                    ?
                                                    
                                                    <Tippy key={i} content={
                                                <ul data-simplebar>
                                                    {tags.slice(1,tags.length).map((tag, i) => 
                                                        tag&& <li key={i} className="capitalize">{tag}</li>
                                                    )}
                                                </ul>
                                            } animateFill={false} duration={500} delay={[100, 50]} theme="iot" interactive={true} trigger="click"><button key={i}  className="btn tag capitalize">{`+${tags.length - (i)}`}</button></Tippy>

                                                    :<button key={i} className="btn tag capitalize">{tag}</button>
                                                )
                                            )}
                                            </span> 
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className={`col ${moreInfo?'content':'content hidden'}`}>  
                                            {shortDescription}
                                        </div>
                                    </div>
                                    <div className="d-md-none w-100 grey-border-2 margin-tb"></div>
                                </div>
                                <div className="d-none d-md-block col-4 text-right">
                                    <span className="time-left">{inProgress ? 'In Progress' : isNotEnded?
                                        (fullDaysLeft <= 2 && hoursLeft === 1? `In ${hoursLeft} hour`: 
                                            hoursLeft < 1? `In less than an hour`: 
                                                hoursLeft <= 48? `In ${hoursLeft} hours`: 
                                                    fullDaysLeft <= 14? `In ${fullDaysLeft} days`: 
                                                        fullDaysLeft <= 56? `In ${weeksLeft} weeks`: 
                                                            fullDaysLeft > 56 && monthsLeft === 1? 'In 1 month': 
                                                                fullDaysLeft > 56 && monthsLeft >1? `In ${monthsLeft} months`: 'In Progress') 
                                         :`Already ended`}</span>
                                </div>
                            </div>
                            <div className="display-flex vertical-align">
                            {
                                shortDescription&&
                                <div onClick={this.handleMoreInfo} className="show-more-info vertical-align">
                                        Show {moreInfo?'less':'more'} info
                                </div>
                            }
                            {/* <div className="d-md-none col-2 margin-right">
								<div className="likes" style={{display: "flex"}}>
									<div className="likes">
										{this.state.likeLength}
									</div>
									<Button onClick={(() => this.likeHandle())} color="link" className="likes">
										{this.props.user?'':
											<RestrictedPopover classN="d-block d-md-none" showModal={this.state.showModalMobile} handleCloseModal={this.toggleModalMobile}  id={`Like-competitions1${this.props.id}`} />
										}
										{this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
									</Button>
								</div> 
                            </div> */}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(EventsItem)