import React from "react";
import AdvancedFiltersMobile from "../components/AdvancedFiltersCompaniesMobile/AdvancedFiltersMobile";
import ResultsTable from "../components/ResultsTable/ResultsTable.jsx";
import ResultsTableMobile from "../components/ResultsTable/ResultsTableMobile.jsx";
import ResultsTableTablet from "../components/ResultsTable/ResultsTableTablet.jsx";
import { Subscription } from "../components/Subscribe/Subscribe";
import RegisterPopover from "../components/Modals/RegisterPopover/RegisterPopover";
import CompanyGridDisclaimer from "../components/SubscriberDisclaimer/CompanyGridDisclaimer";
import "tippy.js/dist/tippy.css";
import "simplebar";
import "simplebar/dist/simplebar.css";
import "./Search.scss";
import style from "./Search.module.scss";
import { Helmet } from "react-helmet";
import Spinner from "../components/Spinner/Spinner";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs.jsx";
import AdvancedFiltero from "../components/AdvancedFiltero/AdvancedFiltero.jsx";
import { FaFileCsv } from "react-icons/fa";
export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      firstLoad: true,
    };
  }

  toggleModal = () => {
    this.setState({ modal: false });
  };

  componentDidMount() {
    if (!this.props.user && this.state.firstLoad) {
      setTimeout(() => {
        this.setState({ modal: true, firstLoad: false });
      }, 60000);
    }
  }
  render() {
    const {
      count,
      limit,
      searchResults,
      start,
      loading,
      currentPage,
    } = this.props.data;
    const { sort, user, api, updatePropsState } = this.props;
    const {
      handlePageChange,
      handleSortChange,
      handleSubmit,
      handleQueryChange,
    } = this.props;
    const { query, firstLoad, history, downloadApi } = this.props;
    const userType =
      this.props.user &&
      this.props.user["cognito:groups"] &&
      this.props.user["cognito:groups"][0];
    return (
      <div className={style.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Companies</title>
        </Helmet>
        <RegisterPopover
          pathname={window.location.pathname}
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        />
        <CompanyGridDisclaimer user={user} />
        <Breadcrumbs last="Companies" />

        <AdvancedFiltero
          {...{
            ...this.props.advancedFiltersValues,
            query,
            currentPage,
            history,
            user,
            updatePropsState,
            downloadApi,
          }}
          whenToShow="d-none d-md-block"
          link1={{ url: "map", name: "To Map" }}
          link2={
            user && user.email !== "mitchelldemo@iotnation.com"
              ? { url: "contacts", name: "To Contacts" }
              : false
          }
        />

        <div className="d-block d-md-none">
          <AdvancedFiltersMobile
            whenToShow="d-block d-md-none"
            visible={true}
            aFHandle={{
              ...this.props.advancedFiltersHandlers,
              ...this.props.aFMobileHandle,
              handleSubmit,
              handleQueryChange,
            }}
            aFValue={{
              ...this.props.advancedFiltersValues,
              ...this.props.aFMobileValue,
              value: this.props.query,
            }}
          />
        </div>

        <>
          <div className="d-block d-md-none">
            {loading ? (
              <Spinner height={"40vh"} />
            ) : !firstLoad && (searchResults && searchResults.length < 1) ? (
              <div className="d-flex">
                <div className="mx-auto my-5 h4">
                  No results found. Consider removing filters.
                </div>
              </div>
            ) : (
              <ResultsTableMobile
                data={{
                  count,
                  limit,
                  start,
                  currentPage,
                  searchResults,
                  sort,
                }}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                api={api}
                user={user}
              />
            )}
          </div>
          <div className="d-none d-md-block d-lg-none">
            {loading ? (
              <Spinner height={"40vh"} />
            ) : !firstLoad && (searchResults && searchResults.length < 1) ? (
              <div className="d-flex">
                <div className="mx-auto my-5 h4">
                  No results found. Consider removing filters.
                </div>
              </div>
            ) : (
              <ResultsTableTablet
                data={{
                  count,
                  limit,
                  start,
                  currentPage,
                  searchResults,
                  sort,
                }}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                api={api}
                user={user}
              />
            )}
          </div>
          <div className="d-none d-lg-block">
            {loading ? (
              <Spinner height={"40vh"} />
            ) : !firstLoad && (searchResults && searchResults.length < 1) ? (
              <div className="d-flex">
                <div className="mx-auto my-5 h3">
                  No results found. Consider removing filters.
                </div>
              </div>
            ) : (
              <ResultsTable
                data={{
                  count,
                  limit,
                  start,
                  currentPage,
                  searchResults,
                  sort,
                }}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
                api={api}
                user={user}
              />
            )}
          </div>
        </>
        {userType !== "admin" && userType !== "corporate" && (
          <div>
            <Subscription />
          </div>
        )}
      </div>
    );
  }
}
