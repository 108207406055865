import React from 'react'

import FakeData from '../../../components/FakeData/FakeData';
import getRandomInt from '../../../utils/getRandomInt';

import Scores from './Scores/Scores'
import Organizations from './Organizations/Organizations'
import Conferences from './Conferences/Conferences'
import Ecosystem from './Ecosystem/Ecosystem'


export default class IotIndustryActivity extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            scoreIsFaked: false,
            coeIsFaked: false,
        }
        this.generateYearsArray = this.generateYearsArray.bind(this);
        this.generateFakes = this.generateFakes.bind(this);
        this.fakeCoeDetails = this.fakeCoeDetails.bind(this);
        this.fakeiotActivityScore = this.fakeiotActivityScore.bind(this);
    }


    generateYearsArray() {

        let arr = []
        let howMany=getRandomInt(1,3)

        for(let i = 0; i < howMany; i++){
            arr.push({year:String(getRandomInt(2016, 2018)), type: FakeData({toFake:'name.findName', returnJustValue:true})})
        }
        return arr 
    }

    generateFakes(withYear) {

        let list = []
        for (let i = 0; i < 10; i++) {
            if(withYear){
                list.push({name: FakeData({toFake:'lorem.words', returnJustValue:true}), typeWithYear:this.generateYearsArray()})
            }else{
                list.push({name: FakeData({toFake:'lorem.words', returnJustValue:true}), type: FakeData({toFake:'name.findName', returnJustValue:true})})
            }
    
        }

        return list;
    }
    fakeCoeDetails(coeDetails = null) {
        this.setState({coeIsFaked: true})
        if(!coeDetails) {
            return {
                conferences:{
                    list:this.generateFakes(true),
                    total: 9
                },
                ecosystems:{
                    list:this.generateFakes(),
                    total: 9
                },
                organizations:{
                    list:this.generateFakes(),
                    total: 9
                }
            }
        }
        coeDetails.conferences.list = this.generateFakes(true);
        coeDetails.ecosystems.list = this.generateFakes();
        coeDetails.organizations.list = this.generateFakes();
        return coeDetails;
    }
    fakeiotActivityScore() {
        this.setState({scoreIsFaked: true})
        return {
            components:{
                conferences:getRandomInt(0,30),
                ecosystems:getRandomInt(30,60),
                organizations:getRandomInt(60,100),
            },
            score: {
                score: 1000, value: 1000, max: 1000, min: 0
            }
        }
    }
    render() {
    const goldTypes=[
        'Diamond Member', 'Founding Member'
    ]
    const diamondTypes=[];
     const { iotIndustryActivity } = this.props
    const {conferences, ecosystems, organizations, socialmedia} = iotIndustryActivity ?
        iotIndustryActivity.coeDetails.conferences.list && iotIndustryActivity.coeDetails.ecosystems.list && iotIndustryActivity.coeDetails.organizations.list?
            iotIndustryActivity.coeDetails :
            this.fakeCoeDetails(iotIndustryActivity.coeDetails)
        : this.fakeCoeDetails()
    const {score, components} = iotIndustryActivity? iotIndustryActivity.iotActivityScore.components.conferences !== null? iotIndustryActivity.iotActivityScore:this.fakeiotActivityScore():this.fakeiotActivityScore()
    const {companyProfile, financialData} = this.props;

    if(!(conferences&& ecosystems&& organizations ))return false
    return (
        <div className="card activity">
            <div className="card-header">
            <div className="icon activity"><img alt="activity" src="/images/building-4.svg"/></div>
            <div className="title-holder"><p id="activity">IoT Industry Activity</p></div>
            </div>
            <div className="menu-divider"></div>
            {
                score&&components&&
                <Scores companyProfile={companyProfile} financialData={financialData} scoreIsFaked={this.state.scoreIsFaked} score={score} components={components} ></Scores>
            }
            
            {
                organizations.list&&
                <>
                    <div className="scores-divider"></div>
                    <Organizations coeIsFaked={this.state.coeIsFaked} total={organizations.total} organizations={organizations.list} goldTypes={goldTypes} user={this.props.user}></Organizations>
                </>
            }
            {
                
            }
            
             {
                conferences.list&&
                <>
                    <div className="scores-divider"></div>
                    <Conferences coeIsFaked={this.state.coeIsFaked} conferences={conferences.list} total={conferences.total} diamondTypes={diamondTypes} user={this.props.user}></Conferences>
                </>
            }
            
            {   
                ecosystems.list&&
                <>
                    <div className="scores-divider"></div>
                    <Ecosystem coeIsFaked={this.state.coeIsFaked} total={ecosystems.total} ecosystems={ecosystems.list} goldTypes={goldTypes} user={this.props.user}></Ecosystem>
                </>
            }
        </div>
    )
}
}