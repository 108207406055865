import React from 'react'

function Title({src, name}) {
  return (
    <div>
      <div className="row m-0">
          <div className=" orange-block justify-center display-flex">
              <img width="100%" src={src} alt=""/>
          </div>
          <div className="col title-border align-center display-flex">
              <p className="bold" style={{margin: "0px"}}>{name}</p>
          </div>
      </div>
    </div>
  )
  
}
export default Title