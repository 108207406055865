import React from 'react'
import Jobs from './Jobs'

const JobsContainer = () => {
    return (
        <Jobs />
    )
}

export default JobsContainer
