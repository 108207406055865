import React, { PureComponent } from "react";
import NewEvents from './NewEvents'
export default class CalendarView extends PureComponent {
  render() {
    const {
      children,
      upcomingEvents,
      newEvents,
    } = this.props;
    return (
      <div className="container">
        <div className="row special-row calendar-view">
          <div className="col-md-12 col-lg-3 d-none d-md-block">
            <div className="calendar-sidebar border rounded bg-white">
              <div className="sidebar-header d-flex">
                <div className="img-wrapper d-flex">
                  <img className="m-auto w-100" src="/images/calendar-2.svg" alt=""/>
                </div>
                <div className="h6 font-weight-bold my-auto pl-3">What’s New</div>
              </div> 
              <div className="d-block d-md-none d-lg-block">
                {
                  newEvents.map((newEvent, key)=><NewEvents {...newEvent} key={key}/>)
                }
              </div>
              <div className="d-none d-md-flex d-lg-none overflow-x-auto">
                {
                  newEvents.map((newEvent, key)=><NewEvents {...newEvent} key={key}/>)
                }
              </div>
            </div>
          </div>
          <div className="col calendar-cards">
            <div className="row">
              {children}
            </div>
          </div>
          <div className="col-lg-2 righter d-noe d-lg-block">
            <div className="now-listing">
              Upcoming events: <span className="amount">{upcomingEvents}</span>
            </div>


          </div>
        </div>
      </div>
    );
  }
}
