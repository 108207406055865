import React, { PureComponent } from 'react'
import './CustomSelect.scss'
import OutsideClickHandler from 'react-outside-click-handler';
import ClearButton from "../../components/ClearButton/ClearButton";
export default class CustomSelect extends PureComponent {
    state = {
        isOpen: false
    }

    handleIsOpen = (isOpen) =>{
        this.setState({isOpen}) 
    }
    
    render() {

        const { className, headerText = '', children, onClear ,hiddenClearBtn = false, manualPadding= false  } = this.props
        const isOpen = this.state.isOpen

        const header = (
            <div 
                style={{
                            padding:hiddenClearBtn?'0px':'0 20px 0 0'
                }}
                className="ClearButton-container">
              <ClearButton manualPadding={manualPadding} active={isOpen} clearInfo={onClear} hidden={hiddenClearBtn}  />
              <span>{headerText}</span>
            </div>
        )

        return (
            <OutsideClickHandler
                disabled={!isOpen}
                onOutsideClick={()=>this.handleIsOpen(false)}
            >
                <div className={`CustomSelect select-icon ${isOpen?'active':''} ${className}`}>
                    <div 
                        onClick={()=>this.handleIsOpen(!isOpen)}  
                        className="header"
                    >
                        {header}
                    </div>
                    <div 
                        style={{
                           visibility:isOpen?'visible':'hidden' 
                        }} 
                        className={"options"}
                    >
                        { React.Children.map(children, (child => React.cloneElement(child, {onClick:this.handleIsOpen})))}
                    </div>
                </div>
            </OutsideClickHandler>
        )
    }
}

