import React, { Component } from "react";
import Sector from "./Sector";
import SectorArray from "../content/sector.json";

export default class SectorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: "",
    };
  }
  render() {
    const { user } = this.props;

    if (this.state.page !== window.location.pathname.split("sector/")[1].toLowerCase()) {
      if (this.state.page !== "") {
        window.location.reload();
      } else {
        this.setState({ page: window.location.pathname.split("sector/")[1].toLowerCase() });
      }
    }

    let exist = SectorArray.filter((item) => item.slug.toLowerCase() === window.location.pathname.split("sector/")[1].toLowerCase());
    if (exist.length > 0) {
      return (
        <div>
          <Sector data={exist[0]} user={user} api={this.props.api} />
        </div>
      );
    } else {
      return <div className="container">Not existing tag</div>;
    }
  }
}
