import React, { Component } from 'react'
import './CalendarItem.scss'
import  getEventTypeImage from '../functions/getEventTypeImage'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {getFilter, getMainAttributeInfo} from '../functions/eventFilters'

const randPicNumber = Math.round(Math.random())
export default class CalendarItem extends Component {
    render() {
		
        let { month, counts:{ webinars, events }, majorEvent = {} } = this.props.data
        const squareIcon = majorEvent.images&&majorEvent.images.squareIcon
        const eventType = majorEvent.eventType&&majorEvent.eventType
		const image =  squareIcon?squareIcon:eventType&&getEventTypeImage(eventType, randPicNumber)
        const eventsMonthFilter = [
            {
                attribute:'dateEnd',
                value: moment(month.monthStart).isAfter(moment().format('YYYY-MM-DD')) ? month.monthStart : moment().format('YYYY-MM-DD')
            },
            {
                attribute:'dateStart',
                value:month.monthEnd
            },
            {
                attribute:'eventType',
                value: getMainAttributeInfo('eventType').value.filter(item=>item.toLowerCase() !== 'webinar')
            }
        ].map((item)=>getFilter(item.attribute, true,true, {
            value:item.value
        }))

        const webinarMonthFilter = [
            {
                attribute:'dateEnd',
                value: moment(month.monthStart).isAfter(moment().format('YYYY-MM-DD')) ? month.monthStart : moment().format('YYYY-MM-DD')
            },
            {
                attribute:'dateStart',
                value:month.monthEnd
            },
            {
                attribute:'eventType',
                value:['Webinar']
            }
        ].map((item)=>getFilter(item.attribute, true,true, {
            value:item.value
        }))


        const viewAllFilter = [
            {
                attribute:'dateEnd',
                value: moment(month.monthStart).isAfter(moment().format('YYYY-MM-DD')) ? month.monthStart : moment().format('YYYY-MM-DD')
            },
            {
                attribute:'dateStart',
                value:month.monthEnd
            },
            {
                attribute:'eventType'
            }
        ].map((item)=>getFilter(item.attribute, true, true, {
            value:item.value
        }))
        
        if(events !== 1) {
            events = `${events} Events`;
        } else {
            events = `${events} Event`;
        }
        if(webinars !== 1) {
            webinars = `${webinars} Webinars`;
        } else {
            webinars = `${webinars} Webinar`;
        }
        return (
            <div className="col-12 col-6 col-sm-4">
                <div className="row">
                    <div className="col">
                        <div className="CalendarItem">
                            <div className="calendar-month pl-0 pr-0">{month.monthStart.includes(moment().add(1, 'years').format('YYYY') + '-01')?
                                <Link className="white-color"
                                    to={{
                                        pathname:`/events`,
                                        state: { 
                                            filters: viewAllFilter
                                        }
                                    }}
                                >{ `${month.name} ${moment().add(1, 'years').format('YYYY')}`} 
                                </Link> : 
                                <Link className="white-color"
                                    to={{
                                        pathname:`/events`,
                                        state: { 
                                            filters: viewAllFilter
                                        }
                                    }}
                                >{month.name}
                                </Link>} </div>
                                
                            <div className="calendar-content">
                                <div className="row">
                                    <div className="col-6 col-12">
                                        <div className="calendar-image">
                                            {
                                                image&&
                                                <Link
                                                    to={{
                                                        pathname:`/events`,
                                                        state: { 
                                                            filters: viewAllFilter
                                                        }
                                                    }}
                                                >
                                                <img src={image} alt=""/>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6 col-12" style={{display: "flex"}}>
                                        <div style={{margin: "auto", width: "100%"}}>
                                        <Link
                                            to={{
                                                pathname:`/events`,
                                                state: { 
                                                    filters: eventsMonthFilter
                                                }
                                            }}
                                            
                                        >
                                            <div className="calendar-events">{events}</div> 
                                        </Link>
                                        <Link
                                            to={{
                                                pathname:`/events`,
                                                state: { 
                                                    filters: webinarMonthFilter
                                                }
                                            }}
                                        >     
                                            <div className="calendar-webinars">{webinars}</div>
                                        </Link>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                                
                                <Link
                                    to={{
                                        pathname:`/events`,
                                        state: { 
                                            filters: viewAllFilter

                                        }
                                        
                                    }}
                                >
                                    <div className="view-all">View All</div>
                                </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
