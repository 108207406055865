import React, { Component } from 'react'
import {Helmet} from "react-helmet";
export default class Partners extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partners | IoT Nation</title>
                </Helmet>
                <div className="top-panel">
                    <div className="container">
                        <div className="title">Partners</div>
                    </div>
                </div>
                <div className="container">
                    <div className=" information-block rounded-6 grey-border d-block d-md-flex">
                        <div className="information-image rounded-6 grey-border vertical-align d-none d-md-flex "><img className="w-100" src="/images/handshake.svg" alt=""/></div>
                        <div className="d-none d-md-block info align-vertical">
                            <div className="title"> Become an IoT Nation Partner </div>
                            <div className="description"> Join our mission to connect the Internet of Things ecosystem. </div>
                        </div>
                        <div className="d-block d-md-none info align-vertical">
                            <div className="title"> Become an IoT Nation Partner </div>
                            <div className="description"> Join our mission to connect the Internet of Things ecosystem. </div>
                        </div>
                        <div className="apply-btn">
                            <a target="_blank" className="white-link white-link-hover" href="https://forms.gle/mJFmAQwSp8LEswRX7">    
                                Apply Here
                            </a>
                        </div>
                    </div>
                    <div className="row margin-b-50">
                        <div className="col">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
