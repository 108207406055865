import React, { useState } from 'react'
import AddtoCalendarDropdownImage from '../../../components/AddToCalendarDropdownImage/AddtoCalendarDropdown'
import moment from 'moment-timezone';
import getTimezoneNumber from '../../../utils/getTimezoneNumber';
import Tippy from '@tippy.js/react'
import getEventTypeImage from '../../../Events/functions/getEventTypeImage';

const EventItem = (props) => {
  const { key, item, sortArray, dayLeft } = props.data
  const [ content, showContent ] = useState(false)

  return (
    <div key={key}>
        <div className="row mx-0 p-3 d-block d-md-flex"> 
            <div className="rounded-8 d-flex pb-2 pb-md-0">
                <a className="mx-auto" href={`/event/${item.eventId}`}>
                    <img className="rounded-8 main-img border" src={item.images && item.images.squareIcon ? item.images.squareIcon : getEventTypeImage(item.eventType)} alt=""/> 
                </a>
            </div>

            <div className="col pr-0 pl-0 pl-md-3">
                <div><a className="h6 color-blue font-weight-bold" href={`/event/${item.eventId}`}>{item.title}</a></div>
                <div className="line mt-2 mb-3 d-md-none" />
                <div className="d-flex d-md-none">
                    <div className={`custom-label ${item.eventType.toLowerCase()}`}>{item.eventType}</div>
                    <div className="mr-0 ml-auto"> { dayLeft(item.date) } </div>
                </div>
                <div className="row mx-0 mt-2">
                    <div className="col row mx-0 px-0 mr-2">
                        {
                        item.date.startTime &&
                            <div className="d-flex">
                                <AddtoCalendarDropdownImage
                                    event={{
                                        description: `For info on this event, click here: \n<a href="https://www.iotnation.com/event/${item.eventId}">www.iotnation.com/event/${item.eventId}</a>
                                        \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events">www.iotnation/events</a>`,
                                        duration: moment.duration(moment.unix(item.date.endTime).diff(moment.unix(item.date.startTime))).asHours().toString(),
                                        endDatetime: moment.unix(item.date.endTime).utc().format('YYYYMMDDTHHmmssZ'),
                                        location: Object.assign([], item.location.address.street1&&{0:`${item.location.address.street1}`},
                                        item.location.address.city&&item.location.address.street1?{1:`, ${item.location.address.city}`}:item.location.address.city&&{1:`${item.location.address.city}`}, 
                                        item.location.address.stateCode&&(item.location.address.street1 || item.location.address.city)?{2:`, ${item.location.address.stateCode}`}:item.location.address.stateCode&&{2:`${item.location.address.stateCode}`},
                                        item.location.address.zipCode&&(item.location.address.street1 || item.location.address.stateCode || item.location.address.city)?{3:`, ${item.location.address.zipCode}`}:item.location.address.zipCode&&{3:`${item.location.address.zipCode}`},
                                        item.location.address.country&&(item.location.address.street1 || item.location.address.stateCode || item.location.address.city || item.location.address.zipCode)?{3:`, ${item.location.address.country}`}:item.location.address.country&&{3:`${item.location.address.country}`}).join(''),
                                        startDatetime: moment.unix(item.date.startTime).utc().format('YYYYMMDDTHHmmssZ'),
                                        title: item.title,
                                    }}
                                    className="AddtoCalendarDropdown mt-2"
                                />
                                <div className="calendar-text vertical-align px-2 mt-2">
                                    { (moment.unix(item.date.startTime).utc().format('MMMM DD, YYYY ')) }
                                </div>
                            </div>
                        }
                        {
                        item.date.startTime &&
                        <div className="d-flex">
                            <img className="img-size mt-2 mr-2" src="/images/clock.svg" alt=""/>
                            <div className="calendar-text vertical-align mt-2 mr-2">
                                { (moment.unix(item.date.startTime).utc().format('HH:mm ') + moment.unix(item.date.startTime).utc().format(`z [(GMT ]${getTimezoneNumber(moment.unix(item.date.startTime).utc().format(' Z'))}[)]`)) }
                            </div>
                        </div>
                        }

                        {
                        item.location && item.location.address && (item.location.address.stateCode || item.location.address.city) &&
                        <div className="d-flex">
                            <img className="mt-2 mr-2" src="/images/pin-3.svg" alt=""/>
                            <div className="calendar-text mt-2">{item.location.address.stateCode ? `${item.location.address.stateCode}, ${item.location.address.city}` : item.location.address.city}</div>
                        </div>
                        }
                    </div>
                    <div className={`custom-label d-none d-md-block ${item.eventType.toLowerCase()}`}>{item.eventType}</div>
                </div>
                <div className="row ml-0 d-none d-md-flex">
                    <div className="col">
                        <div className="row">
                            {
                                sortArray(item.tags).map((tag, key) => (
                                    key < 2 && <button key={key} className="btn custom-tag capitalize mr-2 mt-2">{tag}</button>
                                ))

                            }
                            {
                                sortArray(item.tags).length >= 3 && (
                                    <Tippy
                                        key={key}
                                        content={
                                        <ul data-simplebar>
                                            {sortArray(item.tags).slice(2, sortArray(item.tags).length).map((tag, i) => 
                                                tag&& <li className="capitalize mt-2" key={i}>{tag}</li>
                                            )}
                                        </ul>
                                        }
                                        animateFill={false} duration={500} delay={[100, 50]}
                                        theme="iot" interactive={true} trigger="click"
                                    >
                                        <button key={key} className="btn custom-tag capitalize mt-2">{`+${ sortArray(item.tags).length - 2 }`}</button>
                                    </Tippy>
                                )
                            }
                        </div>
                        <div className="line mt-2 mb-3 d-md-none" />
                        <div className="row">
                            <div className={`col-12 px-0 show-content ${content === key ? '' : 'hidden'}`}>
                                {item.descriptions && item.descriptions.shortDescription}
                            </div>
                            <div onClick={() => showContent(content === key? false : key)} className="color-blue pointer pt-2">{ content === key ? 'Show less info' : 'Show more content' }</div>
                        </div>
                    </div>
                    <div className="col-4 d-none d-md-flex">
                        { dayLeft(item.date) }
                    </div>
                </div>

                
                <div className="d-md-none">
                    { sortArray(item.tags).length > 0 &&
                        <div className="row mx-0">
                        <div className="custom-tag-header mt-2 mr-1">Tags:</div>
                        {
                            sortArray(item.tags).map((tag, key) => (
                                key < 2 && <button key={key} className="btn custom-tag capitalize mr-2 mt-2">{tag}</button>
                            ))

                        }
                        {
                            sortArray(item.tags).length >= 3 && (
                                <Tippy
                                    key={key}
                                    content={
                                    <ul data-simplebar>
                                        {sortArray(item.tags).slice(2, sortArray(item.tags).length).map((tag, i) => 
                                            tag&& <li className="capitalize mt-2" key={i}>{tag}</li>
                                        )}
                                    </ul>
                                    }
                                    animateFill={false} duration={500} delay={[100, 50]}
                                    theme="iot" interactive={true} trigger="click"
                                >
                                    <button key={key} className="btn custom-tag capitalize mt-2">{`+${ sortArray(item.tags).length - 2 }`}</button>
                                </Tippy>
                            )
                        }
                    </div>}
                    <div className="line mt-2 mb-3 d-md-none" />
                    <div className="row mx-0">
                        <div className={`col-12 px-0 show-content ${content === key ? '' : 'hidden'}`}>
                            {item.descriptions && item.descriptions.shortDescription}
                        </div>
                        <div onClick={() => showContent(content === key? false : key)} className="color-blue pointer pt-2">{ content === key ? 'Show less info' : 'Show more content' }</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default EventItem
