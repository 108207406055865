import React from "react";
import { Input, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdvancedFilters from "../components/AdvancedFilters/AdvancedFilters";
import ContactResultsTable from "../components/ResultsTable/ContactResultsTable";
import { FaLock } from "react-icons/fa";
import OutsideClickHandler from "react-outside-click-handler";
import { Popover, PopoverBody } from "reactstrap";
import AdvancedFiltero from "../components/AdvancedFiltero/AdvancedFiltero";
function FilterToggle(props) {
  const { visible, toggleVisible } = props;
  let content = "Hide company filters";
  if (!visible) {
    content = "Show company filters";
  }
  return (
    <button
      type="button"
      className="btn filters-toggle"
      onClick={toggleVisible}
    >
      {content}
    </button>
  );
}

export default class Contacts extends React.Component {
  constructor() {
    super();
    this.state = {
      companyFiltersVisible: false,
      showModal: false,
      subscriberHover: false,
      popoverHover: false,
      firstTimeToggle: true,
    };
  }
  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  handleHoverSubscriber = (status) => {
    if (status === true || this.state.popoverHover === true) {
      this.setState({ subscriberHover: status, showModal: true });
    } else {
      setTimeout(() => {
        this.setState({ subscriberHover: status });
      }, 500);
    }
  };
  componentDidUpdate() {
    if (
      this.props.advancedFiltersValues.advancedFilters.length > 0 &&
      this.state.companyFiltersVisible == false &&
      this.state.firstTimeToggle
    ) {
      this.setState({ companyFiltersVisible: true });
    }
  }
  handleHoverPopover = (status) => {
    if (status === true || this.state.subscriberHover === true) {
      this.setState({ popoverHover: status, showModal: true });
    } else {
      setTimeout(() => {
        this.setState({ popoverHover: status });
      }, 500);
    }
  };
  toggleCompanyFilters = () => {
    const { companyFiltersVisible } = this.state;
    if (this.state.firstTimeToggle) this.setState({ firstTimeToggle: false });
    if (companyFiltersVisible) {
      this.setState({
        companyFiltersVisible: false,
      });
    } else {
      this.setState({
        companyFiltersVisible: true,
      });
    }
  };

  render() {
    const {
      count,
      limit,
      searchResults,
      start,
      currentPage,
      loading,
    } = this.props.data;
    const { sort, location, userType, user, history } = this.props;
    const {
      handleSubmit,
      handleQueryChange,
      handleSortChange,
      handlePageChange,
      updatePropsState,
    } = this.props;
    const { contactQuery } = this.props;
    return (
      <div className="search-grid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Nation - Contacts</title>
        </Helmet>
        <form
          className="form"
          onSubmit={!userType || userType === "pro" ? "" : handleSubmit}
        >
          <div className="container-fluid d-inline-flex align-items-center search-field">
            <div className="container">
              <div className="row">
                <div className="col-md-6 bg-white search-box position-relitive my-auto">
                  <div className="row">
                    <div className="col-md-9 d-inline-flex align-items-center">
                      <span className="image-wrapper">
                        <Input
                          className="form-control mr-sm-2"
                          type="search"
                          value={contactQuery}
                          placeholder="Search the IoT ecosystem"
                          aria-label="Search"
                          onChange={handleQueryChange}
                        />
                      </span>
                    </div>
                    <div className="col-md-3 button-wrapper">
                      <button className="btn search" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offset-sm-3 d-flex">
                  <div className="filter-buttons my-auto">
                    <Link
                      className="btn"
                      to={{
                        pathname: `/companies/`,
                        search: `${location.search}`,
                      }}
                    >
                      To companies
                    </Link>
                  </div>
                  {userType === "admin" && (
                    <div className="ml-2 d-flex">
                      <img
                        style={{
                          height: "70px",
                        }}
                        className="mt-3 mt-md-1 ml-auto mr-auto mr-md-0 pointer"
                        onClick={() => {
                          this.props.downloadApi();
                        }}
                        src="/images/download_csv.png"
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <FilterToggle
              visible={this.state.companyFiltersVisible}
              toggleVisible={this.toggleCompanyFilters}
            />
          </div>
        </form>
        <div
          onMouseEnter={() => this.handleHoverSubscriber(true)}
          onMouseLeave={() => this.handleHoverSubscriber(false)}
          className="position-relitive"
        >
          <div
            className={` ${
              !userType || userType === "pro" ? "blurred-box" : ""
            }`}
          >
            <Collapse isOpen={this.state.companyFiltersVisible}>
              <AdvancedFiltero
                {...{
                  ...this.props.advancedFiltersValues,
                  query: "",
                  currentPage,
                  history,
                  user,
                  updatePropsState,
                }}
                hideTopSearch={true}
                title="Company filters:"
                whenToShow="d-none d-md-block"
                link1={{ url: "map", name: "To Map" }}
                link2={
                  user && user.email !== "mitchelldemo@iotnation.com"
                    ? { url: "contacts", name: "To Contacts" }
                    : false
                }
              />
            </Collapse>
            <div>
              <AdvancedFilters
                title="Contact filters:"
                aFHandle={this.props.aFCompanyHandle}
                aFValue={{ ...this.props.aFCompanyValue }}
              />
            </div>
            <>
              <ContactResultsTable
                data={{
                  count,
                  limit,
                  start,
                  currentPage,
                  searchResults,
                  sort,
                  loading,
                }}
                handlePageChange={handlePageChange}
                handleSortChange={handleSortChange}
              />
            </>
          </div>
          {(!userType || userType === "pro") && (
            <div className="subscriber-content">
              <FaLock /> <div id="contacts" />
              Subscriber content
            </div>
          )}
          {(!userType || userType === "pro") && (
            <Popover
              onMouseLeave={() => this.handleHoverPopover(false)}
              onMouseEnter={() => this.handleHoverPopover(true)}
              className="popover-subscriber"
              placement="top"
              isOpen={this.state.subscriberHover || this.state.popoverHover}
              target="contacts"
              toggle={this.toggle}
            >
              <OutsideClickHandler onOutsideClick={this.handleCloseModal}>
                <PopoverBody>Upgrade to Premium to Access Contacts</PopoverBody>
              </OutsideClickHandler>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}
