import React from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import moment from "moment-timezone";
import "./Footer.scss";
import { Tooltip } from "reactstrap";
import hidePagesJSON from "../../content/hidePages.json";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: false,
      products: false,
      resources: false,
      community: false,
      tooltip: {
        company: false,
        event: false,
        usecase: false,
        job: false,
        competition: false,
        addContent: false,
      },
    };
  }
  handleClick = (item) => {
    this.setState({
      [item]: !this.state[item],
    });
  };
  handleTooltip = (type, e) => {
    e.preventDefault();
    const tooltipCopy = this.state.tooltip;

    this.setState({
      tooltip: {
        company: false,
        event: false,
        usecase: false,
        job: false,
        competition: false,
        addContent: false,
        [type]: !tooltipCopy[type],
      },
    });
  };

  render() {
    const TooltipBlock = ({ id, placement, tooltip, forMobile = "" }) => {
      return (
        <Tooltip
          placement={placement}
          isOpen={tooltip[id]}
          target={`${id}Tooltip${forMobile}`}
          toggle={(e) => this.handleTooltip(id, e)}
        >
          You need to be logged in!
        </Tooltip>
      );
    };
    const { company, products, community, resources, addContent } = this.state;
    const { user } = this.props;
    const userType =
      user && user["cognito:groups"] && user["cognito:groups"][0];

    if (window.location.pathname.includes("/map")) {
      return "";
    }
    return (
      <footer>
        <div className="container">
          <div className="row">
            {/* Products */}
            <div className="d-block d-md-none col-sm-6">
              <div
                onClick={() => this.handleClick("products")}
                className="d-flex pointer"
              >
                <h5>Products</h5>{" "}
                {products ? (
                  <FaCaretUp className="caret-style" />
                ) : (
                  <FaCaretDown className="caret-style" />
                )}
              </div>
              {products && (
                <ul>
                  <li>
                    <Link className="grey" to="/iotace">
                      IoT Ace
                    </Link>
                  </li>

                  <li>
                    <Link className="grey" to="/reports">
                      Reports
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="col-md-2 flex-1-of-5 d-none d-md-block">
              <h5>Products</h5>
              <ul>
                <li>
                  <Link className="grey" to="/iotace">
                    IoT Ace
                  </Link>
                </li>

                <li>
                  <Link className="grey" to="/reports">
                    Reports
                  </Link>
                </li>
              </ul>
            </div>
            {/* Resources */}
            <div className="d-block d-md-none col-sm-6">
              <div
                onClick={() => this.handleClick("resources")}
                className="d-flex pointer"
              >
                <h5>Resources</h5>{" "}
                {resources ? (
                  <FaCaretUp className="caret-style" />
                ) : (
                  <FaCaretDown className="caret-style" />
                )}
              </div>
              {resources && (
                <ul>
                  <li>
                    <Link className="grey" to="/casestudies/sales">
                      Case Studies
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/companies">
                      Companies
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/map">
                      IoT Map
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/blog">
                      Blog
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="col-md-2 flex-1-of-5 d-none d-md-block">
              <h5>Resources</h5>
              <ul>
                <li>
                  <Link className="grey" to="/casestudies/sales">
                    Case Studies
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/companies">
                    Companies
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/map">
                    IoT Map
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/blog">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
            {/* Commuinty */}
            <div className="d-block d-md-none col-sm-6">
              <div
                onClick={() => this.handleClick("community")}
                className="d-flex pointer"
              >
                <h5>Commuinty</h5>{" "}
                {community ? (
                  <FaCaretUp className="caret-style" />
                ) : (
                  <FaCaretDown className="caret-style" />
                )}
              </div>
              {community && (
                <ul>
                  <li>
                    <Link className="grey" to="/events">
                      Industry Events
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/usecases">
                      Industry Use Cases
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/jobs">
                      Jobs Board
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="grey" to="/competitions">
                      Competitions
                    </Link>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://www.meetup.com/pro/iotnation"
                    >
                      Meetup Chapters
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div className="col-md-2 flex-1-of-5 d-none d-md-block">
              <h5>Community</h5>
              <ul>
                <li>
                  <Link className="grey" to="/events">
                    Industry Events
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/usecases">
                    Industry Use Cases
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/jobs">
                    Jobs Board
                  </Link>
                </li>
                {/* <li>
                  <Link className="grey" to="/competitions">
                    Competitions
                  </Link>
                </li> */}
                <li>
                  <a
                    target="_blank"
                    className="grey"
                    href="https://www.meetup.com/pro/iotnation"
                  >
                    Meetup Chapters
                  </a>
                </li>
              </ul>
            </div>
            {/* Add Content */}
            <div className="d-block d-md-none col-sm-6">
              <div
                onClick={() => this.handleClick("addContent")}
                className="d-flex pointer"
              >
                <h5>Add Content</h5>{" "}
                {addContent ? (
                  <FaCaretUp className="caret-style" />
                ) : (
                  <FaCaretDown className="caret-style" />
                )}
              </div>
              {addContent && user ? (
                <ul>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    >
                      Add Event
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/bbkpbH3X7o1zg84PA"
                    >
                      Add Use Case
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://airtable.com/shrPI54ZqQbPk9lkA"
                    >
                      Add Jobs
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    >
                      Add a Competition
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://docs.google.com/forms/d/1MwccI98-NW0I9-Mig5Yd66yYdkCuD_prjqg6FIAah18/viewform?edit_requested=true"
                    >
                      Add Company
                    </a>
                  </li>
                </ul>
              ) : addContent ? (
                <ul>
                  <li>
                    <div className="grey fit-content" id="eventTooltip1">
                      Add Event
                    </div>
                    <TooltipBlock
                      forMobile="1"
                      id="event"
                      placement="top"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content" id="usecaseTooltip1">
                      Add Use Case
                    </div>
                    <TooltipBlock
                      forMobile="1"
                      id="usecase"
                      placement="top"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content" id="jobTooltip1">
                      Add Jobs
                    </div>
                    <TooltipBlock
                      forMobile="1"
                      id="job"
                      placement="top"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content" id="competitionTooltip1">
                      Add a Competition
                    </div>
                    <TooltipBlock
                      forMobile="1"
                      id="competition"
                      placement="top"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content" id="companyTooltip1">
                      Add Company
                    </div>
                    <TooltipBlock
                      forMobile="1"
                      id="company"
                      placement="top"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="col-3 flex-1-of-5 d-none d-md-block">
              <h5>Add Content</h5>
              {user ? (
                <ul>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    >
                      Add Event
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/bbkpbH3X7o1zg84PA"
                    >
                      Add Use Case
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://airtable.com/shrPI54ZqQbPk9lkA"
                    >
                      Add Jobs
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://forms.gle/vsCt94LQhSDGbxDx5"
                    >
                      Add a Competition
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="grey"
                      href="https://docs.google.com/forms/d/1MwccI98-NW0I9-Mig5Yd66yYdkCuD_prjqg6FIAah18/viewform?edit_requested=true"
                    >
                      Add Company
                    </a>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <div className="grey fit-content pr-2" id="eventTooltip">
                      Add Event
                    </div>
                    <TooltipBlock
                      id="event"
                      placement="right"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content pr-2" id="usecaseTooltip">
                      Add Use Case
                    </div>
                    <TooltipBlock
                      id="usecase"
                      placement="right"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content pr-2" id="jobTooltip">
                      Add Jobs
                    </div>
                    <TooltipBlock
                      id="job"
                      placement="right"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div
                      className="grey fit-content pr-2"
                      id="competitionTooltip"
                    >
                      Add a Competition
                    </div>
                    <TooltipBlock
                      id="competition"
                      placement="right"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                  <li>
                    <div className="grey fit-content pr-2" id="companyTooltip">
                      Add Company
                    </div>
                    <TooltipBlock
                      id="company"
                      placement="right"
                      tooltip={this.state.tooltip}
                    />
                  </li>
                </ul>
              )}
            </div>
            {/* Company */}
            <div className="d-block d-md-none col-sm-6">
              <div
                onClick={() => this.handleClick("company")}
                className="d-flex pointer"
              >
                <h5>Company</h5>{" "}
                {company ? (
                  <FaCaretUp className="caret-style" />
                ) : (
                  <FaCaretDown className="caret-style" />
                )}
              </div>
              {company && (
                <ul>
                  <li>
                    <Link className="grey" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/partners">
                      Partners
                    </Link>
                  </li>
                  <li>
                    <Link className="grey" to="/about">
                      Contact Us
                    </Link>
                  </li>
                  <li className="my-2">
                    <SocialIcon
                      target="_blank"
                      url="https://www.linkedin.com/company/iotnation/about/"
                      className="icons linkedin-icon"
                    />
                    <SocialIcon
                      target="_blank"
                      url="https://twitter.com/iotnationHQ"
                      className="icons twitter-icon"
                    />
                    <SocialIcon
                      target="_blank"
                      url="https://www.facebook.com/iotnation"
                      className="icons facebook-icon"
                    />
                    <SocialIcon
                      target="_blank"
                      url="https://www.youtube.com/channel/UCs8IomybzWyA7DTKuxxVPaQ"
                      className="icons youtube-icon"
                    />
                  </li>
                  <li>
                    <a target="_blank" href="mailto:info@iotnation.com">
                      info@iotnation.com
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div className="col-3 flex-1-of-5 d-none d-md-block">
              <h5>Company</h5>
              <ul>
                <li>
                  <Link className="grey" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/partners">
                    Partners
                  </Link>
                </li>
                <li>
                  <Link className="grey" to="/about">
                    Contact Us
                  </Link>
                </li>
                <li className="my-2">
                  <SocialIcon
                    target="_blank"
                    url="https://www.linkedin.com/company/iotnation/about/"
                    className="icons linkedin-icon"
                  />
                  <SocialIcon
                    target="_blank"
                    url="https://twitter.com/iotnationHQ"
                    className="icons twitter-icon"
                  />
                  <SocialIcon
                    target="_blank"
                    url="https://www.facebook.com/iotnation"
                    className="icons facebook-icon"
                  />
                  <SocialIcon
                    target="_blank"
                    url="https://www.youtube.com/channel/UCs8IomybzWyA7DTKuxxVPaQ"
                    className="icons youtube-icon"
                  />
                </li>
                <li>
                  <a target="_blank" href="mailto:info@iotnation.com">
                    info@iotnation.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="border-top" />
        <div className="container py-3">
          Data may not be copied, published electronically or in print. ‘IoT
          Nation’ and its logo and ‘IoT Ace’ and its logo are trademarks of IoT
          Nation, LLC. All other company names, trademarks, and logos are the
          property of their respective owners.
        </div>
        <div className="border-top" />
        <div className="container py-4">
          <div className="row mx-0">
            <p className="m-0">
              Copyright © {moment().format("YYYY")} IoT Nation LLC. All Rights
              Reserved.
            </p>
            <div className="row mr-auto mr-md-0 ml-auto mt-3 mt-md-0">
              <Link to="/privacy-policy">Privacy policy</Link>
              <div className="horizontal-line mx-2 my-auto" />
              <Link to="/terms-of-service">Terms of Service</Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
