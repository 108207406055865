import React, { Component } from 'react'
import NewsItem from './NewsItem'
export default class Meniu extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: '1',
        }
    }
    
    handleMeniu = (event) => {
        const id = event.target.id
        this.setState({id:id})
    }
    render() {
        const {id} = this.state
        const {results} = this.props
        return (
            <div className="Meniu d-lg-none">
                <div className="container">
                    <div className="popular-topics">Popular Topics</div>
                </div>
                <div className="row grey-border-b grey-border-t m-0">
                    <div onClick={this.handleMeniu} id={'1'} className={`col-6 grey-border-b grey-border-r ${id === '1'? 'SelectedMeniu':''}`}>Data Science</div>
                    <div onClick={this.handleMeniu} id={'2'} className={`col-6 grey-border-b ${id === '2'? 'SelectedMeniu':''}`}>5G</div>
                    <div onClick={this.handleMeniu} id={'3'} className={`col-6 grey-border-b grey-border-r ${id === '3'? 'SelectedMeniu':''}`}>System integration</div>
                    <div onClick={this.handleMeniu} id={'4'} className={`col-6 grey-border-b ${id === '4'? 'SelectedMeniu':''}`}>Healthcare</div>
                    <div onClick={this.handleMeniu} id={'5'} className={`col-6 grey-border-r ${id === '5'? 'SelectedMeniu':''}`}>How to..</div>
                    <div onClick={this.handleMeniu} id={'6'} className={`col-6 ${id === '6'? 'SelectedMeniu':''}`}>IoT Partnerships</div>
                </div>
                <div className="meniu-results">
                    <div className="container">
                        <div className="row">
                            {
                                results.map((item, key) =>
                                    <div key={key} className="col-12 col-sm-12 col-md-6">
                                        <NewsItem
                                            id={key}
                                            results={item}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}
