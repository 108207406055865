import React from 'react';
import Spinner from '../components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { Subscription } from '../components/Subscribe/Subscribe';
import {Helmet} from "react-helmet";
import "./Login.scss";

export default class Login extends React.PureComponent {

    render() {
        const { handleInputChange, handleSubmit, formData, loading,  user } = this.props;
        if(loading) {
            return <Spinner height={'calc( 100vh - ( 64px + 67px ) )'}/>;
        }
        
        return (
            <div className="Login">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login | IoT Nation</title>
                </Helmet> 
                <div className="container">
                    <div className="col-sm-10 offset-md-1 col-md-8 offset-lg-2">
                        <div className="Rectangle">
                            <div className="row">
                                <div className="col-6">
                                    <div className="login-btn"><p style={{margin: "auto"}}>Login</p></div>
                                </div>

                                <div className="col-6">
                                    <Link to="/signup" className="signup-btn"><p style={{margin: "auto"}}>Sign up</p></Link> 
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input value={formData.username} name="username" required type="text" className="form-control" placeholder="Email address" onChange={handleInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input value={formData.password} name="password" required type="password" className="form-control" placeholder="Password" onChange={handleInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <Link to='/reset-password'>Forgot password?</Link>
                                        </div>
                                        <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                            <button className={`${loading ? 'disabled' : ''} btn btn-primary`} type="submit">Login</button>
                                        </div>                                        
                                    </form>
                                </div>
                            </div>  
                            
                        </div>                         
                    </div>                    
                </div>
                <div style={{marginTop: '50px'}}>
                    <Subscription />
                </div>
            </div>
            
        );
    }
}