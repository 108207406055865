import React, { Component } from 'react'
import Title from '../Title.jsx';
import CompanyBlock from './CompanyBlock.jsx'
import { Link } from 'react-scroll';

import './ExhibitSide.scss';
export default class ExhibitSide extends Component {
  state = {
    showMore:false
  }
 
  handleShowMore = () => this.setState(({showMore})=>({showMore:!showMore}))
  render() {
    const { showMore } = this.state
    return (
      <div className="ExhibitSide">
        <Title src='/images/new-construction.svg' name={"See who's " + this.props.title} />
        <div className="side-block-border p-16-42">
          <div className="">
          <div className={showMore?'togglee display-flex ml-mr-15':'togglee hidden display-flex ml-mr-15'} >  
            {this.props.data.map((item, key) =>
              <div key={key} className="col-md-4 pl-pr-8 exhibitBlock">
                <CompanyBlock src={item.info.logo} id={item.info.id} />
              </div>
            )}

            </div>
          </div>
          <div className="align-center display-flex button-padding m-0-n15">
              <Link className="view-blue active" activeClass="active" to={this.props.id} spy={true} smooth={true} offset={-62.5} duration={500} onSetActive={this.handleSetActive}>
                View All
              </Link>
          </div>
        </div>
      </div>
    )
  }
}
