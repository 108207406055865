import React from "react";

export default class ClearButton extends React.PureComponent {
    constructor() {
        super();

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.props.handleClearSpecificFilter(this.props.index);
    }

    render() {
        const { clear } = this.props
        return (
            <span className={`btn ${clear?'clear':'special'}`} onClick={this.handleClick}></span> 
        )
    }
}