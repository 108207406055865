import React from "react";
import { Input } from 'reactstrap';

export default class Select extends React.PureComponent {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.setQuery(event.target.value, true);
        
    }

  render() {
    const { fieldOptions, initialValue, isShown = true, mainDropdown = false, firstSelect, user} = this.props;
    const attributeArray = mainDropdown ? fieldOptions.attributes : fieldOptions
    let iterator = 0;
    let cn = isShown?'':'d-none';

    return isShown ? (
      <div className={`margin-r-8 break ${cn}`}>
        <Input type="select" name="operator"  value={initialValue} onChange={this.handleChange}>
            {Object.entries(attributeArray).map(([key, value]) => {
                iterator++;
                if(mainDropdown){
                    return <option
                        disabled={!user && (key === "Company Rank" || key === "Participation In" || key === "Industry Activity Score" || key === "Number of Employees" || key === "Private/Public")}
                        value={key}
                        key={iterator}
                        className={!user && (key === "Company Rank" || key === "Participation In" || key === "Industry Activity Score" || key === "Number of Employees" || key === "Private/Public") ? "text-muted" : ""}
                    >
                        {fieldOptions.alias[key]? fieldOptions.alias[key].name : key.replace(/\//g, ' / ')}&nbsp;&nbsp;
                    </option>;
                }else{
                    return <option value={key}  key={iterator}>{key.replace(/\//g, ' / ')}&nbsp;&nbsp;</option>;
                }
                
            })}
        </Input>
      </div>
    ): false
  }
}