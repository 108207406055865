import React from "react";
import WeeklyCh from './WeeklyCh/WeeklyCh';
import MembershipsGained from './MembershipsGained/MembershipsGained';
import MembershipsLost from './MembershipsLost/MembershipsLost';
import Rank from './Rank/Rank';
import SortableHeader from './SortableHeader/SortableHeader';

import './ResultsTable.scss';

export default class ResultsTable extends React.Component {

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.data.limit);
   this.props.handlePageChange(offset);
  };

  render() {
    const {results, sort, fakeData} = this.props.data;
        return (
          <div className='Results-Table-Eco-Stats d-flex horizontal-align'>
              <table className="row margin-0">
                <thead className="col-12">
                  <tr className="row">
                    <SortableHeader data={{type: 'rank', name: 'Rank', sort: sort, gridSize: 'col-2 col-sm-2 col-md-1 col-lg-1 padding-lr-7'}} handleSortChange={this.props.handleSortChange}/>
                    <SortableHeader data={{type: 'name', name: 'Ecosystem', sort: sort, gridSize: 'col-3' }} handleSortChange={this.props.handleSortChange}/>
                    <SortableHeader data={{type: 'members', name: 'Partners', sort: sort, gridSize: 'col-2 col-sm-2 col-md-2 col-lg-2 padding-lr-7'}} handleSortChange={this.props.handleSortChange}/>
                    <SortableHeader data={{type: 'weeklyCh', name: 'Change', sort: sort, gridSize: 'col-2 col-sm-2 col-md-2 col-lg-1 padding-lr-7'}} handleSortChange={this.props.handleSortChange}/>
                    <SortableHeader data={{type: 'membershipsGained', name: 'Partners Gained', sort: sort, gridSize: 'col-2'}} handleSortChange={this.props.handleSortChange}/>
                    <SortableHeader data={{type: 'membershipsLost', name: 'Partners Lost', sort: sort, gridSize: 'col-2'}} handleSortChange={this.props.handleSortChange}/>
                  </tr>
                </thead>
                <tbody className="col-12">
                  {results.map((result, index) => {
                    return (
                    <tr className='row' key={index}>
                      <td className={`col-2 col-sm-2 col-md-1 col-lg-1 padding-lr-7 ${fakeData || result.fake ? `blurred-box` : ``}`}><Rank data={{rank: result.rank, rankChange: result.totalchange}}/></td>
                      <td className={`col-3 ${fakeData || result.fake ? `blurred-box` : ``}`}><a href={result.url} target="_blank" >{result.name}</a></td>
                      <td className={`col-2 col-sm-2 col-md-2 col-lg-2 padding-lr-7 ${fakeData || result.fake ? `blurred-box` : ``}`}>{result.members}</td>
                      <td className={`col-2 col-sm-2 col-md-2 col-lg-1 padding-lr-7 ${fakeData || result.fake ? `blurred-box` : ``}`}><WeeklyCh data={result.totalchange}/></td>
                      <td className={`col-2 d-flex  ${fakeData || result.fake ? `blurred-box` : ``}`}><MembershipsGained gained={result.membershipsGained}/></td>
                      <td className={`col-2 d-flex  ${fakeData || result.fake ? `blurred-box` : ``}`}><MembershipsLost lost={result.membershipsLost}/></td>
                    </tr>
                    )
                  })}
                </tbody>
              </table>
        </div>
    )
  }
};