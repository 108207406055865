import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import './CompanyBlock.scss';

export default class CompanyBlock extends Component {
  render() {
    return (
      <Link className="justify-center" to= {'/company/' + this.props.id}>
        <div className="CompanyBlock">
          <img className="width-75 justify-center" src={this.props.src} alt=""/>
        </div>
      </Link>
    )
  }
}
