import React, { Component } from 'react'
import moment from 'moment-timezone'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationManager} from 'react-notifications';

export default class TableRow extends Component {
    constructor(props){
        super(props)
        this.state = {
            copied: false
        }
    }
    handleCopy = () =>{
        this.setState({copied: true});
        NotificationManager.success('Copied !');
    }
    render() {
        
        const {results, handleDelete, handleEdit} = this.props
        const expiration = moment(moment.unix(results.expirationDate)).zone('+0000').utc().format('YYYY MMM DD HH:mm')
        const now = moment().zone('+0000').utc().format('YYYY MMM DD HH:mm')

        // const months = moment(moment.unix(results.expirationDate), "DD/MM/YYYY").diff(moment(), 'months')
        // const days = moment(moment.unix(results.expirationDate), "DD/MM/YYYY").diff(moment(), 'days')
        // const hours = moment(moment.unix(results.expirationDate), "DD/MM/YYYY").diff(moment(), 'hours')
        const minutes = moment(moment.unix(results.expirationDate), "DD/MM/YYYY").diff(now, 'minutes') > 0 ? true : false
        const timeLeft =  minutes? 'Active': 'Inactive'
        const period = `${(results.period - results.period % (24 * 3600)) / (24 * 3600)} days ${((results.period % (24 * 3600)) - (results.period % 3600)) / 3600} hours`

        return (
            <div  className="row">
                <div className="code col-2">{results.code}</div>
                <div className="membership col-2">{period}</div>
                <div className="expiration-date col-2">{expiration}</div>
                <div className="time-left col-1">{timeLeft}</div>
                <div className="level-premium col-1">{results.accessLevel}</div>
                <div className="description-test-code col-2">{results.description}</div>
                <div className="delete col-1">
                    <button className=" btn-danger p-1" onClick={handleDelete} name={results.code}>X</button> 
                    <button className="btn-warning p-1" onClick={handleEdit} name={results.code}>Edit</button>    
                </div>
                <div className="delete col-1">
                <CopyToClipboard text={`${window.location.origin}/signup?code=${results.code}`}
                    onCopy={() => this.handleCopy()}>
                    <button className="btn-primary p-1"  name={results.code}>Copy</button>    
                </CopyToClipboard>
                    <div style={{display:'none'}}>{this.state.copied ?setTimeout(() => {this.setState({ copied: false })}, 3000):''}</div>
                </div>
            </div>
        )
    }
}
