import React from "react";
import { Marker } from "react-google-maps";

export default class DoctorMarker extends React.Component {

  render(){
    return(
        <Marker
          position={this.props.location}
          icon={{url:'/images/cluster/location-marker-smaller.png'}}  
          //icon={}
        >
        </Marker>
    );
  }
}