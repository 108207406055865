import React from "react";
import { Navbar } from "reactstrap";
import "./Header.scss";
import ReactGA from "react-ga";
import LogRocket from "logrocket";
import FirstLogin from "../Modals/FirstLogin/FirstLogin";
import OutsideClickHandler from "react-outside-click-handler";
import PcNavbar from "./PcNavbar";
import MobileNavbar from "./MobileNavbar";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
ReactGA.pageview(window.location.pathname);
let first = true;
function DetectIsSelected(array, pathname, type) {
  if (
    array
      .map(
        (item) =>
          pathname.includes(
            item.type === type &&
              item.title
                .replace(/ \/ /g, "-")
                .replace(/ & /g, "-and-")
                .replace(/ /g, "-")
          ) && true
      )
      .filter(Boolean).length > 0
  ) {
    return true;
  } else {
    return false;
  }
}
function RocketFunction(props) {
  if (first) {
    first = false;
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID);
    LogRocket.identify(props.user.sub, {
      name: props.user.name,
      email: props.user.email,
    });
  }

  return "";
}

export default class Header extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isOpenCopy: false,
      showModal: true,
      fullHeight: false,
      scroll: 0,
      clickedId: 0,
    };
  }

  closeToggle = () => {
    this.setState({
      isOpen: false,
      isOpenCopy: false,
    });
    setTimeout(
      function() {
        this.setState({ fullHeight: false });
      }.bind(this),
      500
    );
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      isOpenCopy: !this.state.isOpenCopy,
    });
    if (this.state.isOpen) {
      setTimeout(
        function() {
          this.setState({ fullHeight: !this.state.fullHeight });
        }.bind(this),
        500
      );
    } else if (!this.state.isOpen) {
      this.setState({ fullHeight: !this.state.fullHeight });
    }
  };
  toggleModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    window.addEventListener(
      "scroll",
      () => this.handleScroll(this.state.scroll),
      { passive: true }
    );
  }

  handleClickMenu = (id) => {
    if (id === this.state.clickedId) {
      this.setState({ clickedId: 0 });
    } else {
      this.setState({ clickedId: id });
    }
  };
  handleCloseMenu = () => {
    this.setState({ clickedId: 0 });
  };
  handleScroll(lastScroll) {
    if (this.state.isOpenCopy) {
      if (window.scrollY < lastScroll) {
        this.setState({
          isOpen: true,
          scroll: window.scrollY,
          fullHeight: true,
          clickedId: 0,
        });
      } else if (window.scrollY > lastScroll) {
        this.setState({
          isOpen: false,
          scroll: window.scrollY,
          fullHeight: false,
          clickedId: 0,
        });
      }
    }
  }
  cognitoStore = () => {
    let result = {},
      key;
    let obj = window.localStorage;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key.includes("CognitoIdentityServiceProvider")) {
          result[key] = obj[key];
        }
      }
    }
    return result;
  };
  render() {
    this.props.handleIntercom();
    const { distanceFromTop, style, location, data } = this.props;
    const { isOpen, showModal, clickedId } = this.state;
    const blueNav = distanceFromTop.distanceFromTop * -1 > 272;
    return (
      <Navbar
        className={` px-0 ${
          blueNav
            ? `blue-navbar ${isOpen ? "index-1050" : ""}`
            : `w-100 ${isOpen ? "index-1050" : null}`
        } `}
        style={{ ...style }}
        light
        expand="lg"
      >
        <div className="w-100">
          <OutsideClickHandler onOutsideClick={() => this.closeToggle()}>
            {data && <RocketFunction user={data} />}
            {location &&
            location.state &&
            location.state.firstLogin === true &&
            showModal ? (
              <FirstLogin isOpen={showModal} toggle={this.toggleModal} />
            ) : (
              ""
            )}
            {/* for pc screens */}
            <PcNavbar
              data={this.props}
              DetectIsSelected={DetectIsSelected}
              cognitoStore={this.cognitoStore}
              handleClickMenu={this.handleClickMenu}
              clickedId={clickedId}
              handleCloseMenu={this.handleCloseMenu}
            />
            {/* for small screens */}
            <MobileNavbar
              data={this.props}
              DetectIsSelected={DetectIsSelected}
              cognitoStore={this.cognitoStore}
              handleClickMenu={this.handleClickMenu}
              stateData={this.state}
              handleCloseMenu={this.handleCloseMenu}
              closeToggle={this.closeToggle}
              toggle={this.toggle}
            />
          </OutsideClickHandler>
        </div>
      </Navbar>
    );
  }
}
