import React, { Component } from 'react'
import Partners from './Partners'
import infoArray from '../content/partners.json'
import './Partners.scss'

export default class PartnersContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            menuOpened: 'Companies'
        }
    }

  render() {
    return (
        <div className="Partners">
            <Partners>
                <div className="grey-border-l grey-border-r grey-border-b grey-border-t main-block rounded-6 d-flex">
                    <div className="row mx-auto">
                        { infoArray.map((item, key) =>
                            <div key={key} className="mx-2 mb-2">
                                <a target="_blank" className="black-link" href={item.link}>
                                    <div className='Companies mx-auto grey-border'>
                                        <div className="m-3 d-flex">
                                            <img className="main-image mx-auto" src={item.img} alt=""/>
                                        </div>
                                        <div className="title mx-2 ">{item.title}</div>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </Partners>
        </div>
    )
  }
}
