/*global AmazonCognitoIdentity*/
import React from "react";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import Cookies from "universal-cookie";
import Signup from "./Signup";
import Confirm from "./Confirm";
import ConfirmModal from "./ConfirmModal";
import { Redirect } from "react-router-dom";
import TOSModal from "../components/Modals/TOSModal/TOSModal.jsx";
import Spinner from "../components/Spinner/Spinner";
import GeneratedApi from "../components/functions/generatedApi";

const cookies = new Cookies();

export default class SignupContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableEdit: false,
      confirmModalChecked: false,
      tooltipOpen: false,
      formData: {
        email: "",
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        companyName: "",
        activationCode: "",
        userId: "",
        tosVersion: "1.0",
        loadingScreen: false,
      },
      confirmData: {
        code: "",
      },
      activationCodeSatatus: false,
      firstLoad: true,
      user: null,
      confirm: false,
      loading: false,
      redirectToLogin: false,
      confirmCount: 1,
      confirmAvailable: true,
      showConfirmModal: false,
      tos: {
        firstName: "",
        lastName: "",
        companyName: "",
      },
      tosModalIsOpen: false,
      acceptTos: false,
      confirmLoaded: false,
    };

    this.handleRegInputChange = this.handleRegInputChange.bind(this);
    this.handleRegSubmit = this.handleRegSubmit.bind(this);
    this.handleConfirmInputChange = this.handleConfirmInputChange.bind(this);
    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    this.handleRegChangePhone = this.handleRegChangePhone.bind(this);
    this.handleResendCode = this.handleResendCode.bind(this);
    this.initResetCounter = this.initResetCounter.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.handleShowConfirmModal = this.handleShowConfirmModal.bind(this);
    this.handleTOSSubmit = this.handleTOSSubmit.bind(this);
  }

  handleShowConfirmModal(event) {
    //IOTACTIVATIONCODE
    event.preventDefault();
    // this.handleTOSModal(false)
    const formData = this.state.formData;
    if (!formData.password.match(/\d+/g)) {
      NotificationManager.error("Password must contain a number");
    } else if (!formData.password.match(/[A-Z]/g)) {
      NotificationManager.error("Password must contain uppercase");
    } else if (!formData.password.match(/[a-z]/g)) {
      NotificationManager.error("Password must contain lowercase");
    } else if (formData.password.length < 8) {
      NotificationManager.error("Password too short");
    } else {
      const q = {
        activationCode:
          this.state.formData.activationCode === ""
            ? "BLANK_STRING"
            : this.state.formData.activationCode,
      };

      GeneratedApi("", false, false)
        .post("/checkActivationCode", q)
        .then((response) => {
          const formData = { ...this.state.formData };
          this.handleShowTOSModal();
          this.setState({
            activationCodeSatatus: true,
            showConfirmModal: true,
            tos: {
              agree: false,
              firstName: formData.firstName,
              lastName: formData.lastName,
              companyName: formData.companyName,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Invalid Activation Code");
        });
    }
  }
  handleConfirmModal(accept = false) {
    if (accept) {
      this.handleRegSubmit();
      this.setState({ loadingScreen: true });
    } else {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
    this.setState({ showConfirmModal: false });
  }
  componentWillMount() {
    if (this.state.firstLoad) {
      this.setState({
        firstLoad: false,
        formData: {
          username: this.state.formData.username,
          password: this.state.formData.password,
          firstName: this.state.formData.firstName,
          lastName: this.state.formData.lastName,
          phoneNumber: this.state.formData.phoneNumber,
          companyName: this.state.formData.companyName,
          activationCode: this.props.location.search
            ? this.props.location.search.split("?code=")[1]
            : cookies.get("activationCode")
            ? cookies.get("activationCode")
            : this.state.formData.activationCode,
          userId: this.state.formData.userId,
        },
      });
    }
  }
  handleRegSubmit() {
    const formData = { ...this.state.formData };
    const attributeList = [];
    const username = formData.username;
    const password = formData.password;
    attributeList.push(
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "email",
        Value: username,
      })
    );
    attributeList.push(
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "phone_number",
        Value: formData.phoneNumber,
      })
    );
    attributeList.push(
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "name",
        Value: `${formData.firstName} ${formData.lastName}`,
      })
    );
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    this.setState({
      loading: true,
    });
    userPool.signUp(username, password, attributeList, null, (err, result) => {
      if (err) {
        window.scrollTo(0, 0);
        this.setState({
          loading: false,
          loadingScreen: false,
        });
        NotificationManager.error(err.message);
      } else {
        const formData = { ...this.state.formData };
        formData.email = username;
        formData.userId = result.userSub;
        formData.username = username;
        formData.tosVersion = "1.0";
        formData.password = password;
        this.setState({ formData: formData });
        formData.activationCode =
          formData.activationCode === ""
            ? "BLANK_STRING"
            : formData.activationCode;

        GeneratedApi("", false, false)
          .post("/register_new_user", formData)
          .then((response) => {
            this.setState({
              user: result.user,
              confirm: true,
              loading: false,
              loadingScreen: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              loading: false,
              loadingScreen: false,
            });
          });
      }
    });
  }

  handleRegInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formData = { ...this.state.formData };
    formData[name] = name === "username" ? value.trim() : value;
    this.setState({ formData });
  }

  handleRegChangePhone(data) {
    this.setState({
      formData: {
        ...this.state.formData,
        phoneNumber: data.getNumber(),
      },
    });
  }

  handleConfirmInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const confirmData = { ...this.state.confirmData };
    confirmData[name] = value;
    this.setState({ confirmData });
  }
  async componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.purpose === "confirm code"
    ) {
      this.handleResendCode();
    }
  }
  handleResendCode() {
    const user = this.state.user
      ? this.state.user.username
      : this.props.location.state.username;
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: user,
      Pool: userPool,
    });
    this.setState({
      loading: true,
    });
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        this.setState({
          loading: false,
          loadingScreen: false,
        });
        console.log(err);
        NotificationManager.error(err.message);
        return false;
      }
      this.setState({
        loading: false,
        loadingScreen: false,
        confirmAvailable: false,
        confirmCount: this.state.confirmCount + 1,
      });
      this.initResetCounter();
      NotificationManager.success("Confirmation message resent.");
    });
  }

  initResetCounter() {
    let interval = setInterval(() => {
      const { confirmCount } = this.state;
      if (confirmCount <= 5) {
        this.setState({ confirmAvailable: true });
      } else {
        alert("You have exhausted the maximum amount of retries available.");
      }
      clearInterval(interval);
    }, 60000);
  }

  async handleConfirmSubmit(event) {
    event.preventDefault();
    if (
      this.props.location.state &&
      this.props.location.state.purpose === "confirm code"
    ) {
      this.setState({
        confirm: true,
        confirmLoaded: true,
      });
    }
    const confirmData = { ...this.state.confirmData };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: this.state.formData.username,
      Pool: userPool,
    });
    this.setState({
      loading: true,
    });
    cognitoUser.confirmRegistration(confirmData.code, true, (err, result) => {
      if (err) {
        this.setState({
          loading: false,
          loadingScreen: false,
        });
        NotificationManager.error(err.message);
        return false;
      }
      const formData =
        this.state.formData && this.state.formData.username
          ? { ...this.state.formData }
          : { ...this.props.location.state.formData };
      formData.email = formData.username;
      var authenticationData = {
        Username: formData.username,
        Password: formData.password,
      };
      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authenticationData
      );
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (response) => {
          this.setState({
            loading: false,
            loadingScreen: false,
          });

          this.handleSendToken(response);
        },

        onFailure: (err) => {
          NotificationManager.error(err.message);
          this.setState({
            loading: false,
            loadingScreen: false,
            disableEdit: false,
          });
        },
        mfaRequired: (codeDeliveryDetails) => {
          this.setState({
            loading: false,
            loadingScreen: false,
          });
        },
      });
    });
  }
  async handleSendToken(response) {
    await this.props.handleLoginToken(response);
    this.setState({ lastPath: true });
  }
  handleTOSSubmit(event) {
    event.preventDefault();
    this.handleTOSModal(true);
  }
  handleConfirmCheckbox = (event) => {
    this.setState({ confirmModalChecked: !this.state.confirmModalChecked });
  };
  handleConfirmTooltip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const tos = { ...this.state.tos };
    tos[name] = value;
    this.setState({
      tos,
      acceptTos: true,
    });
  };
  handleTOSModal = (result) => {
    if (result === true || result === false) {
      this.setState({
        tosModalIsOpen: false,
        acceptTos: true,
      });
      if (result === false) {
        this.setState({
          acceptTos: false,
        });
      }
      //   this.postTosStatus(result)
      return;
    }

    if (this.state.user === null) return;
  };
  handleShowTOSModal = () => {
    if (!this.state.acceptTos) {
      this.setState({
        tosModalIsOpen: true,
      });
    }
  };

  render() {
    const { lastLocation, location } = this.props;
    if (
      location.state &&
      location.state.purpose === "confirm code" &&
      !this.state.confirmLoaded
    ) {
      return (
        <Confirm
          data={{ username: location.state.username }}
          confirmAvailable={this.state.confirmAvailable}
          confirmData={this.state.confirmData}
          handleResendCode={this.handleResendCode}
          handleSubmit={this.handleConfirmSubmit}
          handleInputChange={this.handleConfirmInputChange}
          loading={this.state.loading}
        />
      );
    } else {
      if (this.state.lastPath) {
        return (
          <Redirect
            to={{
              pathname:
                lastLocation.pathname.includes("/companies") ||
                lastLocation.pathname.includes("/map") ||
                lastLocation.pathname.includes("/events") ||
                lastLocation.pathname.includes("/usecases") ||
                lastLocation.pathname.includes("/competitions") ||
                lastLocation.pathname.includes("/contacts")
                  ? `${lastLocation.pathname}${lastLocation.search}`
                  : "/companies",
              state: {
                prevQuery: location.state && location.state.prevQuery,
                firstLogin: true,
                filters: location.state && location.state.filters,
              },
            }}
          />
        );
      }

      if (this.state.confirm === false) {
        if (this.state.loadingScreen) {
          return (
            <>
              <Spinner position={"50%"} />
              <Signup
                opacity="0.3"
                handleChangePhone={this.handleRegChangePhone}
                handleSubmit={this.handleShowConfirmModal}
                handleInputChange={this.handleRegInputChange}
                formData={this.state.formData}
                loading={this.state.loading}
              />
            </>
          );
        }
        return (
          <>
            <Signup
              handleChangePhone={this.handleRegChangePhone}
              handleSubmit={this.handleShowConfirmModal}
              handleInputChange={this.handleRegInputChange}
              formData={this.state.formData}
              loading={this.state.loading}
            />
            {!this.state.acceptTos && (
              <TOSModal
                handleInputChange={this.handleInputChange}
                tos={this.state.tos}
                handleTOSModal={this.handleTOSSubmit}
                toggleTOSModal={this.handleTOSModal}
                tosModalIsOpen={this.state.tosModalIsOpen}
              />
            )}
            {this.state.acceptTos && (
              <ConfirmModal
                handleConfirmTooltip={this.handleConfirmTooltip}
                tooltipOpen={this.state.tooltipOpen}
                confirmModalChecked={this.state.confirmModalChecked}
                handleConfirmCheckbox={this.handleConfirmCheckbox}
                handleConfirmModal={this.handleConfirmModal}
                visible={this.state.showConfirmModal}
                data={this.state.formData}
              />
            )}
          </>
        );
      } else {
        return (
          <Confirm
            data={this.state.formData}
            confirmAvailable={this.state.confirmAvailable}
            confirmData={this.state.confirmData}
            handleResendCode={this.handleResendCode}
            handleSubmit={this.handleConfirmSubmit}
            handleInputChange={this.handleConfirmInputChange}
            loading={this.state.loading}
          />
        );
      }
    }
  }
}
