import React, { Component } from 'react'

export default class MarketTrend extends Component {
    render() {
        return (
            <div className="Market-Trend row ml-0">
                <div className="col">
                    <div className="content-header row">
                            <div className="col-3"><div className="ranking"><img alt="" src="/images/ranking.svg"/></div></div>
                            <div className="col-9 d-inline-flex align-items-center"><p className="title">Market trend</p></div>
                    </div>
                    <div className="row">
                            <div className="col d-inline-flex align-items-center justify-content-center chart-content"><p>chart here</p></div>
                    </div>
                </div>
                
            </div>
        )
    }
}
