/*global AmazonCognitoIdentity*/
import React from "react";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import Login from "./Login";
import Confirm from "../PasswordReset/Confirm";
import { Redirect } from "react-router-dom";

export default class SignupContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        username: "",
        password: "",
      },
      confirmData: {
        code: "",
      },
      lastPath: false,
      user: null,
      confirm: false,
      loading: false,
      redirectToSearch: false,
      redirectTo: false,
      path: this.props.location.state && this.props.location.state.page,
      prevQuery:
        this.props.location.state &&
        this.props.location.state.prevQuery &&
        this.props.location.state.prevQuery,
      filters:
        this.props.location.state &&
        this.props.location.state.filters &&
        this.props.location.state.filters,
      hash: false,
      transferToSignUp: false,
      session: false,
    };

    if (props.user) {
      this.state.redirectToSearch = true;
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirmInputChange = this.handleConfirmInputChange.bind(this);
    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    this.handleSendToken = this.handleSendToken.bind(this);
  }
  handleConfirmSubmit(event) {
    event.preventDefault();
    const { cognitoUser } = this.state;
    cognitoUser.sendMFACode(this.state.confirmData.code, {
      onSuccess: (result) => {
        this.handleSendToken(result);
      },

      onFailure: (err) => {
        this.setState({ confirm: false, confirmData: { code: "" } });
        NotificationManager.error(err.message);
      },
    });
  }

  handleConfirmInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const confirmData = { ...this.state.confirmData };
    confirmData[name] = value;
    this.setState({ confirmData });
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = { ...this.state.formData };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });
    var authenticationData = {
      Username: formData.username,
      Password: formData.password,
    };
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: formData.username,
      Pool: userPool,
    });
    this.setState({
      loading: true,
    });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        this.handleSendToken(result);
      },

      onFailure: (err) => {
        console.log(err);
        if (err.message === "User is not confirmed.") {
          this.setState({
            transferToSignUp: true,
          });
        } else {
          NotificationManager.error(err.message);
          this.setState({
            loading: false,
          });
        }
      },
      mfaRequired: (codeDeliveryDetails) => {
        this.setState({
          confirm: true,
          loading: false,
          cognitoUser: cognitoUser,
        });
        // var verificationCode = prompt('Please input verification code' ,'');
        // cognitoUser.sendMFACode(verificationCode, this);
      },
    });
  }

  async handleSendToken(result) {
    await this.props.handleLoginToken(result);
    this.setState({ lastPath: true });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formData = { ...this.state.formData };
    formData[name] = value;
    this.setState({ formData });
  }

  render() {
    const { lastLocation, user } = this.props;

    if (this.state.transferToSignUp) {
      this.setState({ transferToSignUp: false });
      return (
        <Redirect
          to={{
            pathname: "/signup",
            state: {
              purpose: "confirm code",
              username: this.state.formData.username,
              formData: this.state.formData,
            },
          }}
        />
      );
    }
    if (this.state.lastPath) {
      return (
        <Redirect
          to={{
            pathname:
              lastLocation.pathname.includes("/companies") ||
              lastLocation.pathname.includes("/map") ||
              lastLocation.pathname.includes("/events") ||
              lastLocation.pathname.includes("/usecases") ||
              lastLocation.pathname.includes("/competitions") ||
              lastLocation.pathname.includes("/contacts")
                ? `${lastLocation.pathname}${lastLocation.search}`
                : "/companies",
            state: {
              prevQuery: this.state.prevQuery,
              filters: this.state.filters,
            },
          }}
        />
      );
    }
    if (this.state.redirectToSearch) {
      return <Redirect to="/companies" />;
    }
    if (this.state.confirm) {
      return (
        <Confirm
          data={this.state.formData}
          confirmData={this.state.confirmData}
          handleInputChange={this.handleConfirmInputChange}
          handleSubmit={this.handleConfirmSubmit}
        />
      );
    }
    return (
      <Login
        handleSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
        formData={this.state.formData}
        user={user}
        loading={this.state.loading}
      />
    );
  }
}
