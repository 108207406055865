import React from "react";

export default class Rank extends React.PureComponent{
    render() {
        const { rank, rankChange } = this.props.data;
        let position = 'stable';
        if(rankChange > 0) {
            position = 'up';
        } else if(rankChange < 0) {
            position = 'down'
        }
        position += ' rank';
        return <span className={position}>{rank}</span>
    }
}