import React, { Component } from 'react'
import Card from '../Ambassadors/components/Card'
import { FaRegEnvelope, FaMobileAlt, FaMapMarkerAlt} from 'react-icons/fa';
import Map from './components/Map'
import aboutJSON from '../content/about.json';
import arrayAbout from '../content/about-old.json';
import { SocialIcon } from 'react-social-icons';
import {Helmet} from "react-helmet";
import './About.scss'

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    componentDidMount = async () => {
        await setTimeout(() => {
            this.setState({ open: true })
        }, 1000)
    }
    render() {
        return (
            <div className="About">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>IoT About</title>
                </Helmet> 
                <div className="top-panel">
                    <div className="container">
                        <div className="title">{aboutJSON.pageTitle}</div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="h4 m-0 font-weight-500 pt-5 pb-3">{aboutJSON.title1}</div>
                        <div className="font-s-18 line-h-15">{aboutJSON.text1}</div>
                        <div className="h4 m-0 font-weight-500 pt-5 pb-3">{aboutJSON.title2}</div>
                        <div className="font-s-18 line-h-15">{aboutJSON.text2}</div>
                    </div>
                </div>
                {aboutJSON.showTeamInfo && <>
                    <hr className="m-0" />
                    <div className="container">
                        <div className="team-block">
                            <div className="d-flex">
                                <div className="team-highlights horizontal-align">THE TEAM</div>
                            </div>
                            <div className="title">{`IoT Nation Team (${arrayAbout.length})`}</div>
                            <div className="row">
                            {
                                arrayAbout.map((item, key) =>
                                    <Card key={key} address={item.address} companyName={item.companyName}
                                        description={item.description} email={item.email} expertise={item.expertise} img={item.img} linkedinUrl={item.linkedinUrl}
                                        twitterUrl={item.twitterUrl} user={item.user}
                                    />
                                )
                            }
                            </div>
                        </div>
                    </div>
                </>}
                <div className="white-background">
                    <div className="container">
                        <div className="row m-0">
                            <div className="contacts-block">
                                {aboutJSON.showContactUs && <>
                                    <div className="title">CONTACT US</div>
                                    <div className="information">
                                        <div className="email d-flex"><FaRegEnvelope /><a href="mailto:info@iotnation.com">info@iotnation.com</a></div>
                                        <div className="mobile d-flex"><FaMobileAlt /> +1 (917) 472 0148</div>
                                        <div className="location"><FaMapMarkerAlt /> 335 Madison Avenue, Floor 4 New York, NY 10017</div>
                                    </div>
                                </>}
                                <hr/>
                                <div className="title">CONNECT ON:</div>
                                <div>
                                    <SocialIcon target="_blank" url="https://www.linkedin.com/company/iotnation/about/" className="icons linkedin-icon" />
                                    <SocialIcon target="_blank" url="https://twitter.com/iotnationHQ" className="icons twitter-icon" />
                                    <SocialIcon target="_blank" url="https://www.facebook.com/iotnation" className="icons facebook-icon" />
                                    <SocialIcon target="_blank" url="https://www.youtube.com/channel/UCs8IomybzWyA7DTKuxxVPaQ" className="icons youtube-icon" />
                                </div>
                            </div>
                            <div className="margin-right map-block grey-border" >

                                {window.google&& this.state.open &&
                                <Map
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
                    
                <hr className="m-0" /> 
            </div>
        )
    }
}
