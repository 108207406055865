import React from 'react'

import SpeakersMiddle from './Speaker/SpeakersMiddle.jsx';
import ExhibitMiddle from './Exhibit/ExhibitMiddle.jsx';

function MiddleSwitcher({eventType, speakers, organizers, sponsors}){

    switch (eventType.toLowerCase().replace(/\s/g, '')) {
      case 'conference':
        return(
          <>
            {speakers.length > 0? <SpeakersMiddle title="See who's Speaking" data={speakers} id="speakers" />:null }
            {organizers.length > 0? <ExhibitMiddle title="Exhibiting" data={organizers} id="organizers" />:null}
          </>
        )
        case 'webinar':
        return(
          <>
            {speakers.length > 0? <SpeakersMiddle title="See who's Speaking" data={speakers} id="speakers" />:null}
          </>
        )
        case 'hackathon':
        return(
          <>
            {sponsors.length > 0? <ExhibitMiddle title="Sponsoring" data={sponsors} id="sponsors" />:null}
          </>
        )
        case 'panel':
        return(
          <>
            {speakers.length > 0? <SpeakersMiddle title="See who's Speaking" data={speakers} id="speakers" />:null }
          </>
        )
        case 'networking':
        return(
          <>
            {sponsors.length > 0? <ExhibitMiddle title="Sponsoring" data={sponsors} id="sponsors" />:null}
            {organizers.length > 0? <ExhibitMiddle title="Exhibiting" data={organizers} id="organizers" />:null}
            
          </>
        )
        case 'course':
        return(
          <>
            {speakers.length > 0? <SpeakersMiddle title="Course Instructors" data={speakers} id="speakers" />:null }
          </>
        )
        case 'fair':
        return(
          <>
            {organizers.length > 0? <ExhibitMiddle title="Exhibiting" data={organizers} id="organizers" />:null}
            {sponsors.length > 0? <ExhibitMiddle title="Sponsoring" data={sponsors} id="sponsors" />:null}
            
          </>
        )
        case 'startup':
        case 'exhibition':
        return(
          <>
            {speakers.length > 0? <SpeakersMiddle title="See who's Speaking" data={speakers} id="speakers" />:null }  
            {organizers.length > 0? <ExhibitMiddle title="Exhibiting" data={organizers} id="organizers"/>:null}
            {sponsors.length > 0? <ExhibitMiddle title="Sponsoring" data={sponsors} id="sponsors"/>:null}
          </>
        )
        
      default:
        return false;
    }
  }
  export default MiddleSwitcher