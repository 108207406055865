import React, { Component } from "react";
import { Button } from "reactstrap";
import Map from "./Map.jsx";
import _ from "lodash";
import getSuggestions from "./functions/getSuggestions";
import debounce from "lodash/debounce";
import Spinner from "../components/Spinner/Spinner";
import aFC from "../assets/aFC.json";
import { isObject } from "util";
import MapWithAMarker from "./MapWithAMarker";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import RegisterPopover from "../components/Modals/RegisterPopover/RegisterPopover";
import sampleResults from "./sampleData.json";
import proLoad from "./initialLoad/pro.json";
import corpLoad from "./initialLoad/corp.json";
import premLoad from "./initialLoad/prem.json";
import regLoad from "./initialLoad/reg.json";
import loadSearchLocationData from "../components/AdvancedFiltero/components/functions/loadSearchLocationData.js";
import advancedFilterCleaning from "../components/AdvancedFilters/Functions/advancedFilterCleaning.jsx";
import advancedFilterUrlGenerator from "../components/AdvancedFiltero/components/functions/advancedFilterUrlGenerator.js";
import { isMobile, isMacOs } from "react-device-detect";
import GeneratedApi from "../components/functions/generatedApi.js";
import flattenObject from "../components/AdvancedFilters/Functions/flattenObject.js";

export default class MapContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      TotalFundingAdded: false,
      locationFilter: [],
      LocationArray: "",
      openFilter: false,
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      locationCount: 0,
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      modal: false,
      isOpen: [],
      mapLoaded: false,
      searchResults: [],
      query: "",
      advancedFilters: [],
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  async componentDidMount() {
    this._isMounted = true;
    await this.getSchemas();
    const data = loadSearchLocationData(this.state.filterFields);
    await this.setState({ query: data.query });

    if (data.advancedFilters.length > 0) {
      this.updateSearchResults(data.advancedFilters);
    } else if (this.props.user) {
      const user = this.props.user["cognito:groups"];
      const fakeData =
        user && user[0] === "pro"
          ? proLoad
          : user && user[0] === "corporate"
          ? corpLoad
          : user && user[0] === "premium"
          ? premLoad
          : regLoad;
      this.setState({
        searchResults: fakeData.body.data.searchResults,
        mapLoaded: true,
        advancedFilters: data.advancedFilters,
      });
    } else {
      this.setState({
        searchResults: sampleResults.body.data.searchResults,
        mapLoaded: true,
        advancedFilters: data.advancedFilters,
      });
    }
  }

  async getSchemas() {
    await GeneratedApi("", false, false)
      .get("/schemas/companyFilter")
      .then((res) => {
        if (this._isMounted === true) {
          const reso = res.data.schemas;
          reso.organizations = [
            { name: " ", years: [""] },
            ...reso.organizations,
          ];
          reso.ecosystems = [{ name: " ", years: [""] }, ...reso.ecosystems];
          reso.conferences = [{ name: " ", years: [""] }, ...reso.conferences];
          reso.technologies = ["", ...reso.technologies];
          reso.verticals = ["", ...reso.verticals];
          reso["private/public"] = ["", ...reso["private/public"]];
          this.setState({
            filterFields: {
              ...aFC,
              schemas: reso,
              classificationArray: flattenObject(reso.classification),
            },
          });
        }
      });
  }

  updateState = (item) => {
    this.setState(item);
  };

  async componentDidUpdate(prevProps) {
    if (this._isMounted && this.props.location !== prevProps.location) {
      this.updateSearchResults();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateSearchResults = (data) => {
    this.setState({ mapLoaded: false });
    let { query } = this.state;
    const { user } = this.props;
    const filterData = data
      ? advancedFilterCleaning(data, user)
      : advancedFilterCleaning(this.state.advancedFilters, user);
    this.setState({ advancedFilters: filterData.advancedFilters });

    const q = {
      search: {
        q: query,
        start: 0,
        limit: 10000,
      },
      filters: [
        ...filterData.dataForPost,
        { attribute: "Company Rank", operator: "Less than", value: "40000" },
      ],
    };
    GeneratedApi("", true, true)
      .post("/iotMap", q)
      .then((response) => {
        response = response.data.body.data;
        this.setState({
          mapLoaded: true,
          searchResults: response.searchResults,
        });
      })
      .catch((error) => {
        this.setState({ mapLoaded: true });
        if (error.response && error.response.status === 429) {
          return false;
        }
        console.log(error);
      });
  };

  handleQueryChange = (event) => {
    event.preventDefault();
    this.setState({
      query: event.target.value,
    });
  };

  handleAdvancedFilterChange = async (filter, key, update = false) => {
    const { advancedFilters } = this.state;
    //advancedFilters[key] = filter;
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        { ...filter },
        ...advancedFilters.slice(key + 1),
      ],
    });
    if (!update || update === "timer") {
      advancedFilterUrlGenerator(
        this.state.query,
        this.state.advancedFilters,
        "",
        this.props.history
      );
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  handleAddFilter = async () => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters,
        {
          attribute: "Name",
          operator: "Begins with",
          value: "",
        },
      ],
    });
  };

  handleClearSpecificFilter = async (key) => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        ...advancedFilters.slice(key + 1),
      ],
    });
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  handleClearFilters = async () => {
    const advancedFilters = [];
    await this.setState({
      advancedFilters,
    });
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  handleLocationInput = (input, { action }) => {
    if (action === "input-change" && input.length > 0) {
      debounce(
        () =>
          getSuggestions(input).then((locationOptions) =>
            this.setState({ locationOptions })
          ),
        500
      )();
    }
  };

  handleLocationChange = async (value) => {
    const Location = this.state.advancedFilters.find(
      (filter, i) => filter.attribute === "Location"
    );

    if (value) {
      const newArrayValue = {
        attribute: "Location",
        operator: "Is near",
        value: value.label,
        attribute2: "Distances",
        operator2: "Within",
        value2: "100",
      };
      this.setState({
        locationFilter: { value: value.label, label: value.label },
        locationCount: 1,
        LocationArray: newArrayValue,
      });

      if (Location) {
        await this.setState((prevState) => ({
          advancedFilters: this.state.advancedFilters.map((obj) =>
            obj.attribute === "Location"
              ? {
                  ...obj,
                  value: value.label,
                  value2:
                    obj.value2 && obj.value2 !== "undefined"
                      ? obj.value2
                      : "100",
                }
              : {
                  attribute: obj.attribute,
                  operator: obj.operator,
                  value: obj.value,
                }
          ),
        }));
      } else {
        await this.setState((prevState) => ({
          advancedFilters: [...this.state.advancedFilters, newArrayValue],
        }));
      }
    } else {
      await this.setState({
        locationFilter: [],
        locationCount: 0,
        LocationArray: "",
        advancedFilters: this.state.advancedFilters
          .map((obj) => obj.attribute !== "Location" && obj)
          .filter(Boolean),
      });
    }
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  handlePcLocationChange = async (value) => {
    const Location = this.state.advancedFilters.find(
      (filter, i) => filter.attribute === "Location"
    );
    if (value) {
      const newArrayValue = {
        attribute: "Location",
        operator: "Is near",
        value: value.label,
        attribute2: "Distances",
        operator2: "Within",
        value2: "100",
      };
      this.setState({
        locationFilter: { value: value.label, label: value.label },
        locationCount: 1,
        LocationArray: newArrayValue,
      });
      if (Location) {
        await this.setState((prevState) => ({
          advancedFilters: this.state.advancedFilters.map((obj) =>
            obj.attribute === "Location"
              ? {
                  ...obj,
                  value: value.label,
                  value2:
                    obj.value2 && obj.value2 !== "undefined"
                      ? obj.value2
                      : "100",
                }
              : {
                  attribute: obj.attribute,
                  operator: obj.operator,
                  value: obj.value,
                }
          ),
        }));
      } else {
        await this.setState((prevState) => ({
          advancedFilters: [...this.state.advancedFilters, newArrayValue],
        }));
      }
    } else {
      await this.setState({
        locationFilter: [],
        locationCount: 0,
        LocationArray: "",
        advancedFilters: this.state.advancedFilters
          .map((obj) =>
            obj.attribute !== "Location"
              ? obj
              : {
                  attribute: obj.attribute,
                  attribute2: obj.attribute2,
                  operator: obj.operator,
                  operator2: obj.operator2,
                  value2: obj.value2,
                  value: "",
                }
          )
          .filter(Boolean),
      });
    }
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  handleSelectLocation = async (value) => {
    const Location = this.state.advancedFilters.find(
      (filter, i) => filter.attribute === "Location" && filter.value
    );

    if (value) {
      const newArrayValue = {
        attribute: "Location",
        operator: "Is near",
        value: value,
        attribute2: "Distances",
        operator2: "Within",
        value2: "100",
      };
      this.setState({
        locationFilter: { value: value, label: value },
        locationCount: 1,
        LocationArray: newArrayValue,
      });

      if (Location) {
        await this.setState((prevState) => ({
          advancedFilters: this.state.advancedFilters.map((obj) =>
            obj.attribute === "Location"
              ? {
                  ...obj,
                  value: value.label,
                  value2:
                    obj.value2 && obj.value2 !== "undefined"
                      ? obj.value2
                      : "100",
                }
              : {
                  attribute: obj.attribute,
                  operator: obj.operator,
                  value: obj.value,
                }
          ),
        }));
      } else {
        await this.setState((prevState) => ({
          advancedFilters: [...this.state.advancedFilters, newArrayValue],
        }));
      }
    } else {
      await this.setState({
        locationFilter: [],
        LocationArray: "",
        locationCount: 0,
        advancedFilters: this.state.advancedFilters
          .map((obj) => obj.attribute !== "Location" && obj)
          .filter(Boolean),
      });
    }
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      "",
      this.props.history
    );
  };

  validateSchemas(whichSchemas, schemas, validatedValue, dependsOn) {
    let schemaValues = [];
    whichSchemas.forEach((schema) => {
      if (!dependsOn || dependsOn.parrentAttribute !== "Year") {
        schemaValues = schemaValues.concat(
          schemas[schema].map((val, i) => {
            if (isObject(val)) {
              return val.name;
            } else {
              return val;
            }
          })
        );
      } else if (dependsOn.parrentAttribute === "Year") {
        const filtered = schemas[schema].filter(
          (val) => val.name === dependsOn.value
        );
        schemaValues = filtered[0] && filtered[0].years;
      }
    });
    return _.includes(schemaValues, validatedValue);
  }

  handleFilterCheckbox = async (event) => {
    const target = event.target;
    const newArrayValue = {
      attribute: target.placeholder.replace(/ \/ /g, "/"),
      operator: target.name,
      value: target.value,
    };
    const keyId = target.id;

    if (target.checked) {
      if (target.placeholder === "Total Funding") {
        this.setState({ totalFcount: this.state.totalFcount + 1 });
      } else if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount + 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount + 1 });
      } else if (target.placeholder === "Number Of Employees") {
        this.setState({ employeeCount: this.state.employeeCount + 1 });
      } else if (target.placeholder === "Private / Public") {
        this.setState({
          publicPrivateCount: this.state.publicPrivateCount + 1,
        });
      }

      await this.setState((prevState) => ({
        checkedFilter: [
          ...prevState.checkedFilter,
          {
            _id: keyId,
            checked: {
              attribute: target.placeholder.replace(/ \/ /g, "/"),
              operator: target.name,
              value: target.value,
            },
          },
        ],
      }));
    } else {
      if (target.placeholder === "Total Funding") {
        this.setState({ totalFcount: this.state.totalFcount - 1 });
      } else if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount - 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount - 1 });
      } else if (target.placeholder === "Number Of Employees") {
        this.setState({ employeeCount: this.state.employeeCount - 1 });
      } else if (target.placeholder === "Private / Public") {
        this.setState({
          publicPrivateCount: this.state.publicPrivateCount - 1,
        });
      }

      await this.setState((prevState) => ({
        advancedFilters: [...prevState.advancedFilters, newArrayValue],
        checkedFilter: prevState.checkedFilter
          .map((obj) => obj._id !== keyId && obj)
          .filter(Boolean),
      }));
    }
    if (this.state.LocationArray) {
      await this.setState({
        advancedFilters: [
          ...this.state.checkedFilter.map((obj) => ({
            attribute: obj.checked.attribute,
            operator: obj.checked.operator,
            value: obj.checked.value,
          })),
          this.state.LocationArray,
        ],
      });
    } else {
      await this.setState({
        advancedFilters: this.state.checkedFilter.map((obj) => ({
          attribute: obj.checked.attribute,
          operator: obj.checked.operator,
          value: obj.checked.value,
        })),
      });
    }
  };
  handleClearFilter = async (filter) => {
    if (filter === "Total Funding") {
      this.setState({ totalFcount: 0 });
    } else if (filter === "Technologies") {
      this.setState({ technologiesCount: 0 });
    } else if (filter === "Verticals") {
      this.setState({ verticalsCount: 0 });
    } else if (filter === "Number Of Employees") {
      this.setState({ employeeCount: 0 });
    } else if (filter === "Private / Public") {
      this.setState({ publicPrivateCount: 0 });
    } else if (filter === "Location") {
      this.setState({
        locationCount: 0,
        locationFilter: [],
      });
    }
    await this.setState((prevState) => ({
      advancedFilters: [...prevState.advancedFilters],
      checkedFilter: prevState.checkedFilter
        .map(
          (obj) => obj.checked.attribute !== filter.replace(/ \/ /g, "/") && obj
        )
        .filter(Boolean),
    }));
    if (this.state.LocationArray && filter !== "Location") {
      await this.setState({
        advancedFilters: [
          ...this.state.checkedFilter.map((obj) => ({
            attribute: obj.checked.attribute,
            operator: obj.checked.operator,
            value: obj.checked.value,
          })),
          this.state.LocationArray,
        ],
      });
    } else {
      await this.setState({
        advancedFilters: this.state.checkedFilter.map((obj) => ({
          attribute: obj.checked.attribute,
          operator: obj.checked.operator,
          value: obj.checked.value,
        })),
      });
    }
  };
  handleClearAllFilters = async () => {
    await this.setState({
      advancedFilters: [],
      checkedFilter: [],
      totalFcount: 0,
      technologiesCount: 0,
      verticalsCount: 0,
      employeeCount: 0,
      publicPrivateCount: 0,
      locationCount: 0,
    });
  };
  handleFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  };
  handleClickOnMap = () => {
    this.setState({
      openFilter: false,
    });
  };

  render() {
    const aFHandle = {
      handleAdvancedFilterChange: this.handleAdvancedFilterChange,
      handleClearFilters: this.handleClearFilters,
      handleAddFilter: this.handleAddFilter,
      handleClearSpecificFilter: this.handleClearSpecificFilter,
      handleLocationChange: this.handleLocationChange,
      handleLocationInput: this.handleLocationInput,
      handleSelectLocation: this.handleSelectLocation,
    };
    const aFValue = {
      advancedFilters: this.state.advancedFilters,
      filterFields: this.state.filterFields,
      user: this.props.user,
      locationOptions: this.state.locationOptions,
      locationFilter: this.state.locationFilter,
    };
    const aFMobileHandle = {
      handleFilterCheckbox: this.handleFilterCheckbox,
      handleClearFilter: this.handleClearFilter,
      handleClearAllFilters: this.handleClearAllFilters,
    };
    const aFMobileValue = {
      checkedFilter: this.state.checkedFilter,
      totalFcount: this.state.totalFcount,
      technologiesCount: this.state.technologiesCount,
      verticalsCount: this.state.verticalsCount,
      employeeCount: this.state.employeeCount,
      publicPrivateCount: this.state.publicPrivateCount,
      locationCount: this.state.locationCount,
    };

    return (
      <div>
        {this.props.user ? (
          ""
        ) : (
          <RegisterPopover
            pathname={window.location.pathname}
            isOpen={this.state.modal}
            toggle={this.toggleModal}
          />
        )}
        <Button
          className="btn-search-filter d-none d-md-block"
          onClick={this.handleFilter}
        >
          Search/Filters{" "}
          <span className="badge">
            <span>{this.state.advancedFilters.length}</span>
          </span>{" "}
          {this.state.openFilter ? <FaArrowUp /> : <FaArrowDown />}
        </Button>
        <Map
          advancedFiltersHandlers={aFHandle}
          advancedFiltersValues={aFValue}
          aFMobileHandle={aFMobileHandle}
          aFMobileValue={aFMobileValue}
          handlePcLocationChange={this.handlePcLocationChange}
          openFilter={this.state.openFilter}
          checkedFilter={this.state.checkedFilter}
          handleQueryChange={this.handleQueryChange}
          history={this.props.history}
          user={this.props.user}
          query={this.state.query}
          handleSubmit={this.handleSubmit}
          updatePropsState={this.updateState}
        >
          <div
            onClick={this.handleClickOnMap}
            className=" map-block position-relitive"
            style={{ width: "100%", height: "100vh" }}
          >
            {!this.state.mapLoaded && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
            {window.google && (
              <MapWithAMarker
                searchResults={this.state.searchResults}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                api={this.props.api}
                user={this.props.user}
                advancedFilters={this.state.advancedFilters}
              />
            )}
          </div>
        </Map>
      </div>
    );
  }
}
