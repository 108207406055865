import React from 'react'

import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import customMapStyle from'../../Map/customMapStyle.json'
const Map = (withGoogleMap((props) =>{


  return (     
    
        <GoogleMap
        defaultOptions={{ styles: customMapStyle }}
          defaultZoom={16}
          center={ { lat: 40.7535575, lng: -73.9803248} }
          loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
        //   defaultOptions={{ styles: styles }}
          >
          <Marker
          position={{ lat: 40.7535575, lng: -73.9803248 }}
          icon={{url:'/images/cluster/location-marker-smaller.png'}}  
          //icon={}
        >
        </Marker>
        </GoogleMap>       
       
    );
  }
))

export default Map;
