import React from "react";
import generateForm from "./functions/generateForm";
import advancedFilterUrlGenerator from "./functions/advancedFilterUrlGenerator";

const Dropdown = ({ array, id, handleOptionChange, filter }) => {
  if (Object.getOwnPropertyNames(array).length > 0) {
    return (
      <div className="my-2 mr-2">
        <select
          onChange={(e) => handleOptionChange(e, id)}
          className="form-control"
          name=" "
          id={id}
          value={
            filter[`value${id === 1 ? "" : id}`]
              ? filter[`value${id === 1 ? "" : id}`]
              : " "
          }
        >
          <option value=" "> </option>
          {Object.getOwnPropertyNames(array).map((item, key) => (
            <option key={key} id={key} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    );
  }
  return "";
};

const ClassificationBlock2 = ({
  filter,
  filterFields,
  user,
  updatePropsState,
  index,
  advancedFilters,
  history,
  query,
}) => {
  const classificationJSON =
    filterFields && filterFields.schemas && filterFields.schemas.classification;

  const handleMainChangeOption = (e) => {
    const data = advancedFilters;
    if (e.target.value === "Classification") {
      data[index] = {
        attribute: "Classification",
        operator: "Belongs to",
        value: " ",
      };
    } else {
      data[index] = generateForm(e.target, index, filterFields, filter);
    }
    updatePropsState({ advancedFilters: data });
  };
  const handleOptionChange = (e, id) => {
    const data = advancedFilters;
    let array = {};
    if (id === 0) {
      array = {
        attribute: "Classification-2",
        operator: "Is",
        value: e.target.value,
      };
    } else if (e.target.value !== "" && e.target.value !== " ") {
      for (let i = 1; i <= id; i++) {
        array = {
          ...array,
          [i === 1 ? "attribute" : `attribute${i}`]: `Classification-2${
            i === 1 ? "" : `-${i}`
          }`,
          [i === 1 ? "operator" : `operator${i}`]: "Is",
          [i === 1 ? "value" : `value${i}`]:
            id === i ? e.target.value : data[index][`value${i === 1 ? "" : i}`],
        };
      }
    } else {
      for (let i = 1; i < id; i++) {
        array = {
          ...array,
          [i === 1 ? "attribute" : `attribute${i}`]: `Classification-2${
            i === 1 ? "" : `-${i}`
          }`,
          [i === 1 ? "operator" : `operator${i}`]: "Is",
          [i === 1 ? "value" : `value${i}`]:
            id === i ? e.target.value : data[index][`value${i === 1 ? "" : i}`],
        };
      }
    }
    data[index] = array;
    updatePropsState({ advancedFilters: data });
  };
  const handleCloseFilter = () => {
    const data = advancedFilters.filter((_item, key) => key !== index);
    updatePropsState({
      advancedFilters: data,
    });
    advancedFilterUrlGenerator(query, data, 1, history);
  };
  const applyFilter = (e) => {
    e.preventDefault();
    advancedFilterUrlGenerator(query, advancedFilters, 1, history);
  };
  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];
  return (
    <div className="row mx-0">
      <div className="my-2 mr-2">
        <select
          onChange={(e) => handleMainChangeOption(e)}
          className="form-control font-weight-bold"
          id={`attribute${index}`}
          value={filter.attribute}
        >
          {Object.getOwnPropertyNames(filterFields.attributes).map(
            (item, key) => (
              <option
                disabled={
                  ((!user || !userType) &&
                    (item === "Company Rank" ||
                      item === "Participation In" ||
                      item === "Industry Activity Score" ||
                      item === "Number of Employees" ||
                      item === "Private/Public" ||
                      item === "Technologies" ||
                      item === "Verticals")) ||
                  (item === "Classification" &&
                    userType !== "admin" &&
                    userType !== "corporate") ||
                  (item === "Classification-2" &&
                    userType !== "admin" &&
                    userType !== "corporate")
                }
                // className={item === "Total Funding" && userType !== "admin" ? "d-none": "d-block"}
                className={
                  item === "Total Funding"
                    ? "d-none font-weight-bold"
                    : "d-block font-weight-bold"
                }
                key={key}
                id={key}
                value={item}
              >
                {filterFields.alias[item]
                  ? filterFields.alias[item].name
                  : item}
                &nbsp;&nbsp;
              </option>
            )
          )}
        </select>
      </div>
      <Dropdown
        {...{
          id: 1,
          array: classificationJSON,
          handleOptionChange,
          filter,
        }}
      />
      <Dropdown
        {...{
          id: 2,
          array:
            filter.value && filter.value !== " "
              ? classificationJSON[filter.value]
              : {},
          handleOptionChange,
          filter,
        }}
      />
      <Dropdown
        {...{
          id: 3,
          array:
            filter.value2 && filter.value2 !== " "
              ? classificationJSON[filter.value][filter.value2]
              : {},
          handleOptionChange,
          filter,
        }}
      />
      <Dropdown
        {...{
          id: 4,
          array:
            filter.value3 && filter.value3 !== " "
              ? classificationJSON[filter.value][filter.value2][filter.value3]
              : {},
          handleOptionChange,
          filter,
        }}
      />
      <Dropdown
        {...{
          id: 5,
          array:
            filter.value4 && filter.value4 !== " "
              ? classificationJSON[filter.value][filter.value2][filter.value3][
                  filter.value4
                ]
              : {},
          handleOptionChange,
          filter,
        }}
      />

      <div className="my-2 mr-2">
        {filter.value && filter.value !== " " && (
          <button
            onClick={(e) => applyFilter(e)}
            className="custom-button background-blue px-4 py-2 rounded-6 mr-2"
          >
            Apply Filter
          </button>
        )}
        <span
          className="btn clear pointer"
          onClick={() => handleCloseFilter()}
        />
      </div>
    </div>
  );
};

export default ClassificationBlock2;
