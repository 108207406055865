import React, { useState } from "react";
import generateForm from "./functions/generateForm";
import advancedFilterUrlGenerator from "./functions/advancedFilterUrlGenerator";
import _ from "lodash";
import CustomSelect from "./functions/CustomSelect";

const ClassificationBlock = ({
  filter,
  filterFields,
  user,
  updatePropsState,
  index,
  advancedFilters,
  history,
  query,
}) => {
  const handleMainChangeOption = (e) => {
    const data = advancedFilters;
    if (e.target.value === "Classification-2") {
      data[index] = { attribute: "Classification-2", operator: " " };
    } else {
      data[index] = generateForm(e.target, index, filterFields, filter);
    }
    updatePropsState({ advancedFilters: data });
  };

  const handleValueChange = (value) => {
    const data = advancedFilters;
    let array = {
      ...data[index],
      value: value,
    };
    data[index] = array;
    updatePropsState({ advancedFilters: data });
    advancedFilterUrlGenerator(query, advancedFilters, 1, history);
  };

  const handleOperator = (e) => {
    const data = advancedFilters;
    let array = {
      ...data[index],
      operator: e.target.value === "Is Not" ? "Not belongs to" : "Belongs to",
    };
    data[index] = array;
    updatePropsState({ advancedFilters: data });
    if (data[index].value !== "" && data[index].value !== " ") {
      advancedFilterUrlGenerator(query, data, 1, history);
    }
  };

  const handleCloseFilter = () => {
    const data = advancedFilters.filter((_item, key) => key !== index);
    updatePropsState({
      advancedFilters: data,
    });
    advancedFilterUrlGenerator(query, data, 1, history);
  };

  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];

  return (
    <div className="row mx-0">
      <div className="my-2 mr-2">
        <select
          onChange={(e) => handleMainChangeOption(e)}
          className="form-control font-weight-bold"
          id={`attribute${index}`}
          value={filter.attribute}
        >
          {Object.getOwnPropertyNames(filterFields.attributes).map(
            (item, key) => (
              <option
                disabled={
                  ((!user || !userType) &&
                    (item === "Company Rank" ||
                      item === "Participation In" ||
                      item === "Industry Activity Score" ||
                      item === "Number of Employees" ||
                      item === "Private/Public" ||
                      item === "Technologies" ||
                      item === "Verticals")) ||
                  (item === "Classification" &&
                    userType !== "admin" &&
                    userType !== "corporate")
                }
                // className={item === "Total Funding" && userType !== "admin" ? "d-none": "d-block"}
                className={
                  item === "Total Funding"
                    ? "d-none font-weight-bold"
                    : "d-block font-weight-bold"
                }
                key={key}
                id={key}
                value={item}
              >
                {filterFields.alias[item]
                  ? filterFields.alias[item].name
                  : item}
                &nbsp;&nbsp;
              </option>
            )
          )}
        </select>
      </div>

      <div className="my-2 mr-2">
        <select
          value={filter.operator === "Not belongs to" ? "Is Not" : "Is"}
          onChange={(e) => {
            handleOperator(e);
          }}
          className="form-control"
        >
          <option value="Is">Is</option>
          <option value="Is Not">Is Not</option>
        </select>
      </div>

      <div style={{ width: "200px" }} className="my-2 mr-2">
        <CustomSelect
          {...{
            data: [
              {
                value: filter.value,
                label: filter.value,
              },
            ],
            updatePropsState,
            advancedFilters,
            index,
            handleValueChange,
            options: filterFields.classificationArray,
          }}
        />
      </div>

      <div className="my-2 mr-2">
        <span
          className="btn clear pointer"
          onClick={() => handleCloseFilter()}
        />
      </div>
    </div>
  );
};

export default ClassificationBlock;
