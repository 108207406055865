export default function(eventType){
    switch (eventType.toLowerCase()) {
        case 'conference':
            return  [
                '/images/events/conference.jpg'
            ];
        case 'course':
            return  [
                '/images/events/course.jpg'
            ];
        case 'fair':
            return  [
                '/images/events/fair.jpg'
            ];
        case 'hackathon':
            return  [
                '/images/events/hackathon.jpg'
            ];
        case 'networking':
            return  [
                '/images/events/networking.jpg'
            ];
        case 'panel':
            return  '/images/events/panel.jpg';
        case 'Startup':
            return  [
                '/images/events/startup.jpg'
            ];
        case 'webinar':
            return '/images/events/webinar.png';
        case 'exhibition':
            return [
                '/images/events/exhibition.jpg'
            ];
        default:
            return ''
    }
}