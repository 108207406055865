import React, { Component } from "react";
import "./CardsTitle.scss";
import { FaCameraRetro } from "react-icons/fa";
export default class CardsTitle extends Component {
  render() {
    const { image, titleName, name, isExtraInfo, extraInfo } = this.props;
    return (
      <div className="CardsTitle">
        <div className={`title-image cards-${name}`}>
          {name === "iGallery" ? <FaCameraRetro /> : <img className="p-2" src={image.toLowerCase()} alt="" />}
        </div>
        <div className="title-block">
          <div className="title">{titleName}</div>
          {isExtraInfo && extraInfo}
        </div>
      </div>
    );
  }
}
