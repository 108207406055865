import React, { Component } from "react";
import ActivationCode from "./ActivationCode";
import Spinner from "../components/Spinner/Spinner";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import GeneratedApi from "../components/functions/generatedApi";

export default class ActivationCodeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        code: "",
        membership: {
          days: "",
          hours: "",
          full: "",
        },
        expiration: Date.now(),
        expirationUnix: moment().unix(),
        level: "corporate",
        description: "",
      },
      response: null,
      loaded: false,
      update: false,
    };
  }
  componentDidMount() {
    GeneratedApi("", true, true)
      .get(`/activationCode`)
      .then((response) => {
        if (response.data) {
          this.setState({
            response: response.data.activationCodes,
            loaded: true,
          });
        }
      });
  }
  handleChangeDate = (date) => {
    const formData = { ...this.state.formData };
    formData["expiration"] = date;
    formData["expirationUnix"] = moment().unix(date);
    this.setState({ formData });
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formData = { ...this.state.formData };

    if (name === "hours") {
      formData["membership"]["hours"] = value;
      formData["membership"]["full"] = formData["membership"]["days"] * 24 * 3600 + formData["membership"]["hours"] * 3600;
    } else if (name === "days") {
      formData["membership"]["days"] = value;
      formData["membership"]["full"] = formData["membership"]["days"] * 24 * 3600 + formData["membership"]["hours"] * 3600;
    } else if (name === "code") {
      formData[name] = value.replace(/\s/g, "");
    } else {
      formData[name] = value;
    }
    this.setState({ formData });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loaded: false,
    });

    const formData = this.state.formData;
    const q = {
      newCode: {
        code: formData.code ? formData.code : "BLANK_STRING",
        expirationDate: moment(moment(formData.expiration).format("YYYY MMM DD"))
          .add(23, "hours")
          .add(59, "minutes")
          .add(59, "seconds")
          .zone("ET")
          .unix(),
        period: formData["membership"]["full"],
        accessLevel: formData.level,
        description: formData.description,
      },
    };

    GeneratedApi("", true, true)
      .put("/activationCode", q)
      .then((response) => {
        GeneratedApi("", true, true)
          .get(`/activationCode`)
          .then((response) => {
            this.setState({
              response: response.data.activationCodes,
              loaded: true,
            });
          });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data) {
          NotificationManager.error(error.response.data);
          this.setState({
            loaded: true,
          });
        }
      });
  };
  handleDelete = (event) => {
    event.preventDefault();
    this.setState({
      loaded: false,
    });
    const code = event.target.name;
    GeneratedApi("", true, true)
      .delete(`/activationCode/${code}`)
      .then((response) => {
        GeneratedApi("", true, true)
          .get(`/activationCode`)
          .then((response) => {
            this.setState({
              response: response.data.activationCodes,
              loaded: true,
              update: false,
            });
          });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data) {
          NotificationManager.error(error.response.data);
          this.setState({
            loaded: true,
          });
        }
      });
  };
  handleEdit = (event) => {
    event.preventDefault();
    this.state.response.map(
      (item, key) =>
        item.code === event.target.name &&
        this.setState({
          formData: {
            code: item.code,
            membership: {
              days: (item.period - (item.period % (24 * 3600))) / (24 * 3600),
              hours: ((item.period % (24 * 3600)) - (item.period % 3600)) / 3600,
              full: item.period,
            },
            expiration: moment.unix(item.expirationDate)["_i"],
            expirationUnix: `"${item.expirationDate}"`,
            level: item.accessLevel,
            description: item.description,
          },
        })
    );
    this.setState({
      update: true,
    });
  };

  handleUpdate = (event) => {
    event.preventDefault();
    this.setState({
      loaded: false,
    });
    const formData = this.state.formData;

    const q = {
      newCode: {
        code: formData.code ? formData.code : "BLANK_STRING",
        expirationDate: moment(moment(formData.expiration).format("YYYY MMM DD")).unix(),
        period: formData["membership"]["full"],
        accessLevel: formData.level,
        description: formData.description,
      },
    };

    GeneratedApi("", true, true)
      .post("/activationCode", q)
      .then((response) => {
        GeneratedApi("", true, true)
          .get(`/activationCode`)
          .then((response) => {
            this.setState({
              response: response.data.activationCodes,
              loaded: true,
              update: false,
            });
          });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data) {
          NotificationManager.error(error.response.data);
          this.setState({
            loaded: true,
          });
        }
      });
  };

  handleReject = () => {
    this.setState({
      update: false,
    });
  };

  render() {
    const { user } = this.props;
    const { response, loaded, formData } = this.state;
    const userType = user && user["cognito:groups"] && user["cognito:groups"][0];
    if (userType !== "admin") {
      return <Redirect to="/" />;
    }
    if (loaded) {
      return (
        <div>
          <ActivationCode
            results={response}
            handleChange={this.handleChange}
            formData={formData}
            handleSubmit={this.handleSubmit}
            handleChangeDate={this.handleChangeDate}
            handleDelete={this.handleDelete}
            handleUpdate={this.handleUpdate}
            update={this.state.update}
            handleEdit={this.handleEdit}
            handleReject={this.handleReject}
          />
        </div>
      );
    } else {
      return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
    }
  }
}
