import React from 'react';
import { FaCheck } from 'react-icons/fa';

export default function ShowValue(props) {
    if(props.user) {
        if(props.user['cognito:groups']) {
            if(props.user['cognito:groups'].indexOf('pro') >= 0 || props.user['cognito:groups'].indexOf('premium') >= 0) {
                return <FaCheck/>;
            }
        }
    }
    return props.data;
}