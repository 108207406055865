import React, { useState, useEffect } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import advancedFilterUrlGenerator from "./functions/advancedFilterUrlGenerator";
import getSuggestions from "../../../Search/functions/getSuggestions";
import locationChanger from "./functions/locationChanger";

const SuggestedLocation = (props) => {
  const [locationOptions, setLocationOptions] = useState([
    { value: null, label: "", longLabel: "" },
  ]);
  const [locationFilter, setLocationFilter] = useState([]);
  const {
    placeholder,
    updatePropsState,
    id,
    index,
    advancedFilters,
    query,
    history,
    value,
  } = props;

  const handleLocationInput = (input, { action }) => {
    if (action === "input-change" && input.length > 0) {
      debounce(
        () =>
          getSuggestions(input).then((locationOption) =>
            setLocationOptions(locationOption)
          ),
        500
      )();
    }
  };

  const handleLocationChange = (val) => {
    let data = locationChanger(advancedFilters, val, index);
    setLocationFilter(data.locationFilter);
    updatePropsState({ currentPage: 1, ...data.data });
    advancedFilterUrlGenerator(query, data.data.advancedFilters, 1, history);
  };

  useEffect(() => {
    if (value) {
      setLocationFilter({ value: value, label: value });
    }
  }, []);

  const fakeHandle = () => {};

  const CustomOption = ({
    innerProps,
    isDisabled,
    data: { label, longLabel },
  }) =>
    !isDisabled ? (
      <div className="option" {...innerProps}>
        <div className="label">{label}</div>
        <div className="long-label">{longLabel}</div>
      </div>
    ) : null;

  return (
    <div style={{ width: "200px" }}>
      <input
        id={id}
        onChange={() => fakeHandle}
        type="text"
        value={value}
        className="d-none"
      />
      <Select
        options={locationOptions}
        isClearable={true}
        name="Location"
        value={locationFilter}
        onChange={handleLocationChange}
        onInputChange={handleLocationInput}
        components={{
          Option: CustomOption,
          IndicatorSeparator: false,
          DropdownIndicator: false,
        }}
        placeholder={placeholder}
        className="react-select-container w-100"
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default SuggestedLocation;
