import React from 'react'
//import ReactPaginate from 'react-paginate';
import {  withRouter } from "react-router-dom";
import RowResultsMobile from './RowResultsMobile'
import Pagination from 'rc-pagination';
import localeInfo from './Pagination/pagination_locale';
// import RegisterPopover from '../Modals/RegisterPopover/RegisterPopover'
import RestrictedPopover from '../Modals/RestrictedPopoverSmall/RestrictedPopover'
import SortableHeader from './SortableHeader/SortableHeader';
//import './Pagination/pagination.css';
import './ResultsTable.scss';

class ResultsTableMobile extends React.Component {
  constructor() {
    super();
    this.state = {
      sort: {
        column: 'rank',
        sort: 'asc',
        showModal: false
      }
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handleResultCount = (start, count ,limit)=>{
    return count - start < limit ? count : start > 0 ? start + limit : limit
  }
  toggleModal = () => {this.setState({showModal: !this.state.showModal})}
  handlePageClick = (current, pageSize) => {
    //let selected = data.selected;
    //let offset = Math.ceil(selected * this.props.data.limit);
    if(this.props.user){
      let offset = Math.ceil((current-1) * pageSize);
      this.props.handlePageChange(offset, current);
      this.setState({showModal: false})
    }else{
      this.setState({showModal: true})
    }    
  };
  handleCloseModal = () => {
    this.setState({showModal: false})
  }
      
  render() {
    const {searchResults, start, count, limit, sort, currentPage} = this.props.data;
        return (
        <div className="container results companies">
          {/* <RegisterPopover pathname={window.location.pathname} isOpen={this.state.showModal} toggle={this.toggleModal} /> */}
          <div className="display-flex">
            <div className="col">
              <div className="result-count">{count===0?start:start+1}-{this.handleResultCount(start, count, limit)} of {count} results</div>
              <table className="row">
                <thead className="col-12">
                  <tr className="row">
                    <SortableHeader data={{type: 'rank', name: 'Rank', sort: sort, gridSize: 'col-4'}} handleSortChange={this.props.handleSortChange}/>
                    
                    <SortableHeader textAlign="true" data={{type: 'name', name: 'Company / Org name', sort: sort, gridSize: 'col-8'}} handleSortChange={this.props.handleSortChange}/>
                  </tr>
                </thead>
                <tbody className="col-12">
                  {searchResults.map((result, index) => 
                    <RowResultsMobile id={`Like-resultsTableMobile${index}`} user={this.props.user} api={this.props.api} result={result} key={index} pathname={this.props.location.pathname} search={this.props.location.search} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="display-flex">
            <div className="col">
              <nav aria-label="Page navigation example">
              <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.handleCloseModal}  id='resultsTableMobile'  />
              <Pagination
                current={this.props.user?Number(currentPage):1}
                className={'pages pagination'}
                prevIcon={<div className="pagination-button previous"></div>}
                nextIcon={<div className="pagination-button next"></div>}
                pageSize={Number(limit)}
                onChange={this.handlePageClick}
                total={Number(count)}
                locale={localeInfo}
                showTitle={true}
                />
                </nav>
                
            </div>
          </div>
        </div>
    )
  }
}
export default withRouter(ResultsTableMobile);