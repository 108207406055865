import React, { Children } from 'react'
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import './AddtoCalendarDropdown.scss'

function ButtonComponent({children, onClick}) {

    return (
        
        <button className="link" onClick={onClick} ><div className="display-flex"><div className="oval"><div className="center">+</div></div> <div className="center-text">Add to calendar</div></div></button>
    )
}

function DropdownComponent({children, isOpen, onRequestClose}) {
    return ( 
        <div className={isOpen?"dropdown-container":"d-none"}>
            {Children.toArray(children).map((child, i)=>
                <div className="dropdown-item" onClick={onRequestClose}  key={i}>{child}</div>
            )}
        </div>
    )
}



export default AddToCalendarHOC(ButtonComponent, DropdownComponent)