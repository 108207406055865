import React from 'react'
// import { SocialIcon } from 'react-social-icons';
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';
export default function ContactRow(props) {
    const { contact, fakedContacts, show } = props;
    return (
        <>
            <tr className="row d-none d-md-flex">
                <td className="col-2">{contact.name}</td>
                <td className="col-3">{contact.title}</td>
                <td className="col-3 padding-b-16">{contact.email ? <a target="_blank" href={`mailto:${contact.email.workEmail}`}>{contact.email.workEmail}</a> : ''}</td>
                <td className="col-2 padding-b-16">{contact.address ? <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${contact.address.city}`}>{contact.address.city}</a> : ''}</td>
                {contact.linkedin && <td className="col"><a className="rectangle" target="_blank" href={contact.linkedin}><img className="single-02-3" src="/images/single-02-3.svg" alt=""/></a></td>}
                {/* <td className={`col padding-top-bottom-0 ${fakedContacts && "blurred-box"}`}><SocialIcon target="_blank" url={contact.linkedin} className="icons linkedin-icon" /></td> */}
            </tr>
            <tr className="row d-flex d-md-none position-relitive">
                <td className={`col-9 padding-top-bottom-2 ${fakedContacts && "blurred-box"}`}>{contact.name} </td>
                {contact.linkedin && <td className={`col-3 padding-top-bottom-2  ${fakedContacts && "blurred-box"}`} ><a className="rectangle" target="_blank" href={contact.linkedin}><img className="single-02-3" src="/images/single-02-3.svg" alt=""/></a></td>}
                {/* <td className={`col padding-top-bottom-0 ${fakedContacts && "blurred-box"}`}><SocialIcon target="_blank" url={contact.linkedin} className="icons linkedin-icon" /></td> */}
                <td className={`col-9 padding-top-bottom-2  ${fakedContacts && "blurred-box"}`}>{contact.title}</td>
                <td className={`col-12 padding-top-bottom-2 padding-b-16 ${fakedContacts && "blurred-box"}`}>{contact.email ? <a target="_blank" href={`mailto:${contact.email.workEmail}`}>{contact.email.workEmail}</a> : ''}</td>
                <td className={`col-12 padding-top-bottom-2 padding-b-16 ${fakedContacts && "blurred-box"}`}>{contact.address ? <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${contact.address.city}`}>{contact.address.city}</a> : ''}</td>
                {
                    show && <td className="p-0 subs-top-30"><SubscriberContent hidden={fakedContacts}/></td>
                }  
            </tr>
        </>
    )
}