import React, { Component } from "react";
import { Marker } from "react-google-maps";
import { Button } from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";
// import RestrictedPopover from '../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
import { FaTimes, FaMapMarkerAlt, FaCaretLeft, FaCaretRight } from "react-icons/fa";
import GeneratedApi from "../components/functions/generatedApi";
// import { usePosition } from 'use-position';

export default class MarkerContainer extends Component {
  constructor(props) {
    super(props);
    this.mobileInfoRef = React.createRef();
    this.state = {
      isOpen: false,
      withSpace: false,
      likeData: null,
      showModal: false,
      thumbsUp: false,
      likeLength: 0,
      openDesktopInfo: true,
      openDesktopInfoDelay: true,
      width: false,
    };
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !this.state.showModal,
    }));
  };
  likeHandle = () => {
    if (!this.props.user) {
      this.setState({ showModal: true });
    } else {
      const like = { dataId: this.props.results && this.props.results.id, dataType: "companies", operation: "LIKE" };
      const unlike = { dataId: this.props.results && this.props.results.id, dataType: "companies", operation: "INDIFFERENT" };
      if (this.state.thumbsUp) {
        if (this.state.likeLength < 1) {
          this.setState({
            thumbsUp: false,
            likeLength: 0,
          });
        } else {
          this.setState({
            thumbsUp: false,
            likeLength: this.state.likeLength - 1,
          });
        }

        GeneratedApi("", true, true)
          .post("/updateLikeInfo", unlike)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            if (this.state.likeLength < 1) {
              this.setState({
                thumbsUp: false,
                likeLength: 0,
              });
            } else {
              this.setState({
                thumbsUp: false,
                likeLength: this.state.likeLength + 1,
              });
            }
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      } else {
        this.setState({
          thumbsUp: true,
          likeLength: this.state.likeLength + 1,
        });

        GeneratedApi("", true, true)
          .post("/updateLikeInfo", like)
          .then((response) => {
            response = response.data;
            this.setState({
              likeData: response,
            });
          })
          .catch((error) => {
            this.setState({
              thumbsUp: true,
              likeLength: this.state.likeLength - 1,
            });
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 429) {
                return false;
              }
            }
          });
      }
    }
  };

  handleToggle = (isOpen = false) => {
    if (!this.state.showModal) {
      this.setState({ isOpen });
      if (isOpen === false) {
        this.setState({ showModal: false });
      }
    }

    // const q = {"dataId":this.props.results&&this.props.results.id ,"dataType":"companies"}
    // GeneratedApi("", true, true).post('/getLikeInfo', q)
    //     .then((response) => {
    //         response = response.data;
    //         this.setState({
    //             likeData:response,
    //             thumbsUp: response.currentUserLike,
    //             likeLength: response.userList.length,
    //         });

    //     })
    // .catch((error) => {
    //     console.log(error);
    //     if(error.response) {
    //         if(error.response.status === 429) {

    //             return false;
    //         }
    //     }
    // });
  };

  handleOpenInfoWindow = () => {
    this.setState({
      openDesktopInfo: !this.state.openDesktopInfo,
    });
    setTimeout(() => {
      this.setState({ openDesktopInfoDelay: !this.state.openDesktopInfoDelay });
    }, 500);
  };
  handleCloseInfoWindow = () => {
    this.setState({
      openDesktopInfo: false,
    });
    setTimeout(() => {
      this.setState({ openDesktopInfoDelay: false });
    }, 500);
  };

  onTouchStartHandler = ({ target }) => {
    if (
      this.state.isOpen &&
      !this.mobileInfoRef.current.contains(target) &&
      !target.contains(document.getElementById("Like-marker2-body")) &&
      !target.contains(document.getElementById("Like-marker2-link"))
    ) {
      this.setState({
        isOpen: false,
        showModal: false,
      });
    }
  };
  updateDimensions = () => {
    if (this.state.isOpen) {
      this.setState({ width: window.innerWidth });
    }
  };

  componentDidMount() {
    document.addEventListener("touchstart", this.onTouchStartHandler);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener("touchstart", this.onTouchStartHandler);
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { isOpen } = this.state;
    const { results } = this.props;
    const Address = results.address;
    const width = this.state.width ? this.state.width : window.innerWidth;
    const street1 = Address.street1 && Address.street2 ? `${Address.street1}, ` : Address.street1 ? Address.street1 : "";
    const street2 =
      Address.street2 && (Address.city || Address.state || Address.zipCode || Address.country)
        ? `${Address.street2}, `
        : Address.street2
        ? Address.street2
        : "";
    const city = Address.city && (Address.state || Address.zipCode || Address.country) ? `${Address.city}, ` : Address.city ? Address.city : "";
    const state = Address.state && (Address.zipCode || Address.country) ? `${Address.state} ` : Address.state ? Address.state : "";
    const zipCode = Address.zipCode && Address.country ? `${Address.zipCode} ` : Address.zipCode ? Address.zipCode : "";
    const country = Address.country ? Address.country : "";

    return (
      <>
        <Marker
          noRedraw
          position={{
            lat: results.location.lat,
            lng: results.location.lon,
          }}
          onClick={() => this.handleToggle(true)}
          icon={this.state.isOpen ? { url: "/images/cluster/active-marker-2.png" } : { url: "/images/cluster/location-marker-smaller.png" }}
        >
          {isOpen && width > 992 ? (
            // defaultOptions={{disableAutoPan: true}}
            <OutsideClickHandler onOutsideClick={() => this.handleToggle(false)}>
              <div ref={this.mobileInfoRef} className={`pc-info-window d-none d-lg-block ${this.state.openDesktopInfo ? "open-info" : ""}`}>
                <FaTimes onClick={() => this.handleToggle(false)} className="pointer cancel-marker" />
                <div onClick={this.handleOpenInfoWindow} className="colapse-info-block pointer">
                  <div>{this.state.openDesktopInfoDelay ? <FaCaretLeft /> : <FaCaretRight />}</div>
                </div>
                <div className={this.state.openDesktopInfo ? "collapse-open-block" : "collapse-close-block"}>
                  {/* <Collapse isOpen={this.state.openDesktopInfo}> */}
                  <div className="marker-block">
                    {results.logo ? (
                      <img className="marker-img-big d-flex horizontal-align" src={results.logo} alt="" />
                    ) : (
                      <div className="marker-img-big d-flex horizontal-align" />
                    )}
                    {results.logo ? <div className="text-center f-s-14">{results.name}</div> : <h3 className="text-center f-s-14">{results.name}</h3>}
                    <div className="striped-solid" />
                    <div className="row address-block">
                      <div className="col-1 marker-logo vertical-align">
                        <FaMapMarkerAlt />
                      </div>
                      <div className="col middle-address-block">
                        <div className="address">
                          <div className="street1">
                            <a target="_blank" href={`https://www.google.com/maps/?q=${results.location.lat},${results.location.lon}`}>
                              {street1} {street2} {city} {state} {zipCode} {country}{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="striped-solid" />
                    <div className="company-profile-btn d-flex">
                      <Button className="horizontal-align" color="primary">
                        <a target="_blank" href={`/company/${results.id}`}>
                          <b>See Profile</b>
                        </a>
                      </Button>
                    </div>

                    <div style={{ display: "flex" }}>
                      <a
                        className="margin-auto"
                        target="_blank"
                        href="mailto:iotmap@iotnation.com?subject=IoT Map: please Edit Our Company Entry on the IoT Map"
                      >
                        Edit This Company
                      </a>
                      {/* <div style={{display: "flex"}} className="rigth-side likes-block">
                                    <div className="likes">
                                        {this.state.likeLength}
                                    </div>
                                    <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                                        {this.props.user?'':
                                            <RestrictedPopover forRef={this.mobileLikeInfoRef} tog={this.tog} showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id='Like-marker1' />
                                        }
                                        {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                    </Button>
                                </div> */}
                    </div>
                  </div>
                </div>
                {/* </Collapse> */}
              </div>
            </OutsideClickHandler>
          ) : isOpen && width <= 992 ? (
            <div className="d-block d-lg-none">
              <OutsideClickHandler useCapture={true} onOutsideClick={() => this.handleToggle(false)}>
                <div ref={this.mobileInfoRef} className="mobile-info-block">
                  <div id="mobile-info-block" className={width < 540 ? "marker-block container" : "marker-block container p-0"}>
                    <div style={{ display: "flex", borderBottom: "1px solid #e2e6ee", padding: "10px 0 16px 0" }}>
                      {results.logo ? <img className="marker-img" src={results.logo} alt="" /> : ""}
                      {results.logo ? (
                        <div className="d-flex vertical-align f-s-14">{results.name}</div>
                      ) : (
                        <h3 className="d-flex vertical-align f-s-14">{results.name}</h3>
                      )}
                      <FaTimes onClick={() => this.handleToggle(false)} className="pointer cancel-marker" />
                    </div>
                    <div className="row address-block">
                      <div className="col-1 marker-logo">
                        <FaMapMarkerAlt />
                      </div>
                      <div className="col middle-address-block">
                        <div className="address">
                          <div className="street1">
                            <a target="_blank" href={`https://www.google.com/maps/?q=${results.location.lat},${results.location.lon}`}>
                              {street1} {street2} {city} {state} {zipCode} {country}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-2 likes-block">
                                    <div className="likes">
                                        <div className="display-flex">
                                            {this.state.likeLength}
                                        </div>
                                        <Button onClick={(() => this.likeHandle())} color="link" className="likes">
                                            {this.props.user?'':
                                                <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.toggleModal} id='Like-marker2' />
                                            }
                                            {this.state.thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                                        </Button>
                                    </div>
                                </div> */}
                    </div>
                    <div className="company-profile-btn">
                      <Button color="primary">
                        <a target="_blank" href={`/company/${results.id}`}>
                          <b>See Profile</b>
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          ) : (
            ""
          )}
        </Marker>
      </>
    );
  }
}
