export default async function getSuggestions(input) {
        if(window.google) {
            const google = window.google
            const service = new google.maps.places.AutocompleteService();
            const checkSecondaryText = (text) => {
                if(text){
                    return (`,+${text.replace(" ","+")}`)
                }else{
                    return("")
                }
            }
            const data = await new Promise((resolve, reject)=>service.getPlacePredictions({
                input,
                types:['(regions)'],// ['geocode'], (cities)
            },(predictions, status)=>{ 
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    if (predictions.length > 0) {
                        resolve(
                            predictions.map( (prediction)=>({
                                value:prediction.place_id,
                                label: prediction.structured_formatting.main_text,
                                longLabel: prediction.structured_formatting.secondary_text,
                                url: `${prediction.structured_formatting.main_text.replace(" ","+")}${checkSecondaryText(prediction.structured_formatting.secondary_text)}`
                            }))
                        )
                    }
                }else{
                    reject(status)
                }
            }))
            return data
        }
}


export async function getPlaceLatLong(placeId){
    if(window.google) {
        const google = window.google
        const service = new google.maps.Geocoder()

        const data = await new Promise((resolve, reject)=>service.geocode({
            placeId,
        }, (place, status)=>{ 
            if (status === google.maps.GeocoderStatus.OK) {
                resolve({
                    lat:place[0].geometry.location.lat(),
                    lon:place[0].geometry.location.lng()
                })
            }else{
                reject(status)
            }
        }))

        
        return data
    }
}
            
