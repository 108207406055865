import React from "react";
import {
  Input } from 'reactstrap';

export default class SchemaSelect extends React.PureComponent {     

    handleChange = (event)=> {
        this.props.setQuery(event.target.value);
    }
    render() {

        const { fieldOptions, initialValue } = this.props;
        if (fieldOptions.length === 0 || (fieldOptions.length === 1 && fieldOptions[0] === '')){
            return ''
        }else{
            return (
                <div className={`margin-r-8 bigger-padding break`}>
                    <Input value={initialValue} type="select" name="value" onChange={this.handleChange}>
                        {fieldOptions.map((value, index) => {
                            return <option key={index}>{value}</option>
                        })}
                    </Input>
                </div>
            )    
        }
        
    }
}