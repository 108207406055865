import React from "react";
import Company from "./Company.jsx";
import GeneratedApi from "../components/functions/generatedApi.js";

export default class SearchContainer extends React.Component {
  constructor(data) {
    super(data);
    this.state = {
      companyId: data.match.params.companyId,
      loading: true,
    };
  }

  componentDidMount() {
    GeneratedApi("", true, true)
      .get(`companies/${this.state.companyId}`)
      .then((response) => {
        this.setState({
          response: response.data.body.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return <Company data={this.state} user={this.props.user} />;
  }
}
