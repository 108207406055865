import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import Report from './Report'
import dataJSON from '../content/reports.json'

const ReportContainer = () => {
  const [ formData, setFormData ] = useState({
    email: "",
    firstName: "",
    lastName: '',
    phoneNumber: '',
    jobTitle: '',
    company: ''
  })
  const [ redirect, setRedirect ] = useState(false)

  const handleInputChange = (event) => {
    event.preventDefault();
    const { value, name } = event.target
    const data = formData
    data[name] = value;
    setFormData({...formData, ...data});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setRedirect(true)
  }

  const handleChangePhone = (iti) => {
    const data = formData
    data.phoneNumber = iti.getNumber()
    setFormData({...formData, ...data});
  }

  const filteredJSON = dataJSON.filter((item) => item["button-link"] === window.location.pathname)

  if( filteredJSON.length === 0 ) {
    return <Redirect to="/reports" />
  }
  if( redirect && filteredJSON ) {
    return <Redirect to={{pathname: `${window.location.pathname}/download`}} />
  }
  return (
    <Report
      dataJSON={filteredJSON[0].report}
      formData={formData}
      handleChangePhone={handleChangePhone}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />
  )
}

export default ReportContainer
