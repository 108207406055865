import qs from "query-string";
import _, { forEach } from "lodash";

export default function loadSearchLocationData({ attributes }) {
  let queryObj = qs.parse(window.location.search);
  if (queryObj.length === 0) {
    return;
  }
  let filtersArr = Object.keys(queryObj)
    .map((item) => {
      if ("filter" === item.replace(/[0-9]/g, "")) {
        let tempArr = queryObj[item].split("_");
        let generatedData = {
          attribute: tempArr[0].replace(" theAnd ", " & "),
          operator: tempArr[1].replace(" theAnd ", " & "),
          value: tempArr[2].replace(" theAnd ", " & "),
        };
        if (tempArr[3]) {
          for (let i = 4; i <= tempArr.length; i = i + 3) {
            generatedData = {
              ...generatedData,
              [`attribute${(i + 2) / 3}`]: tempArr[i - 1].replace(
                " theAnd ",
                " & "
              ),
              [`operator${(i + 2) / 3}`]: tempArr[i].replace(" theAnd ", " & "),
              [`value${(i + 2) / 3}`]: tempArr[i + 1].replace(
                " theAnd ",
                " & "
              ),
            };
          }
        }
        return generatedData;
      }
      return false;
    })
    .filter(
      (val) =>
        val.attribute === "Classification" ||
        val.attribute === "Classification-2" ||
        (val !== null &&
          typeof val === "object" &&
          _.has(attributes, [val.attribute, val.operator]))
    );
  let { q = "", currentPage } = queryObj;
  currentPage = !isNaN(Number(currentPage)) ? currentPage : 1;
  return {
    query: q,
    advancedFilters: filtersArr,
    currentPage: currentPage,
  };
}
