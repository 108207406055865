import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './InvestmentsBlock.scss'

export default class InvestmentsBlock extends Component {
    render() {
        return (
            <div className="d-flex mt-32 mb-32 InvestmentsBlock">
                <div className="img-border d-none d-md-flex">
                    <img src="/images/globe-1.svg" alt=""/>
                </div>
                <div className="text">
                    <div className="title">Get access to the full IoT Nation Ecosystem Database</div>
                    <div className="description">Companies, Use Cases, Events, News, Contacts, and more!</div>
                </div>
                <Link className="my-auto mr-0 ml-auto" to="/iotace">
                    <button>Subscribe</button>
                </Link>
            </div>
        )
    }
}
