import React from 'react';
// import { FaClock } from 'react-icons/fa';

import './Error.scss';

export default class ErrorPage extends React.PureComponent{
    render() {
        return (
            <div class="error-page" style={{minHeight: '100vh'}}>
                <div class="container">
                    <div class="row">
                        <div class="col">  
                            <div className="content"> 
                                <h1>You have reached the data limit. Please try again later.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}