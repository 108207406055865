import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import {  Popover, PopoverBody } from 'reactstrap';
import './RestrictedPopover.scss'
export default class RestrictedPopover extends Component {

    render() {
        const { id, showModal, handleCloseModal, classN, classN2} = this.props
        return (
          <div className={classN2 && classN2} >
            <div className={classN2 && classN2} id={id?id:'restricted1'} ></div>
            <Popover className={`smallPopoverRestricted ${classN && classN}`} placement="top" isOpen={showModal} target={id?id:'restricted1'} toggle={this.toggle}>
              <OutsideClickHandler onOutsideClick={handleCloseModal} >
              
                {
                  id.includes('Like-')? <PopoverBody id={`${id}-body`}>Get access to the Like feature when you <a id={`${id}-link`} href="/signup">Register</a>.</PopoverBody>:
                  id.includes('AdvancedFilters-')? <PopoverBody id={`${id}-body`}>To access unlimited filters and more results <a id={`${id}-link`} href="/signup">Sign up</a></PopoverBody>:
                  id.includes('GetAccess-')? <PopoverBody id={`${id}-body`}>Get access to editing features when you <a id={`${id}-link`} href="/signup">Register</a></PopoverBody>:
                  <PopoverBody id={`${id}-body`}>Search or add filters and see up to 5 results. <a id={`${id}-link`} href="/signup">Sign up</a> and unlock 1,500 companies for free!</PopoverBody>
                }
              
              </OutsideClickHandler>
            </Popover>
          </div>
        )
    }
}
