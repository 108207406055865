import React from 'react';
import { FaCheck, FaMinus } from 'react-icons/fa';

export default function ShowValue(props) {
    if(props.user) {
        if(props.user['cognito:groups']) {
            if(props.user['cognito:groups'].indexOf('pro') >= 0 || props.user['cognito:groups'].indexOf('premium') >= 0) {
                if(props.data !== 'plain') {
                    return <FaCheck/>;
                }
                return <FaMinus/>;
            }
        }
    }
    if(props.data === 'plain') {
        return <FaMinus/>;
    }
    return props.data;
}