/*global Intercom*/
import React from "react";
import "./Profile.scss";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import GeneratedApi from "../components/functions/generatedApi";

export default class Profile extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      userData: null,
      limits: null,
      expiration: null,
      activationCode: "",
    };
    this.handleContactButtonClick = this.handleContactButtonClick.bind(this);
  }

  handleContactButtonClick() {
    Intercom("show");
  }
  componentDidMount() {
    GeneratedApi("", true, true)
      .get("/userprofile")
      .then((response) => {
        this.setState({
          userData: response.data.account_info,
          limits: response.data.usage,
          legalAgreements: response.data.legal_agreements,
          expiration: response.data.expiration,
        });
      });
  }
  SplitTime = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };
  handleActivationCode = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    this.setState({ activationCode: value });
  };
  render() {
    const { userData, legalAgreements, expiration } = this.state;
    if (!userData) {
      return <Spinner height={"calc( 100vh - ( 64px + 67px ) )"} />;
    }
    return (
      <div className="profile container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Profile | IoT Nation</title>
        </Helmet>
        <div className="container p-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Pricing
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-8 offset-sm-2">
          <h1>Account Information</h1>
          <div className="row">
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>Current Plan</td>
                    <td>
                      <b>{userData.usergroup}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Expiration</td>
                    <td>
                      <b>{typeof expiration.timeLeft === "number" ? this.SplitTime(expiration.timeLeft * -1) : ""}</b>
                    </td>
                  </tr>
                  {/* <tr>
                                        <td>Username</td>
                                        <td><b>{userData.username}</b></td>
                                    </tr> */}
                  <tr>
                    <td>Name</td>
                    <td>
                      <b>{userData.name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Company</td>
                    <td>
                      <b>{userData.companyName}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Add Activation Code</td>
                    {/* <input value={formData.code} name="code" required  className="form-control" placeholder="Code" onChange={handleChange}/> */}
                    <td className="d-flex">
                      <input
                        name="code"
                        onChange={this.handleActivationCode}
                        className="form-control d-flex activation-code"
                        placeholder="Add Activation Code"
                      />
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href={`mailto:upgrades@iotnation.com?subject=Add Activation Code: ${
                          this.state.activationCode
                        }&body=Please apply the activation code in the subject line to my account.`}
                      >
                        <button className="blue">Add Activation Code</button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <b>{userData.email}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      <b>{userData.phone}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Password</td>
                    <td>
                      <b>*************</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col button-block">
              <a className="blue" target="_blank" href="mailto:subscriptions@iotnation.com">
                Payment and Billing
              </a>
            </div>
            <div className="col button-block">
              <Link className="blue" to="/iotace">
                Upgrade
              </Link>
            </div>
            <div className="col button-block">
              <button onClick={this.handleContactButtonClick} className="blue" href="#">
                Contact Admin
              </button>
            </div>
          </div>
        </div>
        {/* <div className="col-8 offset-sm-2">
                    <h1>Usage Limit</h1>
                    <div className="row">
                        <div className="col">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>5 - Minute Limit</td>
                                        <td><b>{limits.minuteusage}/{limits.minutelimit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>1 - Hour Limit</td>
                                        <td><b>{limits.hourusage}/{limits.hourlimit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>1 - Day Limit</td>
                                        <td><b>{limits.dayusage}/{limits.daylimit}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col button-block">

                            <button className="blue" >Payment and Billing</button>                      
                        </div>
                        <div className="col button-block">
                            <button onClick={this.handleContactButtonClick} className="blue" href="#">Contact Admin</button>                      
                        </div>
                    </div>
                </div> */}
        <div className="col-8 offset-sm-2">
          <h1>Legal Agreements</h1>
          <div className="row mb-3">
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>Privacy Policy and terms of Service</td>
                    <td>Status: {legalAgreements.tos ? "Accepted" : "Declined"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
