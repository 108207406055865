import Cookies from "universal-cookie";
import * as jwtDecode from "jwt-decode";
import intercom from "../../utils/intercom";
import logoutChecker from "./logoutChecker";
import GeneratedApi from "./generatedApi";

const cookies = new Cookies();

const Login = (propToken = "", saveState) => {
  const allCookies = cookies.getAll();
  const token = propToken ? propToken : allCookies["jwtToken"] ? allCookies["jwtToken"] : "";
  const decodeToken = token ? jwtDecode(token) : "";
  let key;
  let obj = window.localStorage;

  if (decodeToken) {
    if (process.env.REACT_APP_JSON_TYPE === "production") {
      if (allCookies["PSId"]) {
        saveState({ user: decodeToken });
        if (!window.localStorage[`CognitoIdentityServiceProvider.${allCookies["PSId"]}.idToken`]) {
          for (key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (key.includes("CognitoIdentityServiceProvider")) {
                localStorage.removeItem(key);
              }
            }
          }
          for (key in allCookies) {
            if (key.includes("CognitoIdentityServiceProvider")) {
              localStorage.setItem(key, allCookies[key]);
            }
            if (key === "jwtToken") localStorage.setItem(`CognitoIdentityServiceProvider.${allCookies["PSId"]}.idToken`, allCookies[key]);
            if (key === "refreshToken") localStorage.setItem(`CognitoIdentityServiceProvider.${allCookies["PSId"]}.refreshToken`, allCookies[key]);
          }
        }
      } else {
        for (key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (key.includes("CognitoIdentityServiceProvider")) {
              cookies.remove(key, {
                path: "/",
                domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
                expires: new Date(Date.now() + 86400000),
                sameSite: false,
              });
            }
          }
        }
        cookies.remove("jwtToken", {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
        cookies.remove("refreshToken", {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
      }
    } else {
      if (allCookies["DSId"]) {
        saveState({ user: decodeToken });
        if (!window.localStorage[`CognitoIdentityServiceProvider.${allCookies["DSId"]}.idToken`]) {
          for (key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (key.includes("CognitoIdentityServiceProvider")) {
                localStorage.removeItem(key);
              }
            }
          }
          for (key in allCookies) {
            if (key.includes("CognitoIdentityServiceProvider")) {
              localStorage.setItem(key, allCookies[key]);
            }
            if (key === "jwtToken") localStorage.setItem(`CognitoIdentityServiceProvider.${allCookies["DSId"]}.idToken`, allCookies[key]);
            if (key === "refreshToken") localStorage.setItem(`CognitoIdentityServiceProvider.${allCookies["DSId"]}.refreshToken`, allCookies[key]);
          }
        }
      } else {
        for (key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (key.includes("CognitoIdentityServiceProvider")) {
              cookies.remove(key, {
                path: "/",
                domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
                expires: new Date(Date.now() + 86400000),
                sameSite: false,
              });
            }
          }
        }
        cookies.remove("jwtToken", {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
        cookies.remove("refreshToken", {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
      }
    }

    GeneratedApi(token, true)
      .get("/userprofile")
      .then((response) => {
        const res = response.data;
        saveState({
          tos: {
            firstName: res.account_info.firstName,
            lastName: res.account_info.lastName,
            companyName: res.account_info.companyName,
          },
          fullProfile: res.account_info,
          legalAgreements: res.legal_agreements,
          limits: res.usage,
        });
        intercom(res.account_info, res.usage, res.expiration, res.legal_agreements.tos);
      })
      .catch((error) => {
        console.log(error);
      });
    GeneratedApi(token, true)
      .get("/usertos")
      .then((res) => {
        saveState({
          agreenment: res.data,
          tosModalIsOpen: !res.data.status,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    logoutChecker(decodeToken, saveState);
  }

  return "";
};

export default Login;
