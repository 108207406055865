import React, { useRef, useLayoutEffect, useState } from 'react'
import { PieChart, Pie, Label, Legend, Cell, ResponsiveContainer } from 'recharts';
                  
function DonutChart(props){

    const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  useLayoutEffect(()=>{
    const handleResize = () => setContainerWidth(containerRef.current.container.clientWidth);
    const handleResizeH = () => setContainerHeight(containerRef.current.container.clientHeight);
    window.addEventListener('resize', handleResize, handleResizeH);
    if(containerRef && containerRef.current){
        setContainerWidth(containerRef.current.container.clientWidth)
        setContainerHeight(containerRef.current.container.clientHeight)
    }
    return () => {
        window.removeEventListener('resize', handleResize, handleResizeH);
        };
    })

        const {score, colors}=props
        const data = [{name: 'Iot Nation Score', value:score.score }, {name: 'Grown', value: 0},
                  {name: 'Empty', value: score.max-score.score}]
        //const radian = Math.PI / 180;  
        if(containerHeight > 200){
            return (
                <ResponsiveContainer className="border rounded background-white-2" ref={containerRef} width="100%" height="100%">        
                    <PieChart>
                        <Pie
                        dataKey={"value"}
                        startAngle={360+90}
                        endAngle={90}
                        data={data} 
                        cx={containerWidth/2}
                        innerRadius={375/7}
                        outerRadius={375/6}  
                        //cornerRadius={7}
                        //paddingAngle={-5}
                        //minAngle={20}
                        >
                            <Label
                                value={score.score}
                                position="center"
                                fontSize= '24px'
                                fontWeight={500}
                            />
                            {
                                data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}/>)
                            }
                        </Pie>
                        <Legend layout="vertical" align="center"  verticalAlign="top"  content={(props)=>{
                        return (
                        <div className="text">
                            <p className="iot mt-3">IoT Ace™ Market Visibility Performance (MVP) Score</p>
                        </div> 
                        )
                }} />
                    </PieChart>
                    
                </ResponsiveContainer>
            )
        }else{
            return (
                <ResponsiveContainer ref={containerRef} width="100%" height="100%">        
                    <PieChart>
                        <Pie
                        dataKey={"value"}
                        startAngle={360+90}
                        endAngle={90}
                        data={data} 
                        cx={24+10+44.7} 
                        cy={170/2} 
                        innerRadius={34}
                        outerRadius={44.7} 
                        //cornerRadius={7}
                        //paddingAngle={-5}
                        //minAngle={20}
                        >
                            <Label
                                value={score.score}
                                position="center"
                                fontSize= '24px'
                                fontWeight={500}
                             />
                            {
                                data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}/>)
                            }
                        </Pie>
                        <Legend layout="vertical" align="right"  verticalAlign="middle"  content={(props)=>{
                            return (
                            <div className="text">
                                <p className="iot mt-3">IoT Ace™ Market Visibility Performance (MVP) Score</p>
                            </div> 
                            )
                        }} />
                    </PieChart>
                    
                </ResponsiveContainer>
            )
        }
    
}
export default DonutChart