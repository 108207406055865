import React from 'react'
import ReportDownload from './ReportDownload'

const ReportDownloadContainer = () => {

  return (
    <ReportDownload

    />
  )
}

export default ReportDownloadContainer
