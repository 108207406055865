import React from 'react';
import * as sortObjectsArray from 'sort-objects-array';
import {Link} from 'react-router-dom'
import FakeData from '../../../components/FakeData/FakeData';
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';

import ContactRow from './ContactRow';
import SortableHeader from '../../../components/ResultsTable/SortableHeader/SortableHeader';
import './ContactTable.scss';


export default class ContactTable extends React.Component {
    constructor() {
        super();
        this.state = {
            sort: {
              attribute: 'name',
              sort: 'asc'
            }
        }

        this.handleSortChange = this.handleSortChange.bind(this);
    }

    handleSortChange(attribute,sort) {
        this.setState({
            sort: {
                attribute,
                sort
            }
        });
    }

    renderFakeContacts(){
        let arr=[];
        for(let i = 0; i < 10; i++){
            arr.push({
                firstName:FakeData({toFake:'name.firstName', returnJustValue: true}),
                lastName:FakeData({toFake:'name.lastName', returnJustValue: true}),
                title:FakeData({toFake:'lorem.words', returnJustValue: true}),
                email:{
                    workEmail:FakeData({toFake:'internet.email', returnJustValue: true}),
                },
                linkedin:FakeData({toFake:'internet.url', returnJustValue: true}),
                name:FakeData({toFake:'name.findName', returnJustValue: true}),
                address:{
                    city:FakeData({toFake:'address.city', returnJustValue: true}),
                    country:FakeData({toFake:'address.country', returnJustValue: true}),
                }
            })
        }
        return arr;
    }

    render() {
        const { sort } = this.state;
        let fakedContacts =false;
        var contacts  = this.props.contacts?this.props.contacts:this.renderFakeContacts();

        if(!contacts) {
            return false;
        }

        if(!this.props.contacts){
            fakedContacts = true;
        }

        contacts.forEach((contact) => {
            contact.name = `${contact.lastName}, ${contact.firstName}`;
        });
        contacts = sortObjectsArray(contacts, sort.attribute, sort.sort);
        return (
            <div className="card contacts">
                <div className="card-header">
                    <div className="icon contacts"><img src="/images/multiple-2.svg" alt="multiple-icon"/></div>
                    <div className="title-holder"><p id="contacts" >Company & Organization Contacts</p></div>
                </div>
                <div className="d-none d-md-block menu-divider"></div>
                <div className="d-none d-md-block">
                    <table className={fakedContacts?'blurred-box':''}>
                        <thead>
                            <tr className="row">
                                <SortableHeader data={{name: 'Contact name', type: 'name', sort: sort, gridSize:'col-2'}} handleSortChange={this.handleSortChange}/>
                                <SortableHeader data={{name: 'Title', type: 'title', sort: sort, gridSize:'col-3'}} handleSortChange={this.handleSortChange}/>
                                <th className="col-3">Work Email</th>
                                <th className="col-2">Location</th>
                                <th className="col-2">Profile</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((contact, i) => {
                                return i < 15 ?( i % 2 === 0?  <ContactRow fakedContacts={fakedContacts} show={true} key={i} contact={contact}/>
                                : <ContactRow fakedContacts={fakedContacts} show={false} key={i} contact={contact}/> ): ''
                            }).filter(Boolean)}
                        </tbody>
                    </table> 
                    <div className="contacts-btn d-flex grey-border-t">
                        <button className="suggest-btn">
                        <Link to={{pathname: '/contacts/', search: `?q=&filter1=Name_Begins%20with_${this.props.profileName.replace(/ /g, '%20')}&limit=10&currentPage=1&fullProfiles=1`}}>{contacts.length > 15? 'View More Contacts': 'View Contacts'}</Link>
                        </button>
                    </div>
                    
                </div>
                <div className="d-block d-md-none">
                    <table>
                        <tbody>
                        {contacts.map((contact, i) => {
                                return i < 15 ?( i % 2 === 0?  <ContactRow fakedContacts={fakedContacts} show={true} key={i} contact={contact}/>
                                : <ContactRow fakedContacts={fakedContacts} show={false} key={i} contact={contact}/> ): ''
                            }).filter(Boolean)}
                        </tbody>
                    </table>
                    <div className="contacts-btn d-flex">
                        <button className="suggest-btn">
                            <Link to={{pathname: '/contacts/', search: `?q=&filter1=Name_Begins%20with_${this.props.profileName.replace(/ /g, '%20')}&limit=10&currentPage=1&fullProfiles=1`}}>{contacts.length > 15? 'View More Contacts': 'View Contacts'}</Link>
                        </button>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <SubscriberContent target="contactTable" hidden={fakedContacts}/>
                </div> 
            </div>
        );
    }
}

