import React, { Component } from 'react'
import Title from '../Title.jsx';

import Speaker from './Speaker.jsx';

import './SpeakersMiddle.scss';

export default class SpeakersMiddle extends Component {
 
  render() {
    return (
      <div className="SpeakersMiddle" id={this.props.id}>
        <Title src='/images/mic.svg' name={this.props.title}  />
        <div className="side-block-border  main-padding">
        {this.props.data.map((item, key)=>{
          const { image, firstName, linkedin, lastName, biography, name, title, company} = item.speaker?item.speaker:item
          return <Speaker key={key} src={image} firstName={firstName} 
          lastName={lastName} linkedin={linkedin}
          title={title} biography={biography}
          companyName={name} company={company} />
        }
          
        )}
        </div>
      </div>
    )
  }
}
