import React from "react";

import SearchInput from './SearchInput.jsx';
import SchemaSelect from './SchemaSelect.jsx';
import { isObject } from "util";
//import * as filterFields from '../../../assets/filterFields.json';

export default class Inputs extends React.PureComponent {
    constructor() {
        super();

        this.setValue = this.setValue.bind(this);
    }
    
    setValue(value, update) {
        this.props.setQuery(value, update);
    }

    componentDidUpdate({dependsOn: prevDependsOn}){
        
        // const { dependsOn, setQuery } = this.props 
       
        // if (
        //     dependsOn && dependsOn.parrentAttribute === 'Year' && 
        //     (dependsOn.value !==  prevDependsOn.value)
        // ){
        //     setQuery(null);
        // }
    }


    render() {
        let { fieldOptions, filterFields, initialValue, dependsOn,  canGetMoreWidth = false } = this.props;

        if(fieldOptions.value.type === 'dropdown' || fieldOptions.value.type === 'mult') {
            let schemaValues = [];
            fieldOptions.value.schemas.forEach((schema) => {  
                if (!dependsOn || dependsOn.parrentAttribute !== 'Year'){
                    schemaValues = schemaValues.concat(filterFields.schemas[schema].map((val, i)=>{
                        if(isObject(val)){
                            return val.name
                        }else{
                            
                            return val
                        }

                    }))
                }else if (dependsOn.parrentAttribute === 'Year'){
                    const filtered = filterFields.schemas[schema].filter((val)=>val.name===dependsOn.value)
                    schemaValues = filtered[0]?filtered[0].years:[]
                }
                
            });
                return <SchemaSelect canGetMoreWidth={canGetMoreWidth}  initialValue={initialValue}  fieldOptions={schemaValues} setQuery={this.setValue}/>
        }
        fieldOptions = Object.entries(fieldOptions);
        if(fieldOptions)
        return (
            fieldOptions.map(([key,value]) => {
                    return <SearchInput key={key} dependsOn={dependsOn} initialValue={initialValue} fieldData={{key, value}}  setQuery={this.setValue}
                    locationOptions={this.props.locationOptions} locationFilter={this.props.locationFilter} handleLocationChange={this.props.handleLocationChange} handleLocationInput={this.props.handleLocationInput} handleSelectLocation={this.props.handleSelectLocation}
                    />
            })
        )
    }
}