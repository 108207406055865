import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Gallery.scss'
import moment from 'moment-timezone'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 3,
    }

  };

export default class Gallery extends Component {
    render() {
        const {title, galleryClass, results} = this.props

        return (
            <div className={`Gallery ${galleryClass}`}>
                <hr />
                <div className="gallery-title">{title}</div>
                <div className="gallery-image">
                <Carousel
                    className="Blog-gallery"
                    responsive={responsive}
                    draggable
                    autoPlaySpeed={300}
                    infinite={results.length > 2 ? true : false}
                    slidesToSlide={1}
                >
                    {
                        results && results.map((item, key) =>
                        <div key={key}>
                            <a href={`/blog/${item.slug}`}>
                            {item.jetpack_featured_media_url ?
                                <img src={item.jetpack_featured_media_url} alt=""/> : <div className="fake-img"></div>
                            }</a>
                            <div className="image-description">
                                <a href={`/blog/${item.slug}`}>
                                    <div dangerouslySetInnerHTML={{__html: item.title && item.title.rendered}} className="description-title" />    
                                </a>
                                {/* <div className="description-text">{item.content && `${item.content.rendered.replace(/['"]+/g, '')}`}</div> */}
                                <div  dangerouslySetInnerHTML={{__html: item.content.rendered}} className="description-text" />
                                {/* <div className="description-text"> {item.content && item.content.rendered.replace(/['"]+/g, '')} </div> */}
                                <div className="description-date">{item.date && `ON ${moment(item.date).format('MMMM DD, YYYY')}`}</div>
                            </div>
                        </div>
                        )
                    }
                    
                    
                </Carousel>
                </div>
            </div>
        )
    }
}
