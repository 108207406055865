import React from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom';
import getEventTypeIcon from '../functions/getEventTypeIcon'

function NewEvents({date, title, eventType, eventId}) {

    const startTime = moment.unix(date.startTime)
    const endTime = moment.unix(date.endTime)
    const endDateFormated = startTime.format('MMMM')===endTime.format('MMMM')?endTime.format('DD'):endTime.format('MMMM DD')
    const endDateFormated2 = startTime.format('MMMM DD')===endTime.format('MMMM DD')? null: '- ' + endDateFormated
    const dateFormated = startTime&&startTime.format('YYYY MMMM DD')

    return (
        <div className="event d-flex py-3">
            <div className="img-wrapper mt-2 text-center">
                <img src={getEventTypeIcon(eventType)} alt="" />
            </div>
            <div className="event-info px-3">
                <div className="mb-2">
                    <Link to={{
                        pathname: '/event/' + eventId, 
                        state: { 
                            prevQuery: window.location.href
                        } 
                        }}>{title}
                    </Link>
                </div>
                <div className="font-s-12">
                {dateFormated} {endDateFormated2}
                </div>
            </div>
        </div>
    )
}

export default NewEvents
