import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import SubscriberContent from '../../../components/SubscriberContent/SubscriberContent';

import './Situation.scss'
export default class Situation extends Component {
    render() {
        const situation = this.props.situation
        const user = this.props.user
        const type = !user
        return (
            <div className="Situation" id="situation">
                <div>
                    <CardsTitle image="/images/preview-2.svg" titleName={'Situation'} name="situation" />
                    <div className={user?`description`:'description not-login'}>
                        {situation}
                    </div>   
                </div>
                <SubscriberContent target="situation1" hidden={type} />
            </div>
        )
    }
}
