import React, { Component } from 'react'
import { FaLock } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import {  Popover, PopoverBody } from 'reactstrap';
import './SubscriberContent.scss'
export default class SubscriberContent extends Component {
    constructor(props){
        super(props)
        this.state = {
            showModal: false,
            subscriberHover: false,
            popoverHover: false
        }
    }
    handleShowModal = () => {
        this.setState({
            showModal: true
        })
    }
    handleCloseModal = () => {
        this.setState({
            showModal: false
        })
    }
    handleHoverSubscriber = (status) => {
        if(status === true || this.state.popoverHover === true){
            this.setState({subscriberHover: status, showModal: true})
        }else{
            setTimeout(() => {this.setState({subscriberHover: status})}, 500)
        }
    }
    handleHoverPopover = (status) => {
        if(status === true || this.state.subscriberHover === true){
            this.setState({popoverHover: status, showModal: true})
        }else{
            setTimeout(() => {this.setState({popoverHover: status})}, 500)
        } 
    }
    render() {
        const {target, content} = this.props
        if (!this.props.hidden) {
            return '';
        }
        return(
        <>
        <div className="subscriber-content" onMouseEnter={() => this.handleHoverSubscriber(true)} onMouseLeave={() => this.handleHoverSubscriber(false)} ><FaLock/> <div id={target} ></div>Subscriber content</div>
            {
                target &&
                <Popover onMouseLeave={() => this.handleHoverPopover(false)} onMouseEnter={() => this.handleHoverPopover(true)} className="popover-subscriber" placement="top" isOpen={this.state.subscriberHover || this.state.popoverHover} target={target} toggle={this.toggle}>
                    <OutsideClickHandler onOutsideClick={this.handleCloseModal} >
                        {
                            content? <PopoverBody>Upgrade to Access Contacts</PopoverBody> :
                            <PopoverBody>To access subscriber content you need to <a href="/signup">Sign up</a> or <a href="/login">Login</a></PopoverBody>
                        }
                    </OutsideClickHandler>
                </Popover>
            }
             
        </>
        )
    }

} 