import React from "react";
// import {Link} from 'react-router-dom'
import { Redirect } from "react-router";
import Search from "./Search.jsx";
import getSuggestions from "./functions/getSuggestions";
import debounce from "lodash/debounce";
import _ from "lodash";
import "../components/scss/breadcrumbs.scss";
import aFC from "../assets/aFC.json";
import filterCheckbox from "../components/AdvancedFilters/Functions/filterCheckbox";
import clearFilter from "../components/AdvancedFilters/Functions/clearFilter";
import clearAllFilters from "../components/AdvancedFilters/Functions/clearAllFilters.jsx";
import selectLocation from "../components/AdvancedFilters/Functions/selectLocation.jsx";
import locationChange from "../components/AdvancedFilters/Functions/locationChange.jsx";
import advancedFilterCleaning from "../components/AdvancedFilters/Functions/advancedFilterCleaning.jsx";
import loadSearchLocationData from "../components/AdvancedFiltero/components/functions/loadSearchLocationData.js";
import advancedFilterUrlGenerator from "../components/AdvancedFiltero/components/functions/advancedFilterUrlGenerator.js";
import GeneratedApi from "../components/functions/generatedApi.js";
import flattenObject from "../components/AdvancedFilters/Functions/flattenObject.js";
class SearchContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      downloadData: "",
      firstLoad: true,
      TotalFundingAdded: false,
      locationFilter: [],
      LocationArray: "",
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      locationCount: 0,
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      count: 0,
      searchResults: [],
      loading: true,
      currentPage: 1,
      limitReached: false,
      query: "",
      advancedFilters: [],
      sort: {
        attribute: "rank",
        how: "asc",
      },
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getSchemas();
    const data = loadSearchLocationData(this.state.filterFields);

    await this.setState({ query: data.query, currentPage: data.currentPage });
    this.updateSearchResults("", data.advancedFilters);
  }

  getSchemas = async () => {
    await GeneratedApi("", false)
      .get("/schemas/companyFilter")
      .then((res) => {
        if (this._isMounted === true) {
          const reso = res.data.schemas;
          reso.organizations = [
            { name: " ", years: [""] },
            ...reso.organizations,
          ];
          reso.ecosystems = [{ name: " ", years: [""] }, ...reso.ecosystems];
          reso.conferences = [{ name: " ", years: [""] }, ...reso.conferences];
          reso.technologies = ["", ...reso.technologies];
          reso.verticals = ["", ...reso.verticals];
          reso["private/public"] = ["", ...reso["private/public"]];
          this.setState({
            filterFields: {
              ...aFC,
              schemas: reso,
              classificationArray: flattenObject(reso.classification),
            },
          });
        }
      });
  };

  updateState = (item) => {
    this.setState(item);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.query !== prevState.query) {
      this.setState({ currentPage: 1 });
    }
    if (this._isMounted && this.props.location !== prevProps.location) {
      this.updateSearchResults();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePageChange = async (offset, currentPage) => {
    await this.setState({ currentPage });
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      currentPage,
      this.props.history
    );
  };

  updateSearchResults = (attribute, data) => {
    this.setState({ loading: true });
    const { currentPage, query, sort } = this.state;
    const { user } = this.props;
    const filterData = data
      ? advancedFilterCleaning(data, user)
      : advancedFilterCleaning(this.state.advancedFilters, user);
    this.setState({ advancedFilters: filterData.advancedFilters });

    const q = {
      search: {
        q: query,
        start: user ? Math.ceil((currentPage - 1) * (user ? 10 : 5)) : 0,
        limit: user ? 10 : 5,
      },
      sort,
      filters: [
        ...filterData.dataForPost,
        { attribute: "Company Rank", operator: "Less than", value: "40000" },
      ],
    };
    if (attribute) {
      q.sort = {
        attribute,
        how:
          sort.attribute === attribute
            ? sort.how === "asc"
              ? "desc"
              : "asc"
            : "asc",
      };
      this.setState({ sort: q.sort });
    }
    GeneratedApi("", true, true)
      .post("/companies/gridView", q)
      .then((response) => {
        response = response.data.body.data;
        this.setState({
          searchResults: response.searchResults,
          loading: false,
          firstLoad: false,
          count: response.count,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, firstLoad: false });
        if (error.response && error.response.status === 429) {
          this.setState({ limitReached: true });
          return false;
        }
        console.log(error);
      });
  };

  handleQueryChange = (event) => {
    event.preventDefault();
    this.setState({
      query: event.target.value,
    });
  };

  handleAdvancedFilterChange = async (filter, key, update = false) => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        { ...filter },
        ...advancedFilters.slice(key + 1),
      ],
    });
    if (!update || update === "timer") {
      advancedFilterUrlGenerator(
        this.state.query,
        this.state.advancedFilters,
        this.state.currentPage,
        this.props.history
      );
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };

  handleAddFilter = async () => {
    const { advancedFilters } = this.state;
    await this.setState({
      advancedFilters: [
        ...advancedFilters,
        {
          attribute: "Name",
          operator: "Begins with",
          value: "",
        },
      ],
    });
  };

  handleClearSpecificFilter = async (key) => {
    this.setState({ loading: true });
    const { advancedFilters } = this.state;
    if (advancedFilters[key].attribute === "Location") {
      this.setState({ locationFilter: [] });
    }
    await this.setState({
      advancedFilters: [
        ...advancedFilters.slice(0, key),
        ...advancedFilters.slice(key + 1),
      ],
    });
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };

  handleClearFilters = async () => {
    const advancedFilters = [];
    await this.setState({
      advancedFilters,
      locationFilter: [],
    });
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };

  handleLocationInput = (input, { action }) => {
    if (action === "input-change" && input.length > 0) {
      debounce(
        () =>
          getSuggestions(input).then((locationOptions) =>
            this.setState({ locationOptions })
          ),
        500
      )();
    }
  };

  handleLocationChange = async (value) => {
    await this.setState(locationChange(this.state.advancedFilters, value));
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };

  handleSelectLocation = async (value) => {
    await this.setState(selectLocation(value, this.state.advancedFilters));
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };

  handleFilterCheckbox = async (event) => {
    const {
      checkedFilter,
      advancedFilters,
      LocationArray,
      totalFcount,
      technologiesCount,
      verticalsCount,
      employeeCount,
      publicPrivateCount,
    } = this.state;
    const counter = {
      totalFcount: totalFcount,
      technologiesCount: technologiesCount,
      verticalsCount: verticalsCount,
      employeeCount: employeeCount,
      publicPrivateCount: publicPrivateCount,
    };
    await this.setState(
      filterCheckbox(
        event,
        checkedFilter,
        advancedFilters,
        LocationArray,
        counter
      )
    );

    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };
  handleClearFilter = async (filter) => {
    const {
      advancedFilters,
      checkedFilter,
      LocationArray,
      locationFilter,
    } = this.state;
    await this.setState(
      clearFilter(
        filter,
        advancedFilters,
        checkedFilter,
        LocationArray,
        locationFilter
      )
    );
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };
  handleClearAllFilters = async () => {
    await this.setState(clearAllFilters());
    advancedFilterUrlGenerator(
      this.state.query,
      this.state.advancedFilters,
      this.state.currentPage,
      this.props.history
    );
  };
  validateSchemas = (whichSchemas, schemas, validatedValue, dependsOn) => {
    let schemaValues = [];
    whichSchemas.forEach((schema) => {
      if (!dependsOn || dependsOn.parrentAttribute !== "Year") {
        schemaValues = schemaValues.concat(
          schemas[schema].map((val, i) => {
            if (val !== null && typeof val === "object") {
              return val.name;
            } else {
              return val;
            }
          })
        );
      } else if (dependsOn.parrentAttribute === "Year") {
        const filtered = schemas[schema].filter(
          (val) => val.name === dependsOn.value
        );
        schemaValues = filtered[0] && filtered[0].years;
      }
    });

    return _.includes(schemaValues, validatedValue);
  };
  downloadApi = async () => {
    const q = {
      search: {
        q: "",
        start: 0,
        limit: 100,
      },
      sort: {
        attribute: "rank",
        how: "asc",
      },
      filters: [
        ...this.state.advancedFilters,
        { attribute: "Company Rank", operator: "Less than", value: "40000" },
      ],
    };

    await GeneratedApi("", true, true)
      .post("/companies/download", q)
      .then((response) => {
        response = response.data;

        let csv = response;
        let downloadLink = document.createElement("a");
        let blob = new Blob(["\ufeff", csv]);
        let url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Company Data.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (this.state.limitReached) {
      return <Redirect to="/error" />;
    }
    const aFHandle = {
      handleAdvancedFilterChange: this.handleAdvancedFilterChange,
      handleClearFilters: this.handleClearFilters,
      handleAddFilter: this.handleAddFilter,
      handleClearSpecificFilter: this.handleClearSpecificFilter,
      handleLocationChange: this.handleLocationChange,
      handleLocationInput: this.handleLocationInput,
      handleSelectLocation: this.handleSelectLocation,
    };
    const aFValue = {
      advancedFilters: this.state.advancedFilters,
      filterFields: this.state.filterFields,
      user: this.props.user,
      locationOptions: this.state.locationOptions,
      locationFilter: this.state.locationFilter,
    };
    const aFMobileHandle = {
      handleFilterCheckbox: this.handleFilterCheckbox,
      handleClearFilter: this.handleClearFilter,
      handleClearAllFilters: this.handleClearAllFilters,
    };
    const aFMobileValue = {
      checkedFilter: this.state.checkedFilter,
      totalFcount: this.state.totalFcount,
      technologiesCount: this.state.technologiesCount,
      verticalsCount: this.state.verticalsCount,
      employeeCount: this.state.employeeCount,
      publicPrivateCount: this.state.publicPrivateCount,
      locationCount: this.state.locationCount,
    };

    return (
      <>
        <Search
          firstLoad={this.state.firstLoad}
          advancedFiltersHandlers={aFHandle}
          advancedFiltersValues={aFValue}
          aFMobileHandle={aFMobileHandle}
          aFMobileValue={aFMobileValue}
          updatePropsState={this.updateState}
          history={this.props.history}
          api={this.props.api}
          handleQueryChange={this.handleQueryChange}
          user={this.props.user}
          query={this.state.query}
          sort={this.state.sort}
          data={{
            start: this.state.currentPage * 10 - 10,
            limit: this.props.user ? 10 : 5,
            count: this.state.count,
            loading: this.state.loading,
            searchResults: this.state.searchResults,
            currentPage: this.state.currentPage,
          }}
          handleSubmit={this.handleSubmit}
          handlePageChange={this.handlePageChange}
          handleSortChange={this.updateSearchResults}
          downloadApi={this.downloadApi}
        />
      </>
    );
  }
}
export default SearchContainer;
