import React, { Component } from 'react'
// import RestrictedPopover from '../../components/Modals/RestrictedPopoverSmall/RestrictedPopover'
// import { Button} from 'reactstrap';
// import { FaThumbsUp, FaRegThumbsUp} from 'react-icons/fa';
import moment from 'moment-timezone'

export default class NewsItem extends Component {
  constructor(props){
    super(props)
    this.state = {
        showModal: false
    }
  }
  toggleModal = () => {
      this.setState({showModal: !this.state.showModal})
  }
    render() {
        const { results} = this.props
        // const {likeLength,  thumbsUp, id, user} = this.props
        return (
            <>
              <div className="d-flex">
                  <div className="info">
                      {/* <div className="vertical-align d-flex">
                        <div className="vertical-align">
                          {likeLength? likeLength : 0}
                        </div>
                        <Button onClick={this.toggleModal} color="link" className="vertical-align d-flex">
                          {user?'':
                            <RestrictedPopover showModal={this.state.showModal} handleCloseModal={this.toggleModal}  id={`Like-blogNews${id}`} />
                          }
                          {thumbsUp?<FaThumbsUp/>:<FaRegThumbsUp />}
                        </Button>
                      </div>  */}
                      <a href={`/blog/${results.slug}`}>
                        <div dangerouslySetInnerHTML={{__html: results.title && results.title.rendered}} className="title" />  
                      </a>
                      <div className="date">{results.date && `ON ${moment(results.date).format('MMMM DD, YYYY')}`}</div>
                  </div>
                  <a className="margin-right" href={`/blog/${results.slug}`}>
                    {
                      results.jetpack_featured_media_url ? <img className="image-block rounded-6 " src={results.jetpack_featured_media_url} alt=""/> : <div className="image-block rounded-6 margin-right"></div>
                    }
                  </a>
              </div>
              <div  dangerouslySetInnerHTML={{__html: results.content.rendered}} className="info-description" />
              <hr />
            </>
        )
    }
}
