import React, { Component } from "react";
import { NavLink as RouterNavLink, Link } from "react-router-dom";
import { NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import logout from "../functions/logout";
import SalesJSON from "../../content/sales.json";
import hidePagesJSON from "../../content/hidePages.json";
import SectorJSON from "../../content/sector.json";

function LoginButtons(props) {
  const isLoggedIn = props.data ? true : false;
  const { data, handleCloseMenu } = props;
  if (isLoggedIn) {
    return (
      <Nav className="ml-auto user">
        <NavItem>
          <NavLink
            onClick={handleCloseMenu}
            activeClassName="selected"
            tag={RouterNavLink}
            to="/profile"
          >
            My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <button
            className="nav-link sign-up"
            onClick={() => {
              logout(data);
              handleCloseMenu();
            }}
          >
            Logout
          </button>
        </NavItem>
      </Nav>
    );
  }

  return (
    <Nav className="ml-auto user">
      <NavItem>
        <NavLink
          onClick={handleCloseMenu}
          activeClassName="selected"
          to={{ pathname: "/login", state: { page: window.location.pathname } }}
          tag={RouterNavLink}
        >
          Login
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          onClick={handleCloseMenu}
          activeClassName="selected"
          tag={RouterNavLink}
          className="sign-up"
          to={{
            pathname: "/signup",
            state: { page: window.location.pathname },
          }}
        >
          <b>Sign Up</b>
        </NavLink>
      </NavItem>
    </Nav>
  );
}
export default class PcNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }
  toggle = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });

  render() {
    const { distanceFromTop, data, signUpUrl, loginUrl } = this.props.data;
    const { handleCloseMenu, handleClickMenu, DetectIsSelected } = this.props;
    const userType =
      data && data["cognito:groups"] && data["cognito:groups"][0];
    const location = window.location.pathname;

    return (
      <div className="container d-none d-lg-flex pc-block">
        <NavbarBrand className="d-flex mr-0" tag={Link} to="/">
          <img
            className="logo"
            src={
              distanceFromTop.distanceFromTop * -1 > 272
                ? "/images/logo-white.png"
                : "/images/logo.png"
            }
            alt="IoT Nation logo"
          />
        </NavbarBrand>
        {(userType === "corporate" ||
          userType === "pro" ||
          userType === "premium") && (
          <Link
            to="/companies"
            className="user-type-block rounded-6 ml-2 mr-3 my-auto d-flex black-link"
          >
            <div className="font-s-14 font-weight-bold m-auto">
              {userType === "corporate"
                ? "CORP"
                : userType === "pro"
                ? "PRO "
                : "PREM"}
            </div>
          </Link>
        )}

        <div className="d-flex my-auto w-100">
          <Nav className="my-auto" navbar>
            {userType === "admin" && (
              <NavItem>
                <div
                  onClick={handleCloseMenu}
                  className={
                    location.includes("/admin/activationcode")
                      ? "selected nav-link second-link pointer"
                      : "nav-link pointer"
                  }
                >
                  Admin
                </div>
                <Nav>
                  <div className="Hover-Nav">
                    <div className="container">
                      <NavLink
                        onClick={handleCloseMenu}
                        activeClassName="selected"
                        tag={RouterNavLink}
                        to="/admin/activationcode"
                      >
                        Activation codes
                      </NavLink>
                      <a
                        href={process.env.REACT_APP_REDIRECT_URL}
                        target="_blank"
                        className="nav-link"
                      >
                        UGC panel
                      </a>
                    </div>
                  </div>
                </Nav>
              </NavItem>
            )}

            <NavItem>
              <div
                onClick={handleCloseMenu}
                className={
                  location === "/reports" || location === "/iotace"
                    ? "selected nav-link second-link pointer"
                    : "nav-link pointer"
                }
              >
                Products
              </div>
              <Nav>
                <div className="Hover-Nav">
                  <div className="container">
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/iotace"
                    >
                      IoT Ace
                    </NavLink>

                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/reports"
                    >
                      Reports
                    </NavLink>
                  </div>
                </div>
              </Nav>
            </NavItem>
            <NavItem>
              <div
                onClick={handleCloseMenu}
                className={
                  location === "/companies" ||
                  location === "/map" ||
                  location.includes("/casestudies") ||
                  location === "/blog"
                    ? "selected nav-link second-link pointer"
                    : "nav-link pointer"
                }
              >
                Resources
              </div>
              <Nav>
                <div className="Hover-Nav">
                  <div className="container">
                    <div className="second-link">
                      <div
                        onClick={() => handleClickMenu(1)}
                        className={
                          location.includes("/casestudies")
                            ? "selected nav-link second-link pointer"
                            : "nav-link pointer"
                        }
                      >
                        Case Studies
                      </div>
                      <div className="p-0 dropdown-item">
                        <div className="Case-studies-block">
                          <div className="container">
                            {SalesJSON.map((item, key) => (
                              <NavLink
                                key={key}
                                onClick={handleCloseMenu}
                                activeClassName="selected"
                                tag={RouterNavLink}
                                to={`/casestudies/${item.slug}`}
                              >
                                {item.navbar}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/companies"
                    >
                      Companies
                    </NavLink>
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/map"
                    >
                      IoT Map
                    </NavLink>
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/blog"
                    >
                      Blog
                    </NavLink>
                  </div>
                </div>
              </Nav>
            </NavItem>

            {userType === "admin" && (
              <NavItem>
                <div
                  onClick={handleCloseMenu}
                  className={
                    DetectIsSelected(SectorJSON, location, "Verticals")
                      ? "selected nav-link second-link pointer"
                      : "nav-link pointer"
                  }
                >
                  Sector
                </div>
                <Nav>
                  <div className="Hover-Nav">
                    <div className="container">
                      <div className="second-link">
                        <div
                          onClick={() => handleClickMenu(2)}
                          className={
                            location.includes("/sector")
                              ? "selected nav-link second-link pointer"
                              : "nav-link pointer"
                          }
                        >
                          Verticals
                        </div>
                        <div className="p-0 dropdown-item">
                          <div className="Case-studies-block">
                            <div className="container">
                              {SectorJSON.map(
                                (item, key) =>
                                  item.type === "Verticals" && (
                                    <NavLink
                                      key={key}
                                      onClick={handleCloseMenu}
                                      activeClassName="selected"
                                      tag={RouterNavLink}
                                      to={`/sector/${item.title
                                        .replace(/ \/ /g, "-")
                                        .replace(/ & /g, "-and-")
                                        .replace(/ /g, "-")}`}
                                    >
                                      {item.title}
                                    </NavLink>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="second-link">
                        <div
                          onClick={() => handleClickMenu(3)}
                          className={
                            DetectIsSelected(
                              SectorJSON,
                              location,
                              "Technologies"
                            )
                              ? "selected nav-link second-link pointer"
                              : "nav-link pointer"
                          }
                        >
                          Technologies
                        </div>
                        <div className="p-0 dropdown-item">
                          <div className="Case-studies-block">
                            <div className="container">
                              {SectorJSON.map(
                                (item, key) =>
                                  item.type === "Technologies" && (
                                    <NavLink
                                      key={key}
                                      onClick={handleCloseMenu}
                                      activeClassName="selected"
                                      tag={RouterNavLink}
                                      to={`/sector/${item.title
                                        .replace(/ \/ /g, "-")
                                        .replace(/ & /g, "-and-")
                                        .replace(/ /g, "-")}`}
                                    >
                                      {item.title}
                                    </NavLink>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Nav>
              </NavItem>
            )}

            <NavItem>
              <div
                onClick={handleCloseMenu}
                className={
                  location === "/events" ||
                  location === "/usecases" ||
                  location === "/competitions"
                    ? "selected nav-link second-link pointer"
                    : "nav-link pointer"
                }
              >
                Community
              </div>
              <Nav>
                <div className="Hover-Nav">
                  <div className="container">
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/events"
                    >
                      Events
                    </NavLink>
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/usecases"
                    >
                      Industry Use Cases
                    </NavLink>
                    {/* <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/competitions"
                    >
                      Competitions
                    </NavLink> */}
                    <a
                      target="_blank"
                      href="https://www.meetup.com/pro/iotnation"
                      className="nav-link"
                    >
                      Chapter Meetups
                    </a>
                  </div>
                </div>
              </Nav>
            </NavItem>
            <NavItem>
              <div
                onClick={handleCloseMenu}
                className={
                  location === "/about" || location === "/partners"
                    ? "selected nav-link second-link pointer"
                    : "nav-link pointer"
                }
              >
                Company
              </div>
              <Nav>
                <div className="Hover-Nav">
                  <div className="container">
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/about"
                    >
                      About Us
                    </NavLink>
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/partners"
                    >
                      Partners
                    </NavLink>
                    <NavLink
                      onClick={handleCloseMenu}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/about"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </Nav>
            </NavItem>
          </Nav>
          <LoginButtons
            handleCloseMenu={this.handleCloseMenu}
            data={data}
            loginUrl={loginUrl}
            signUpUrl={signUpUrl}
          />
        </div>
      </div>
    );
  }
}
