import React, { Component } from 'react'

import './InfoBlock.scss'

export default class InfoBlock extends Component {
    render() {
        const {img, title, description, btnName, topBlock} = this.props
        return (
            <div className={topBlock?"InfoBlock m-b-40":"InfoBlock"}>
                <div className="row block" >
                    <div className="col-5 img-block">
                        <img src={img} alt=""/>
                    </div>

                    <div className="col-7">
                        <div className="title">{title}</div>
                        <div className="description">
                            {description} 
                        </div>
                        
                        <button className="get-started">{btnName}</button>
                    </div>   
                </div>
                
            </div>
        )
    }
}
