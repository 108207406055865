import React, { Component } from 'react'
import Title from '../Title.jsx';
import SpeakerSideBlock from './SpeakerSideBlock';
import { Link } from 'react-scroll';

import './SpeakerSide.scss';

export default class SpeakerSide extends Component {
  state = {
    showMore:false,
    key: null
  }
 
  handleShowMore = () => this.setState(({showMore})=>({showMore:!showMore}))
  render() {
    
    const { showMore } = this.state
    return (
      <div className="SpeakerSide">
        <Title src='/images/mic.svg' name={this.props.title} />
        <div className={showMore?'togglee':'togglee hidden'}>
          {this.props.data.map((item, key)=>{
          const { image, firstName, linkedin, lastName, speakerType, company, title} = item
          return <SpeakerSideBlock key={key} src={image&&image} firstName={firstName&&firstName} 
          lastName={lastName&&lastName} linkedin={linkedin&&linkedin} title={title&&title} companyName={company&&company.name&&company.name} speaker={speakerType} />
        }
          )}
        </div>

        <div className="side-block-border p-24">    
          <div className="align-center display-flex button-padding">
              <Link className="view-blue active" activeClass="active" to="speakers" spy={true} smooth={true} offset={-62.5} duration={500} onSetActive={this.handleSetActive}>
                View All
              </Link>
          </div>
        </div>
      </div>
    )
  }
}
