import React from "react";

import FakeData from "../../../components/FakeData/FakeData";
import SubscriberContent from "../../../components/SubscriberContent/SubscriberContent";
// import Tippy from '@tippy.js/react'

function IotTags({ iotTags, user }) {
  let isIotFaked = false;

  const generateFakes = () => {
    isIotFaked = true;
    let list = [];
    for (let i = 0; i < 10; i++) {
      list.push({
        name: FakeData({ toFake: "lorem.words", returnJustValue: true }),
      });
    }

    return list;
  };
  const fakedIotTags = () => {
    isIotFaked = true;
    return {
      technologies_: generateFakes(),
      verticals_: generateFakes(),
    };
  };

  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];

  const { verticals_, technologies_ } = user
    ? iotTags && Object.keys(iotTags).length !== 0
      ? iotTags
      : {}
    : fakedIotTags();
  const verticalsSpans = verticals_
    ? verticals_.map((val, i) => (
        <a
          key={i}
          target="_blank"
          href={`/companies/?q=&filter=Verticals_Include_${val.name.replace(
            "&",
            "theAnd"
          )}&limit=10&currentPage=1&fullProfiles=1`}
          className="company-tags text-dark"
        >
          <span>
            {userType === "admin" ||
            userType === "premium"
              ? `${val.name}: ${val.weight}` :
                userType === "corporate" 
              ? `${val.name}: ${val.weight}%`
              : val.name}
          </span>
        </a>
      ))
    : [];
  const technologiesSpans = technologies_
    ? technologies_.map((val, i) => (
        <a
          key={i}
          target="_blank"
          href={`/companies/?q=&filter=Technologies_Include_${val.name.replace(
            "&",
            "theAnd"
          )}&limit=10&currentPage=1&fullProfiles=1`}
          className="company-tags text-dark"
        >
          <span>
            {userType === "admin" ||
            userType === "premium"
              ? `${val.name}: ${val.weight}` :
                userType === "corporate" 
              ? `${val.name}: ${val.weight}%`
              : val.name}
          </span>
        </a>
      ))
    : [];

  if (verticals_ || technologies_) {
    return (
      <div className="card tags">
        <div className="card-header">
          <div className="icon tags">
            <img src="/images/tag-2.svg" alt="tag" />
          </div>
          <div className="title-holder">
            <p id="tags">IoT Tags</p>
          </div>
        </div>
        <div className="menu-divider" />
        {/* pc */}
        {verticals_ ? (
          <>
            <div className="row category d-none d-md-flex position-relitive">
              <div className="col-2 padding-lr-5">
                <p>Verticals</p>
              </div>
              <div className={`col ${isIotFaked ? "blurred-box" : ""}`}>
                {verticalsSpans}
              </div>
              <SubscriberContent target="tags1" hidden={isIotFaked} />
            </div>
            <div className="menu-divider d-none d-md-flex" />
          </>
        ) : (
          ""
        )}
        {technologies_ ? (
          <div className="row category d-none d-md-flex position-relitive">
            <div className="col-2 padding-lr-5">
              <p>Technologies</p>
            </div>
            <div className={`col ${isIotFaked ? "blurred-box" : ""}`}>
              {technologiesSpans}
            </div>
            <SubscriberContent target="tags2" hidden={isIotFaked} />
          </div>
        ) : (
          ""
        )}

        {/* mobile */}
        {verticals_ ? (
          <>
            <div className="row category d-flex d-md-none position-relitive">
              <div className="col-12">
                <p>Verticals</p>
              </div>
              <div className={`col ${isIotFaked ? "blurred-box" : ""}`}>
                {verticalsSpans}
              </div>
              <SubscriberContent target="tags3" hidden={isIotFaked} />
            </div>
            <div className="menu-divider d-flex d-md-none" />
          </>
        ) : (
          ""
        )}
        {technologies_ ? (
          <>
            <div className="row category d-flex d-md-none position-relitive">
              <div className="col-12">
                <p>Technologies</p>
              </div>
              <div className={`col ${isIotFaked ? "blurred-box" : ""}`}>
                {technologiesSpans}
              </div>
              <SubscriberContent target="tags4" hidden={isIotFaked} />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return "";
  }
}

export default IotTags;
