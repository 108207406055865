import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Events from "./Events";
import ListView from "./components/ListView.jsx";
import EventsItem from "./components/EventsItem.jsx";
import Spinner from "../components/Spinner/Spinner";
import {
  getMainFilters,
  setFiltersQuery,
  isFiltersChanged,
} from "./functions/eventFilters";
import sortByFeatured from "./functions/sortByFeatured";
import getSuggestions, { getPlaceLatLong } from "./functions/getSuggestions";
import { animateScroll as scroll } from "react-scroll";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import "../components/scss/breadcrumbs.scss";
import ScrollUpButton from "react-scroll-up-button";
import Filter from "./components/Filter.jsx";
import RegisterPopover from "../components/Modals/RegisterPopover/RegisterPopover";
import GeneratedApi from "../components/functions/generatedApi";

export default class EventsContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      checkedFilter: [],
      publicPrivateCount: 0,
      employeeCount: 0,
      verticalsCount: 0,
      totalFcount: 0,
      technologiesCount: 0,
      searchResults: [],
      locationOptions: [{ value: null, label: "", longLabel: "" }],
      start: 0,
      limit: 10,
      count: 0,
      filtersArray: [],
      filters: [],
      searchInput: "",
      selectedAllEventTypes: true,
      calendarChanged: false,
      focusedInput: null,
      focusedInputMobile: null,
      focusedInputTablet: null,
      limitReached: false,
      moreFilters: false,
      loading: true,
      dataLoaded: false,
      firstLoad: true,
      increasing: 0,
      modal: false,
    };
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  loadData = (data) => {
    const filters = data && data.filters;
    this.setState(
      Object.assign(
        { dataLoaded: true },
        {
          limit: this.state.limit,
          filters:
            filters && filters.length > 0
              ? [
                  ...getMainFilters(filters),
                  ...filters.filter((filter) => !filter.mainAttribute),
                ]
              : getMainFilters(),
        }
      )
    );
    filters &&
      this.props.history.replace({ ...this.props.location, state: undefined });
  };

  handleMoreFilters = (moreFilters) => {
    this.setState({ moreFilters });
  };
  onCalendarFocusChangeMobile = (focusedInputMobile) => {
    this.setState({ focusedInputMobile });
  };
  onCalendarFocusChangeTablet = (focusedInputTablet) => {
    this.setState({ focusedInputTablet });
  };
  onCalendarFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };
  onDatesChange = ({ startDate: dateEnd, endDate: dateStart }) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute &&
        (filter.attribute === "dateStart" || filter.attribute === "dateEnd")
          ? {
              ...filter,
              useFilter: true,
              value:
                filter.attribute === "dateStart"
                  ? dateStart
                    ? dateStart.endOf("day").format()
                    : null
                  : dateEnd
                  ? dateEnd.startOf("day").format()
                  : null,
            }
          : filter
      ),
    }));
  };

  onDateChangeStart = (dateStart) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "dateStart"
          ? {
              ...filter,
              useFilter: true,
              value:
                filter.attribute === "dateStart"
                  ? dateStart.endOf("day").format()
                  : null,
            }
          : filter
      ),
    }));
  };

  onDateChangeEnd = (dateEnd) => {
    this.setState(({ filters }) => ({
      calendarChanged: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "dateEnd"
          ? {
              ...filter,
              useFilter: true,
              value:
                filter.attribute === "dateEnd"
                  ? dateEnd.endOf("day").format()
                  : null,
            }
          : filter
      ),
    }));
  };

  handleInput = ({ target: { value, name } }) => {
    this.props.history.push(`/events${value ? `?q=${value}` : ""}`);  
    this.setState({ [name]: value });
    if(value === "") {
      this.getEventsData();
      scroll.scrollToTop();
    }
  };

  handleLocationInput = (input, { action }) => {
    if (action === "input-change" && input.length >= 0) {
      this.setState({ locationFilter: input })
      this.setState(({ filters, locationFilter }) => ({
        locationFilter: input,
        filters: filters.map((filter) =>
          filter.mainAttribute && filter.attribute === "Location"
            ? {
                ...filter,
                useFilter: false,
              }
            : filter
        ),
      }));
      debounce(
        () =>
          getSuggestions(input).then((locationOptions) => {
            this.setState({ locationOptions })
          }
          
          ),
        500
      )();
    }
  };

  handleLocationChange = async (value, { name }) => {
    if(value){
      const currentCoordinates = value && (await getPlaceLatLong(value.value));
      this.setState(({ filters, locationFilter }) => ({
        locationFilter: value.label ? value.label : value.longLabel,
        filters: filters.map((filter) =>
          filter.mainAttribute && filter.attribute === "Location"
            ? {
                ...filter,
                currentCoordinates,
                useFilter: true,
                value: value,
              }
            : filter
        ),
      }));   
    }
  };

  handleSelectLocation = async (value) => {
    const currentCoordinates = value && (await getPlaceLatLong(value.value));
    this.setState(({ filters, locationFilter }) => ({
      locationFilter: value.label ? value.label : value.longLabel,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "Location"
          ? {
              ...filter,
              currentCoordinates,
              useFilter: value ? true : false,
              value: value,
            }
          : filter
      ),
    })); 
    
  };

  handleSelectedSegments = ({ target: { checked, name } }) => {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: checked
                ? filter.value.includes(name)
                  ? filter.value
                  : filter.value.concat([name])
                : filter.value.filter((item) => item !== name),
            }
          : filter
      ),
    }));
  };

  handleClearAllSegments = () => {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: [],
            }
          : filter
      ),
    }));
  };
  handleSelectAllSegments = () => {
    this.setState(({ filters }) => ({
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventSegment"
          ? {
              ...filter,
              useFilter: true,
              value: ["IoT", "AI", "VR/AR", "Blockchain"],
            }
          : filter
      ),
    }));
  };

  handleSelectedEventTypes = ({ target: { checked, name } }) => {
    this.setState(({ filters, selectedAllEventTypes }) => ({
      selectedAllEventTypes: !checked ? false : selectedAllEventTypes,

      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: checked
                ? filter.value.includes(name)
                  ? filter.value
                  : filter.value.concat([name])
                : filter.value.filter((item) => item !== name),
            }
          : filter
      ),
    }));

    // this.setState(({ filters,selectedAllEventTypes})=>({
    //   selectedAllEventTypes:filters.find((filter, i)=>
    //   filter.attribute ==='eventType' && (filter.value.length < 9) ? false:filter.attribute ==='eventType'?true:null
    //   )}))
  };

  handleSelectedAll = ({ target: { checked } }) => {
    this.setState(({ filters }) => ({
      selectedAllEventTypes: checked,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: checked ? filter.values : [],
            }
          : filter
      ),
    }));
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.getEventsData();
    scroll.scrollToTop();
  };

  getEventsData = debounce(() => {
    if (!this._isMounted) return false;

    this.setState({
      filtersArray: [
        ...this.state.filters,
        ...this.state.checkedFilter.map((obj) => ({
          attribute: obj.checked.attribute.toLowerCase(),
          mainAttribute: true,
          operator: obj.checked.operator.toLowerCase(),
          operators: [obj.checked.operator],
          useFilter: true,
          value: obj.checked.value.toLowerCase(),
        })),
      ],
    });
    const { searchInput, start, limit, filtersArray } = this.state;
    const query = {
      search: {
        q: window.location.search
          ? window.location.search.replace("?q=", "")
          : "",
        start,
        limit,
      },
      filters: setFiltersQuery(filtersArray),
      sort: {},
    };
    GeneratedApi("", false, true)
      .post("/events/gridView", query)
      .then((response) => {
        const { searchResults, count } = response.data.body.data;
        if (this._isMounted) {
          this.setState({
            searchResults,
            count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 429) {
            console.log("limit reached");
            this._isMounted && this.setState({ limitReached: true });
            return false;
          }
        }
      });
  }, 300);

  handleShowAllEvents = () => {
    this.setState(({ count, filters }) => ({
      limit: 10,
      selectedAllEventTypes: true,
      filters: filters.map((filter) =>
        filter.mainAttribute && filter.attribute === "eventType"
          ? {
              ...filter,
              useFilter: true,
              value: [
                "Webinar",
                "Conference",
                "Networking",
                "Hackathon",
                "Course",
                "Exhibition",
                "Panel",
                "Fair",
                "Startup",
              ],
            }
          : filter
      ),
    }));
  };

  componentDidMount() {
    if (window.location.search) {
      this.setState({
        searchInput: window.location.search.split("?q=")[1].split("&")[0],
      });
    }
    if (!this.props.user && this.state.firstLoad) {
      setTimeout(() => {
        this.setState({ modal: true, firstLoad: false });
      }, 45000);
    }
    this._isMounted = true;
    this.loadData(this.props.location.state);
    this.setState(({ filters, selectedAllEventTypes }) => ({
      selectedAllEventTypes: filters.find((filter, i) =>
        filter.attribute === "eventType" && filter.value.length < 10
          ? false
          : filter.attribute === "eventType"
          ? true
          : null
      ),
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    isFiltersChanged(prevState.filters, this.state.filters) &&
      this.getEventsData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchMoreData = () => {
    this.setState(
      ({ count }) => ({ limit: this.state.limit + 10 }),
      this.getEventsData
    );
  };

  handleFilterCheckbox = async (event) => {
    const target = event.target;
    // const newArrayValue = {attribute: target.placeholder, operator: target.name, value: target.value}
    const keyId = target.id;
    if (target.checked) {
      if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount + 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount + 1 });
      }

      await this.setState((prevState) => ({
        checkedFilter: [
          ...prevState.checkedFilter,
          {
            _id: keyId,
            checked: {
              attribute: target.placeholder,
              operator: target.name,
              value: target.value,
            },
          },
        ],
      }));
    } else {
      if (target.placeholder === "Technologies") {
        this.setState({ technologiesCount: this.state.technologiesCount - 1 });
      } else if (target.placeholder === "Verticals") {
        this.setState({ verticalsCount: this.state.verticalsCount - 1 });
      }

      await this.setState((prevState) => ({
        checkedFilter: prevState.checkedFilter
          .map((obj) => obj._id !== keyId && obj)
          .filter(Boolean),
      }));
    }
    await this.setState({
      filtersArray: [
        ...this.state.filters,
        ...this.state.checkedFilter.map((obj) => ({
          attribute: obj.checked.attribute.toLowerCase(),
          mainAttribute: true,
          operator: obj.checked.operator.toLowerCase(),
          operators: [obj.checked.operator],
          useFilter: true,
          value: obj.checked.value.toLowerCase(),
        })),
      ],
    });
    this.getEventsData();
  };
  handleClearAllFilters = async () => {
    await this.setState({
      filtersArray: [],
      checkedFilter: [],
      technologiesCount: 0,
      verticalsCount: 0,
    });
    this.getEventsData();
  };

  render() {
    const {
      searchInput,
      filters,
      moreFilters,
      count,
      limit,
      locationOptions,
      focusedInput,
      focusedInputMobile,
      focusedInputTablet,
      calendarChanged,
      selectedAllEventTypes,
      searchResults,
      loading,
      dataLoaded,
      locationFilter
    } = this.state;
    // const Increase = () =>{
    //   this.setState({increasing: limit});
    //   return(true);
    // }

    if (!dataLoaded) return false;

    const selectedEventTypes = filters.find(
      (filter, i) => filter.attribute === "eventType" && filter.mainAttribute
    );

    const selectedSegments = filters.find(
      (filter, i) => filter.attribute === "eventSegment" && filter.mainAttribute
    );

    const dateStart = filters.find(
      (filter, i) => filter.attribute === "dateStart" && filter.mainAttribute
    ).value;

    const dateEnd = filters.find(
      (filter, i) => filter.attribute === "dateEnd" && filter.mainAttribute
    ).value;

    const linkLocation = this.props.location.state && this.props.location.state;
    const filtersLink =
      linkLocation && linkLocation.filters ? linkLocation.filters : filters;

    return (
      <>
        {this.props.user ? (
          ""
        ) : (
          <RegisterPopover
            pathname={window.location.pathname}
            isOpen={this.state.modal}
            toggle={this.toggleModal}
          />
        )}
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Events List
              </li>
            </ol>
          </nav>
        </div>

        <Events
          handleInput={this.handleInput}
          searchInput={searchInput}
          handleSearchSubmit={this.handleSearchSubmit}
          user={this.props.user}
        >
          <Filter
            data={{
              moreFilters,
              dateStart: dateEnd ? moment(dateEnd) : null,
              dateEnd: dateStart ? moment(dateStart) : null,
              selectedEventTypes,
              selectedSegments,
              locationFilter,
              locationOptions,
              focusedInput,
              focusedInputMobile,
              calendarChanged,
              selectedAllEventTypes,
              filters,
              count,
              limit,
              loading,
              filtersLink,
            }}
            handleClearAllFilters={this.handleClearAllFilters}
            handleFilterCheckbox={this.handleFilterCheckbox}
            handleClearFilter={this.handleClearFilter}
            verticalsCount={this.state.verticalsCount}
            technologiesCount={this.state.technologiesCount}
            checkedFilter={this.state.checkedFilter}
            handleInput={this.handleInput}
            searchInput={searchInput}
            handleSearchSubmit={this.handleSearchSubmit}
            handleMoreFilters={this.handleMoreFilters}
            onCalendarFocusChangeMobile={this.onCalendarFocusChangeMobile}
            onDatesChange={this.onDatesChange}
            onDateChangeStart={this.onDateChangeStart}
            onDateChangeEnd={this.onDateChangeEnd}
            handleSelectedEventTypes={this.handleSelectedEventTypes}
            handleSelectedAll={this.handleSelectedAll}
            handleSelectedSegments={this.handleSelectedSegments}
            handleSelectAllSegments={this.handleSelectAllSegments}
            handleLocationChange={this.handleLocationChange}
            handleShowAllEvents={this.handleShowAllEvents}
            handleLocationInput={this.handleLocationInput}
            handleSelectLocation={this.handleSelectLocation}
          />
          <ListView
            data={{
              moreFilters,
              dateStart: dateEnd ? moment(dateEnd) : null,
              dateEnd: dateStart ? moment(dateStart) : null,
              selectedEventTypes,
              selectedSegments,
              locationFilter,
              locationOptions,
              focusedInput,
              focusedInputMobile,
              focusedInputTablet,
              calendarChanged,
              selectedAllEventTypes,
              filters,
              count,
              limit,
              loading,
            }}
            handleSelectLocation={this.handleSelectLocation}
            handleInput={this.handleInput}
            searchInput={searchInput}
            handleSearchSubmit={this.handleSearchSubmit}
            handleMoreFilters={this.handleMoreFilters}
            onCalendarFocusChangeMobile={this.onCalendarFocusChangeMobile}
            onCalendarFocusChangeTablet={this.onCalendarFocusChangeTablet}
            onCalendarFocusChange={this.onCalendarFocusChange}
            onDatesChange={this.onDatesChange}
            onDateChangeStart={this.onDateChangeStart}
            onDateChangeEnd={this.onDateChangeEnd}
            handleSelectedEventTypes={this.handleSelectedEventTypes}
            handleSelectedAll={this.handleSelectedAll}
            handleSelectedSegments={this.handleSelectedSegments}
            handleSelectAllSegments={this.handleSelectAllSegments}
            handleClearAllSegments={this.handleClearAllSegments}
            handleLocationChange={this.handleLocationChange}
            handleShowAllEvents={this.handleShowAllEvents}
            handleLocationInput={this.handleLocationInput}
          >
            {loading ? (
              <Spinner />
            ) : searchResults.length === 0 ? (
              <p className="mt-5 h5 text-center">
                No results found. Consider removing filters or modifying the
                date range.
              </p>
            ) : (
              <InfiniteScroll
                style={{
                  height: "100%",
                  overflow: "unset",
                }}
                dataLength={searchResults.length}
                next={this.fetchMoreData}
                hasMore={count >= limit ? true : false}
                loader={
                  searchResults.length < limit ? <Spinner /> : "No more results"
                }

                // scrollThreshold={'0.6'}
              >
                {searchResults.sort(sortByFeatured).map((item, i) => (
                  <EventsItem
                    id={i}
                    data={{ ...item, filters }}
                    user={this.props.user}
                    instance2={GeneratedApi("", true, true)}
                    key={i}
                  />
                ))}
              </InfiniteScroll>
            )}
            <ScrollUpButton
              ToggledStyle={{ top: "400px", backgroundColor: "#1B7BEB" }}
              style={{ top: "400px", backgroundColor: "#1B7BEB" }}
            />
          </ListView>
        </Events>
      </>
    );
  }
}
