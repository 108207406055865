import React, { Component } from 'react'
export default class Ambassadors extends Component {
    render() {
        return (
            <div>
                <div className="top-panel">
                    <div className="container">
                        <div className="title">Ambassadors</div>
                    </div>
                </div>
                <div className="container">
                    {this.props.children}
                    <div className="row information-block rounded-6 grey-border d-block d-md-flex">
                        <div className="information-image rounded-6 grey-border vertical-align  d-none d-md-flex"> <img className="apply-img" src="/images/human-with-flag.svg" alt=""/> </div>
                        <div className="info align-vertical">
                            <div className="title"> Become an Ambassador in your Location </div>
                            <div className="description"> Are you an IoT "Connector" in your community? Join our mission to connect the IoT ecosystem. </div>
                        </div>
                        <div className="apply-btn pointer"><a target="_blank" href="https://forms.gle/KbJvAS5XbyDQHGF97">Apply Here</a></div>
                    </div>
                </div>
                
            </div>
        )
    }
}
