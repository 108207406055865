import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa'
export default class PublicPrivate extends Component {
    render() {
        const { checkedFilter, handleFilterCheckbox, IsChecked } = this.props
        return (
            <div className='side-padding-15'>
                <label className="custom-control-label" htmlFor='public/private-1' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='public/private-1' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'public/private-1')}  placeholder="Private / Public" name="Is" value="Public"  type="checkbox" />
                                {'Public Company'}
                            {IsChecked(checkedFilter, 'public/private-1') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='public/private-2' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='public/private-2' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'public/private-2')}  placeholder="Private / Public" name="Is" value="Private"  type="checkbox" />
                                {'Private Company'}
                            {IsChecked(checkedFilter, 'public/private-2') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}
