import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa'
export default class Verticals extends Component {
    render() {
        const { checkedFilter, handleFilterCheckbox, IsChecked } = this.props

        return (
            <div className='side-padding-15'>
                <label className="custom-control-label" htmlFor='verticals-7' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-7' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-7')}  placeholder="Verticals" name="Include" value="Aerospace & Defense"  type="checkbox" />
                                {'Aerospace & Defense'}
                            {IsChecked(checkedFilter, 'verticals-7') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-9' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-9' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-9')}  placeholder="Verticals" name="Include" value="Agriculture"  type="checkbox" />
                                {'Agriculture'}
                            {IsChecked(checkedFilter, 'verticals-9') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-23' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-23' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-23')}  placeholder="Verticals" name="Include" value="Banking & Finance"  type="checkbox" />
                                {'Banking & Finance'}
                            {IsChecked(checkedFilter, 'verticals-23') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-19' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-19' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-19')}  placeholder="Verticals" name="Include" value="Connected Cars"  type="checkbox" />
                                {'Connected Cars'}
                            {IsChecked(checkedFilter, 'verticals-19') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-24' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-24' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-24')}  placeholder="Verticals" name="Include" value="Connected Transportation"  type="checkbox" />
                                {'Connected Transportation'}
                            {IsChecked(checkedFilter, 'verticals-24') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-8' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-8' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-8')}  placeholder="Verticals" name="Include" value="Education"  type="checkbox" />
                                {'Education'}
                            {IsChecked(checkedFilter, 'verticals-8') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-5' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-5' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-5')}  placeholder="Verticals" name="Include" value="Energy"  type="checkbox" />
                                {'Energy'}
                            {IsChecked(checkedFilter, 'verticals-5') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-11' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-11' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-11')}  placeholder="Verticals" name="Include" value="Entertainment"  type="checkbox" />
                                {'Entertainment'}
                            {IsChecked(checkedFilter, 'verticals-11') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-20' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-20' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-20')}  placeholder="Verticals" name="Include" value="Government"  type="checkbox" />
                                {'Government'}
                            {IsChecked(checkedFilter, 'verticals-20') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-12' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-12' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-12')}  placeholder="Verticals" name="Include" value="Industry 4.0"  type="checkbox" />
                                {'Industry 4.0'}
                            {IsChecked(checkedFilter, 'verticals-12') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-18' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-18' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-18')}  placeholder="Verticals" name="Include" value="Internet Of Things"  type="checkbox" />
                                {'Internet Of Things'}
                            {IsChecked(checkedFilter, 'verticals-18') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-25' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-25' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-25')}  placeholder="Verticals" name="Include" value="Manufacturing"  type="checkbox" />
                                {'Manufacturing'}
                            {IsChecked(checkedFilter, 'verticals-25') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-6' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-6' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-6')}  placeholder="Verticals" name="Include" value="Media & Communication"  type="checkbox" />
                                {'Media & Communication'}
                            {IsChecked(checkedFilter, 'verticals-6') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-17' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-17' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-17')}  placeholder="Verticals" name="Include" value="Medical & Healthcare"  type="checkbox" />
                                {'Medical & Healthcare'}
                            {IsChecked(checkedFilter, 'verticals-17') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-28' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-28' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-28')}  placeholder="Verticals" name="Include" value="Military"  type="checkbox" />
                                {'Military'}
                            {IsChecked(checkedFilter, 'verticals-28') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-15' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-15' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-15')}  placeholder="Verticals" name="Include" value="Oil & Gas"  type="checkbox" />
                                {'Oil & Gas'}
                            {IsChecked(checkedFilter, 'verticals-15') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-29' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-29' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-29')}  placeholder="Verticals" name="Include" value="Retail"  type="checkbox" />
                                {'Retail'}
                            {IsChecked(checkedFilter, 'verticals-29') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-14' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-14' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-14')}  placeholder="Verticals" name="Include" value="Smart Buildings"  type="checkbox" />
                                {'Smart Buildings'}
                            {IsChecked(checkedFilter, 'verticals-14') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-27' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-27' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-27')}  placeholder="Verticals" name="Include" value="Smart Cities"  type="checkbox" />
                                {'Smart Cities'}
                            {IsChecked(checkedFilter, 'verticals-27') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-13' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-13' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-13')}  placeholder="Verticals" name="Include" value="Smart Home"  type="checkbox" />
                                {'Smart Home'}
                            {IsChecked(checkedFilter, 'verticals-13') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-10' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-10' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-10')}  placeholder="Verticals" name="Include" value="Smart Lighting"  type="checkbox" />
                                {'Smart Lighting'}
                            {IsChecked(checkedFilter, 'verticals-10') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-26' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-26' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-26')}  placeholder="Verticals" name="Include" value="Smart Metering & Grid"  type="checkbox" />
                                {'Smart Metering & Grid'}
                            {IsChecked(checkedFilter, 'verticals-26') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-4' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-4' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-4')}  placeholder="Verticals" name="Include" value="Smart Supply Chain"  type="checkbox" />
                                {'Smart Supply Chain'}
                            {IsChecked(checkedFilter, 'verticals-4') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-21' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-21' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-21')}  placeholder="Verticals" name="Include" value="Smart Water"  type="checkbox" />
                                {'Smart Water'}
                            {IsChecked(checkedFilter, 'verticals-21') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-16' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-16' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-16')}  placeholder="Verticals" name="Include" value="Surveillance"  type="checkbox" />
                                {'Surveillance'}
                            {IsChecked(checkedFilter, 'verticals-16') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-3' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-3' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-3')}  placeholder="Verticals" name="Include" value="Telecom"  type="checkbox" />
                                {'Telecom'}
                            {IsChecked(checkedFilter, 'verticals-3') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-22' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex" >
                            <input className="custom-control-input" id='verticals-22' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-22')}  placeholder="Verticals" name="Include" value="Utilities"  type="checkbox" />
                                {'Utilities'}
                            {IsChecked(checkedFilter, 'verticals-22') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
                <div className="row divider"></div>
                <label className="custom-control-label" htmlFor='verticals-2' >
                    <div className="display-flex row-pad">
                        <div className="custom-control custom-checkbox vertical-align display-flex">
                            <input className="custom-control-input" id='verticals-2' onChange={handleFilterCheckbox} checked={IsChecked(checkedFilter, 'verticals-2')}  placeholder="Verticals" name="Include" value="Wearables"  type="checkbox" />
                                {'Wearables'}
                            {IsChecked(checkedFilter, 'verticals-2') && <div className="blue-check"><FaCheck /></div>}
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}
