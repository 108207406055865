import React from 'react';
import Modal from 'react-modal';
import { Tooltip } from 'reactstrap';
import '../components/Modals/TOSModal/TOSModal.scss';
import './ConfirmModal.scss';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      zIndex                : '999',
      width                 : '',
    }
  };

  function ConfirmButtons(props) {
    return (
      <>
        <span><button onClick={() => {props.handleConfirmModal(false)}} key="button" type="button" name="agree" value={false} className="negative">EDIT</button></span>
        {
            props.confirmModalChecked?
            <span><button onClick={() => {props.handleConfirmModal(true)}} key="button" type="submit" name="agree" value={true}>CONFIRM</button></span>:
            <span><button className='disabled' id='tooltip'  key="button" type="submit" name="agree" value={true}>CONFIRM</button></span>
        }
        {
            !props.confirmModalChecked &&
            <Tooltip className="tooltip-box" innerClassName='tooltip-inner-box' placement="top" isOpen={props.tooltipOpen} target="tooltip" toggle={props.handleConfirmTooltip}>
                Please click the checkbox to Submit
            </Tooltip>
        }
      </>
    )
}

export default class ConfirmModal extends React.PureComponent {
    render() {
        const {visible, data, handleConfirmModal, handleConfirmCheckbox, confirmModalChecked, tooltipOpen, handleConfirmTooltip} = this.props;
        if(!visible) {
            return '';
        }
        return (
            <Modal
                isOpen={true}
                style={customStyles}
            >
                <div className="TOS-Modal-signup">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p className='confirm-title'>Text Message Conformation</p>
                                <p className='text'>For security purposes, you will receive a text message with a 6-digit code to confirm your identity. If you cannot receive a text message at the Mobile Number you entered (shown below), please press the Edit button below to correct it.</p>
                                <p className='mt-3 mb-3'>Mobile number: <b>{data.phoneNumber}</b></p>
                                <label className='d-flex checkbox-label' htmlFor="confirm"> I can receive a text message at this number
                                    <input defaultChecked={confirmModalChecked} onChange={handleConfirmCheckbox} className='confirm-input' name='confirm' type="checkbox"/>
                                </label>

                                
                            </div>
                        </div>
                    </div>
                    <div className="container tos-sign">
                        <div className="row">
                            <div className="col-12"></div>
                            <div className="col-12 agreement">
                                <div className="wrapper">
                                    <ConfirmButtons handleConfirmTooltip={handleConfirmTooltip} tooltipOpen={tooltipOpen} confirmModalChecked={confirmModalChecked} handleConfirmModal={handleConfirmModal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}