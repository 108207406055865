import React from "react";
import style from "../AdvancedFilters.module.scss";
import advancedFilterUrlGenerator from "./functions/advancedFilterUrlGenerator";
import generateForm from "./functions/generateForm";
import SuggestedLocation from "./SuggestedLocation";

var globalData = "";
const FilterLine = (props) => {
  const {
    filter,
    filterFields,
    advancedFilters,
    user,
    index,
    query,
    currentPage,
    history,
  } = props.data;
  const { updatePropsState } = props.handle;
  const attributes = filterFields.attributes;

  const handleChangeOption = (e) => {
    e.preventDefault();
    const data = advancedFilters;

    if (e.target.value === "Classification") {
      data[index] = {
        attribute: "Classification",
        operator: "Belongs to",
        value: " ",
      };
    } else if (e.target.value === "Classification-2") {
      data[index] = { attribute: "Classification-2", operator: " " };
    } else {
      let formData = generateForm(e.target, index, filterFields, filter);
      data[index] = formData;
      clearTimer();
      if (e.target.id === `operator${index}` || e.target.id.includes("Array")) {
        if (e.target.id === `operator${index}` && data[index].value === "") {
        } else {
          advancedFilterUrlGenerator(query, data, 1, history);
        }
      } else {
        setTimer(data, e.target.id);
      }
    }
    const Page =
      (e.target.id === `operator${index}` && data[index].value === "") ||
      e.target.id === `attribute${index}`
        ? currentPage
        : 1;
    updatePropsState({ currentPage: Page, advancedFilters: data });
  };

  const handleClearFilter = () => {
    const data = advancedFilters.filter((_item, key) => key !== index);
    updatePropsState({ advancedFilters: data });
    advancedFilterUrlGenerator(query, data, 1, history);
  };

  const setTimer = (item, id) => {
    globalData = setTimeout(() => {
      if (
        (id === `operator${index}` && item[index].value === "") ||
        id === `attribute${index}`
      ) {
      } else {
        advancedFilterUrlGenerator(query, item, 1, history);
      }
    }, 1000);
  };
  const clearTimer = () => {
    clearTimeout(globalData);
  };
  const generateArray = (value, newFilter) => {
    const valueName = newFilter ? newFilter : filter.value;
    let item = "";
    if (value.schemas) {
      if (value.filter) {
        item = filterFields.schemas[value.schemas[0]].filter(
          (item) => item.name === (valueName === "" ? " " : valueName)
        );
        item = item.length > 0 ? item[0] : item;
        item = item.years ? item.years : item;
      } else {
        item = filterFields.schemas[value.schemas[0]];
      }
    } else if (value.supportArray) {
      item = filterFields.supportArray[value.supportArray];
    } else {
      item = Object.getOwnPropertyNames(value);
    }
    return item;
  };
  // third block
  const thirdBlockV = filter.operator
    ? attributes[filter.attribute][filter.operator].value
      ? attributes[filter.attribute][filter.operator].value
      : attributes[filter.attribute][filter.operator].attributes
    : "";
  const thirdBlockArray = filter.operator
    ? attributes[filter.attribute][filter.operator].attributes
      ? generateArray(attributes[filter.attribute][filter.operator].attributes)
      : ""
    : "";
  // fourth block
  const fourthBlockV = thirdBlockV.value
    ? thirdBlockV.value
    : thirdBlockV.attributes
    ? thirdBlockV.attributes
    : "";
  const fourthBlockArray =
    thirdBlockV && thirdBlockV.attributes
      ? generateArray(thirdBlockV.attributes)
      : "";
  //fifth block
  const fifthBlockV = fourthBlockV.value
    ? fourthBlockV.value
    : fourthBlockV.attributes
    ? fourthBlockV.attributes
    : "";
  const fifthBlockArray =
    fourthBlockV && fourthBlockV.attributes
      ? generateArray(fourthBlockV.attributes)
      : "";
  //sixth block
  const sixthBlockV = fifthBlockV.value
    ? fifthBlockV.value
    : fifthBlockV.attributes
    ? fifthBlockV.attributes
    : "";
  const sixthBlockArray =
    fifthBlockV && fifthBlockV.attributes
      ? generateArray(fifthBlockV.attributes)
      : "";

  const userType = user && user["cognito:groups"] && user["cognito:groups"][0];
  return (
    <div className="row mx-0">
      <div className="my-2 mr-2">
        <select
          onChange={(e) => handleChangeOption(e)}
          className="form-control font-weight-bold"
          id={`attribute${index}`}
          value={filter.attribute}
        >
          {Object.getOwnPropertyNames(attributes).map((item, key) => (
            <option
              disabled={
                ((!user || !userType) &&
                  (item === "Company Rank" ||
                    item === "Participation In" ||
                    item === "Industry Activity Score" ||
                    item === "Number of Employees" ||
                    item === "Private/Public" ||
                    item === "Technologies" ||
                    item === "Verticals")) ||
                (item === "Classification" &&
                  userType !== "admin" &&
                  userType !== "corporate") ||
                (item === "Classification-2" &&
                  userType !== "admin" &&
                  userType !== "corporate")
              }
              // className={item === "Total Funding" && userType !== "admin" ? "d-none": "d-block"}
              className={item === "Total Funding" ? "d-none" : "d-block"}
              key={key}
              id={key}
              value={item}
            >
              {filterFields.alias[item] ? filterFields.alias[item].name : item}
              &nbsp;&nbsp;
            </option>
          ))}
        </select>
      </div>
      <div className="my-2 mr-2">
        <select
          onChange={(e) => handleChangeOption(e)}
          className="form-control"
          id={`operator${index}`}
          value={filter.operator}
        >
          {Object.getOwnPropertyNames(attributes[filter.attribute]).map(
            (item, key) => (
              <option key={key} id={key} value={item}>
                {item}&nbsp;&nbsp;
              </option>
            )
          )}
        </select>
      </div>
      <div className="my-2 mr-2 position-relative">
        {thirdBlockArray ? (
          <select
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            id={`valueArray${index}`}
            value={filter.value}
          >
            {thirdBlockArray.map((item, key) => (
              <option
                key={key}
                id={key}
                value={item.name ? item.name : item}
              >
                {item.name ? item.name : item}&nbsp;&nbsp;
              </option>
            ))}
          </select>
        ) : filter.attribute === "Location" ? (
          <SuggestedLocation
            placeholder={thirdBlockV.placeholder}
            updatePropsState={updatePropsState}
            index={index}
            id={`valueInput${index}`}
            advancedFilters={advancedFilters}
            query={query}
            value={filter.value}
            history={history}
          />
        ) : filter.attribute === "Total Funding" ? (
          <>
            <input
              onChange={(e) => handleChangeOption(e)}
              className="form-control pl-4"
              id={`valueInput${index}`}
              value={filter.value}
              placeholder={thirdBlockV.placeholder}
              type={thirdBlockV.type}
            />
            <div className={style.input_text}>
              <div className="pr-1">$</div>
            </div>
          </>
        ) : (
          <input
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            id={`valueInput${index}`}
            value={filter.value}
            placeholder={thirdBlockV.placeholder}
            type={thirdBlockV.type}
          />
        )}
      </div>
      {fourthBlockArray ? (
        <div
          className={`my-2 mr-2 ${
            fourthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          <select
            onChange={(e) => handleChangeOption(e)}
            className="form-contro"
            id={`attribute2Array${index}`}
            value={filter.operator2}
          >
            {fourthBlockArray.map((item, key) => (
              <option key={key} id={key} value={item}>
                {item}&nbsp;&nbsp;
              </option>
            ))}
          </select>
        </div>
      ) : fourthBlockV && fourthBlockV.type !== "dropdown" ? (
        <div
          className={`my-2 mr-2 ${
            fourthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          <input
            id={`attribute2Input${index}`}
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            value={filter.operator2}
            placeholder={fourthBlockV.placeholder}
            type={fourthBlockV.type}
          />
        </div>
      ) : (
        ""
      )}
      {fifthBlockArray ? (
        <div
          className={`my-2 mr-2 ${
            fifthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          <select
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            id={`operator2Array${index}`}
            value={filter.operator2}
          >
            {fifthBlockArray.map((item, key) => (
              <option key={key} id={key} value={item}>
                {item}&nbsp;&nbsp;
              </option>
            ))}
          </select>
        </div>
      ) : fifthBlockV && fifthBlockV.type !== "dropdown" ? (
        <div
          className={`my-2 mr-2 ${
            fifthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          <input
            id={`operator2Input${index}`}
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            value={filter.operator2}
            placeholder={fifthBlockV.placeholder}
            type={fifthBlockV.type}
          />
        </div>
      ) : (
        ""
      )}
      {sixthBlockArray &&
      sixthBlockArray.length > 0 &&
      sixthBlockArray[0] !== "" ? (
        <div
          className={`my-2 mr-2 ${
            sixthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          <select
            onChange={(e) => handleChangeOption(e)}
            className="form-control"
            id={`value2Array${index}`}
            value={filter.value2}
          >
            {sixthBlockArray.map((item, key) => (
              <option key={key} id={key} value={item}>
                {item}&nbsp;&nbsp;
              </option>
            ))}
          </select>
        </div>
      ) : sixthBlockV && sixthBlockV.type !== "dropdown" ? (
        <div
          className={`my-2 mr-2 position-relative ${
            sixthBlockV.show === "hidden" ? "d-none" : ""
          }`}
        >
          {filter.attribute === "Location" ? (
            <>
              <input
                id={`value2Input${index}`}
                onChange={(e) => handleChangeOption(e)}
                className="form-control pl-5"
                value={filter.value2}
                placeholder={sixthBlockV.placeholder}
                type={sixthBlockV.type}
              />
              <div className={style.input_text}>
                <div>Miles</div>
              </div>
            </>
          ) : (
            <input
              id={`value2Input${index}`}
              onChange={(e) => handleChangeOption(e)}
              className="form-control"
              value={filter.value2}
              placeholder={sixthBlockV.placeholder}
              type={sixthBlockV.type}
            />
          )}
        </div>
      ) : (
        ""
      )}
      <span className="btn clear pointer" onClick={handleClearFilter} />
    </div>
  );
};

export default FilterLine;
