import React, { Component } from 'react'
import AddtoCalendarDropdown from '../../../components/AddToCalendarSector/AddtoCalendarDropdown'
export default class SmartCitiesEvents extends Component {
    render() {
        const event = {
            description: `For info on this event, click here: \n<a href="https://www.iotnation.com/event/${'ss'}">www.iotnation.com/event/${'ss'}</a>
             \nSee more events listed on IoT Nation. Visit \n<a href="https://www.iotnation/events">www.iotnation/events</a>`,
            duration: '10 days',
            endDatetime: '10 days',
            location: 'england',
            startDatetime: '10 days',
            title: 'name',
          }
        return (
            <div className="Smart-Cities-Events row">
                <div className="col">
                <div className="content-header row">
                        <div className="col-1"><div className="calendar-2-icon"><img alt="" src="/images/calendar-2.svg"/></div></div>
                        <div className="col d-flex align-items-center"><p className="title">Smart Cities Events</p></div>
                    </div>
                    <div className="row events">
                        <div className="col-3">
                            <div className="events-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re 
                            </p>
                            <AddtoCalendarDropdown
                                event={event}
                                className="AddtoCalendarDropdown"
                            />
                        </div>
                    </div>
                    <div className="row events">
                        <div className="col-3">
                            <div className="events-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re 
                            </p>
                            <AddtoCalendarDropdown
                                event={event}
                                className="AddtoCalendarDropdown"
                            />
                        </div>
                    </div>
                    <div className="row events">
                        <div className="col-3">
                            <div className="events-img">
                                <img src="/images/bitmap.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="date">
                                <img  src="/images/calendar-1.svg" alt=""/>
                                <span>2018 December 12</span>
                            </div>
                            <h5>Via Satellite: Iridium Enters Extensive IoT Service Partnership with Amazon</h5>
                            <p>
                                Darian McBain, Ph.D., global director of sustainability for Thai Union, looks at the increasing demands being placed on companies to meet sustainability targets and how they’re 
                            </p>
                            <AddtoCalendarDropdown
                                event={event}
                                className="AddtoCalendarDropdown"
                            />
                        </div>
                    </div>
                </div>
                    
                    
            </div>
        )
    }
}
