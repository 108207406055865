import React from 'react';
import { Link } from "react-router-dom";

export function Subscription() {
    let subscriptionHeader = 'Upgrade to access more data!';
    let subscriptionText = 'Check out IoT Ace™ Subscription Plans.';
    let button = 'Upgrade';

    return (
      <div className="w-100 d-inline-flex align-items-center subscribe">
        <div className="container-fluid">
            <div className="row">
              <div className=" col-10 offset-1  offset-md-3 col-md-6 offset-md-3">
                  <h2>{subscriptionHeader}</h2>
                  <p>{subscriptionText}</p>
                  <Link to="/iotace" className="btn upgrade">{button}</Link>
              </div>
            </div>
          </div>
        </div>
    );
  }