import React, { Component } from "react";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import MarkerContainer from "./MarkerContainer.jsx";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import customMapStyle from "./customMapStyle.json";
import { isMobile, isMacOs } from "react-device-detect";

var clusterStyles = [
  {
    textColor: "white",
    url: "/images/cluster/small-v2.png",
    height: 56,
    width: 56,
  },
];

class MapWithAMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateZoom: isMobile ? 3 : 2,
    };
  }

  shouldComponentUpdate(nextProps) {
    console.log(
      this.props.advancedFilters,
      "=p=p=p=",
      nextProps.advancedFilters
    );
    if (nextProps.advancedFilters !== this.props.advancedFilters) {
      this.setState({ stateZoom: isMobile ? 3 : 2 });
      this.forceUpdate();

      return true;
    } else {
      // if (nextProps.searchResults) {
      //   return nextProps.searchResults.some(
      //     (res, i) => res.id !== this.props.searchResults[i].id
      //   );
      // }
      return false;
    }
  }
  handZoom = () => {
    this.setState({ stateZoom: this.refs.map.getZoom() });
  };

  render() {
    return (
      <GoogleMap
        ref="map"
        defaultOptions={{ styles: customMapStyle }}
        defaultZoom={
          window.location.search.includes("lat=") ? 16 : isMobile ? 3 : 2
        }
        onZoomChanged={(item) => this.handZoom(item)}
        defaultCenter={{ lat: 0, lng: 0 }}
        center={{ lat: 0, lng: 0 }}
        zoom={this.state.stateZoom}
      >
        <MarkerClusterer
          noRedraw
          onClick={this.props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          gridSize={50}
          maxZoom={8}
          styles={clusterStyles}
        >
          {this.props.searchResults.map((markerItem, index) => (
            <MarkerContainer
              user={this.props.user}
              key={index}
              results={markerItem}
              api={this.props.api}
            />
          ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  }
}

export default withGoogleMap(MapWithAMarker);
