import React, { Component } from 'react'
import CardsTitle from "../../../components/CardsTitle/CardsTitle"
import * as sortObjectsArray from 'sort-objects-array';
import FakeData from '../../../components/FakeData/FakeData';
import ContactRow from './ContactRow';
import SortableHeader from '../../../components/ResultsTable/SortableHeader/SortableHeader';
import './Contacts.scss'
export default class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            sort: {
              attribute: 'name',
              sort: 'asc'
            }
        }

        this.handleSortChange = this.handleSortChange.bind(this);
    }

    handleSortChange(attribute,sort) {
        this.setState({
            sort: {
                attribute,
                sort
            }
        });
    }

    renderFakeContacts(){
        let arr=[];
        for(let i = 0; i < 10; i++){
            arr.push({
                firstName:FakeData({toFake:'name.firstName', returnJustValue: true}),
                lastName:FakeData({toFake:'name.lastName', returnJustValue: true}),
                title:FakeData({toFake:'lorem.words', returnJustValue: true}),
                email:{
                    workEmail:FakeData({toFake:'internet.email', returnJustValue: true}),
                },
                linkedin:FakeData({toFake:'internet.url', returnJustValue: true}),
                name:FakeData({toFake:'name.findName', returnJustValue: true}),
                address:{
                    city:FakeData({toFake:'address.city', returnJustValue: true}),
                    country:FakeData({toFake:'address.country', returnJustValue: true}),
                }
            })
        }
        return arr;
    }
    render() {
        const { sort } = this.state;
        var contacts  = this.props.contacts?this.props.contacts:this.renderFakeContacts();

        if(!contacts) {
            return false;
        }

        contacts.forEach((contact) => {
            contact.name = `${contact.lastName}, ${contact.firstName}`;
        });
        contacts = sortObjectsArray(contacts, sort.attribute, sort.sort);
        return (
            <div className="Contacts" id="contacts">
                <CardsTitle image="/images/multiple-2.svg" titleName={'Company & Organization Contacts'} name="contacts" />
                <div className="description">
                    <table>
                        <thead>
                            <tr className="row">
                                <SortableHeader data={{name: 'Contact name', type: 'name', sort: sort, gridSize:'col-2'}} handleSortChange={this.handleSortChange}/>
                                <SortableHeader data={{name: 'Title', type: 'title', sort: sort, gridSize:'col-3'}} handleSortChange={this.handleSortChange}/>
                                <th className="col-3">Work Email</th>
                                <th className="col-2">Location</th>
                                <th className="col-2">Profile</th>
                            </tr>
                        </thead>
                        <tbody >
                            {contacts.map((contact, i) => {
                                return <ContactRow key={i} contact={contact}/>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
