import React, { Component } from "react";
import { NavLink as RouterNavLink, Link } from "react-router-dom";
import {
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logout from "../functions/logout";
import SalesJSON from "../../content/sales.json";
import { FaTimes } from "react-icons/fa";
import hidePagesJSON from "../../content/hidePages.json";
import SectorJSON from "../../content/sector.json";

function LoginButtonsSmallScreen(props) {
  const isLoggedIn = props.data ? true : false;
  const { data, distanceFromTop, closeToggle } = props;
  if (isLoggedIn) {
    return (
      <Nav className="ml-auto user small-login-block">
        <NavItem
          className={`first-button mr-2 mb-2  ${
            distanceFromTop.distanceFromTop * -1 > 272
              ? "border-white"
              : "border-blue"
          }`}
        >
          <NavLink
            onClick={closeToggle}
            activeClassName="selected"
            tag={RouterNavLink}
            to="/profile"
          >
            My Profile
          </NavLink>
        </NavItem>
        <NavItem className="second-button mb-2 mr-2">
          <button
            className="nav-link sign-up"
            onClick={() => {
              logout(data);
              closeToggle();
            }}
          >
            Logout
          </button>
        </NavItem>
      </Nav>
    );
  }

  return (
    <Nav className="ml-auto user small-login-block">
      <NavItem
        className={
          distanceFromTop.distanceFromTop * -1 > 272
            ? "first-button border-white mr-2 mb-2"
            : "first-button border-blue mr-2 mb-2"
        }
      >
        <NavLink
          onClick={closeToggle}
          activeClassName="selected"
          to={{ pathname: "/login", state: { page: window.location.pathname } }}
          tag={RouterNavLink}
        >
          Login
        </NavLink>
      </NavItem>
      <NavItem className="second-button mb-2 mr-2">
        <NavLink
          onClick={closeToggle}
          activeClassName="selected"
          tag={RouterNavLink}
          to={{
            pathname: "/signup",
            state: { page: window.location.pathname },
          }}
        >
          Sign Up
        </NavLink>
      </NavItem>
    </Nav>
  );
}

export default class MobileNavbar extends Component {
  render() {
    const { distanceFromTop, data, signUpUrl, loginUrl } = this.props.data;
    const {
      toggle,
      handleCloseMenu,
      closeToggle,
      handleClickMenu,
      DetectIsSelected,
    } = this.props;
    const { clickedId, isOpen, fullHeight } = this.props.stateData;
    const userType =
      data && data["cognito:groups"] && data["cognito:groups"][0];
    const location = window.location.pathname;
    return (
      <>
        <div className="w-100 d-lg-none">
          <div className="container display-flex">
            <NavbarBrand
              className="d-flex mr-0"
              onClick={closeToggle}
              tag={Link}
              to="/"
            >
              <img
                className="logo"
                src={
                  distanceFromTop.distanceFromTop * -1 > 272
                    ? "/images/logo-white.png"
                    : "/images/logo.png"
                }
                alt="IoT Nation logo"
              />
            </NavbarBrand>
            {(userType === "corporate" ||
              userType === "pro" ||
              userType === "premium") && (
              <Link
                to="/companies"
                className="user-type-block rounded-6 ml-1 my-auto d-flex black-link"
              >
                <div className="font-s-14 font-weight-bold m-auto">
                  {userType === "corporate"
                    ? "CORP"
                    : userType === "pro"
                    ? "PRO "
                    : "PREM"}
                </div>
              </Link>
            )}
            {!isOpen && (
              <NavbarToggler className="margin-right" onClick={toggle} />
            )}
          </div>
        </div>
        <div className="d-lg-none collapse-block mobile-block">
          <Collapse
            isOpen={isOpen}
            className={`${fullHeight ? "full-height" : ""}`}
            navbar
          >
            <Nav navbar>
              <NavItem className="close-nav-block w-100 border-bottom d-flex">
                {isOpen && (
                  <FaTimes
                    className="close-nav my-auto ml-auto mr-4 pointer"
                    onClick={toggle}
                  />
                )}
              </NavItem>
              {userType === "admin" && (
                <UncontrolledDropdown className="tablet">
                  <DropdownToggle
                    className={
                      location.includes("/admin/activationcode")
                        ? "selected"
                        : ""
                    }
                    nav
                    caret
                  >
                    Admin
                  </DropdownToggle>
                  <DropdownMenu className="p-0">
                    <DropdownItem>
                      <NavLink
                        onClick={() => {
                          closeToggle();
                          handleCloseMenu();
                        }}
                        activeClassName="selected"
                        tag={RouterNavLink}
                        to="/admin/activationcode"
                      >
                        Company Profiles
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        href={process.env.REACT_APP_REDIRECT_URL}
                        target="_blank"
                        className="nav-link"
                      >
                        UGC panel
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <UncontrolledDropdown className="tablet">
                <DropdownToggle
                  className={
                    location === "/reports" || location === "/iotace"
                      ? "selected"
                      : ""
                  }
                  nav
                  caret
                >
                  Products
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      exact
                      to="/iotace"
                    >
                      IoT Ace
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/reports"
                    >
                      Reports
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="tablet">
                <DropdownToggle
                  className={
                    location === "/comapnies" ||
                    location === "/map" ||
                    location.includes("/casestudies") ||
                    location === "/blog"
                      ? "selected"
                      : ""
                  }
                  nav
                  caret
                >
                  Resources
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  <div
                    onClick={() => handleClickMenu(1)}
                    className={`px-3 py-1 px-md-4 py-md-2 pointer ${
                      location.includes("/casestudies")
                        ? "selected second-link"
                        : "nav-link"
                    } `}
                  >
                    Case Studies
                  </div>
                  <div className="dropdown-item">
                    <div
                      className={
                        clickedId === 1 ? "Hover-Nave-open" : "Hover-Nave"
                      }
                    >
                      <div className="pl-3">
                        {SalesJSON.map((item, key) => (
                          <NavLink
                            key={key}
                            className="pr-0"
                            onClick={() => {
                              closeToggle();
                              handleCloseMenu();
                            }}
                            activeClassName="selected"
                            tag={RouterNavLink}
                            to={`/casestudies/${item.slug}`}
                          >
                            {item.navbar}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/companies"
                    >
                      Companies
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/map"
                    >
                      IoT Map
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/blog"
                    >
                      Blog
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {userType === "admin" && (
                <UncontrolledDropdown className="tablet">
                  <DropdownToggle
                    className={location.includes("/sector/") ? "selected" : ""}
                    nav
                    caret
                  >
                    Sector
                  </DropdownToggle>
                  <DropdownMenu className="p-0">
                    <div
                      onClick={() => handleClickMenu(2)}
                      className={`px-3 py-1 px-md-4 py-md-2 pointer ${
                        DetectIsSelected(SectorJSON, location, "Verticals")
                          ? "selected second-link"
                          : "nav-link"
                      } `}
                    >
                      Verticals
                    </div>
                    <div className="dropdown-item">
                      <div
                        className={
                          clickedId === 2 ? "Hover-Nave-open" : "Hover-Nave"
                        }
                      >
                        <div className="pl-3">
                          {SectorJSON.map(
                            (item, key) =>
                              item.type === "Verticals" && (
                                <NavLink
                                  key={key}
                                  onClick={handleCloseMenu}
                                  activeClassName="selected"
                                  tag={RouterNavLink}
                                  to={`/sector/${item.title
                                    .replace(/ \/ /g, "-")
                                    .replace(/ & /g, "-and-")
                                    .replace(/ /g, "-")}`}
                                >
                                  {item.title}
                                </NavLink>
                              )
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() => handleClickMenu(3)}
                      className={`px-3 py-1 px-md-4 py-md-2 pointer ${
                        DetectIsSelected(SectorJSON, location, "Technologies")
                          ? "selected second-link"
                          : "nav-link"
                      } `}
                    >
                      Technologies
                    </div>
                    <div className="dropdown-item">
                      <div
                        className={
                          clickedId === 3 ? "Hover-Nave-open" : "Hover-Nave"
                        }
                      >
                        <div className="pl-3">
                          {SectorJSON.map(
                            (item, key) =>
                              item.type === "Technologies" && (
                                <NavLink
                                  key={key}
                                  onClick={handleCloseMenu}
                                  activeClassName="selected"
                                  tag={RouterNavLink}
                                  to={`/sector/${item.title
                                    .replace(/ \/ /g, "-")
                                    .replace(/ & /g, "-and-")
                                    .replace(/ /g, "-")}`}
                                >
                                  {item.title}
                                </NavLink>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown className="tablet">
                <DropdownToggle
                  className={
                    location.includes("/events") ||
                    location.includes("/usecases") ||
                    location.includes("/competitions")
                      ? "selected"
                      : ""
                  }
                  nav
                  caret
                >
                  Community
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      exact
                      to="/events"
                    >
                      Events
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/usecases"
                    >
                      Industry Use Cases
                    </NavLink>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/competitions"
                    >
                      Competitions
                    </NavLink>
                  </DropdownItem> */}
                  <DropdownItem>
                    <a
                      target="_blank"
                      className="nav-link"
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      href="https://www.meetup.com/pro/iotnation"
                    >
                      Chapter Meetups
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="tablet">
                <DropdownToggle
                  className={
                    location.includes("/about") ||
                    location.includes("/partners")
                      ? "selected"
                      : ""
                  }
                  nav
                  caret
                >
                  Company
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      exact
                      to="/about"
                    >
                      About Us
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      to="/partners"
                    >
                      Partners
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={() => {
                        closeToggle();
                        handleCloseMenu();
                      }}
                      activeClassName="selected"
                      tag={RouterNavLink}
                      exact
                      to="/about"
                    >
                      Contact Us
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <LoginButtonsSmallScreen
              distanceFromTop={distanceFromTop}
              closeToggle={closeToggle}
              data={data}
              loginUrl={loginUrl}
              signUpUrl={signUpUrl}
            />
          </Collapse>
        </div>
      </>
    );
  }
}
