import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import {Helmet} from "react-helmet";
import style from "./Report.module.scss"
import _ from "lodash"

const Report = (props) => {

  const { dataJSON } = props

//   useEffect(() => {
//     var input = document.querySelector("#phoneNumber");
//     iti = window.intlTelInput(input, {separateDialCode: true, utilsScript: '/js/utils.js'});
//   }, [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Report | IoT Nation</title>
      </Helmet>
      <Breadcrumbs last="IoT Nation Report" />
      <div className="top-panel">
        <div className="title mx-auto">{dataJSON["page-title"]}</div>
      </div>
      {
        dataJSON &&
        <div className="position-relative">
          <div className="my-3 m container">
            <div className="col-12 col-md-6 col-xl-7 px-0">
              <div className="font-s-18">{ dataJSON.subheading }</div>
              <div className="h2 font-weight-bold my-3">{ dataJSON.title }</div>
              <div className="line-h-17">{ dataJSON.description }</div>
              <div className="my-3 row">
                <img
                  className={`mx-auto col-6 col-sm-3 ${style.image}`}
                  src={dataJSON.thumbnail ? dataJSON.thumbnail : "/images/PDF_report.svg"}
                  alt=""
                />
                <div className="col-12 col-sm-9 line-h-17 font-weight-bold my-auto">
                  {
                    dataJSON.keyPoints && Object.getOwnPropertyNames(dataJSON.keyPoints).map((item, key) => (
                      <div key={`firstLvl-${key}`}>
                        <div className="d-flex">
                            <div className={`${style.dot} mt-2 mr-2 mr-md-3`} /> <div>{item}</div>
                        </div>
                        {
                          !_.isEmpty(dataJSON.keyPoints[item]) && Object.getOwnPropertyNames(dataJSON.keyPoints[item]).map((item2, key2) => (
                            <div key={`secondLvl-${key2}`}>
                                <div className="d-flex">
                                    <div className={`${style.empty_dot} mt-2 mr-2 mr-md-3 ml-2 ml-md-4`} /> <div>{item2}</div>
                                </div>
                                {
                                    !_.isEmpty(dataJSON.keyPoints[item][item2]) 
                                    && Object.getOwnPropertyNames(dataJSON.keyPoints[item][item2]).map((item3, key3) =>(
                                        <div key={`thirdLvl-${key3}`} className="d-flex">
                                            <div className={`${style.dot} mt-2 mr-2 mr-md-3 ml-3 ml-md-5`} /> <div>{item3}</div>
                                        </div>
                                    ))
                                }
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="d-flex">
                  <a className="text-decoration-none mx-auto" target="_blank" href={dataJSON["button-link"]}>
                      {
                          dataJSON.button && (
                              <button className="custom-button background-blue-2 rounded py-1">
                                  <div className="custom-button background-blue-2 py-2 px-5">{dataJSON.button}</div>
                              </button>
                          )
                      }
                  </a>
              </div>
            </div>
          </div>
          {/* <div className="pt-5 px-4 pb-4 background-blue">
              <div className="h5 mb-3 px-3 text-center text-white">Fill out this from to view the full report.</div>
              <form onSubmit={handleSubmit}>
                  <div className="row">
                      <div className="form-group col-6">
                          <input value={formData.firstName} name="firstName" required type="firstName" className="form-control" placeholder="First Name" onChange={handleInputChange}/>
                      </div>
                      <div className="form-group col-6">
                          <input value={formData.lastName} name="lastName" required type="lastName" className="form-control" placeholder="Last Name" onChange={handleInputChange}/>
                      </div>
                  </div>
                  <div className="form-group">
                      <input value={formData.email}  name="email" required type="email" className="form-control" placeholder="Company Email" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group">
                      <input value={formData.phoneNumber} name="phoneNumber" id="phoneNumber" required type="phone" className="form-control" placeholder="Phone Number" onChange={() => handleChangePhone(iti)}/>
                  </div>
                  <div className="form-group">
                      <input value={formData.jobTitle} name="jobTitle" required type="jobTitle" className="form-control" placeholder="Job Title" onChange={handleInputChange}/>
                  </div> 
                  <div className="form-group">
                      <input value={formData.company} name="company" required type="company" className="form-control" placeholder="Company" onChange={handleInputChange}/>
                  </div> 
                  <div className="form-group my-5 d-flex">
                      <button className="orange-button py-2 px-4 mx-auto" type="submit">Download the Report</button>
                  </div>
              </form>
          </div> */}
        </div>
      }
    </div>
  )
}

export default Report
